import { Box, Grid, Typography, useTheme } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import WarningIcon from "@mui/icons-material/Warning";
import { FaSimCard } from "react-icons/fa"; // For Agent Line Number SIM icon
import { themes } from "../../../context";
import { useGetDigitalKycRecords } from "../hook/digitalKycHooks";
import { getValueFromLangJson } from "../../../helpers/common_helper";

const KycInfo = () => {
  const theme = useTheme();
  const { data } = useGetDigitalKycRecords();
  const custInfo = data?.cust_info;
  const custInfoIcons: Record<
    "name" | "account" | "eligibility" | "first_fa_limit" | "distributor_code",
    JSX.Element
  > = {
    name: <PersonIcon />,
    account: (
      <Box>
        <img
          src={`/img/${custInfo?.acc_prvdr_code}.png`}
          style={{ height: "35px", width: "40px", borderRadius: "50%" }}
        />
      </Box>
    ),
    eligibility: <CreditCardIcon />,
    first_fa_limit: <WarningIcon />,
    distributor_code: <LocalPhoneIcon />,
  };

  type CustInfoKeys = keyof typeof custInfoIcons;
  return (
    <Box sx={{ p: 2, borderRadius: 2 }}>
      {custInfo && (
        <Grid container spacing={4} justifyContent="center">
          {Object.entries(custInfo).map(
            ([key, value], idx) =>
              key !== "acc_prvdr_code" && (
                <Grid item key={key} mx={2}>
                  <Box display="flex" alignItems="center">
                    <Box sx={{ color: theme.palette.text.primary, mx: 1 }}>
                      {custInfoIcons[key as CustInfoKeys] ?? null}
                    </Box>
                    <Box>
                      <Typography
                        variant="caption"
                        color="grey.400"
                        sx={{ fontSize: "0.8rem" }}
                      >
                        {getValueFromLangJson(key)}
                      </Typography>
                      <Typography
                        variant="body1"
                        fontWeight={600}
                        sx={{ fontSize: "0.87rem" }}
                        color="white"
                      >
                        {value}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              )
          )}
        </Grid>
      )}
    </Box>
  );
};

export default KycInfo;
