import moment from "moment";
import * as yup from "yup";

interface FormValues {
  nationalId?: { uploadedRes?: { photo_national_id?: { is_editable?: boolean } } }[];
  is_interested?: boolean | null;
  not_interested?: boolean | null;
  rdbCertificate?: { uploadedRes?: { photo_rdb_certificate?: { is_editable?: boolean } } }[];
  first_name?: string;
  last_name?: string;
  dob?: string;
  id_proof_num?: string;
  gender?: string;
  company_code?: string;
  enterprise_name?: string;
  owner_name?: string;
  registration_date?: Record<string, string>;
}

const FormSchema = yup
  .object()
  .shape({
    nationalId: yup.array().min(1, "National ID is required"),
    is_interested: yup.boolean().nullable(),
    not_interested: yup.boolean().nullable(),

    registration_date: yup
      .mixed() // Using mixed because it's a moment object, not a string
      .test("valid-date", "Invalid registration date", function (value) {
        const { parent } = this; // 'this' gives you access to the context of the validation
        const isInterested = parent?.is_interested; // Access the 'is_interested' value from the parent
        if (isInterested) {
          if (!value) return false; // Ensure the value is not empty if required
          return moment(value).isValid(); // Validate using moment
        }
        return true; // If 'is_interested' is not true, skip validation
      }),
    rdbCertificate: yup.array().when(["is_interested", "not_interested"], {
      is: (values: Partial<FormValues> | undefined | null) => {
        const isInterested = values?.is_interested ?? false;
        const notInterested = values?.not_interested ?? false;
        return isInterested === true && notInterested !== true;
      },
      then: (schema) => schema.min(1, "RDB Certificate is required"),
      otherwise: (schema) => schema.notRequired(),
    }),

    // registration_date: yup
    //   .mixed() // Using mixed because it's a moment object, not a string
    //   .when("is_interested", {
    //     is: true, // Only validate if is_interested is true
    //     then: yup
    //       .mixed()
    //       .test(
    //         "valid-date",
    //         "Invalid registration date",
    //         (value) => {
    //           if (!value) return true; // If no value, pass validation (or make it required if needed)
    //           return moment(value).isValid(); // Validate using moment
    //         }
    //       ),
    //     otherwise: yup.mixed().notRequired(), // Skip validation if is_interested is not true
    //   }),

  })
  .test(
    "validate-editable-fields",
    "Required fields are missing",
    function (values: FormValues | undefined | null) {
      if (!values) return true;
      // National ID Editable Check
      const nationalId = values.nationalId || [];
      if (
        nationalId.length > 0 &&
        nationalId[0]?.uploadedRes?.photo_national_id?.is_editable
      ) {
        const requiredFields: (keyof FormValues)[] = ["first_name", "last_name", "dob", "id_proof_num", "gender"];
        for (const field of requiredFields) {
          if (!values[field]) {
            return this.createError({
              path: field,
              message: `${field.replace("_", " ")} is required`,
            });
          }
        }
      }

      // RDB Certificate Editable Check (Only when is_interested is true)
      const rdbCertificate = values.rdbCertificate || [];
      if (

        values.is_interested &&
        rdbCertificate.length > 0 &&
        rdbCertificate[0]?.uploadedRes?.photo_rdb_certificate?.is_editable
      ) {
        const requiredFields: (keyof FormValues)[] = ["company_code", "enterprise_name", "owner_name"];
        for (const field of requiredFields) {
          if (!values[field]) {
            return this.createError({
              path: field,
              message: `${field.replace("_", " ")} is required`,
            });
          }
        }
      }

      return true;
    }
  )
  .test(
    "not-interested-valid",
    "Form is valid if 'not_interested' is true",
    (values: FormValues | undefined | null) => {
      return values?.not_interested === true || true;
    }
  );

export default FormSchema;