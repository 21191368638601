import { Box, List, ListItem, ListItemText } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useSoftPhoneCtx } from "../../../../context/softPhoneCtx/useSoftPhoneCtx";
import { common_req, get_path } from "../../../../helpers/common_helper";
import { call_fetch } from "../../../../helpers/fetch_helper";
import { lbl_amt } from "../../../../helpers/common_helper";
import MUIAccordion from "../../../../MUI/Components/MUIAccordion";
import RHFCheckBoxField from "../../../CommonComponent/rhfComponents/rhfCheckBoxField";
import TextWrappedByHrTag from "../../../CommonComponent/textWrappedByHrTag";
import { BodyVarientTwoTypo } from "../../../CommonComponent/typography";
import useGetInquiryList from "../queryHooks/useGetInquiryList";
import { useGetNewCustOrientationListByTaskId } from "../queryHooks/useGetNewCustOrientationList";

/**
 * @param setdisableForm @param setDisableForm (used during checkbox selection, when a status update API is called, all remaining fields are disabled during that period.)
 * @returns the accordion with checkbox, inside the acc body the content is shown
 */
const CustInquiryChecklistUI = ({
  setdisableForm,
  eligibleAmount,
}: {
  setdisableForm: Function;
  eligibleAmount: string;
}) => {
  // ctx
  const {
    softPhoneState: {
      callLogDetails: { selectedNewCustOrientationtaskId },
    },
  } = useSoftPhoneCtx();

  // query
  const { newCustData } = useGetNewCustOrientationListByTaskId({
    taskId: selectedNewCustOrientationtaskId,
  });
  const leadId = newCustData?.lead_id;
  const { inquiryList } = useGetInquiryList({
    leadId: leadId,
  });

  // form ctx
  const { getValues } = useFormContext();

  // const
  const reqObj = common_req({} as any);
  delete reqObj.access_token;

  // handler
  const handleOnChangeCb = async () => {
    const inquries = getValues("inquries");
    setdisableForm(true);
    await call_fetch(
      // @ts-ignore
      `${get_path("app")}/task/update_orientation_flags`,
      {
        ...reqObj,
        task_id: selectedNewCustOrientationtaskId,
        task_type: "new_cust_orientation",
        inquries,
      }
    ).finally(() => setdisableForm(false));
  };

  return (
    <>
      <TextWrappedByHrTag text={"Customer Inquiry Checklist"} />
      {Object.keys(inquiryList!).map((key, idx) => (
        <React.Fragment key={idx}>
          <MUIAccordion
            titleUI={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <BodyVarientTwoTypo
                  text={inquiryList ? inquiryList[key].title : "..."}
                  overRideStyles={{
                    fontSize: "16px",
                    color: "#B2B2B2",
                    fontWeight: 500,
                    pl: 2,
                  }}
                />
                <RHFCheckBoxField
                  name={`inquries.${key}`}
                  onClick={(e) => e.stopPropagation()}
                  customOnchangeCb={handleOnChangeCb}
                />
              </Box>
            }
            body={
              <Box
                sx={{
                  py: 2,
                  px: 4,
                  backgroundColor: "#202940",
                  border: "solid 1px #919191",
                  borderRadius: "5px",
                }}
              >
                <List>
                  {inquiryList &&
                    inquiryList[key].content.map((inq, idx) => (
                      <ListItem disableGutters key={idx}>
                        <ListItemText
                          sx={{
                            "& span": { fontWeight: 500, fontFamily: "Inter" },
                          }}
                        >
                          <InquiryListUI
                            inq={inq}
                            inqKey={key}
                            eligibleAmount={eligibleAmount}
                          />
                        </ListItemText>
                      </ListItem>
                    ))}
                </List>
              </Box>
            }
          />
        </React.Fragment>
      ))}
    </>
  );
};

export default CustInquiryChecklistUI;

const InquiryListUI = ({
  inq,
  inqKey,
  eligibleAmount,
}: {
  inq: string;
  inqKey: string;
  eligibleAmount: string;
}) => {
  let textToShow = inq;
  if (inqKey === "fa_eligibility_and_confirmation") {
    // for inqKey fa_eligibility_and_confirmation we will have XXXXXX before UGX
    // need to replace that value with the actual user's elligible amount
    textToShow = inq.replace(
      /XXXXXX/g,
      String(eligibleAmount ? lbl_amt(eligibleAmount) : "...")
    );
  }
  return <>• {textToShow}</>;
};
