import React, { useState, useEffect } from 'react';
import { Box, Typography, Badge, useTheme, IconButton, colors } from '@mui/material';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FaCheckCircle, FaRegTimesCircle } from 'react-icons/fa';
import { BiSolidLock } from 'react-icons/bi';
import { get_time_diff, Homeacc_prvdrLogoselector, lbl_amt, round_off_value, RoundOffSplitter, timeDiff } from '../../helpers/common_helper';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Link, useNavigate } from 'react-router-dom';
import { get } from '../../helpers/storage_helper';
interface WalletStatementProps {
  acc_list: Array<{
    acc_prvdr_code: string;
    acc_number: string;
    float_acc_stmt_end_time: string;
    balance: number;
    status: string;
    id: any;
  }>;
  server_time: string;
}

const WalletStatements: React.FC<WalletStatementProps> = ({ acc_list, server_time }) => {
  const theme = useTheme();
  const providerItemsPerPage = 4;
  const [accPrvdrList, setAccPrvdrList] = useState<string[]>([]);
  const [currentProvider, setCurrentProvider] = useState<string>('');
  const [providerPage, setProviderPage] = useState(0);
  const navigate = useNavigate()

  console.log('acc_list', acc_list)
  useEffect(() => {
    if (acc_list?.length > 0) {
      const uniqueProviders = Array.from(
        new Set(
          acc_list
            .sort((a, b) => (a.status === 'failed' ? -1 : b.status === 'failed' ? 1 : 0))
            .map(item => item.acc_prvdr_code)
        )
      );
      setAccPrvdrList(uniqueProviders);
    }
  }, [acc_list]);

  useEffect(() => {
    if (!currentProvider) {
      const failedProvider = acc_list.find(item => item.status === 'failed');
      setCurrentProvider(failedProvider ? failedProvider.acc_prvdr_code : accPrvdrList[0]);
    }
  }, [acc_list, accPrvdrList, currentProvider]);

  const displayedProviders = accPrvdrList.slice(providerPage * providerItemsPerPage, (providerPage + 1) * providerItemsPerPage);

  const handleProviderPageChange = (direction: 'left' | 'right') => {
    setProviderPage(prev => {
      if (direction === 'right' && (providerPage + 1) * providerItemsPerPage < accPrvdrList.length) {
        return prev + 1;
      }
      if (direction === 'left' && providerPage > 0) {
        return prev - 1;
      }
      return prev;
    });
  };

  const handleProviderSelect = (provider: string) => {
    setCurrentProvider(provider);
  };

  const currentProviderStatements = acc_list.filter(item => item.acc_prvdr_code === currentProvider);

  const statusHandler = (status: string, time: string | null = null) => {
    const renderIcon = (icon: JSX.Element, color: string) => (
      <p className="no-margin p-0">{React.cloneElement(icon, { style: { color } })}</p>
    );

    if (status === 'imported') {
      return renderIcon(<FaCheckCircle className="icon icon-tick" />, 'green');
    }

    if (status === 'locked' && time) {
      const diff = Math.round((new Date(server_time).getTime() - new Date(time).getTime()) / (1000 * 60));
      return renderIcon(<BiSolidLock className="icon icon-wrong" />, diff < 240 ? '#ffcb00' : '');
    }

    return renderIcon(<FaRegTimesCircle className="icon icon-wrong" />, 'red');
  };

  const getProviderStatusBadge = (provider: string) => {
    return acc_list.some(item => item.acc_prvdr_code === provider && item.status === 'failed') ? (
      <PriorityHighIcon sx={{ color: 'red' }} />
    ) : null;
  };

  const stmt_text = {
    color: theme.palette.text.primary,

  }

  return (
    <Box sx={{ p: 3, height: "40vh", position: 'relative', background: '#202940' }}>
      <Typography component="h5" sx={{ fontWeight: 500, textAlign: 'center', color: theme.palette.text.primary }}>
        Wallet Statements
      </Typography>

      <Box display="flex" justifyContent="space-around" alignItems="center" mb={2}>
        {/* Left Arrow */}
        <IconButton className='p-0'
          sx={{ display: accPrvdrList.length > 4 ? "" : "none", p:0 }}
          onClick={() => handleProviderPageChange('left')}
          disabled={providerPage === 0}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>

        <Box display="flex" justifyContent="center" alignItems="center" flexWrap="wrap" gap={2} sx={{ mt: 1 }}>
          {displayedProviders.map((provider, index) => (
            <Badge
              key={index}
              color="primary"
              badgeContent={getProviderStatusBadge(provider)}
              showZero
              onClick={() => handleProviderSelect(provider)}
              sx={{ cursor: 'pointer', border: currentProvider === provider ? '2px solid white' : '', borderRadius: '5px' }}
            >
              <Homeacc_prvdrLogoselector logotype={provider} />
            </Badge>
          ))}
        </Box>

        <IconButton className='p-0'
          sx={{ display: accPrvdrList.length > 4 ? "" : "none" ,p:0}}

          onClick={() => handleProviderPageChange('right')}
          disabled={(providerPage + 1) * providerItemsPerPage >= accPrvdrList.length}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      </Box>


      {/* Statements */}
      {currentProvider && (
       <Box mt={3}>
       {currentProviderStatements.map((item, idx) => (
         <Box
           key={idx}
           display="grid"
           gridTemplateColumns="repeat(4, 1fr)" 
           alignItems="center"
           sx={{
             mb: 1,
             p: 2,
             background: '#111936',
             borderRadius: 1,
             gap: 2, 
           }}
         >
           <Typography
             variant="body2"
             sx={{
               cursor: 'pointer',
              //  @ts-ignore
               color: theme.palette.custom_colors.url, 
             }}
             onClick={() => navigate(`/account/${item.id}/statement`)}
           >
             {item.acc_number}
           </Typography>
           <Typography variant="body2" sx={stmt_text}>
             {item.float_acc_stmt_end_time ? get_time_diff(server_time, item.float_acc_stmt_end_time) : '-'}
           </Typography>
           <Typography variant="body2" sx={stmt_text}>
             {round_off_value(item.balance ?? 0)}
           </Typography>
           <Typography variant="body2">
             {statusHandler(item.status, item.float_acc_stmt_end_time)}
           </Typography>
         </Box>
       ))}
     </Box>
     
      )}
    </Box>
  );
};

export default WalletStatements;
