import { Skeleton, SxProps } from "@mui/material";

const SkeletonLoader = ({
  variant,
  height,
  width,
  overRideStyles,
}: {
  variant: "text" | "rectangular" | "rounded" | "circular";
  height: string | number | undefined;
  width: string | number | undefined;
  overRideStyles?: SxProps;
}) => {
  return (
    <Skeleton
      variant={variant}
      height={height}
      width={width}
      sx={{ borderRadius: 2, ...overRideStyles }}
    />
  );
};

export default SkeletonLoader;