import {
  ATTEND_CALL_DISPATCH,
  DispatchProps,
  HANG_UP_CALL_DISPATCH,
  OPEN_CALL_STATUS_MODAL_DISPATCH,
  SET_SOFT_PHONE_CLIENT_STATUS_DISPATCH,
  SoftPhoneState,
  TRIGGER_CALL_LOG_MODAL_DISPATCH,
  TRIGGER_CALL_LOG_MODAL_DISPATCH_FOR_NEW_CUST_ORIENTATION,
  TRIGGER_CALL_LOG_MODAL_FOR_PREV_UNLOGGED_CALL_DISPATCH,
  TRIGGER_MANUAL_CALL_LOG,
  UPDATE_CALL_INITIATED_FROM_CURRENT_TAB_DISPATCH,
  UPDATE_CALL_LOG_SCREEN_DISPATCH,
  UPDATE_CALL_START_TIME_DISPATCH,
  UPDATE_CUST_NAME_DISPATCH,
} from "./softPhoneCtx";

function softPhoneReducer(state: SoftPhoneState, action: DispatchProps) {
  const { type, payload } = action;
  switch (type) {
    case OPEN_CALL_STATUS_MODAL_DISPATCH:
      return {
        ...state,
        showCallStatusModal: payload.showCallStatusModal,
        callState: {
          ...state.callState,
          status: payload.callStatus,
        },
        callLogDetails: {
          ...state.callLogDetails,
          phoneNumber: payload.phoneNumber,
          callLogForIncomingOrOutGoing: payload.callLogForIncomingOrOutGoing,
        },
      };

    case HANG_UP_CALL_DISPATCH:
      return {
        ...state,
        showCallStatusModal: payload.showCallStatusModal,
        isCallInitiatedFromCurrentTab: payload.isCallInitiatedFromCurrentTab,
        callState: {
          ...state.callState,
          status: payload.callStatus,
        },
        startTimer: payload.startTimer,
      };

    case TRIGGER_CALL_LOG_MODAL_DISPATCH:
      return {
        ...state,
        showCallLogModal: payload.showCallLogModal,
        callLogDetails: {
          ...state.callLogDetails,
          callLogForIncomingOrOutGoing: payload.callLogForIncomingOrOutGoing,
          phoneNumber: payload.phoneNumber,
          callLogForScreen: payload.callLogForScreen,
          callStartedTime: payload.callStartedTime,
        },
      };

    case TRIGGER_CALL_LOG_MODAL_FOR_PREV_UNLOGGED_CALL_DISPATCH:
      return {
        ...state,
        showCallLogModal: payload.showCallLogModal,
        callLogDetails: {
          ...state.callLogDetails,
          callLogForIncomingOrOutGoing: payload.callLogForIncomingOrOutGoing,
          phoneNumber: payload.phoneNumber,
          callLogForScreen: payload.callLogForScreen,
          callStartedTime: payload.callStartedTime,
          // selectedNewCustOrientationtaskId:
          //   payload.selectedNewCustOrientationtaskId,
        },
      };

    case ATTEND_CALL_DISPATCH:
      return {
        ...state,
        callState: {
          ...state.callState,
          status: payload.callStatus,
        },
        startTimer: payload.startTimer,
      };

    case SET_SOFT_PHONE_CLIENT_STATUS_DISPATCH:
      return {
        ...state,
        isSoftPhoneClientReady: payload.isSoftPhoneClientReady,
        isATsInstanceBelongsToCurrentTab:
          payload.isATsInstanceBelongsToCurrentTab,
      };

    case UPDATE_CALL_LOG_SCREEN_DISPATCH:
      return {
        ...state,
        callLogDetails: {
          ...state.callLogDetails,
          callLogForScreen: payload.callLogForScreen,
          churnOrDeliquencyId: payload.churnOrDeliquencyId,
          selectedNewCustOrientationtaskId: payload.taskId,
        },
      };

    case UPDATE_CALL_START_TIME_DISPATCH:
      return {
        ...state,
        callLogDetails: {
          ...state.callLogDetails,
          callStartedTime: payload.callStartedTime,
        },
      };

    case UPDATE_CALL_INITIATED_FROM_CURRENT_TAB_DISPATCH:
      return {
        ...state,
        isCallInitiatedFromCurrentTab: payload.isCallInitiatedFromCurrentTab,
      };

    case UPDATE_CUST_NAME_DISPATCH:
      return {
        ...state,
        callLogDetails: {
          ...state.callLogDetails,
          custName: payload.custName,
        },
      };

    case TRIGGER_CALL_LOG_MODAL_DISPATCH_FOR_NEW_CUST_ORIENTATION:
      return {
        ...state,
        showCallLogModal: payload.showCallLogModal,
        callLogDetails: {
          ...state.callLogDetails,
          callLogForScreen: payload.screen,
          selectedNewCustOrientationtaskId:
            payload.selectedNewCustOrientationtaskId,
        },
      };

    case TRIGGER_MANUAL_CALL_LOG:
      return {
        ...state,
        callState: {
          ...state.callState,
        },
        callLogDetails: {
          ...state.callLogDetails,
          callLogForScreen: payload.screen,
        },
        manualCall: {
          ...state.manualCall,
          isManualCallLog: payload.isManualCallLog,
        },
      };

    default:
      return state;
  }
}

export default softPhoneReducer;
