import { Box } from "@mui/material";
import { HeaderVarientH2Typo } from "./typography";

const TextWrappedByHrTag = ({ text }: { text: string }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box component={"hr"} sx={{ flex: 1, mx: 1 }} />
      <HeaderVarientH2Typo
        text={text}
        overRideStyles={{ color: "#fff!important" }}
      />
      <Box component={"hr"} sx={{ flex: 1, mx: 1 }} />
    </Box>
  );
};

export default TextWrappedByHrTag;
