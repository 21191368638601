import { useQuery } from "@tanstack/react-query";
import { common_req } from "../../../../helpers/common_helper"
import { RhfOptionType } from "../../../../type"
import { getNameList } from "../../../../actions/common_actions";

type RMSupportGetResProps = { data: RhfOptionType[] };


const UseGetRMSupporListViaQuery = () => {
    
    const {
        data: rmsList,
        isError,
        isLoading
    } = useQuery<RMSupportGetResProps>({
        queryKey:['list_of_rm_support'],
        queryFn: ()=>
            getNameList(
                'rm_support',
                {
                    ...common_req(),
                    status: "enabled"
                }
            ),
        staleTime: 1000 * 60 * 5,
    })

    return { rmsList, isError, isLoading };
}

export default UseGetRMSupporListViaQuery;