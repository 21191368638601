import React, { lazy, LazyExoticComponent, Suspense } from "react";

import { resolvePromiseWithDelay } from "../../helpers/common_helper";
import { Outlet } from "react-router-dom";
import { Box, useTheme } from "@mui/material";
import FallBackUI from "./Components/fallBackUI";
// import { DigitalKycProvider } from "./context/digitalKycCtxProvider";

// lazy
const DigitalKYC: LazyExoticComponent<React.ComponentType> = lazy(() =>
  resolvePromiseWithDelay(import("./Components/digitalKYC"))
);

const PhoneCom = () => {
  const theme = useTheme();
  
  return (
    <Suspense fallback={<FallBackUI />}>
        <Box
          sx={{
            background: "#01062C",
            px: 3,
            py: 5,
            m: 4,
            minHeight: "calc(100vh - 123px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            overflow: "hidden",
            borderRadius: "10px",
          }}
        >
          <DigitalKYC />
         
        </Box>
      
    </Suspense>
  );
};

export default PhoneCom;
