import FlowComponent from '../common/component/core/FlowComponent';
import React from 'react';
import '../../styles/flow.css';
import CommonReactTable from '../../helpers/commonReact_table';
import { getRMWiseReport} from '../../actions/report_actions';
import {format_date, title_case, lbl_amt, rt_algn, getValueFromLangJson} from '../../helpers/common_helper';
import Accordion from 'react-bootstrap/Accordion';
import {Card} from 'react-bootstrap';
import {BsChevronDown} from 'react-icons/bs';


class RMReport extends FlowComponent {

	state = {
        rowdatas :[],
        copy : [],
        columns : [],
        show_table: false
    }

    componentWillMount() {
        this.drawTable()
    }

    drawTable(){
        getRMWiseReport(this.req())
        .then((response) => {
            this.resp(response)
            if(!response){return}
            if(response){
                this.setState({refresh: false});
                this.setState(state => state.rowdatas = response.data)
                this.setState(state => state.copy = response.data)
                this.setState(state => state.columns =  this.columndatas())
            }
        });
    }

    handleToggle = (e) => {

        switch(e){
            case("report"):
                this.setState(state => state.show_table = !this.state.show_table);
                break;
        }
    }

    columndatas(){
        return[
            {
                Header : `${ getValueFromLangJson("rm_label_short")} Name`,
                id: 'rm_name',
                minWidth:50,

                accessor: row => row.rm_name
            },
            {
                Header : "Agreement Renewal Due",
                id: 'agrmt_renewal_due',
                minWidth:50,
                accessor: row => <div className={'tableAmount_centerAlign'}>{row.agrmt_renewal_due}</div>
            },
            {
                Header : "Aquisition",
                id: 'aquisition',
                minWidth:50,
                accessor: row => <div className={'tableAmount_centerAlign'}>{row.aquisition}</div>
            },
            {
                Header : "Long Overdues (60D+)",
                id: 'last_60_days_overdue',
                minWidth:50,
                accessor: row =>  <div className={'tableAmount_centerAlign'}>{row.last_60_days_overdue}</div>           
            },
            {
                Header : "New Overdue FAs (30D+)",
                id: 'last_30_days_overdue',
                minWidth:50,
                accessor:row => <div className={'tableAmount_centerAlign'}>{row.last_30_days_overdue}</div>
            },

            {
                Header : "FA Ontime Rate (last 30 days)",
                id: 'repayment_rate',
                minWidth:50,
                accessor:row => <div className={'tableAmount_centerAlign'}>{row.repayment_rate}</div>
            },
                    
        ]
    }
   
    render(){ 
        const rm_label = getValueFromLangJson("rm_label_short")

        return (
            <div>
                <Accordion>
                    <Card>
                        <div className="card-header card-header-icon card-header-primary d-flex justify-content-between ">
                            <div>
                                <h4 className="card-title text-white d-inline-block" style={{padding:'15px 0px 25px 0px'}}>{rm_label} Report</h4>
                            </div>
                            <div>
                                <Accordion.Toggle className = "col" as = {Card.Header} eventKey="0" onClick = {()=>this.handleToggle("report")}>
                                    <BsChevronDown className = {this.state.show_table ? "arrow-icon down" : "arrow-icon"} size="18"/>
                                </Accordion.Toggle>
                            </div>
                        </div>
                        {this.state.show_table ?
                            <div className="card-body">
                                <div className="table-responsive">
                                <p className='no-margin text-white'><b>Total Entries : {this.state.copy.length}</b></p>

                                    <BasicReactTable  row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columns} pagination={true}  csvFile={true} FName={`${rm_label} Report`}   default_page_size={20}   /> 
                                </div>
                            </div>
                            : " "
                        }
                    </Card>
                </Accordion>
            </div>
       
        );

    }
}


export default RMReport;
