import { Box, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import {
  Homeacc_prvdrLogoselector,
  lbl_amt,
} from "../../../../helpers/common_helper";
import TextField from "../../../CommonComponent/field/textField";
import TextFieldWithComponentPair from "../../../CommonComponent/field/textFieldWithComponentPair";
import TextWrappedByHrTag from "../../../CommonComponent/textWrappedByHrTag";
import { HeaderVarientH4Typo } from "../../../CommonComponent/typography";
import { OrientationNewCustProps } from "../queryHooks/useGetNewCustOrientationList";

/**
 * @param newCustData
 * @returns the customer information UI
 */
const CustInfoUI = ({
  newCustData,
}: {
  newCustData: OrientationNewCustProps;
}) => {
  return (
    <>
      <TextWrappedByHrTag text={"Customer Info"} />
      <Grid container spacing={2} alignItems="center" textAlign={"start"} p={2}>
        <Grid item xs={6} md={3}>
          <TextField
            label={"Customer Name"}
            val={newCustData?.cust_name ?? ""}
            direction="column"
            pairKeyCase="capitalize"
            pairKeyColor="#B2B2B2"
            pairValCase="capitalize"
            isLoading={false}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextFieldWithComponentPair
            label={"Flow Cust ID"}
            direction="column"
            pairKeyCase="capitalize"
            pairKeyColor="#B2B2B2"
            pairChildren={
              <Link
                to={`/borrower/indiv/view/${newCustData?.cust_id}`}
                target="_blank"
                style={{ color: "#179BB1" }}
              >
                {newCustData?.cust_id}
              </Link>
            }
            isLoading={false}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            label={"RM Name"}
            val={newCustData?.rm_name ?? ""}
            direction="column"
            pairKeyCase="capitalize"
            pairKeyColor="#B2B2B2"
            pairValCase="capitalize"
            isLoading={false}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            label={"RM Number"}
            val={newCustData?.flow_rel_mgr_number ?? ""}
            direction="column"
            pairKeyCase="capitalize"
            pairKeyColor="#B2B2B2"
            pairValCase="capitalize"
            isLoading={false}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextFieldWithComponentPair
            label={"Account Provider"}
            direction="column"
            pairKeyCase="capitalize"
            pairKeyColor="#B2B2B2"
            pairChildren={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <Homeacc_prvdrLogoselector
                  logotype={newCustData?.acc_prvdr_code}
                  product_takeup_print={""}
                  from_stmt={false}
                />
                <HeaderVarientH4Typo
                  text={newCustData?.account_num ?? ""}
                  overRideStyles={{
                    color: "#fff",
                    pl: 2,
                  }}
                />
              </Box>
            }
            isLoading={false}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            label={"Eligibility Amount"}
            val={`${
              newCustData?.eligible_amount
                ? lbl_amt(newCustData?.eligible_amount)
                : "..."
            }`}
            direction="column"
            pairKeyCase="capitalize"
            pairKeyColor="#B2B2B2"
            pairValCase="capitalize"
            isLoading={false}
          />
        </Grid>
        {newCustData?.distributor_code && (
          <Grid item xs={6} md={3}>
            <TextField
              label={"Distributor"}
              val={newCustData?.distributor_code}
              direction="column"
              pairKeyCase="capitalize"
              pairKeyColor="#B2B2B2"
              pairValCase="capitalize"
              isLoading={false}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default CustInfoUI;
