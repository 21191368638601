import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { getLeadPipeline, viewIdentityVerification, viewLead } from "../../../actions/lead_actions";
import { common_req } from "../../../helpers/helper";

type custInfo = {
  agent_line_name: string;
  distributor_code: string;
  acc_prvdr_with_country: string;
  agent_line_number: string;
};


export type FormValues = {
  nationalIdIsCorrect: boolean | undefined;
  rdbIsCorrect: boolean | undefined;
  isOwnedAccount: boolean | undefined;
  first_name: string;
  last_name: string;
  dob: string; // Change to string
  id_proof_num: string;
  gender: string;
  company_code: string;
  enterprise_name: string;
  owner_name: string;
  registration_date: string; // Change to string
};
type NationalId = {
  photo_national_id: string;
  photo_national_id_full_path: string;
  id_proof_num: string;
  first_name: string;
  last_name: string;
  gender: string;
  dob: string;
};

type RdbCertificate = {
  photo_id_proof: {
    path: string;
    value: string;
  };
  rdb_code: string;
  company_code: string;
  owner_name: string;
  enterprise_name: string
  registration_date: string;
  photo_rdb_certificate: string;
  photo_rdb_certificate_full_path: string;
};

type DigitalKycVerificationProps = {
  self_reg_json: {
    photo_national_id: NationalId;
    photo_rdb_certificate: RdbCertificate;
  }
  cust_info: custInfo;
};

export const useGetDigitalKycVerificationInfo = () => {

  const { id } = useParams();
  const { data, isLoading, isError } = useQuery<DigitalKycVerificationProps>({
    queryKey: ["digitalKycInfo"],
    queryFn: async () => {
      const response = await viewIdentityVerification(common_req({ lead_id: id }));
      if (response?.status == "success") {
        console.log('response_data_response_data', response.data)
        return response.data;
      } else {
        throw new Error(response.message || "Failed to fetch lead data");
      }
    },
  });

  return { data, isLoading, isError };
};
