import { useQuery } from "@tanstack/react-query";
import { getCustDetail } from "../../../actions/cs_management_actions";
import { UGA_PHONE_NUM_DIGIT_COUNT } from "../../../consts";

type CustDetGetResProps = {
  data: {
    customer_list: CustDetailProp[];
    is_rm: boolean;
    rm_details: {
      rm_name: string;
      id: string;
    };
  };
};

export type CustDetailProp = {
  customer_since: string;
  biz_name: string;
  tot_loans: number;
  tot_switch: number;
  activity_status: string;
  cust_mobile_num: string;
  mob_nums: {
    alt_biz_mobile_num_1: string;
    alt_biz_mobile_num_2: string;
    mobile_num: string;
    addl_mob_num: {
      holder_name: string;
      mobile_num: string;
    }[];
  };
  cust_dob: string;
  cust_name: string;
  location: string;
  cust_id: string;
  current_loan: {
    loan_doc_id: string;
    paid_amount: string;
    current_os_amount: string;
    currency_code: string;
    status: string;
    due_date: string;
    due_amount: string;
  };
  last_loan: {
    loan_doc_id: string;
    paid_amount: string;
    current_os_amount: string;
    currency_code: string;
    status: string;
    due_date: string;
    due_amount: string;
  };
};

export const useGetCustDetailsByPhNumQuery = ({
  phoneNumber,
}: {
  phoneNumber: string | undefined;
}) => {
  const { isFetching, isFetched, data, isLoading, isError } =
    useQuery<CustDetGetResProps>({
      queryKey: ["selected_ph_num_s_cust_det", phoneNumber],
      queryFn: () =>
        getCustDetail({ mobile_num: phoneNumber, search_by: "mobile_number" }),
      enabled:
        !!phoneNumber && phoneNumber.length === UGA_PHONE_NUM_DIGIT_COUNT,
      staleTime: 1000 * 60 * 0.5, // 30sec cache
    });

  return { isLoading, isFetching, isFetched, data, isError };
};

export const useGetCustDetailsByUserNameQuery = ({
  userName,
  phoneNumber,
}: {
  userName: string;
  phoneNumber: string;
}) => {
  const { isFetching, isFetched, data, isLoading, isError } =
    useQuery<CustDetGetResProps>({
      queryKey: ["selected_ph_num_s_cust_det", userName],
      queryFn: () =>
        getCustDetail({
          req_parameter: userName,
          search_num: phoneNumber,
          search_by: "cust_name",
        }),
      enabled: !!userName,
    });

  return { isLoading, isFetching, isFetched, data, isError };
};
