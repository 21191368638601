import * as React from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { Box, Typography } from "@mui/material";
import ColorlibStepIcon, { ColorlibConnector, ColorlibStepLabel } from "./StepperComponents";

interface StepItem {
  key: string;
  title: string;
  icon: React.ReactElement;
}

interface MUIStepperProps {
  steps: StepItem[];
  isStepCompleted: number[];
  isStepFailed: number[];
  activeStep?: number;
  stepHandler?: (key: string) => void;
  disableStepOnClick?:boolean
}

const MuiStepper: React.FC<MUIStepperProps> = ({
  steps,
  isStepCompleted,
  isStepFailed,
  activeStep = 0,
  stepHandler,
  disableStepOnClick
}) => {
  const [activeStepper, setActiveStepper] = React.useState<number>(activeStep);

  const isFailed = (step: number) => isStepFailed.includes(step);
  const isCompleted = (step: number) => isStepCompleted.includes(step);

  const handleStepClick = (key: string, step: number) => {
    if(disableStepOnClick){
      setActiveStepper(step);
      stepHandler?.(key);
    }

  };
  const isAllCompleted = isStepCompleted.length === steps.length;

  return (
    <Box>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector  />}
      >
        {steps.map((item, index) => (
          <Step
            key={item.key}
            completed={isCompleted(index)}
            onClick={() => handleStepClick(item.key, index)}
          >
            <ColorlibStepLabel
              error={isFailed(index)}
              StepIconComponent={(props) => (
                <ColorlibStepIcon {...props} steps={steps} />
              )}
            >
              <Typography sx={{fontSize:'0.9rem',marginTop:'-5px'}}>{item.title}</Typography> 
            </ColorlibStepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default MuiStepper;