import CallIcon from "@mui/icons-material/Call";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import { PHONE_NUMS_LIST } from "../../../context/softPhoneAndFreshChatModalUICtx/softPhoneAndFreshChatFloatUICtx";
import { useSoftPhoneAndFreshChatCtx } from "../../../context/softPhoneAndFreshChatModalUICtx/useSoftPhoneAndFreshChatCtx";
import {
  BORROWER_VIEW_SCREEN,
  CHURN_CALL_LOG_NEW_MODAL,
  CHURN_CALL_LOG_OLD_MODAL,
  CUST_REFEREL,
  DELIQUENCY_CALL_TASK_LOG_MODAL,
  DELIQUENCY_OVERDUE_BY_COMMIT_DATE_LOG_MODAL,
  DELIQUENCY_OVERDUE_BY_DAYS_LOG_MODAL,
  FA_APPLY_SCREEN,
  FA_VIEW_LOG_MODAL,
} from "../../../context/softPhoneCtx/softPhoneCtx";
import { checkUserHasCallPriv } from "../../../helpers/helper";
import { check_priv, get } from "../../../helpers/storage_helper";
import FreshChat from "../../utility/Component/FreshChat";
import MissedCallAndCustOrientationFloatIconUI from "../components/missedCallAndCustOrientationFloatIconUI";
import PhoneNumbersOrMissedCallModalUI from "../components/phoneNumbersOrMissedCallModalUI";

const SoftPhoneAndFreshChatContainer = () => {
  // const
  const iconStyle = { width: "25px", height: "25px", color: "#fff" };
  const hasCallPriv = checkUserHasCallPriv();

  // ctx
  const {
    softPhoneAndFreshChatFloatUIState: {
      showFloatBtnsOptions,
      callState: { screen },
    },
    methods: { toggleFloatBtnUI, toggleFloatModalFor },
  } = useSoftPhoneAndFreshChatCtx();

  // const
  const showSoftPhoneIcon =
    screen === CHURN_CALL_LOG_NEW_MODAL ||
    screen === CHURN_CALL_LOG_OLD_MODAL ||
    screen === DELIQUENCY_OVERDUE_BY_DAYS_LOG_MODAL ||
    screen === DELIQUENCY_OVERDUE_BY_COMMIT_DATE_LOG_MODAL ||
    screen === DELIQUENCY_CALL_TASK_LOG_MODAL ||
    screen === FA_VIEW_LOG_MODAL ||
    screen === BORROWER_VIEW_SCREEN ||
    screen === CUST_REFEREL ||
    screen === FA_APPLY_SCREEN;

  const hasFreshChatPriv =
    get("role_codes") && check_priv("user", "chat_support");

  return (
    <>
      {/* missed call indication UI */}
      <MissedCallAndCustOrientationFloatIconUI />
      {/* call and fresh chat icons UI */}
      {showFloatBtnsOptions && (
        <div className="menuslist bottom_divs freshchat_z_index">
          {/* softphone toggle btn UI */}
          { showSoftPhoneIcon && (
            <div className="d-flex  align-items-center my-2">
              <div className="menu_sub_label d-flex align-items-center ">
                <p className="my-0  ml-3 soft_phone_text_clr">Soft Phone</p>
              </div>
              <div className="soft_phone zoomIn_animation  pointer d-flex justify-content-center align-items-center ">
                <IconButton
                  onClick={() => {
                    toggleFloatModalFor({ floatModalFor: PHONE_NUMS_LIST });
                  }}
                >
                  <CallIcon sx={{ ...iconStyle, color: "#231184" }} />
                </IconButton>
              </div>
            </div>
          )}

          {/* fresh chat toggle UI */}
          {hasFreshChatPriv && (
            <div className="d-flex align-items-center my-2">
              <div
                className={`menu_sub_label fresh_chat_label d-flex align-items-center ${
                  showFloatBtnsOptions ? "" : "chat_icon_margin_right"
                }`}
              >
                <p className="my-0  ml-3 soft_phone_text_clr">
                  Chat with Tech Support
                </p>
              </div>
              <div className="soft_phone zoomIn_animation bottom_divs d-flex p-0 justify-content-center align-items-center pointer freshchat_bg ">
                <FreshChat />
              </div>
            </div>
          )}
        </div>
      )}

      {/* float btn UI */}
      {(hasFreshChatPriv || hasCallPriv) && ( // to show the bottom right float icon the user should has the priv of either fresh chat or calling priv
        <div className="zoomIn_animation fresh_chart_div bottom_divs over_view_z_index pointer d-flex justify-content-center align-items-center my-2">
          <IconButton
            onClick={() =>
              toggleFloatBtnUI({
                showFloatBtnsOptions: !showFloatBtnsOptions,
              })
            }
          >
            {showFloatBtnsOptions ? (
              <CloseIcon sx={iconStyle} />
            ) : (
              <MenuIcon sx={iconStyle} />
            )}
          </IconButton>
        </div>
      )}

      {/* phone number or missed call UI */}
      <PhoneNumbersOrMissedCallModalUI />
    </>
  );
};

export default SoftPhoneAndFreshChatContainer;
