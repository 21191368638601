import { Box, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import {
  HeaderVarientH2Typo,
  HeaderVarientH3Typo,
} from "../../CommonComponent/typography";
import KycInfo from "./kycInfo";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import DigitalKycStepper from "./digitalKycStepper";
import { useGetDigitalKycRecords } from "../hook/digitalKycHooks";
import StepperLoader from "../loaders/stepperLoader";

const DigitalKYC = () => {
  const { data, isLoading } = useGetDigitalKycRecords();
  const location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    console.log("data__data", data);
    if (data?.stage) {
      const activeStep = location.pathname.split("/").filter(Boolean).pop();
      if (data?.stage !== activeStep) {
        navigate(`/lead/digital_kyc/${id}/${data.stage}`, { replace: true }); // Redirect to correct step
      }
    }
  }, [data?.stage]);

  if (isLoading) return <StepperLoader />;
  return (
    <Box>
      <Box>
        <HeaderVarientH3Typo
          text="Digital KYC"
          overRideStyles={{
            textAlign: "center",
            fontWeight: 700,
            py: 2,
            fontSize: "1.5rem !important",
          }}
        />
        <KycInfo />
        <Box my={2} mt={4}>
          <DigitalKycStepper />
        </Box>
        <Outlet />
      </Box>
    </Box>
  );
};

export default DigitalKYC;
