import { Box, Grid } from "@mui/material";
import React, { memo } from "react";
import { ERROR_INFO } from "../../../../consts";
import { useSoftPhoneCtx } from "../../../../context/softPhoneCtx/useSoftPhoneCtx";
import SkeletonLoader from "../../../CommonComponent/skeletonLoader";
import TextWrappedByHrTag from "../../../CommonComponent/textWrappedByHrTag";
import { HeaderVarientH2Typo } from "../../../CommonComponent/typography";
import useGetInquiryList from "../queryHooks/useGetInquiryList";
import { useGetNewCustOrientationListByTaskId } from "../queryHooks/useGetNewCustOrientationList";
import ContactNumsUI from "./contactNumsUI";
import CustInfoUI from "./custInfoUI";
import FallBackUI from "./fallBackUI";
import FormUI from "./formUI";

/**
 *
 * @returns the UI for showing the customer details, inquiry and remarks.
 */
const NewCustOrientationCallLogModal = memo(() => {
  // ctx
  const {
    softPhoneState: {
      callLogDetails: { selectedNewCustOrientationtaskId },
    },
  } = useSoftPhoneCtx();

  // query
  const {
    newCustData,
    isLoading: custDetailsGetsLoading,
    isError: custDetailsGotError,
  } = useGetNewCustOrientationListByTaskId({
    taskId: selectedNewCustOrientationtaskId,
  });
  const leadId = newCustData?.lead_id;
  const {
    isLoading: inquiryDetailsGetsLoading,
    isError: inquiryDetailsGotError,
  } = useGetInquiryList({
    leadId: leadId,
  });

  // conts
  const inquiryList = newCustData?.flags;

  if (custDetailsGetsLoading || inquiryDetailsGetsLoading)
    return (
      <>
        {["Customer Info", "Contact Numbers"].map((e, idx) => (
          <React.Fragment key={idx}>
            <TextWrappedByHrTag text={e} />
            <Grid
              container
              spacing={2}
              alignItems="center"
              textAlign={"start"}
              p={2}
            >
              {new Array(5).fill("").map((_a, idx) => (
                <Grid item xs={6} md={3} key={idx}>
                  <SkeletonLoader
                    variant="rectangular"
                    height={17}
                    width={150}
                    overRideStyles={{ mb: 2 }}
                  />
                  <SkeletonLoader
                    variant="rectangular"
                    height={17}
                    width={110}
                  />
                </Grid>
              ))}
            </Grid>
          </React.Fragment>
        ))}
        <TextWrappedByHrTag text={"Customer Inquiry Checklist"} />
        {new Array(5).fill("").map((_a, idx) => (
          <Box sx={{ mb: 2, px: 2 }} key={idx}>
            <FallBackUI />
          </Box>
        ))}
      </>
    );

  if (custDetailsGotError || inquiryDetailsGotError)
    return (
      <>
        {["Customer Inquiry Checklist", "Contact Numbers", "Customer Info"].map(
          (e, idx) => (
            <React.Fragment key={idx}>
              <TextWrappedByHrTag text={e} />
              <HeaderVarientH2Typo
                text={ERROR_INFO}
                overRideStyles={{
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  py: 16,
                }}
              />
            </React.Fragment>
          )
        )}
      </>
    );

  if (!inquiryList) return <></>;

  return (
    <>
      <CustInfoUI newCustData={newCustData} />
      <ContactNumsUI
        phoneNumber={newCustData.mobile_num}
        taskId={selectedNewCustOrientationtaskId}
      />
      <FormUI
        inquiryList={inquiryList}
        key={Math.random()}
        newCustData={newCustData}
      />
    </>
  );
});

export default NewCustOrientationCallLogModal;
