import React, { lazy, LazyExoticComponent, Suspense } from "react";
import { resolvePromiseWithDelay } from "../../../helpers/common_helper";
import FallBackUI from "./components/fallBackUI";

// lazy
const AccUI: LazyExoticComponent<React.ComponentType> = lazy(() =>
  resolvePromiseWithDelay(import("./components/accUI"))
);

const NewOnboardedCustomersUI = () => {
  return (
    <Suspense fallback={<FallBackUI />}>
      <AccUI />
    </Suspense>
  );
};

export default NewOnboardedCustomersUI;
