import FlowComponent from '../common/component/core/FlowComponent';
import React from 'react';
import {format_date_tbl, getValueFromLangJson, Homeacc_prvdrLogoselector, title_case} from '../../helpers/common_helper';
import { Link} from 'react-router-dom';
import {searchLead} from "../../actions/lead_actions";
import {TooltipLabel} from "../common/partial";
import { get } from '../../helpers/storage_helper';
import { listAccountProvider } from '../../actions/account_provider_actions';
import CommonReactTable from '../../helpers/commonReact_table';
import Accordion from 'react-bootstrap/Accordion';
import {Card} from 'react-bootstrap'
import {BsChevronDown} from 'react-icons/bs';
import AdminHome from './AdminHome';
import { dd_value } from '../../actions/common_actions';
import { BasicReactTable } from '../../helpers/react_table';
import MUIAccordion from '../../MUI/Components/MUIAccordion';
import { v4 as uuidv4 } from 'uuid';
import HomeScreenTable from './HomeScreenTable';
import { Box } from '@mui/material';
import DataGridTable from '../CommonComponent/table';
import { GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid';
import CustomTableGridToolbar from '../CommonComponent/table/components/customTableGridToolbar';
import { connect } from 'react-redux';
import { handleProviderSelection } from '../../helpers/helper';
import AccProviderFilter from '../common/component/AccProviderFilter';

class PendingRMAllocList extends FlowComponent {

	state = {
		columns :[],
		lists : [],
		copy:[],
		dyn_Btn_selected:[],
		all_count:0,				
		filter_actions:{},
		all_Btn_status:false,
		all_Acc_pvdrs:[],
		show_table: false	,
		page: { page: 0, pageSize: 10 },
		selectedProviders: [],
        filteredRows: []

		
	}

	handleToggle = (e) => {

        switch(e){
            case("Account"):
                this.setState(state => state.show_table = !this.state.show_table);
                break;
        }
    }
	
	componentWillMount() {
		
		this.drawTable()				
    }

	drawTable () {
		const market = get('market')
		const market_name = market.country_code
		let lead_search_criteria = {status : '09_pending_rm_alloc', profile_status: 'open'}
      	searchLead(this.req({lead_search_criteria}))
			.then((response) => {				
				if(!response){return };
				if(response.status === "success"){
					this.setState(state => state.columns = this.columndatas())		
					const data = response.data							
					if (data.length > 0){																							
						this.setState({lists:data})
						this.setState(state=>state.all_count = data.length)																					
						this.handle_acc_prvdr("All",data)
					}											       		
				}
			})
	}

	columndatas () {
		return (
			[
				{
					headerName : "A/C Number",
					field: 'account_num',
					minWidth: 190,
					headerAlign: 'left', flex: 1, align: 'left',


				},
				{
					headerName : "Lead Channel",
					field : 'channel',
					headerAlign: 'left', flex: 1, align: 'left',

					renderCell : params=> params.row.channel?title_case(params.row.channel):'N/A'
				},
				{
					headerName : "Business Name",
					field: 'biz_name',
					headerAlign: 'left', flex: 1, align: 'left',

					minWidth:200,
					renderCell: cell => <TooltipLabel full_text={cell.row.biz_name} overlay={[cell.row.first_name, cell.row.last_name].join(" ")} no_reduce={true} />
				},
				{
					headerName : "Reg Date",
					field: 'lead_date', 
					minWidth:230,
					renderCell: params => format_date_tbl(params.row.lead_date),
					
				},
				{
					headerName : "ACC PRVDR",
					field: 'acc_prvdr_code',
					headerAlign: 'left', flex: 1, align: 'left',

					renderCell: params => <Homeacc_prvdrLogoselector logotype={params.row.acc_prvdr_code}/>
				},
				{
					headerName : "Mobile Number",
					field: 'mobile_num'	,
					headerAlign: 'left', flex: 1, align: 'left',
					renderCell: params => params.row.mobile_num,

				},
				{
					headerName : "Location",
					field: 'location',
					headerAlign: 'left', flex: 1, align: 'left',
					renderCell: params => params.row.location,

				},
				{
					headerName : "Action",
					sortable: false,
					field: 'id' ,
					headerAlign: 'left', flex: 1, align: 'left',

					renderCell: params =>  <Link to={"lead/edit/"+ params.row.id} target="_blank" > Allocate {getValueFromLangJson("rm_label_short")}</Link>
				}
			]
		)
	}

	

	handle_acc_prvdr(provider , rows = null ) {
		let row_data = rows??this.state.lists ;
		handleProviderSelection(
		  provider,
		  this.state.selectedProviders,
		  this.props.acc_prvdr_list,
		  row_data,
		  (updated_prvdr) => this.setState({ selectedProviders: updated_prvdr }),
		  (filtered_rows) => this.setState({ filteredRows: filtered_rows })
		);
	  }

    render(){	
		// const CustomGridToolbar = () => (
		// 	<div  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '8px' }}>
		// 	<div className='MuiDataGrid-toolbarContainer' style={{ display: 'flex', gap: '8px' }}>
		// 	  <GridToolbarColumnsButton />
		// 	  <GridToolbarFilterButton />
		// 	  <GridToolbarDensitySelector />
		// 	</div>
		// 	<GridToolbarQuickFilter />
		//   </div>
		//   );   	
        return (
       		
			
			<Box sx={{mt:2}}>
			<MUIAccordion
			titleUI={
				<div className='home_header_d-flex_at_response'>
				<h4 className="card-title text-white tableHead_font " style={{width:"max-content"}}>Leads with Pending {getValueFromLangJson("rm_label_short")} Allocation</h4>
				{this.state.lists.length > 0 &&
				this.props.acc_prvdr_list?.length>1&&
							<>
								<AccProviderFilter
                            rows={this.state.lists??[]} 
                            handle_acc_prvdr={(provider)=>this.handle_acc_prvdr(provider)}
                            selectedProviders ={this.state.selectedProviders}
                            />	
						</>
						}
			</div>

			
			}
			totalCount = {this.state.all_Acc_pvdrs?.length>1 ?0: (this.state.copy?.length)}

			body={
				<DataGridTable
								rows={this.state.filteredRows??[] } 
								paginationMode= {"client"}
								page={this.state.page}
								setpage={(newPage) => this.setState({ page: newPage })}
								// isLoading={ this.state.loader }
								rowCount={this.state.filteredRows?.length}
								columns={this.state.columns}
								slots={{ toolbar: CustomTableGridToolbar }}
								slotProps={{
								toolbar: {
									showQuickFilter: true,
								},
								}}
								/>

				// <BasicReactTable  row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columns} pagination={true} defaultSorted={[{id:"lead_date",desc:true}]}   default_page_size={50}   /> 
			}
			/>
          </Box>
			

			
					
									
					

				
        	)

    }
}


const mapStateToProps = (state) => {
	return {
	  acc_prvdr_list : state.common_slice?.acc_prvdr_list

	};
  };

export default connect(mapStateToProps)(PendingRMAllocList);


