import {
  Box,
  Grid,
  IconButton,
  Button,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import DisplayImage from "../../../common/component/DisplayImage";
import {
  get_dir_path,
  getValueFromLangJson,
} from "../../../../helpers/common_helper";
import { HeaderVarientH4Typo } from "../../../CommonComponent/typography";
import RHFCheckBoxField from "../../../CommonComponent/rhfComponents/rhfCheckBoxField";
import { useGetDigitalKycVerificationInfo } from "../../hook/auditorVerificationHooks";
import { useParams } from "react-router-dom";
import { textTransform } from "../../../../styles/common_styles";
import { dd_value } from "../../../../actions/common_actions";
const removableFields = ["photo_id_proof"];
const fullWidthFields = ["id_proof_num", "enterprise_name", "company_code"];
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import RhfDatePicker from "../../../CommonComponent/rhfComponents/rhfDatePicker";
import RHFTextField from "../../../CommonComponent/rhfComponents/rhfTextField";
import PhotoProof from "./photoProof";

const PhotoRdbCerificate = () => {
  const rdbFields = [
    "company_code",
    "enterprise_name",
    "owner_name",
    "registration_date",
  ];

  return (
    <Box>
      <Box mx={5}>
        <PhotoProof fields={rdbFields} />
        <Box>
          <Box display="flex" alignItems="center" my={2}>
            <RHFCheckBoxField name="rdbIsCorrect" />
            <Typography color={"white"}>
              Extracted RDB details are correct
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" my={2}>
            <RHFCheckBoxField name="isOwnedAccount" />
            <Typography color={"white"}>
              I confirm the account is owned by Alexander Tamale based on the
              evidences submitted
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PhotoRdbCerificate;
