import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import Select from "react-select";
import { FiFilter } from "react-icons/fi";
import { BiCalendar } from "react-icons/bi";
import moment from 'moment';
import DatePicker from 'react-datetime';
import { RiSunFill } from "react-icons/ri";
import { WiSunset } from "react-icons/wi";
import CommonReactTable from '../../../helpers/commonReact_table';
import { check_priv, Config, get } from '../../../helpers/storage_helper';
import { Homeacc_prvdrLogoselector, capitalize_first_letter, lbl_amt } from '../../../helpers/common_helper';
import CallLogModal from '../Component/CallLogModal';

import {dd_value} from '../../../actions/common_actions';
import { Link } from "react-router-dom";
import {delinquencyCallTask,getCallLog,overDueDataBydaya,getCommitmentDatas,setCallLog, getDeliquencyById} from '../../../actions/delinquency_actions';
import { TooltipLabel } from '../../common/partial';
import { IoMdAlarm } from "react-icons/io";
import { BasicReactTable } from '../../../helpers/react_table';
import { connect } from 'react-redux';
import Softphone from '../../cs_management/container/cs_soft_phone';
import ConfirmModal from '../../common/component/ConfirmModal';
import { setValuesIntoRedux } from '../../../Redux/Slices/CsSoftphoneSlice';
import { DELIQUENCY_CALL_TASK_LOG_MODAL, DELIQUENCY_OVERDUE_BY_COMMIT_DATE_LOG_MODAL, DELIQUENCY_OVERDUE_BY_DAYS_LOG_MODAL } from '../../../context/softPhoneCtx/softPhoneCtx';
import { PHYSICAl_PHONE } from '../../../consts';

class OverdueCallTasks extends FlowComponent {

    state = {
        active_tab: "all",
        callLogModal: false,
        initial_tab: "call_task",
        active_session: 'morning',
        over_due_byDays: "1_4_days",
        due_commitment_date: "all",
        filter_by_status:"assigned",
        criteria:{},
        fa_status_name : Config("fa_recovery_status"),
        call_start_time: '',
       
        Overdue_call_tasks_menu: [
            { label: "All", key: "all" ,stateName:'active_tab'},
            { label: "Day 1", key: "day_1",stateName:'active_tab' },
            { label: "Day 2", key: "day_2",stateName:'active_tab' },
            { label: "Day 3", key: "day_3" ,stateName:'active_tab'},
            { label: "Day 20", key: "day_20",stateName:'active_tab' ,addl_stmt:true},
            { label : "Commitment Date", key:"commitment_call", stateName:'active_tab'}
        ],
        Overdue_by_commitment_date: [
            { label: `All`, key: "all" ,stateName:'due_commitment_date'},
            { label: `${moment().format("DD MMM")+"-"+ moment().add(3, 'days').format("DD MMM")}`, key:`${moment().format("DD-MM-YYYY")+"_"+moment().add(3, 'days').format("DD-MM-YYYY")}` ,stateName:'due_commitment_date'},
            { label: `${moment().add(4, 'days').format("DD MMM") +"-"+ moment().add(7, 'days').format("DD MMM")}`, key:`${moment().add(4, 'days').format("DD-MM-YYYY")+"_"+moment().add(7, 'days').format("DD-MM-YYYY")}`,stateName:'due_commitment_date' },
            { label: `${moment().add(8, 'days').format("DD MMM") +"-"+ moment().add(11, 'days').format("DD MMM")}`, key:`${moment().add(8, 'days').format("DD-MM-YYYY")+"_"+moment().add(11, 'days').format("DD-MM-YYYY")}` ,stateName:'due_commitment_date'},
        ],
            overdueCommitmentReasons:["all","no_float","robbery","shop_closed","customer_not_available","sick","weather","prison","personal_engagement","business_issues","deceased","not_yet_captured","blocked_lines","missuse_of_floats"],
        raw_datas:[]
    }


    componentWillMount() {

        const  softphone_is_supported =  check_priv('home', 'soft_phone')  && this.props.softPhoneState.is_softphone_supported
        this.setState(
            { softphone_is_supported },
            () => this.setState({ column_data: this.column_datas() })
          );
        if(this.props.isPendingCallLog) { // if the screen for log the pending call, that means in router we will have call_task query
            const initialTab = this.props.screen === DELIQUENCY_CALL_TASK_LOG_MODAL ? "call_task" : this.props.screen === DELIQUENCY_OVERDUE_BY_COMMIT_DATE_LOG_MODAL ? "commitment_date" : "due_by_days"
            // this.setState({initial_tab: initialTab})
            this.get_input_handler(initialTab, 'initial_tab') // if there is a pending call log need to fetch correct tab data from api(call_task, due_by_days or commitment_date)
            const delinquency_task_id = this.props.delinquencyTaskId
            const delinquency_id = this.props.deliquencyId
            const reqObj = {
                ...(delinquency_task_id && {delinquency_task_id}),
                ...(delinquency_id && {delinquency_id})
            }
            getDeliquencyById(reqObj).then(r => {
                this.call_tasks_call_log(delinquency_task_id ? r?.data : r?.data[0])
            })
        }
        this.setCommitmentReasons()
        if(!this.props.isPendingCallLog) this.getDatas() // if there is no pending call log related to delinquency, that means the route should has /call_task no any other query.
        this.props.setValuesIntoRedux({screen_from:"delinquency"})
        
    }
  

    getDatas(call_tasks_date=null){
      

        this.setState({loader:true})
        var req={"country_code":get('market').country_code,sub_task:this.state.initial_tab}
        req['filter_by_date']= this.state.call_tasks_date && this.state.filter_by_status != 'assigned' ? this.state.call_tasks_date : moment().format("YYYY-MM-DD")
        req['status'] = this.state.filter_by_status;
        delinquencyCallTask(this.req(req))
        .then((response)=>{
            if(!response){return}
            if(response.status=="success"){

                let data = response.data
                this.setState({row_data: data, AllDatas:data ,column_data:this.column_datas(), loader:false}, ()=>this.callTasksFilteration(this.state.filter_by_status, "status", "addl_filter"))

            }
        })
    }



    setCommitmentReasons(){
        var arr = [];

        for (var i = 0; i < this.state.overdueCommitmentReasons.length; i++) {
            var obj = {};
            obj["label"] = dd_value(this.state.overdueCommitmentReasons[i],this.state.overdueCommitmentReasons[i]);
            obj["is_true"] = true;
            obj["key"] = this.state.overdueCommitmentReasons[i]

            arr.push(obj);
        }
        this.setState({OverDueCommitmentsReasons:arr})
    }


    column_datas() {
        return [
            {
                Header: <div className='d-flex justify-content-center'>Account Number</div>,
                id: "acc_number",
                width:180,
                show: (this.state.initial_tab === "due_by_days" )? true :false,
                accessor: row => <div className='d-flex justify-content-start align-items-center '>
                    <div className='mx-3'> <Homeacc_prvdrLogoselector logotype={row.acc_prvdr_code}/></div>
                     <p className='no-margin text-white '  ><TooltipLabel full_text={row.acc_number}  no_reduce={true} />  </p></div>,

            },
           
            {
                Header: <div className='d-flex justify-content-center'>Customer Name</div>,
                id: "customer_name",
                disableSortBy: true,
                accessor: row => (
                    <>
                      
                      <p className='m-0 text-center d-flex justify-content-center'>
                        
                       {row.reminder != undefined && row.reminder == 1 && (<IoMdAlarm className='mr-2' size="25" color='red' /> )} 
                       <TooltipLabel full_text={row.cust_name} no_reduce={true} />
                      </p>
                    </>
                  ),
                  width:250
            },
            {
                Header: <div className='d-flex justify-content-center'>FA ID</div>,
                id: "fa_id",
                show: (this.state.initial_tab === "due_by_days" )? true :false,

                disableSortBy: true,
                
                accessor: row => <Link style={{color: "#008fc1"}} to={"/fa/view/"+row.loan_doc_id} target="_blank"><TooltipLabel full_text={row.loan_doc_id}  no_reduce={true} /></Link>   ,


            },
          
            {
                Header: <div className='d-flex justify-content-center'>FA Disbursal</div>,
                id: "fa_disbursal",
                show: (this.state.initial_tab === "due_by_days" )? true :false,

                disableSortBy: true,
                accessor: row => <p className='no-margin text-white text-center '  > { lbl_amt(row.loan_principal, get("market").currency_code)  }</p>,
            },
            {
                Header: <div className='d-flex justify-content-center'>Flat Fees</div>,
                id: "flow_fees",
                show: (this.state.initial_tab === "due_by_days" )? true :false,

                disableSortBy: true,
                accessor: row => <p className='no-margin text-white text-center '  > {  lbl_amt(row.flow_fee, get("market").currency_code)  }</p>,
            },
            {
                Header:  <div className='d-flex justify-content-center'>Penalty</div> ,
                id: "penalty",
                show: (this.state.initial_tab === "due_by_days" )? true :false,

                disableSortBy: true,
                accessor: row => <p className='no-margin text-white text-center '  > {  lbl_amt(((row.provisional_penalty)*(row.penalty_days)), get("market").currency_code)   }</p>,
            },
            {
                Header: <div className='d-flex justify-content-center'>Amount to be paid</div>,
                id: "paid_amount",
                show: (this.state.initial_tab === "due_by_days" )? true :false,

                disableSortBy: true,
                accessor: row => <p className='no-margin text-white  text-center'  > { lbl_amt(row.current_os_amount, get("market").currency_code)  }</p>,
            },
            {
                Header: <div className='d-flex justify-content-center'>Partial Payment</div>,
                id: "partial_payment",
                show: (this.state.initial_tab === "due_by_days" )? true :false,

                disableSortBy: true,
                accessor: row => <p className='no-margin text-white text-center '  > { row.paid_amount>0 ? lbl_amt(row.paid_amount, get("market").currency_code):<p className='m-0 text-center'> -</p>}</p>,
            },
            {
                Header: <div className='d-flex justify-content-center'>Overdue Days</div>,
                id: "overdue_day",
                show: (this.state.initial_tab === "due_by_days" )? true :false,

                disableSortBy: true,
                accessor: row => <p className='no-margin text-white text-center '  > {row.overdue_day ?row.overdue_day:"-" } Day</p>,
            },
            {
                Header: <div className='d-flex justify-content-center'>Mobile Number</div>,
                id: "mobile_num",
                disableSortBy: true,
                show: (this.state.initial_tab !== "due_by_days" )? true :false,

                accessor: row => <p className='no-margin text-center text-white  '  > { (this.state.initial_tab =="call_task" )?row.reg_mob_num:row.cust_mobile_num }</p>,


            },
            {
                Header: <div className='d-flex justify-content-center'>RM Name</div>,
                id: "rm_name",
                disableSortBy: true,
                show: (this.state.initial_tab !== "due_by_days" )? true :false,
                accessor: row => <p className='no-margin text-white text-center'  > {row.rm_name}</p>,


            },
           
            {
                Header: <div className='d-flex justify-content-center'>Outstanding Amount</div>,
                id: "os_amount",
                show: (this.state.initial_tab !== "due_by_days" )? true :false,
                disableSortBy: true,
                accessor: row => <p className='no-margin text-center text-white  '  > {lbl_amt((this.state.initial_tab =="call_task" )?row.os_amt :row.current_os_amount, get("market").currency_code)}</p>,


            },
           
            {
                Header: <div className=' d-flex justify-content-center'>FA Due Date</div>,
                id: "fa_due_date",
                show: (this.state.initial_tab != "due_by_days" )? true :false,
                disableSortBy: true,
                accessor: row => <p className='no-margin text-white text-center '  > {moment(row.fa_due_date).format("DD MMM YYYY")} ({ row.overdue_day} {(row.overdue_day >1 ? " days" :" day")})</p>,


            },
            {
                Header: <div className=' d-flex justify-content-center'>FA Status</div>,
                id: "fa_status",
                disableSortBy: true,
                accessor: row => <p className='no-margin text-white text-center'  >{this.state.fa_status_name[row.fa_status] ? this.state.fa_status_name[row.fa_status] : dd_value(row.fa_status)}</p>,
            },
            {
                Header: <div className='d-flex justify-content-center'>Commitment Date</div>,
                id: "commitment_date",
                show: (this.state.initial_tab === "commitment_date" )? true :false,
                disableSortBy: true,
                accessor: row => <p className='no-margin text-white text-center '  > {moment(row.last_commitment_date).format("DD MMM YYYY")} </p>,


            },
            {
                Header: <div className='d-flex justify-content-center'>Reasons</div>,
                id: "reasons",
                show: (this.state.initial_tab === "commitment_date" )? true :false,
                disableSortBy: true,
                accessor: row => <p className='no-margin text-white text-center '  > {dd_value(row.overdue_reasons,"reason_for_overdue")}</p>,


            },
            // {
            //     Header: "Completed",
            //     id: "completed",
            //     show: ((this.state.filter_by_status === "completed") &&  (this.state.initial_tab == "call_task") ) ,
            //     disableSortBy: true,
            //     accessor: row => <p className='no-margin text-center text-white  '  > {row.is_delayed ? "After Delay":" On-time" }</p>,


            // },
            {
                Header: <div className='d-flex justify-content-center'>Call Log</div>,
                id: "call_log",
                show: this.state.filter_by_status != 'cancelled' ? true : false,
                disableSortBy: true,
                accessor: row => <div className=' d-flex justify-content-center'> <button className='btn product_takeup_submit text-white' onClick={() => this.call_tasks_call_log(row, this.state.initial_tab)} >Log Call</button> </div>,


            },
            {
                Header: <div className='d-flex justify-content-center'>Recovery File</div>,
                id: "recovery_file",
                disableSortBy: true,
                accessor: row => <div className=' d-flex justify-content-center'> <button className='btn product_takeup_submit text-white' onClick={() => this.call_tasks_recovery_file(row)} >View File</button> </div>,


            },

        ]
    }

    call_tasks_recovery_file(row){
        
        window.open(`/recovery_file/${row.loan_doc_id}`, '_blank');

    }

    async call_tasks_call_log(row) {
        const { hasAnyPrevPendCallLog, callLog } = await this.props.softPhoneCtxMethods.checkUserHasAnyPendingCallLog({screen: ""}) 
        const usrHasPendLogButNotInLogPge = hasAnyPrevPendCallLog && !this.props.isPendingCallLog
        if(usrHasPendLogButNotInLogPge) { // if having any pending logs and the user in pend log screen
            this.props.softPhoneCtxMethods.pendingCallLogHandler({
                callLog: {
                    call_log_id: callLog?.call_log_id,
                    screen: callLog?.screen, 
                    device_type: callLog?.device_type, 
                    task_id: callLog?.task_id, 
                    churn_id: callLog?.churn_id,
                    delinquency_id: callLog?.delinquency_id
                }
            })
        } else {
            const initialTab = this.state.initial_tab
            const screen = initialTab === 'call_task' ? DELIQUENCY_CALL_TASK_LOG_MODAL : this.state.initial_tab === "due_by_days" ? DELIQUENCY_OVERDUE_BY_DAYS_LOG_MODAL : DELIQUENCY_OVERDUE_BY_COMMIT_DATE_LOG_MODAL
            const deliquencyId = initialTab === 'call_task' ? row.id : row.delinquency_id
            this.props.softPhoneAndFreshChatCtxMethods.setCustomerDetails({screen, custId: row.cust_id, isCustomer: true, mobileNum: row.reg_mob_num, churnId: "", deliquencyId})
            this.props.setValuesIntoRedux({cust_id:row.cust_id})

            this.getCallLogDatas(row)
        }
    }

    getCallLogDatas(row){
       if(this.state.softphone_is_supported){
            // the softphone or physical phone confirmation is asked only if the user clicks the calllog btn in table, 
            // for pending call log page it will automatically selected
            const isUserInPendCallLogScreen = this.props.isPendingCallLog
            if(isUserInPendCallLogScreen) { 
                const usingPhysicalPhone = this.props.deviceType === PHYSICAl_PHONE
                this.setState({Call_log_data: row, using_physicalphone: usingPhysicalPhone}, ()=>this.getCallLogDetails(usingPhysicalPhone))
            } else {
                this.setState({setCallTypeConfirm:true, Call_log_data: row})
            }
       }
       else{
        this.setState({Call_log_data: row, using_physicalphone: true}, ()=>this.getCallLogDetails(true))
       }
    }


    getCallLogDetails(isPhysicalphone = false){
        var request = {}
        if(this.state.initial_tab=="due_by_days"){
            request['loan_doc_id']=this.state.Call_log_data.loan_doc_id
            request['type']= "overdue_by_days"
        }else if(this.state.initial_tab=="commitment_date"){
         request['loan_doc_id']=this.state.Call_log_data.loan_doc_id
         request['type']= "overdue_by_commit"
        }else{
         request['id']= this.state.Call_log_data.id
 
        }

        getCallLog(this.req(request))
        .then((resp)=>{
            if(!resp){return}
            if(resp.status=="success"){
                    if(resp.data === 'The Overdue followup has been logged already') { // get fresh table if already made log
                        alert(resp?.data ?? 'Already logged')
                        this.getDatas()
                    }else {
                        this.setState({ callLogDatas:resp.data, callLogModal: true })
                        if((!this.state.softphone_is_supported || isPhysicalphone) && !this.props.isPendingCallLog){ // no need to start time entry for physical phone on pending call log case...
                            // this.callLogEntry(this.state.Call_log_data ,null,[],isPhysicalphone)
                            const startTime = moment();
                            const callStrtTime = startTime.format("YYYY-MM-DD HH:mm:ss");
                            this.setState({call_start_time: callStrtTime})
                        }   
                    }  
            }
        })
    }

    callLogEntry(row,addl_key=null,arr=[],isPhysicalphone = false){
        const initialTab = this.state.initial_tab
        const screen = initialTab === 'call_task' ? DELIQUENCY_CALL_TASK_LOG_MODAL : this.state.initial_tab === "due_by_days" ? DELIQUENCY_OVERDUE_BY_DAYS_LOG_MODAL : DELIQUENCY_OVERDUE_BY_COMMIT_DATE_LOG_MODAL
         var request={screen,call_log: addl_key!=null?"end_log":"start_log",call_log_id:(addl_key!=null)?  this.state.EndCallLogID? this.state.EndCallLogID: null:null
        
        }
        if(isPhysicalphone){
            request.physical_phone = true
        }

        

         if(this.state.initial_tab=="due_by_days"){
            this.setState({due_by_days_row:row})
            request['loan_doc_id']= row.loan_doc_id
            request['type']= "overdue_by_days"
            request['mobile_num']=arr.length>0 ? arr:null
        }else if(this.state.initial_tab=="commitment_date"){
           this.setState({due_by_days_row:row})
         request['loan_doc_id']=row.loan_doc_id
         request['type']= "overdue_by_commit"
         request['mobile_num']=arr.length>0 ? arr:null

        }else{
         request['id']= initialTab === 'call_task' ? row.id : row.delinquency_id
        }
        if(addl_key!="end_call_log"){
        setCallLog(this.req(request))
        .then((response)=>{
            if(response&&response.data){
                this.setState({EndCallLogID:response.data.call_log_id})
            }
        })
    }
    }

    getOdDatasBydays(){
        this.setState({loader:true})

        var request = {}

        var resultArray = this.state.over_due_byDays.split('_');
        var numericArray = resultArray.filter(function(element) {return !isNaN(element);});

        if(this.state.over_due_byDays == "after_40_days"){
            request["start_day"]=parseInt(numericArray[0], 10)
        }else{
           request["start_day"]=parseInt(numericArray[0], 10)
           request["end_day"]=parseInt(numericArray[1], 10)
        }
       

        overDueDataBydaya(this.req(request))
        .then((resp)=>{
            if(!resp){return}
            if(resp.status=="success"){
                this.setState({column_data: this.column_datas(),row_data:resp.data,loader:false})

                // this.setState({callLogDatas:resp.data})
            }
        })
    }

    getStatus(data) {
        
        this.get_input_handler(data.value,"filter_by_status","status") 
    }

    getOdDatasByCommitment(){
        this.setState({loader:true})

      var req={}
       if(this.state.due_commitment_date !=="all"){
        var resultArray = this.state.due_commitment_date.split('_');
        

        req['start_date'] =  moment(resultArray[0], "DD-MM-YYYY").format("YYYY-MM-DD");
        req['end_date'] = moment(resultArray[1], "DD-MM-YYYY").format("YYYY-MM-DD"); 

       }
       getCommitmentDatas(this.req(req))
       .then((response)=>{
        if(!response){return}
        if(response.status=="success"){

            if(this.state.reasonsArr){
               this.setState({column_data: this.column_datas(),loader:false,raw_datas:response.data},()=>{
                this.setState({row_data:this.commitmentDatasFilteration(this.state.OverDueCommitmentsReasons)})})

            }else{
        
                this.setState({column_data: this.column_datas(),row_data:response.data,loader:false,raw_datas:response.data})
            }
        

        }
       })

    }

    get_input_handler(value, key,type=null) {

        this.setState({ [key]:value,loader:(type!=null)?true:false},()=>{
            if((key=="call_tasks_date") ||(value=="completed") ||(value=="cancelled") || (value=="unanswered") || (value == "call_task") || (value == "closed")){
                this.getDatas("filter_by_date") 
            }
            if((value=="due_by_days")||(key=="over_due_byDays")){
                    this.getOdDatasBydays()
            }
            if((value=="commitment_date") || (key =="due_commitment_date")){
                    this.getOdDatasByCommitment()

            }        
            if(key=="filter_by_status" && value=="assigned"){
                this.setState({call_tasks_date :moment().format('YYYY-MM-DD')})
            }
             if(type!=null){
                this.setState({loader:true})
                 if(key!="filter_by_status"){
                        this.callTasksFilteration(value,type,(type=="slot")?"slot":(type=="status")?"status":null)
                    }

             }
            if((this.state.initial_tab=="call_task") && value=="assigned" ){
                this.getDatas()

            }
            
        })  
       
       
    }

    handleClose(call_log_submitted=null,arr=[]) {
        if (arr.length > 0) {
            const hasAnsweredButNoLog = arr.some(item => item.status === "answered" && call_log_submitted === null);
            
            if (hasAnsweredButNoLog) {
                const shouldExit = window.confirm(
                    `You have not entered the Reason and Remarks.\nAre you sure to exit the Call log?`
                );
    
                if (shouldExit) {
                    this.setState({ callLogModal: false }, () => {
                        this.callLogEntry(this.state.Call_log_data, "end_call_log", arr);
                    });
                }
            } else {
               
                this.setState({ callLogModal: !this.state.callLogModal }, () => {
                    this.callLogEntry(this.state.Call_log_data, "end_call_log", arr);
                });
            }
        } else {
            if (call_log_submitted !== "call_log_end") {
                this.setState({ callLogModal: !this.state.callLogModal }, () => {
                    this.callLogEntry(this.state.Call_log_data, "end_call_log", arr);
                });
            } else {
                this.setState({ callLogModal: !this.state.callLogModal });
            }
    
            if (this.state.initial_tab == "due_by_days") {
                this.getOdDatasBydays();
            } else if (this.state.initial_tab == "commitment_date") {
                this.getOdDatasByCommitment();
            } else {
                this.getDatas();
            }
        }
        this.setState({setCallTypeConfirm:false, call_start_time: ''})
        
        this.props.setValuesIntoRedux({show_nums:false,cust_id:false})
        this.props.softPhoneAndFreshChatCtxMethods.setCustomerDetails({screen: '', custId: '', isCustomer: false, mobileNum: '', churnId: "", deliquencyId: ''})
        this.props.softPhoneAndFreshChatCtxMethods.closeFloatBtnUIAndFloatModal()
        if(this.props.isPendingCallLog) { // if the user closes the call log modal while in pending screen need to change the route.
            this.props.navigateToDeliquencyRoute()
        }
    }
    
    callTasksFilteration(value,type,addl_key=null,Addl_data=[]){  

      if(type=="overdue_day"){

        var data = this.state.AllDatas

        if(value=="all"){
            this.setState({row_data:data && data.filter((items,idx)=>{ return ((items["slot"] == this.state.active_session) && (items['status']== this.state.filter_by_status )) } ),column_data: this.column_datas()},()=>{this.setState({loader:false})})
          }else if(value == "commitment_call"){
            this.setState({row_data: ((data && data.length >0 )? data.filter((items,idx)=>{ return ((items['reminder'] == 1) && (items['status']== this.state.filter_by_status ) ) }):[]),loader:false,column_data: this.column_datas()})
          }else{
           let val= parseInt((value.split("_")[1]), 10);
           this.setState({row_data: ((data && data.length >0 )? data.filter((items,idx)=>{ return (items[(type=="overdue_day") ? "followup_day" : type]==val && (items["slot"] == this.state.active_session) && (items['reminder'] != 1) && (items['status']== this.state.filter_by_status ) ) }):[]),column_data: this.column_datas()},()=>{this.setState({loader:false})})

        }
      }else{
        this.setState({loader:true})

        var data = (this.state.filter_by_status=="assigned" && this.state.filter_assigned_data) ?( this.state.filter_assigned_data ? this.state.filter_assigned_data : this.state.AllDatas): (Addl_data && Addl_data.length >0)? Addl_data: this.state.AllDatas 
        this.filterFunction(data,type,value,addl_key)

      }
    }
    
    filterFunction(data,type,key,addl_key=null){
        var state_name = this.state.filter_by_status+"_data"
        var FIlterData=( data && data.length >0) ? data : (this.state.AllDatas? this.state.AllDatas :[] )

        var filtered_data = addl_key!=null ? FIlterData.filter((items,idx)=>{ return (items['status']== this.state.filter_by_status ) && (items["slot"] == this.state.active_session) && ( this.state.active_tab!="all" ? (this.state.active_tab == "commitment_call" ? items['reminder'] == 1 : items['reminder'] == 0 && items['followup_day'] == (parseInt(this.state.active_tab.match(/\d+/)[0], 10))) :true)}): FIlterData.filter((items,idx)=>{ return items[type]==key })
       
        this.setState({row_data:filtered_data,filtered_data,column_data: this.column_datas()},()=>{this.setState({loader:false})})

        if(addl_key!=="slot"){
           this.setState({[state_name]:filtered_data},()=>{this.setState({loader:false})})
        }
    }

    multiselectOverdueReasons(data){
      this.setState({loader:true})
    var stateValue=[...this.state.OverDueCommitmentsReasons]
    if (data.label === 'All') {
        const selectAllValue = !stateValue[0].is_true;
        stateValue.forEach(item => {
            item.is_true = selectAllValue;
        });
    } else {
        for (var i = 0; i < stateValue.length; i++) {
            if (data.label === stateValue[i].label) {
                stateValue[i].is_true = !data.is_true;
            }
        }
        const anyOptionUnselected = stateValue.slice(1).some(item => !item.is_true);
        if (anyOptionUnselected) {
            stateValue[0].is_true = false;
        }
    }
    var reasons =[] 

    if(data.is_true){
        reasons.push(data.key)
    }
    
      var filter_data=this.commitmentDatasFilteration(stateValue)
   
     
    this.setState({OverDueCommitmentsReasons:stateValue,row_data:filter_data,loader:false,reasonsArr:reasons})

    }
 

    commitmentDatasFilteration(stateValue){

        var data = [...this.state.raw_datas]
        var selected_reasons = stateValue.filter((items,idx)=>{ return (items.is_true===true)})
        var arr=[]
        for (var i = 0; i < data.length; i++) {
            for (var j = 0; j < selected_reasons.length; j++) {
                if (selected_reasons.length > 0) {
                    if (data[i].overdue_reasons === selected_reasons[j].key) {
                        arr.push(data[i]);
                    }
                }
            }
        }
      
        return arr
    }

    isSessionButtonEnabled(){
        var a=false

        const thresholdTime = moment().set({ hour: 11, minute: 33, second: 0, millisecond: 0 });

        const currentTime = moment();
        if((this.state.filter_by_status=="assigned") ){

            if((currentTime.isSameOrAfter(thresholdTime)) ){
                return true  
            }else{
                return false

            }
        }else if ((currentTime.isSameOrAfter(thresholdTime)) ||((this.state.call_tasks_date!==(moment().format('DD MMM YYYY'))))){
          
            return true
        }

    }
    
    show_tab_menus(data,addl_key=null){
        return(
            data && data.map((items, idx) => {
                return (

                    <>
                    {addl_key!=null ?
                    

                    <div className='pointer my-2 d-flex align-items-center row mx-2' onClick={()=>this.multiselectOverdueReasons(items)}>
                    <p className={` mb-0  mx-1 text-center align-self-center py-2 px-4  ${!items.is_true? " overdue_reasons ":"select_overdue_reasons"} `} style={{fontSize:'13px'}} >{items.label} 
                
                    </p>
                    
                    </div>
                    :
                    <>
                     {(items.stateName=="due_commitment_date") ?
                    
                    <p className={` m-0 ${(items.key==="all")?"col-lg-2":"col-lg-3"}  text-center align-self-center py-2 px-3 ${(this.state[items.stateName] === items.key) ? "active_call_tasks_tab text-white call_task_padding " : "audit_kyc_text-black"} `} onClick={() => { this.get_input_handler(items.key,  items.stateName) }}>{items.label}</p>

                    :
                    <>
                     {(items.addl_stmt) ?
                            <div className={` mx-0   ${(this.state[items.stateName] == "day_20") ? "active_call_tasks_tab   my-2 px-0 py-1" : "audit_kyc_text-black py-2 px-3"}  align-self-center col-2`} onClick={() => { this.get_input_handler("day_20", items.stateName,"overdue_day") }}>
                                <p className={` m-0 text-center`} > Day 20 </p>
                                <p className={`text-center m-0  ${(this.state[items.stateName] == "day_20") ? " text-white" : "audit_kyc_text-black"}`} style={{ fontSize: '7px' }}> Call Additional Number</p>
                            </div> :
                            <p className={` m-0  ${(this.state[items.stateName] === items.key) ? (items.key == "commitment_call" ? "active_call_tasks_tab text-white my-2" : "active_call_tasks_tab  call_task_padding") : "audit_kyc_text-black"} text-center align-self-center py-2 px-3  col-2`} onClick={() => { this.get_input_handler(items.key,items.stateName,"overdue_day") }}> {items.label}</p>
                        }
                    </>
                    }
                       
                    </>
                    
                    }
                    </>
                )
            })
        )
    }

    toggle_modal(type){
        if(type=="yes"){
            this.setState({using_physicalphone:true})      
              this.getCallLogDetails("pysical_phone")
        } else if(type =="no"){
            this.setState({using_physicalphone:false})
            this.getCallLogDetails()
        }
        this.setState({setCallTypeConfirm:false})
    }

    render() {

        return (
            <div className={``}>
          
                <h2 className='text-white  text-center mt-3 font_size_xl'> <b>Overdue Recoveries</b></h2>

                <div className='row col-lg-12  col-md-12 col-sm-12 mx-0 mt-5 d-flex justify-content-center align-items-center' >
                    <div className={` col-lg-3  col-md-3 col-sm-12 pointer   align-items-center `} onClick={ this.state.initial_tab === "call_task" ? null: () =>this.get_input_handler("call_task", "initial_tab")}>
                        <p className={`p-3 text-center m-0 text-white sizem ${(this.state.initial_tab === "call_task") ? " initial_active_tab initial_active_tab" : "un_active_tab"}`}> Overdue Call Tasks </p>
                    </div>

                    <div className={`  col-lg-3 col-md-3 col-sm-12 pointer  justify-content-center align-items-center  `} onClick={() => this.get_input_handler("due_by_days", "initial_tab")}>
                        <p className={`p-3 text-center m-0 text-white sizem ${(this.state.initial_tab === "due_by_days") ? " initial_active_tab active_text" : "un_active_tab"}`}> Overdue by Days </p>
                    </div>

                    <div className={` col-lg-3 col-md-3 col-sm-12 pointer  justify-content-center align-items-center  `} onClick={() => this.get_input_handler("commitment_date", "initial_tab")}>
                        <p className={`p-3 text-center m-0 text-white sizem ${(this.state.initial_tab === "commitment_date") ? " initial_active_tab active_text" : "un_active_tab"}`}> Overdue by Commitment Date </p>
                    </div>
                </div>
                {(this.state.initial_tab == "call_task") &&
                    <div className='row d-flex justify-content-center align-items-center mx-0 mt-5'>
                        <div className='col-md-12 col-sm-12 col-lg-3 mt-4 text-white'>
                            <label> <b>Filter by Status </b></label>
                            <div className='col-12   pl-0 date_select call_tasks_filter' id="call_tasks_filter" style={{ position: 'relative' }}>
                                <Select
                                    onChange={(e) => { this.getStatus(e) }}
                                    value={this.state.select_value}
                                    options={this.getMultiSelectOptions('delinquency_status_web')} 
                                    placeholder="Days"
                                    styles={this.select_custom_styles()}
                                    classNamePrefix="my-select"
                                    defaultValue={{ value: this.state.filter_by_status, label: this.state.filter_by_status == "closed" ? 'Skipped' : capitalize_first_letter(this.state.filter_by_status) }}
                                />
                                <p className='m-0 text-white' style={{ position: 'absolute' }}><FiFilter size={"15"} /></p>
                            </div>
                        </div>

                        <div className='col-md-12 col-sm-12 col-lg-3 mt-4'>
                            <label> <b>Filter by Date</b></label>
                            <div className="form-group pointer mt-0 col-12  p-0  py-0 d-flex align-items-center row" >



                                <div className={`col-12   p-0 date_select call_tasks_filter ${(this.state.filter_by_status!="assigned")?"":"disable_div" }`} id="call_tasks_filter" style={{ position: 'relative' }}>
                                    <DatePicker closeOnSelect={true}
                                        isValidDate={(current) => {
                                            const today = moment().startOf('day');
                                            return current.isSameOrBefore(today, 'day');
                                        }}
                                        value={this.state.call_tasks_date?  moment(this.state.call_tasks_date).format('DD MMM YYYY') : moment().format('DD MMM YYYY')} onChange={(value) => this.get_input_handler(moment(value).format("YYYY-MM-DD"), "call_tasks_date")} inputProps={{ id: 'call_tasks_date', name: 'Referral_from', autoComplete: 'off' }} date
                                        ={"DD MM YYYY"}
                                        showMonthYearPicker timeFormat={false} required="required" onKeyDown={(value) => value.preventDefault()} />
                                    <label className='col-1.5 no-margin d-flex p-0 ' style={{ position: 'static' }} htmlFor='call_tasks_date'>
                                        <p className='m-0 text-white' style={{ position: 'absolute' }}><BiCalendar size={"18"} /></p>
                                    </label>

                                </div>


                            </div>



                        </div>
                    </div>
                }
                <div className={`row d-flex justify-content-center align-items-center mx-0 ${(this.state.initial_tab !== "call_task") ? "mt-5" : "mt-4"}`}>
                    <div className={`col-md-12 col-sm-12 c d-flex align-items-center col-lg-7`}>

                        {(this.state.initial_tab === "call_task") ?
                            <div className='row m-0 d-flex justify-content-around col-12 past_churn_filter call_tasks_filter_bg pointer' >
                               {this.show_tab_menus(this.state.Overdue_call_tasks_menu)}
                            </div>
                            :
                            <>
                                {(this.state.initial_tab === "due_by_days") ?

                                    <div className='row col-lg-12 pointer col-md-12 col-sm-12 mx-0 mt-5 d-flex justify-content-between align-items-center' >
                                        <p className={`m-0 p-3 col-lg-2 text-center ${(this.state.over_due_byDays == "1_4_days") ? "commitment_date_active_tab" : "un_active_tab overdueRecovery_un_active_tab text-white"}`} onClick={() => { this.get_input_handler("1_4_days", "over_due_byDays") }}>1-4 Days </p>
                                        <p className={`m-0 p-3 col-lg-2 text-center ${(this.state.over_due_byDays == "5_10_days") ? "commitment_date_active_tab" : "un_active_tab overdueRecovery_un_active_tab text-white"}`} onClick={() => { this.get_input_handler("5_10_days", "over_due_byDays") }}>5-10 Days </p>
                                        <p className={`m-0 p-3 col-lg-2  text-center ${(this.state.over_due_byDays == "11_20_days") ? "commitment_date_active_tab" : "un_active_tab overdueRecovery_un_active_tab text-white"}`} onClick={() => { this.get_input_handler("11_20_days", "over_due_byDays") }}>11-20 Days </p>
                                        <p className={`m-0 p-3 col-lg-2  text-center ${(this.state.over_due_byDays == "30_40_days") ? "commitment_date_active_tab" : "un_active_tab overdueRecovery_un_active_tab text-white"}`} onClick={() => { this.get_input_handler("30_40_days", "over_due_byDays") }}>30-40 Days </p>
                                        <p className={`m-0 p-3 col-lg-2 text-center ${(this.state.over_due_byDays == "after_40_days") ? "commitment_date_active_tab" : "un_active_tab overdueRecovery_un_active_tab text-white"}`} onClick={() => { this.get_input_handler("after_40_days", "over_due_byDays") }}>{" >40Days"} </p>

                                    </div>

                                    :
                                    <>
                                    <div className='row m-0 d-flex justify-content-around col-12 px-0 py-2 past_churn_filter call_tasks_filter_bg pointer' >
                                       
                                        {this.show_tab_menus(this.state.Overdue_by_commitment_date)}
                                        
                                    </div>

                                  

                                
                                    </>

                                }
                            </>
                        }
                    </div>

                </div>
                {(this.state.initial_tab === "commitment_date")&&
                <div className='row d-flex justify-content-center align-items-center col-12 mt-4 mx-0'>
               <div className=' d-flex justify-content-center align-items-center flex-wrap m-0 col-lg-7 col-md-12 col-sm-12'>
                  
                {this.state.OverDueCommitmentsReasons ?this.show_tab_menus(this.state.OverDueCommitmentsReasons,"addl_key"):""}                                       
                </div>                               
                </div>
                }

                { this.state.loader ?
                 <div className='d-flex justify-content-center mt-5'>
                    <div>
                      <p className='text-light mt-3 text-center'>Loading...</p>
                      <div className="product_takeup_loader"></div>
                      </div>
                    </div>:
                <>

                {(this.state.initial_tab == "call_task") ? ((this.state.active_tab != "commitment_call") ?

                    (<div className='row d-flex justify-content-center align-items-center mx-0 mt-5'>

                        <div className='col-md-12 col-sm-12 col-lg-4 row d-flex align-items-center justify-content-center pointer'>
                            <div className={`p-0 col-6  ${(this.state.active_session == "morning") ? "active_session_bottom" : "bottom_border"}`} onClick={() => { this.get_input_handler("morning", "active_session","slot") }} style={{ position: 'relative' }}>
                                <p className={`p-0 text-center `} > <RiSunFill className='pr-2' size={"30"} /> Morning</p>

                                {this.state.active_session == "morning" &&
                                    <p className='tab_title_position m-0 text_white '>{this.state.row_data ?this.state.row_data.length:"0"}</p>
                                }
                            </div>
                            <div className={`p-0 col-6  ${(this.state.active_session == "evening") ? "active_session_bottom " : "bottom_border"} ${this.isSessionButtonEnabled() ? "":"disable_div"}`} onClick={() => { this.get_input_handler("evening", "active_session","slot") }} style={{ position: 'relative' }}>
                                <p className={`p-0 text-center`}> <WiSunset className="pr-2" size={"30"} />Evening</p>
                                {this.state.active_session == "evening" &&
                                    <p className='tab_title_position m-0 text_white '>{this.state.row_data ?this.state.row_data.length:"0"}</p>
                                }
                            </div>

                        </div>

                    </div>):<div className='mt-5'></div>) :"" 
                }
                <div className='mt-3 row m-0'>
                    
                    <>
                    {this.state.row_data && this.state.row_data.length > 0 ?
                        <div className={`cust_next_follow_up mt-2  w-100 col-lg-12 col-md-12 col-sm-12`} >

                            <BasicReactTable row_data={this.state.row_data ? this.state.row_data : []} column_data={this.state.column_data} FName="delinquency_reports"  global_search={false} pagination={true} default_page_size={10} header_class='table_background_header'/>
                        </div>
                        :
                        <p className='text-white text-center mb-0 mt-5 col-12 '>NO DATA</p>
                    }
                    </>
                   
                </div>
                </>
                }

                {this.state.setCallTypeConfirm &&
                  <ConfirmModal alert_message={"Please note that this activity will be logged.\nKindly confirm the device you used to initiate the call?"} is_toggle={(a) => { this.toggle_modal(a) }} 
                  SoftphoneCallOptions= {true}/>
                
                }

                {this.state.callLogModal && !this.props.softPhoneCtxState.showCallLogModal &&
                    <CallLogModal datas={this.state} getDatas={()=>this.getDatas()}
                        callLogEntry={(a,b)=>this.callLogEntry(a,b)} getOdDatasBydays={()=>this.getOdDatasBydays()} handleClose={(a,b) => this.handleClose(a,b)} softPhoneCtxState={this.props.softPhoneCtxState}
                        softPhoneCtxMethods={this.props.softPhoneCtxMethods} navigateToDeliquencyRoute={this.props.navigateToDeliquencyRoute} 
                        delinquencyOrTaskId={this.props.deliquencyId || this.props.delinquencyTaskId}
                        isPendingCallLog={this.props.isPendingCallLog}
                    />
                }

                {/* {this.props.softPhoneState.softPhone && (!this.props.softPhoneState.missed_calls_view)   && (!this.props.softPhoneState.isIncomingCall) && (!this.props.softPhoneState.outgoingCall) && (this.state.softphone_is_supported) && (!this.state.using_physicalphone )&&  
                
				<div className='Call_log_section zoomIn_animation over_view_z_index'>
					<Softphone screen_from ={"delinquency"} cust_id={this.state.Call_log_data?this.state.Call_log_data.cust_id:null}/>
             
				</div>
			     } */}
            </div>
        );

    }
}

const mapStateToProps = (state) => {
	return {
	  softPhoneState: state.cs_soft_phone,

	};
  };

const mapDispatchToProps ={
    setValuesIntoRedux
  
  };


export default connect(mapStateToProps, mapDispatchToProps)(OverdueCallTasks);





