import React, { lazy, LazyExoticComponent, Suspense } from "react";
import { resolvePromiseWithDelay } from "../../helpers/common_helper";
import FallBackUI from "../PhoneCom/Components/fallBackUI";

const DistributorReferal = lazy(() => resolvePromiseWithDelay(import("./components/distributorReferal")));

const Index = ({ filter }: { filter: any }) => {
  return (
    <Suspense fallback={<FallBackUI />}>
      <DistributorReferal filterProps={filter} />
    </Suspense>
  );
};

export default Index;
