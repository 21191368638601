import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { PHYSICAl_PHONE, REQUIRED, SOFT_PHONE } from "../../../../consts";
import { NEW_CUST_ORIENTATION_CALL_LOG_MODAL } from "../../../../context/softPhoneCtx/softPhoneCtx";
import { useSoftPhoneCtx } from "../../../../context/softPhoneCtx/useSoftPhoneCtx";
import { useSoftPhoneTimerCtx } from "../../../../context/softPhoneTimerCtx/useSoftPhoneTimerCtx";
import { get_path } from "../../../../helpers/common_helper";
import { call_fetch } from "../../../../helpers/fetch_helper";
import { checkUserHasCallPriv } from "../../../../helpers/helper";
import FormProvider from "../../../CommonComponent/rhfComponents/formProvider";
import { OrientationNewCustProps } from "../queryHooks/useGetNewCustOrientationList";
import CustInquiryChecklistUI from "./custInquiryChecklistUI";
import RemarksUI from "./remarksUI";

type InquiriesType = Record<string, boolean>;

type FormValues = {
  remarks: string;
  inquries: InquiriesType;
};

// schema
const FormSchema = Yup.object().shape({
  remarks: Yup.string()
    .required(REQUIRED)
    .max(150, "Only 150 characters are allowed"),
  inquries: Yup.object().test("isAllFieldsChecked", REQUIRED, (obj) =>
    Object.values(obj).every((value) => value === true)
  ),
});

/**
 *
 * @param inquiryList
 * @returns the form UI, *the inquiry cheklist and remarks are kept inside the form
 */
const FormUI = ({
  inquiryList,
  newCustData,
}: {
  inquiryList: Record<string, boolean>;
  newCustData: OrientationNewCustProps;
}) => {
  // state
  const [disableForm, setdisableForm] = useState(false);

  // query
  const queryClient = useQueryClient();

  // const
  const isCallInitiatedViaSoftphone = checkUserHasCallPriv(); // if the user has the softphone priv the call should be initiated via softphone

  // ctx
  const {
    softPhoneState: {
      callLogDetails: { selectedNewCustOrientationtaskId },
    },
    methods: {
      closeCallLogModal,
      handlePhysicalPhonePendingLog,
      triggerManualCallLog,
      triggerCallLogModalForNewCustOrientation,
    },
  } = useSoftPhoneCtx();

  const {
    softPhoneTimerState: { timer },
  } = useSoftPhoneTimerCtx();

  // hookForm
  const methods = useForm<FormValues>({
    resolver: yupResolver(FormSchema),
    defaultValues: {
      inquries: inquiryList,
      remarks: "",
    },
    disabled: disableForm,
  });

  // rhf returns
  const { handleSubmit } = methods;

  // handler

  const closeCallLogModalHandler = () => {
    closeCallLogModal();
    triggerManualCallLog({
      screen: "",
      isManualCallLog: false,
    });
    triggerCallLogModalForNewCustOrientation({
      screen: "",
      selectedNewCustOrientationtaskId: "",
      showCallLogModal: false,
    });
  };

  const onSubmit = handleSubmit(async (formData) => {
    if (isCallInitiatedViaSoftphone) {
      // checking current loggedin user has any pending call log
      // const { callLog } = await checkUserHasAnyPendingCallLog({ screen: "" });
      // if (callLog.call_log_id) {
      // softphone call log
      const res = await call_fetch(
        // @ts-ignore
        `${get_path("app")}/task/update_orientation_task`,
        {
          type: SOFT_PHONE,
          // call_log_id: callLog.call_log_id,
          task_id: selectedNewCustOrientationtaskId,
          cust_id: newCustData.cust_id,
          remarks: formData.remarks,
          screen: NEW_CUST_ORIENTATION_CALL_LOG_MODAL,
        }
      );
      if (res?.status === "success") {
        alert(res?.message ?? "Logged successfully");
        closeCallLogModalHandler();
      }
      queryClient.invalidateQueries({
        queryKey: ["new_cust_orientation_list"],
      });
      // } else {
      //   alert("Please ensure the customer interaction before submitting");
      // }
    } else {
      // physical phone call log
      const endTime = moment();
      const startTime = moment().subtract(timer, "seconds");
      const res = await call_fetch(
        // @ts-ignore
        `${get_path("app")}/task/update_orientation_task`,
        {
          type: PHYSICAl_PHONE,
          task_id: selectedNewCustOrientationtaskId,
          cust_id: newCustData.cust_id,
          call_end_time: endTime.format("YYYY-MM-DD HH:mm:ss"),
          call_start_time: startTime.format("YYYY-MM-DD HH:mm:ss"),
          remarks: formData.remarks,
          mobile_num: newCustData.mobile_num,
          screen: NEW_CUST_ORIENTATION_CALL_LOG_MODAL,
        }
      );
      if (res?.status === "success") {
        if(res?.data?.action === "lastcall") { // if user have any pending call log in physical phone
          const acknwdged = window.confirm(res?.data?.message);
          if (acknwdged) {
            handlePhysicalPhonePendingLog({ callLog: res?.data });
          }
        } else {
          alert("Logged successfully");
        }
        closeCallLogModalHandler();
      }
      queryClient.invalidateQueries({
        queryKey: ["new_cust_orientation_list"],
      });
    }
  });

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <CustInquiryChecklistUI
        setdisableForm={setdisableForm}
        eligibleAmount={newCustData.eligible_amount}
      />
      <RemarksUI />
    </FormProvider>
  );
};

export default FormUI;
