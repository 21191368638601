import React from "react";
import { Typography, Box } from "@mui/material";
import { HeaderVarientH3Typo } from "../../CommonComponent/typography";
import { useForm } from "react-hook-form";
import RHFTextField from "../../CommonComponent/rhfComponents/rhfTextField";
import CustomModal from "../../../MUI/Components/CustomModal";  
import FormProvider from "../../CommonComponent/rhfComponents/formProvider";
import CustomButton from "../../../MUI/Components/CustomButton"; // Import CustomButton
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { referAnAgent } from "../../../actions/common_actions";
import { get } from "../../../helpers/storage_helper";
 


const FormSchema = Yup.object().shape({
  agentName: Yup.string().required("Agent Name is required"),
  ContactNumber: Yup.string()
  .matches(/^[0-9]+$/, "Contact Number must contain only numbers") 
  .length(9, "Contact Number must be of 9 digits") 
  .required("Contact Number is required"),

 
  alternativeContact: Yup.string()
  .matches(/^[0-9]+$/, "Alternative Number must contain only numbers") 
  .length(9, "Alternative Number must be of 9 digits") 
  .required("Alternative Number is required"),


  customerMobile: Yup.string()
  .matches(/^[0-9]+$/, "Customer Mobile Number must contain only numbers") 
  .length(9, "Customer Mobile Number must be of 9 digits") 
  .required("Customer Mobile Number is required"),
});

// Define Props Type
interface CreateLeadProps {
  open: boolean;
  onClose: () => void;
}

const CreateLead: React.FC<CreateLeadProps> = ({ open, onClose }) => {
  // Hook Form
  const methods = useForm({
    resolver: yupResolver(FormSchema),
    mode: "onChange", // Enables live validation
    defaultValues: {
      agentName: "",
      ContactNumber: "",
      alternativeContact: "",
      customerMobile: "",
    },
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const { reset } = methods;

  const handleClose = () => {
    reset(); // Clear form values
    onClose(); 
  };
  

const onSubmit = async (data: any) => {

  // Transform the data to match the required format
  const formattedData = {
    
          name: data.agentName,
          mobile_num: data.ContactNumber,
          alt_mobile_num: data.alternativeContact,
          referrer_mobile_num: data.customerMobile,
          country_code: get('market').country_code, 
          time_zone: get('market').time_zone 
    
  };

  try {
      const response = await referAnAgent(formattedData);
      alert("Lead Referred Successfully")
      reset(); // Reset form after successful submission
      onClose();

  } catch (error) {
      reset(); // Reset form after successful submission
  }
};



  return (
    <CustomModal
      show={open}
      onClose={handleClose}
      header={<HeaderVarientH3Typo text="New Lead" />} // Title
      size="md" 
      body={
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          {/* Agent Information Section */}
          <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
            Agent Information{" "}
            <Typography component="span" sx={{ color: "red" }}>
              *
            </Typography>
          </Typography>

          {/* First Row: Agent Name & Contact Number */}
          <Box display="flex" gap={2} sx={{ marginTop: 2 }}>
            <RHFTextField
              name="agentName"
              label="Agent Name"
              placeholder="Enter Agent Name"
              fullWidth
              sx={{
                backgroundColor: "#293145",
                maxWidth: "400px", 
                "&:focus-within": { backgroundColor: "#35425a" },
              }}
            />
            <RHFTextField
              name="ContactNumber"
              label="Contact Number"
              placeholder="e.g. 9876543215"
              fullWidth
              sx={{
                maxWidth: "400px",
                gap: 3,
                backgroundColor: "#293145", 
                "&:focus-within": { backgroundColor: "#35425a" },
              }}
              inputProps={{ maxLength: 9 }}
              onInput={(e) => {
                const input = e.target as HTMLInputElement; 
                input.value = input.value.replace(/[^0-9]/g, ""); 
              }}
            />
          </Box>

          {/* Space between rows */}
          <Box sx={{ height: 16 }} />

          {/* Second Row: Alternative Number */}
          <Box display="flex">
            <RHFTextField
              name="alternativeContact"
              label="Alternative Number"
              placeholder="e.g. 9876543215"
              fullWidth
              sx={{
                backgroundColor: "#293145",
                "&:focus-within": { backgroundColor: "#35425a" },
                maxWidth: "400px",
              }}
              inputProps={{ maxLength: 9 }}
              onInput={(e) => {
                const input = e.target as HTMLInputElement; // Explicitly cast
                input.value = input.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
              }}
            />
          </Box>

          {/* Referrer Information Section */}
          <Typography variant="subtitle1" sx={{ marginTop: 3 }}>
            Referrer Details{" "}
            <Typography component="span" sx={{ color: "red" }}>
              *
            </Typography>
          </Typography>

          {/* First Row: Customer Mobile No */}
          <Box display="flex" gap={2} sx={{ marginTop: 2 }}>
            <RHFTextField
              name="customerMobile"
              label="Customer Mobile Number"
              placeholder="e.g. 9876543215"
              fullWidth
              sx={{
                backgroundColor: "#293145",
                "&:focus-within": { backgroundColor: "#35425a" },
                maxWidth: "400px",
              }}
              inputProps={{ maxLength: 9 }}
              onInput={(e) => {
                const input = e.target as HTMLInputElement; // Explicitly cast
                input.value = input.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
              }}
            />
          </Box>
        </FormProvider>
      }
      footer={
        <CustomButton
          text="Submit"
          variant="contained"
          onClick={handleSubmit(onSubmit)}
          isDisabled={!isValid}  // Disable button when form is invalid
          style={{
            backgroundColor: isValid ? "#1976d2" : "gray", // Change color when disabled
            color: "white",
          }}
        />
      }
    />
  );
};

export default CreateLead;
