import AddAlarmIcon from "@mui/icons-material/AddAlarm";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { IOSTypeSwitch } from "../../CommonComponent/iosTypeSwitch";
import { CSStatusProp } from "./softPhoneHeaderSection";

const StatusToggleUI = ({
  csDetails,
  updateCSDetails,
  isUpdatingCsDetails,
}: {
  csDetails: CSStatusProp;
  updateCSDetails: Function;
  isUpdatingCsDetails: boolean;
}) => {
  // const
  const switchIconStyle = {
    width: "22px",
    height: "22px",
    backgroundColor: "#fff",
    borderRadius: "50%",
    color: "#000",
    p: "3px",
  };
  const isCSOnBreak = csDetails.on_break === 1;

  // hook
  const theme = useTheme();

  // state
  const [showModal, setshowModal] = useState(false);
  // const [togglingCSStatus, settogglingCSStatus] = useState(false);

  // cb
  const statusToggleHandler = () => {
    // settogglingCSStatus(true);
    updateCSDetails({
      ...csDetails,
      logout: false,
      break: csDetails?.on_break === 1 ? "end" : "start",
    });
    setTimeout(() => {
      // for stablity
      setshowModal(false);
      // settogglingCSStatus(false);
    }, 1000);
  };

  return (
    <>
      <IOSTypeSwitch
        sx={{
          "& .MuiSwitch-track": {
            backgroundColor: isCSOnBreak
              ? "red !important"
              : "#E9E9EA !important",
            opacity: "1 !important",
          },
          // mt: 1,
        }}
        icon={
          <AddAlarmIcon
            sx={{
              ...switchIconStyle,
              backgroundColor: "#4f5167",
              color: "#fff",
            }}
          />
        }
        checkedIcon={
          isUpdatingCsDetails ? (
            <ProgressUI />
          ) : (
            <AddAlarmIcon sx={{ ...switchIconStyle, color: "red" }} />
          )
        }
        checked={isCSOnBreak}
        onChange={(e) => {
          const checked = e.target.checked;
          if (checked)
            setshowModal(true); // asks confirmation before status change
          else statusToggleHandler();
        }}
      />
      {/* confirm dialog ui */}
      <Dialog
        open={showModal}
        PaperProps={{
          style: {
            backgroundColor: theme.palette.primary.main,
            borderRadius: "20px",
          },
        }}
        onClose={() => setshowModal(false)}
      >
        <DialogTitle sx={{ color: "#fff" }}>
          {"Are you sure want to take a break?"}
        </DialogTitle>
        <DialogActions sx={{ m: "15px" }}>
          <Button
            onClick={statusToggleHandler}
            variant="contained"
            color="secondary"
            sx={{ minWidth: "75px" }}
            autoFocus
          >
            {isUpdatingCsDetails ? <ProgressUI /> : "Yes"}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setshowModal(false)}
            disabled={isUpdatingCsDetails}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default StatusToggleUI;

const ProgressUI = () => (
  <CircularProgress
    sx={{
      "&.MuiCircularProgress-root": {
        width: "22px!important",
        height: "22px!important",
      },
    }}
  />
);
