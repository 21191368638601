import * as yup from "yup";
import moment from "moment";

const AuditorVerificationSchema = yup.object({
  id_proof_num: yup.string().required(""),
  first_name: yup.string().required(""),
  last_name: yup.string().required(""),
  gender: yup.string().required(""),
  
  dob: yup
    .mixed<moment.Moment | string>()
    .required("")
    .test("isValidDate", "Invalid date", (value) => 
      moment(value, "YYYY-MM-DD", true).isValid() || moment.isMoment(value)
    ),

  company_code: yup.string().required(""),
  enterprise_name: yup.string().required(""),
  owner_name: yup.string().required(""),
  
  registration_date: yup
    .mixed<moment.Moment | string>()
    .required("")
    .test("isValidDate", "Invalid date", (value) => 
      moment(value, "YYYY-MM-DD", true).isValid() || moment.isMoment(value)
    ),

  nationalIdIsCorrect: yup.boolean().oneOf([true], "").required(),
  rdbIsCorrect: yup.boolean().oneOf([true], "").required(),
  isOwnedAccount: yup.boolean().oneOf([true], "").required(),
});

export default AuditorVerificationSchema;
