import { Box, Grid } from "@mui/material";
import React from "react";
import SkeletonLoader from "../../common/component/skeletonLoader";

const StepperLoader = () => {
  return (
    <Box>
      <Box
        sx={{
          background: "#01062C",
          px: 3,
          py: 5,
          m: 4,
          minHeight: "calc(100vh - 123px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          overflow: "hidden",
          borderRadius: "10px",
        }}
      >

        <Box display={"flex"} justifyContent={"center"}>
        <SkeletonLoader
                      variant="text"
                      width={250}
                      height={50}
                      overRideStyles={{ bgcolor: "#1A2238", my:3 }}
                    />  
        </Box>
        <Grid container spacing={4} justifyContent="center">
          {Array(5)
            .fill("")
            .map((_, index) => (
              <Grid item key={index} mx={2}>
                <Box alignItems={"center"}>
                  <Box sx={{ color: "white" }}>
                    <SkeletonLoader
                      variant="text"
                      width={150}
                      height={20}
                      overRideStyles={{ bgcolor: "#1A2238" }}
                    />
                  </Box>
                  <Box>
                    <SkeletonLoader
                      variant="text"
                      width={150}
                      height={20}
                      overRideStyles={{ bgcolor: "#1A2238" }}
                    />
                  </Box>
                </Box>
              </Grid>
            ))}
        </Grid>

        <Grid container spacing={4} justifyContent="center" py={5}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mt: 3,
              width: "80%",
            }}
          >
            <SkeletonLoader
              variant="circular"
              width={25}
              height={25}
              overRideStyles={{
                bgcolor: "#1A2238",
                mx: 1,
                borderRadius: "50px",
              }}
            />
            <SkeletonLoader
              variant="rectangular"
              width={"30%"}
              height={5}
              overRideStyles={{ bgcolor: "#1A2238", mx: 1 }}
            />
            <SkeletonLoader
              variant="circular"
              width={25}
              height={25}
              overRideStyles={{
                bgcolor: "#1A2238",
                mx: 1,
                borderRadius: "50px",
              }}
            />
            <SkeletonLoader
              variant="rectangular"
              width={"30%"}
              height={5}
              overRideStyles={{ bgcolor: "#1A2238", mx: 1 }}
            />
            <SkeletonLoader
              variant="circular"
              width={25}
              height={25}
              overRideStyles={{
                bgcolor: "#1A2238",
                mx: 1,
                borderRadius: "50px",
              }}
            />
          </Box>
        </Grid>

        <Grid container spacing={4} justifyContent="center" py={5}>
        <SkeletonLoader
            variant="text"
            width={'60%'}
            height={'350px'}
            overRideStyles={{ bgcolor: "#1A2238", mb: 2, borderRadius:'20px' }}
          />
        </Grid>
      </Box>

     
    </Box>
  );
};

export default StepperLoader;
