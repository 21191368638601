import { Box, Button, Grid } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { FormDefaultProps } from "..";
import { get_path, getSelectOptions } from "../../../../helpers/common_helper";
import { call_fetch } from "../../../../helpers/fetch_helper";
import {
  RhfOptionTypeDataKeyOmited,
  RhfOptionTypeInTermsOfDataKey,
} from "../../../../type";
import AuditorsListAutoComplete from "../../../CommonComponent/commonAutoCompleteDD/auditorsListAutoComplete";
import CSListAutoComplete from "../../../CommonComponent/commonAutoCompleteDD/csListAutoComplete";
import RMSupportListAutoComplete from "../../../CommonComponent/commonAutoCompleteDD/rmSupportListAutoComplete";
import RHFAutocomplete from "../../../CommonComponent/rhfComponents/rhfAutoComplete";
import RHFCheckBoxField from "../../../CommonComponent/rhfComponents/rhfCheckBoxField";
import RhfDatePicker from "../../../CommonComponent/rhfComponents/rhfDatePicker";
import RHFTextField from "../../../CommonComponent/rhfComponents/rhfTextField";
import RMsListAutoComplete from "../../../CommonComponent/commonAutoCompleteDD/rmSListAutoComplete";

const FilterFormUI = ({
  setsort,
  defaultValues,
  callerOptions,
}: {
  setsort: Function;
  defaultValues: FormDefaultProps;
  callerOptions: RhfOptionTypeDataKeyOmited[];
}) => {
  const {
    watch,
    formState: { isValid, errors },
    getValues,
    setValue,
  } = useFormContext();

  const caller = getValues("caller");
  const individualCalls: boolean = getValues("individualCallLogs");
  const callDirection = getValues("callDirection");
  const params = useParams<{ cust_id?: string }>();

  const enableButtonCon = isValid && Object.keys(errors).length === 0;
  const filterFields = [
    { name: "startDate", label: "Start date", type: "date", extra_key: "" },
    { name: "endDate", label: "End date", type: "date", extra_key: "" },
    {
      name: "caller",
      label: "Caller",
      type: "select",
      extra_key: "tollfree_caller",
    },
    { name: "callerName", label: "Caller Name", type: "select", extra_key: "" },
    {
      name: "callDirection",
      label: "Call Direction",
      type: "select",
      extra_key: "call_directions",
    },
    {
      name: "callPurpose",
      label: "Call Purpose",
      type: "select",
      extra_key: "call_purpose",
    },
    {
      name: "custIdOrMblNo",
      label: "Customer",
      type: "text",
      extra_key: "Customer ID/Mobile No",
    },
    {
      name: "individualCallLogs",
      label: "Individual Call Logs",
      type: "checkBox",
      extra_key: "",
    },
  ];

  useEffect(() => {
    // If the individual checkbox is not checked, the Customer field should remain empty
    defaultValues;
    if (individualCalls === false) {
      setValue("custIdOrMblNo", defaultValues.custIdOrMblNo);
    }
  }, [individualCalls]);

  useEffect(() => {
    // If the Caller is changed,  the Caller Name should be set to 'All.'
    setValue("callerName", defaultValues.callerName);
  }, [caller]);

  useEffect(() => {
    // If the call direction is selected, the caller purpose should be set to 'All.'
    setValue("callPurpose", defaultValues.callPurpose);
  }, [callDirection]);

  const getDataFromMaster = (
    data_key: string
  ): RhfOptionTypeDataKeyOmited[] => {
    let result: RhfOptionTypeInTermsOfDataKey[];

    if (data_key === "call_directions") {
      result = [
        {
          data_code: "incoming",
          data_value: "Incoming",
          id: "incoming",
          name: "",
          actualId: "",
        },
        {
          data_code: "outgoing",
          data_value: "Outgoing",
          id: "Outgoing",
          name: "",
          actualId: "",
        },
      ];
    } else {
      // The call purpose will change based on the selected call direction
      if (data_key === "call_purpose" && callDirection?.id) {
        data_key = callDirection.id + "_call_purpose";
      }
      // @ts-ignore
      result = getSelectOptions(data_key, false, null, "get_options");
    }

    const selectOptions: RhfOptionTypeDataKeyOmited[] = [
      { id: "all", name: "ALL", actualId: "all" },
      ...(result &&
        result
          .map((item) => ({
            name: item.data_value,
            id: item.data_code,
            actualId: item.id,
          }))
          .sort((a, b) =>
            a.name.localeCompare(b.name, "en", { sensitivity: "base" })
          )),
    ];

    return selectOptions;
  };

  const getFilterFields = ({
    name,
    label,
    type,
    extra_key,
    index,
  }: {
    name: string;
    label: string;
    type: string;
    extra_key: string;
    index: number;
  }) => {
    // The text field should be displayed only if individual calls are selected.
    if (type === "text" && individualCalls === true && !params.cust_id) {
      return (
        <Grid item xs={12} md={6} lg={3} key={index}>
          <RHFTextField
            name={name}
            label={label}
            placeholder={extra_key}
            value={getValues(name)}
          />
        </Grid>
      );
    } else if (type === "date") {
      return (
        <Grid item xs={12} md={6} lg={3} key={index}>
          <RhfDatePicker
            name={name}
            label={label}
            format="DD MMM YYYY"
            value={getValues(name)}
          />
        </Grid>
      );
    } else if (type === "checkBox") {
      return (
        <Grid item xs={12} md={6} lg={3} key={index}>
          <Box>
            <RHFCheckBoxField
              name={name}
              label={label}
              defaultChecked={getValues(name)}
            />
          </Box>
        </Grid>
      );
    } else if (
      type === "select" &&
      name !== "callerName" &&
      (name !== "callPurpose" ||
        ["incoming", "outgoing"].includes(callDirection?.id))
    ) {
      // Items are sourced from the master data.
      // The call purpose will be displayed only if the call direction is selected.
      return (
        <Grid item xs={12} md={6} lg={3} key={index}>
          <RHFAutocomplete
            name={name}
            label={label}
            options={
              name === "caller"
                ? [
                    { id: "all", name: "All", actualId: "all" },
                    ...callerOptions,
                  ]
                : getDataFromMaster(extra_key)
            }
            getOptionLabel={(option) =>
              typeof option === "object" ? option.name : option
            }
            getOptionKey={(option) =>
              typeof option === "object" ? option.id : option
            }
            isOptionEqualToValue={(option, value) =>
              typeof option === "object" && typeof value === "object"
                ? option.id === value.id
                : option === value
            }
          />
        </Grid>
      );
    } else if (type === "select" && name === "callerName" && caller) {
      // The caller name should be displayed only the caller name is selected
      if (caller.id === "verification_officer") {
        return (
          <Grid item xs={12} md={6} lg={3} key={index}>
            <AuditorsListAutoComplete
              name={name}
              label="Verification Officer"
              actAs="dd"
              roleCodes={["verification_officer"]}
            />
          </Grid>
        );
      } else if (caller.id === "operations_auditor") {
        return (
          <Grid item xs={12} md={6} lg={3} key={index}>
            <AuditorsListAutoComplete
              name={name}
              label="Auditor"
              actAs="dd"
              roleCodes={["operations_auditor"]}
            />
          </Grid>
        );
      } else if (caller.id === "customer_success_officer") {
        return (
          <Grid item xs={12} md={6} lg={3} key={index}>
            <CSListAutoComplete
              name={name}
              label="Customer Success Officer"
              actAs="dd"
            />
          </Grid>
        );
      } else if (caller.id === "rm_support") {
        return (
          <Grid item xs={12} md={6} lg={3} key={index}>
            <RMSupportListAutoComplete
              name={name}
              label="Relationship Manager Support"
              actAs="dd"
            />
          </Grid>
        );
      } else if(caller.id === 'relationship_manager') {
        return (
          <Grid item xs={12} md={6} lg={3} key={index}>
            <RMsListAutoComplete
              name={name}
              label="Relationship Manager"
              actAs="dd"
            />
          </Grid>
        )
      }
    }
  };

  return (
    <Box p={2}>
      <Grid container spacing={2}>
        {filterFields.map((val, i) => getFilterFields({ ...val, index: i }))}
        <Grid item xs={12} textAlign="center">
          <Button
            sx={{
              backgroundColor: "#0594FB",
              color: (theme) => theme.palette.text.primary,
            }}
            // @ts-ignore
            variant="xs_small"
            title="Submit"
            type="submit"
            disabled={!enableButtonCon}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FilterFormUI;
