import React from 'react';
import {Tabs,Tab} from 'react-bootstrap';
import CommonReactTable from '../../../helpers/commonReact_table';
import FlowContainer from '../../common/container/core/FlowContainer';
import { getLeadPipeline, getLeadPipelineCount, getRMnamebySalesRep } from '../../../actions/lead_actions';
import { getValueFromLangJson, Homeacc_prvdrLogoselector, page_count, title_case } from '../../../helpers/common_helper';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {check_priv, Config, get} from '../../../helpers/storage_helper';
import { default as ReactSelect } from "react-select";
import DatePicker from 'react-datetime';
import { BiCalendar} from "react-icons/bi";
import { BasicReactTable } from '../../../helpers/react_table';
import DataGridTable from '../../CommonComponent/table';
import ExportCsv from '../../common/component/ExportCsv';
import { Box } from '@mui/material';
import { GiClick } from 'react-icons/gi';
import CreateLead from "../../CommonComponent/RefferalViaCs"; 
import { MdPersonAddAlt1 } from "react-icons/md";
import Index from '../../leadPipeLine';


class LeadPipeline extends FlowContainer {
  state = {
    selected: '',
    column:[],
    lead_data:[],
    lead_data_count:{},
    filter:{'filter_with': "all"},
    category:[],
    paginate : Config('paginate') ? Config('paginate') : null,
    export_data:[],
    select:'',
    selected_option:{ value: 'all', label: 'All' },
    active_tab:"day_wise",
    page: { page: 0, pageSize: 10 },
    openCreateLead: false,

  };
  componentDidMount(){
    this.getTotalcount()
    this.load_flow_rel_mgr(null,"multiselect_rm_name");
    this.get_options()
    this.setState({rm_label_short : getValueFromLangJson("rm_label_short")})
   

  }
  componentDidUpdate(prevProps, prevState) {
    if(prevState.filter != this.state.filter){
      this.getTotalcount()
      this.getData(this.state.category)
    }		

    if (prevState.page !== this.state.page) {
      this.pagination();
    }
}
  getTotalcount=()=>{
    this.setState({mainLoader:true,lead_data_count:{}})    
    getLeadPipelineCount(this.req({
      filter:this.state.filter
    }))
    .then((response)=>{
      this.resp(response)
      if(!response){
        this.setState({mainLoader:false})
        return;
      }
      if(response&&response.status=='success'){
        this.setState({mainLoader:false})
          this.setState({lead_data_count:response.data})
        }
        
  })
  }


  get_options = () => {
    let options = [
        { value: 'all', label: 'All' },
        { value: 'rm', label: ` ${getValueFromLangJson("rm_label")}` },
        { value: 'customer_referral', label: 'Customer Referral' }, 
    ];

    if (Config('sales_rep_access')?.includes(get('market').country_code)) {
        options = [
            ...options, 
            { value: 'sales_rep', label: 'Sales Representative' } 
        ];
    }

    this.setState({ filter_options: options });
};
fetchCustomerReferralData = () => {
  this.setState({ mainLoader: true, customerReferralData: {} });

  fetch("http://localhost:3000/cashbacks_report", {
      method: "GET",
      headers: {
          "Content-Type": "application/json",
      },
  })
  .then((response) => {
      if (!response.ok) {
          this.setState({ mainLoader: false });
          return;
      }
      return response.json();
  })
  .then((data) => {
      if (!data) {
          this.setState({ mainLoader: false });
          return;
      }
      if (data.status === "success") {
          this.setState({ mainLoader: false, customerReferralData: data.data });
      }
  });
};


  getData=(category)=>{
    this.setState({loader:true,lead_data:[],column:[]})
    var request=this.state.paginate != null ? 
    {category:category,filter:this.state.filter,paginate:this.state.paginate} 
    : {category:category, filter:this.state.filter}

    getLeadPipeline(this.req(request))
    .then((response)=>{
      this.resp(response)
      if(!response){
        this.setState({loader:false})
        return;
      };
      if(response&&response.status=='success'){
        this.setState({loader:false})
        this.setState({lead_data:response.data,column:this.column_data(category)},()=>this.setState({loader:false}))
      }
    })
  }

  get_rm_name_by_sales_rep = (id) => {
    let request = {'sales_rep_id' : id}
    getRMnamebySalesRep(this.req(request))
    .then((response)=>{
      this.resp(response)
      if(!response){
        return;
      }
      if(response && response.status == 'success'){
        this.setState({rm_name:response.data.rm_name})
      }
    })
  }

  handleOptionChange = (value, key)=>{
   console.log('value, key', value, key)
    let sales_rep_access = Config('sales_rep_access')?. includes(get('market').country_code)
    this.setState({[key]:value})
    if(key == 'filter_with'){
      this.setState({flow_sales_rep:[], sales_rep:[], rm:[], filter:{...this.state.filter, [key]:value.value, rm:[], sales_rep:[]}})
      if(value.value == 'sales_rep' && sales_rep_access){
        this.load_sales_rep(null, 'sales_rep')
      }
      else if (value.value == 'customer_referral') {
        this.fetchCustomerReferralData(); // Call API when "Customer Referral" is selected
    }
      
    }else if(key == 'rm' || key == 'sales_rep' ){
      let array = [];
      value.map((val, i)=>{
        array.push(val.value)
      })

      if(key == 'rm'){
        if(array.length > 0){
          this.setState({flow_sales_rep:[], sales_rep:[], filter:{...this.state.filter, [key]:array, sales_rep:[]}})
          if(sales_rep_access){
            this.load_sales_rep(null, 'sales_rep', array)
          }
          
        }else{
          this.setState({sales_rep:[], filter:{...this.state.filter, sales_rep:[], rm:[]}})
        }
      }else{
        this.setState({filter:{...this.state.filter, [key]:array}})
      }
      
      if(key == 'sales_rep' && this.state.filter.filter_with == 'sales_rep' && array.length == 1){
        this.get_rm_name_by_sales_rep(array[0])
      }else{
        this.setState({rm_name: null})
      }
    }
    else if(key == "distributor"){
      this.setState({filter:{...this.state.filter, [key]:value?.value}})  
    }
  }

  get_input_handler(value,type){
    if(value == "Invalid date"){
      this.setState({filter:{...this.state.filter, [type]:""}})
    }else{
      this.setState({filter:{...this.state.filter, [type]:value}})
    }

  }


  column_data=(category)=>{
    let column;
    if (category === "Pending follow-up") {
      column = [
          {
              headerName: <p className="text-center m-0 d-flex align-items-center justify-content-center" style={{ height: "40px" }}>Sl No</p>,
              id: "sl_no",
              field: "sl_no",
              align: "center",
              headerAlign: "center",
              flex: 1,
              minWidth: 100,
              renderCell: (cell) => <p className="text-center">{cell.value ?? "N/A"}</p>,
          },
          {
              headerName: <p className="text-center m-0 d-flex align-items-center justify-content-center" style={{ height: "40px" }}>Agent Name</p>,
              id: "agent_name",
              field: "agent_name",
              align: "center",
              headerAlign: "center",
              flex: 1,
              minWidth: 200,
              renderCell: (cell) => <p className="text-center">{cell.value ?? "N/A"}</p>,
          },
          {
              headerName: <p className="text-center m-0 d-flex align-items-center justify-content-center" style={{ height: "40px" }}>Mobile Number</p>,
              id: "mobile_number",
              field: "mobile_number",
              align: "center",
              headerAlign: "center",
              flex: 1,
              minWidth: 200,
              renderCell: (cell) => <p className="text-center">{cell.value ?? "N/A"}</p>,
          },
          {
              headerName: <p className="text-center m-0 d-flex align-items-center justify-content-center" style={{ height: "40px" }}>Alternative Number</p>,
              id: "alternative_number",
              field: "alternative_number",
              align: "center",
              headerAlign: "center",
              flex: 1,
              minWidth: 200,
              renderCell: (cell) => <p className="text-center">{cell.value ?? "N/A"}</p>,
          },
          {
              headerName: <p className="text-center m-0 d-flex align-items-center justify-content-center" style={{ height: "40px" }}>Referred On</p>,
              id: "referred_on",
              field: "referred_on",
              align: "center",
              headerAlign: "center",
              flex: 1,
              minWidth: 200,
              renderCell: (cell) => <p className="text-center">{cell.value ? moment(cell.value).format("DD-MMM-YYYY") : "N/A"}</p>,
          },
          {
              headerName: <p className="text-center m-0 d-flex align-items-center justify-content-center" style={{ height: "40px" }}>Created</p>,
              id: "created",
              field: "created",
              align: "center",
              headerAlign: "center",
              flex: 1,
              minWidth: 200,
              renderCell: (cell) => <p className="text-center">{cell.value ? moment(cell.value).format("DD-MMM-YYYY") : "N/A"}</p>,
          },
          {
              headerName: <p className="text-center m-0 d-flex align-items-center justify-content-center" style={{ height: "40px" }}>Action</p>,
              id: "action",
              field: "id",
              align: "center",
              headerAlign: "center",
              flex: 1,
              minWidth: 200,
              renderCell: (cell) => (
                  <div>
                      <Link to={`/lead/edit/${cell.value}`} className="btn btn-sm btn-primary" target="_blank">
                          View Lead
                      </Link>
                  </div>
              ),
          },
      ];
  } else {
   column=[
      {
          headerName: <p className=' m-0 d-flex align-items-center pl-1 text-center' style={{height:'40px'}}>Biz Name</p>,
          id: "biz_name",
          maxWidth:250,
          minWidth:200,
          field: 'biz_name',
          align: 'center',headerAlign: 'center',  flex: 1,minWidth:200,

          renderCell: (cell) => {
              return <div className='pl-1 text-center'>{(cell.value)}</div>;
          },
        },
        {
          headerName: <p className='m-0 d-flex align-items-center justify-content-center' style={{height:'40px'}}>A/C Number</p>,
          id: "account_num",
          field: "account_num",
          align: 'center',headerAlign: 'center',  flex: 1,minWidth:200,

          renderCell: (cell) => {
              return <>
              <div className='d-flex justify-content-center'>
                {cell.row.acc_prvdr_code?
                  <Homeacc_prvdrLogoselector logotype={cell.row.acc_prvdr_code}/>
                  :<p className='text-light text-center'>N/A</p>
                }
              <p className='mb-0 mt-1 ml-2 text-center'>{cell.row.account_num}</p>
              </div>
              </>;
          },
        },
        {
          headerName: <p className='m-0 d-flex align-items-center justify-content-center' style={{height:'40px'}}>Mobile Number</p>,
          id: "mobile_num",
          align: 'center',headerAlign: 'center',  flex: 1,minWidth:200,

          field: "mobile_num",
          renderCell: (cell) => {
              return <>
              <p className='mb-0 mt-1 ml-2 text-center'>{cell.row.mobile_num ? cell.row.mobile_num : "N/A"}</p>
              </>;
          },
        },
        {
          headerName: <p className='text-center m-0 d-flex align-items-center ' style={{height:'40px'}}>Location</p>,
          id: "location",
          align: 'center',headerAlign: 'center',  flex: 1,minWidth:200,

          field: "location",
          renderCell: (cell) => {
              return <>{(cell.value?title_case(cell.value):'N/A')}</>;
          },
        },
        {
          headerName: <p className=' m-0 d-flex align-items-center justify-content-center' style={{height:'40px'}}>Last Visit</p>,
          id: "last_visit_date",
          field: "last_visit_date",
          align: 'center',headerAlign: 'center',  flex: 1,minWidth:200,

          renderCell: (cell) => {
              return <>{cell.value ?<><p className='text-light m-0 text-center'> {moment(cell.value).format('DD-MMM-YYYY')} </p>
              <p className={` m-0  text-center ${Math.abs(moment().diff(moment(cell.value), 'days'))>7?' lead_pipeline_blink font':'text-light'}`}>{Math.abs(moment().diff(moment(cell.value), 'days')) === 1 ? '1 day ago' :Math.abs(moment().diff(moment(cell.value), 'days')) === 0 ? '': `${Math.abs(moment().diff(moment(cell.value), 'days'))} days ago`}</p>
              </>: <p className='text-center'>{'N/A'}</p>}</>;
          },
        },
        {
          headerName: <p className='text-center m-0 d-flex align-items-center justify-content-center' style={{height:'40px'}}>Assessment Date</p>,
          align: 'center',headerAlign: 'center',  flex: 1,minWidth:200,

          id: "assessment_date",
          field: "assessment_date",
          renderCell: (cell) => {
              return <>{cell.value ?<><p className='text-light m-0 text-center'> {moment(cell.value).format('DD-MMM-YYYY')} </p>
              <p className={` m-0 text-center ${Math.abs(moment().diff(moment(cell.value), 'days'))>7?' lead_pipeline_blink font text-center':'text-center text-light'}`}>{Math.abs(moment().diff(moment(cell.value), 'days')) === 1 ? '1 day ago' :Math.abs(moment().diff(moment(cell.value), 'days')) === 0 ? '': `${Math.abs(moment().diff(moment(cell.value), 'days'))} days ago`}</p>
              </>: <p className='text-center'>{'N/A'}</p>}</>;
          },
        },
        {
          headerName: <p className='text-center m-0 d-flex align-items-center justify-content-center ' style={{height:'40px'}}>{this.state.rm_label_short??''} Name</p>,
          id: "rm_name",
          align: 'center',headerAlign: 'center',  flex: 1,minWidth:200,

          field: "rm_name",
          renderCell: (cell) => {
              return <>{(<div className='d-flex justify-content-center'>{cell.value?cell.value:'N/A'}</div>)}</>;
          },
        },
        {
          headerName: <p className='text-center m-0 d-flex align-items-center justify-content-center' style={{height:'40px'}}>Lead Created By</p>,
          id: "lead_created",
          align: 'center',headerAlign: 'center',  flex: 1,minWidth:200,

          field: 'lead_created',
          renderCell: (cell) => {
              return <>{(<div className='d-flex justify-content-center'>{cell.row.sp_name?'Sales Rep':this.state.rm_label_short??''}</div>)}</>;
          },
        },
        {
          headerName: <p className=' m-0 d-flex align-items-center text-center justify-content-center' style={{height:'40px'}}>Remarks</p>,
          id: "remarks",
          field: 'remarks',
          align: 'center',headerAlign: 'center',  flex: 1,minWidth:200,
          

          renderCell: (cell) => {
              return <div className='d-flex justify-content-center'>{cell.value?(Array.isArray(cell.value)&&cell.value.length>0)?cell.value[0].remarks:cell.value:'N/A'}</div>;
          },
        },
        {
          headerName: <p className=' m-0 d-flex align-items-center text-center justify-content-center' style={{height:'40px'}}>Action</p>,
          id: "action",
          field: 'id',
          align: 'center',headerAlign: 'center',  flex: 1,minWidth:200,

          renderCell: (cell) => {
              return <div className=''><Link to={"/lead/edit/"+cell.value} className='btn btn-sm btn-primary ' target='_blank'>View Lead</Link></div>;
          },
        },
    ]
  }
    if(category?.includes('pending_w_rnc')){
      column.splice(4, 0,
        {
          headerName: <p className='text-center m-0 d-flex align-items-center text-center justify-content-center' style={{height:'40px'}}>Auditor Name</p>,
          id: "rnc",
          align: 'center',headerAlign: 'center',  flex: 1,minWidth:200,

          field: 'audited_by',
          renderCell: (cell) => {
              return <div className='d-flex justify-content-center'>{(cell.value?cell.value:'N/A')}</div>;
          },
        },
      )
    }
    if((this.state.filter.filter_with && this.state.filter.filter_with !='rm') || (this.state.filter.sales_rep && this.state.sales_rep.length > 1)){
      column.splice(5, 0,
        {
          headerName: <p className='text-center m-0 d-flex align-items-center justify-content-center' style={{height:'40px'}}>Sales Rep Name</p>,
          id: "sp_name",
          field: 'sp_name',
          align: 'center',headerAlign: 'center',  flex: 1,minWidth:200,

          renderCell: (cell) => {
              return <div className='d-flex justify-content-center'>{(cell.value?cell.value:'N/A')}</div>;
          },
        },
      )
    }
        

    return column;
  }
  handleSelect=(select)=>{
    this.reset_pagination(0,10)
    if(select==this.state.select){
      this.setState({ select:'' })
    }
    else{
      this.setState({ select })
    }
  }
  filter_count=(lead_data)=>{
    var count=0;
    for(let i=0;i<lead_data.length;i++){
      if(lead_data[i]){
        count +=this.state.lead_data_count[lead_data[i]]
      }
    }
    return count
  }
  handleSelected=(selected)=>{
    this.reset_pagination(0,10)
    if(selected=='pending_assessment' && this.state.select==''){
      this.setState({select:selected})
    }
    if(selected==this.state.selected){
      this.setState({ selected:'' })
    }
    else{
      this.setState({ selected })
    }
  }

  pagination = (page, count, sorted)=>{
      this.setState((state) => {
        return {
          paginate: {
          ...state.paginate,
          pagination_count: this.state.page.pageSize,
          pagination_page: this.state.page.page ,
          // sorting:sorted,
          },
        };
        }, ()=>this.getData(this.state.category));

    
  }

  reset_pagination = (page,count)=>{
    this.setState((state) => {
      return {
        paginate: {
        ...state.paginate,
        pagination_count: count,
        pagination_page:page,
        },
      };
      });
  }

handleExportCSV = (event) => {
    let resp = null;

    if(event == 'email') {
      resp = window.confirm(`The current search exceeds ${Config("export_csv").limit} rows. So the exported data will be sent to your email shortly.`)
    }

    if(event == 'email' && !resp){
      return false
    }else{
      this.setState({export_data:[]},()=>this.handleCSVapi(event))
      return true
    }
  }
  
  handleCSVapi(action){
    const category=this.state.selected=='pending_assessment' ? [this.state.select] : [this.state.selected]

    let csv_header_keys=this.column_data(category).map((val)=>val.id)           //to get the headers for csv file

    let calling_api = true 
    var request={
        category:category,
        filter:this.state.filter,
        paginate : false,
        csv_email: action =='email' ? true: false,
    }
    if (action == 'email'){
      calling_api = false 
      request['export'] = true
    }

    getLeadPipeline(this.req(request))
          .then((response) => {
        this.resp(response)
        if(action == 'email'){
          return
        }
        if(response.data && response.data[category] &&  response.data[category].length>0){
          let export_data = response.data[category].map((obj) => {
            let filteredObject = {};
            Object.keys(obj).forEach((key) => {
              // console.log(key,'key')
              if (csv_header_keys?.includes(key)) {
                filteredObject[key] = obj[key];
              }
              if(key=='remarks'){
                filteredObject[key]= obj[key] && obj[key].length>0 ? obj[key][0].remarks:'N/A'
              }
            });
            return filteredObject;
          });

          // let csv_datas = this.generate_csv_datas(response.data[category], ['remarks'], 'remarks')
          // this.setState({export_data:csv_datas})

          this.setState({export_data:export_data})
        }
        else{
          return;
        }

        
      });
  }

   generate_csv_datas = (data, arr_keys, obj_key) => {
    let final = data.map(item => {
        let newItem = { ...item };
        arr_keys.forEach(key => {
            if (Array.isArray(item[key]) && item[key].length > 0 && item[key][0][obj_key]) {
                newItem[key] = item[key][0][obj_key];
            }
        });
        return newItem;
    });
    return final;
};

  PipelineStages = (stage,heading=[],lead_data=[],eligible=[],elig_data=[]) => {
   const  is_server_side  = this.state.paginate
    return (
    <div className='my-2'>
      {heading.includes('Pending follow-up') ? 
      <Index filter={this.state.filter}/>
      :
        <fieldset className={` ${heading?.includes(this.state.selected)?'pipeline_border_active':'pipeline_border'}`}>
            <legend className="w-auto pipeline_legend_text mb-0 ml-5 p-3" style={{background:'rgb(26 32 53 / 80%)'}}>
              {stage!=='Dropped Leads (Last 30 Days)'?
                stage + '('+this.filter_count(lead_data)+')'
                :
                stage
                }
                </legend>
                <Tabs
                    id="justify-tab-example"
                    activeKey={this.state.selected}
                    onSelect={(selected)=>this.handleSelected(selected)}
                    className="row pipeline_stage_header p-0 ml-0 mr-0"
                >
                    {heading && lead_data.length>0 ?
                    heading.map((item, index) => {
                    return (

                            <Tab eventKey={lead_data[index]} key={index}  onEnter={()=>{this.setState({category:[lead_data[index]]},()=>{this.state.category.length > 0 && this.state.category?.includes(lead_data[index]) ? this.getData(this.state.category) : null})}
                          }
                                  title={
                                      <div className={`d-flex  justify-content-around align-items-center h-100 ${this.state.lead_data_count[lead_data[index]]?'':'very_low_opacity'}`}>
                                          <span>{item}</span>
                                          {item=='Assessment'?
                                            <p className='pipeline_count font mb-0'> { this.filter_count(elig_data)} </p>
                                            : 
                                            <p className='pipeline_count font mb-0'> {this.state.lead_data_count[lead_data[index]]?this.state.lead_data_count[lead_data[index]]:0}</p>
                                          }
                                      </div>
                                  } className='tab_border m-0'>
                      {item=='Assessment'?
                        <Tabs
                        id="justify-tab-example"
                    activeKey={this.state.select}
                    onSelect={(select) => this.handleSelect(select)}
                    className={`row pipeline_stage_com_header `}>
                        {eligible && eligible.length>0 &&
                          eligible.map((item, index) => {
                            return(
                                <Tab eventKey={elig_data[index]} 
                                onEnter={()=>{this.setState({category:[elig_data[index]]},()=>this.getData(this.state.category))}}
                                key={index} 
                                      title={
                                          <div className='d-flex  justify-content-around align-items-center h-100'>

                                              <span>{item}</span>
                                              <p className='pipeline_count font mb-0'> {this.state.lead_data_count[elig_data[index]]?this.state.lead_data_count[elig_data[index]]:0}</p>

                                              
                                          </div>
                                }>
                                     
                                     <div style={{display:this.state.loader?'block':'none'}}>
                                <p className='text-light text-center' style={{marginTop:'5vh'}}>Loading...</p>
                                <div className="product_takeup_loader"></div>
                              </div>



                                  <div className='pipeline_table ' style={{display:this.state.loader?'none':'block'}}>
                                 
                                   <>
                                   

                                    { this.state.category.length > 0 && this.state.category?.includes(lead_data[index])  && 
                                    <>
                                      <Box display={'flex'} justifyContent={'end'}>
                                      <ExportCsv
                                      total_count={
                                        is_server_side ?
                                        this.filter_count(elig_data)??0
                                        : this.state.lead_data[elig_data[index]]?this.state.lead_data[elig_data[index]]?.length:0
                                      
                                      } 
                                      columns={this.state.column??[]}

                                      handleExport={this.handleExportCSV} 
                                      export_csv_datas={
                                        is_server_side ? this.state.export_data? this.state.export_data:[] :
                                        this.state.lead_data[elig_data[index]]?this.state.lead_data[elig_data[index]]:[]

                                      
                                      }
                                      csvFileName = {'call_list'}
                                      paginationMode= { is_server_side?  "server":"client"}
                                      isCsvExportedToMail={false}
                                      />
                                      </Box>
                                    
                                   
                                      <DataGridTable
                                              rows={this.state.lead_data[elig_data[index]]?this.state.lead_data[elig_data[index]]:[]}
                                              columns={this.state.column}
                                              paginationMode= { is_server_side?  "server":"client"}
                                              sortingMode={"client"}
                                              page={this.state.page}
                                              rowCount={this.filter_count(elig_data)??0}

                                              setpage={(newPage) => this.setState({ page: newPage })}
                                              isLoading={ this.state.loader }
                                              
                                          />
                                       </>
                                    // <BasicReactTable row_data={this.state.lead_data[elig_data[index]]?this.state.lead_data[elig_data[index]]:[]} column_data={this.state.column} csv_file={true} FName={item+' Leads'} export_csv_datas={this.state.export_data? this.state.export_data:[]} defaultSorted={[{id:"last_visit_date",desc:true}]} pagination={(this.state.lead_data_count[elig_data[index]]&&this.state.lead_data_count[elig_data[index]]>9)?true:false}  default_page_size={10} filter_data = {this.pagination}  total_page_count={this.state.lead_data_count[elig_data[index]]?page_count(this.state.lead_data_count[elig_data[index]],this.state.paginate.pagination_count):0} handleExport={this.handleExportCSV} csv_total_data={this.state.lead_data_count[elig_data[index]]} server_side_pagination={this.state.paginate}/>
                                    
                                    }
                                    </>
                                  

                                  <>
                                 {/* { this.state.category.length > 0 && this.state.category.includes(lead_data[index])  &&  <BasicReactTable row_data={this.state.lead_data[elig_data[index]]?this.state.lead_data[elig_data[index]]:[]} column_data={this.state.column} csv_file={true} FName={item+' Leads'} export_csv_datas={this.state.lead_data[elig_data[index]]?this.state.lead_data[elig_data[index]]:[]} defaultSorted={[{id:"last_visit_date",desc:true}]} pagination={(this.state.lead_data[elig_data[index]]&&this.state.lead_data[elig_data[index]].length>10)?true:false}  default_page_size={10}/> } */}
                                  </>
                                  
                                  </div>
                                </Tab>
                            )}
                          )}
                          </Tabs>
                            :
                            <>
                              <div style={{display:this.state.loader?'block':'none'}}>
                                <p className='text-light text-center' style={{marginTop:'5vh'}}>Loading...</p>
                                <div className="product_takeup_loader"></div>
                              </div>
                            
                              <div className='pipeline_table py-2' style={{display:this.state.loader?'none':'block'}}>
                                
                                {/* {this.state.paginate ? 
                                <>
                                
                                {this.state.category.length > 0 && this.state.category.includes(lead_data[index])  && <BasicReactTable row_data={this.state.lead_data[lead_data[index]]?this.state.lead_data[lead_data[index]]:[]} column_data={this.state.column} csv_file={true} FName={item+" Leads"}  export_csv_datas={this.state.export_data? this.state.export_data:[]} pagination={(this.state.lead_data_count[lead_data[index]]&&this.state.lead_data_count[lead_data[index]]>5)?true:false} default_page_size={10} defaultSorted={[{id:"last_visit_date",desc:true}]} filter_data = {this.pagination} total_page_count={this.state.lead_data_count[lead_data[index]]?page_count(this.state.lead_data_count[lead_data[index]],this.state.paginate.pagination_count):0} handleExport={this.handleExportCSV} server_side_pagination={this.state.paginate} csv_total_data={this.state.lead_data_count[lead_data[index]]}/>}

                                </>
                              :
                              <>
                              {this.state.category.length > 0 && this.state.category.includes(lead_data[index])  && <BasicReactTable row_data={this.state.lead_data[lead_data[index]]?this.state.lead_data[lead_data[index]]:[]} column_data={this.state.column} csv_file={true} FName={item+" Leads"} export_csv_datas={this.state.lead_data[lead_data[index]]?this.state.lead_data[lead_data[index]]:[]} defaultSorted={[{id:"last_visit_date",desc:true}]} pagination={(this.state.lead_data[lead_data[index]]&&this.state.lead_data[lead_data[index]].length>9)?true:false}  defaultPageSize={10} /> }
                              </>
                              } */}

                                    {this.state.category.length > 0 && this.state.category?.includes(lead_data[index]) &&
                                        <>
                                        {/* total_page_count={this.state.lead_data_count[lead_data[index]]?:0} */}
                                      
                                      <Box display={'flex'} justifyContent={'end'}>
                                      <ExportCsv
                                      total_count={
                                        is_server_side ?
                                        this.state.lead_data_count[lead_data[index]]??0
                                       : this.state.lead_data[lead_data[index]]?this.state.lead_data[lead_data[index]]?.length:0
                                      
                                      } 
                                      columns={this.state.column??[]}

                                      handleExport={this.handleExportCSV} 
                                      export_csv_datas={
                                        is_server_side ? this.state.export_data??[] :
                                        this.state.lead_data[lead_data[index]]?this.state.lead_data[lead_data[index]]:[]
                                      }
                                      csvFileName = {'Lead pipeline'}
                                      paginationMode= { is_server_side?  "server":"client"}
                                      isCsvExportedToMail={false}
                                      />
                                      </Box>

                                      <DataGridTable
                                              rows={this.state.lead_data[lead_data[index]]?this.state.lead_data[lead_data[index]]:[]}
                                              columns={this.state.column}
                                              paginationMode= { is_server_side?  "server":"client"}
                                              sortingMode={"client"}
                                              page={this.state.page}
                                              rowCount={
                                                this.state.lead_data_count[lead_data[index]]??0
                                              
                                              }

                                              setpage={(newPage) => this.setState({ page: newPage })}
                                              isLoading={ this.state.loader }
                                              
                                          />
                                       </>
                                    }
                              </div>
                              </>

                            }
                            </Tab>
                          );
                                
                        })

                    :

                    <p className='text-light text-center p-3'>No Data Found</p>
                    }
                </Tabs>
        </fieldset>
        }
      </div>
    );
  };

  render() {
    return (
      <>
     <div className="pipeline_header mt-3 py-2 align-items-center justify-content-between">
  <h4 className="mb-0">Lead Pipeline</h4>
</div>

<div className="container">
  <div className="row mt-3 d-flex align-items-center justify-content-between">
    {/* Start Date */}
    <div className="col-xl-2 col-lg-4 col-md-6 lead_pipeline_calender">
      <div className="select-container">
        <div className="form-group mt-0 px-2 py-0 d-flex align-items-center lead_pipeline_datepicker">
          <div className="col p-0 py-1 pr-0">
            <DatePicker
              closeOnSelect={true}
              isValidDate={(current) => {
                const today = moment().startOf("day");
                return current.isSameOrBefore(today, "day");
              }}
              inputProps={{
                id: "churn_report_start_date",
                placeholder: "Select",
                name: "Referral_from",
                autoComplete: "off",
              }}
              onChange={(value) =>
                this.get_input_handler(moment(value).format("YYYY-MM-DD"), "start_date")
              }
              dateFormat={"DD MMM YYYY"}
              showMonthYearPicker
              timeFormat={false}
              required="required"
              onKeyDown={(value) => value.preventDefault()}
            />
          </div>
          <label
            className="col-1.5 no-margin d-flex p-0"
            style={{ position: "static" }}
            htmlFor="churn_report_start_date"
          >
            <p className="m-0 pr-1">
              <BiCalendar size={20} />
            </p>
          </label>
        </div>
        <label className="select-label">Start Date</label>
      </div>
    </div>

    {/* End Date */}
    <div className="col-xl-2 col-lg-4 col-md-6 lead_pipeline_calender">
      <div className="select-container">
        <div className="form-group mt-0 px-2 py-0 d-flex align-items-center lead_pipeline_datepicker">
          <div className="col p-0 py-1 pr-0">
            <DatePicker
              closeOnSelect={true}
              isValidDate={(current) => {
                const today = moment().startOf("day");
                return current.isSameOrBefore(today, "day");
              }}
              inputProps={{
                id: "report_end_date",
                placeholder: "Select",
                name: "Referral_from",
                autoComplete: "off",
              }}
              onChange={(value) =>
                this.get_input_handler(moment(value).format("YYYY-MM-DD"), "end_date")
              }
              dateFormat={"DD MMM YYYY"}
              showMonthYearPicker
              timeFormat={false}
              required="required"
              onKeyDown={(value) => value.preventDefault()}
            />
          </div>
          <label
            className="col-1.5 no-margin d-flex p-0"
            style={{ position: "static" }}
            htmlFor="report_end_date"
          >
            <p className="m-0 pr-1">
              <BiCalendar size={20} />
            </p>
          </label>
        </div>
        <label className="select-label">End Date</label>
      </div>
    </div>

    {/* Filter By */}
    <div className="col-xl-2 col-lg-4 col-md-6">
      <div className="select-container" style={{ minHeight: "45px" }}>
        <ReactSelect
          options={this.state.filter_options}
          value={this.state.filter_with}
          onChange={(value) => this.handleOptionChange(value, "filter_with")}
          styles={this.style_for_react_select()}
          placeholder="All"
        />
        <label className="select-label">Filter By</label>
      </div>
    </div>

     {/* RM Selection - Only when filter is "rm" */}
     {Config('distributor_aval_country')?.includes(get('market').country_code)  &&
      <div className="col-xl-2 col-lg-4 col-md-6">
        <div className="select-container">
          <ReactSelect
            options={this.getMultiSelectOptions('distributor')}
            value={this.state.rm}
            onChange={(value) => this.handleOptionChange(value, "distributor")}
            styles={this.style_for_react_select()}
            isMulti={false}
            hideSelectedOptions={true}
            placeholder="All"
          />
          <label className="select-label"> Distributor</label>
        </div>
      </div>
      }


    {/* RM Selection - Only when filter is "rm" */}
    {this.state.filter.filter_with && this.state.filter.filter_with == "rm" ? (
      <div className="col-xl-2 col-lg-4 col-md-6">
        <div className="select-container">
          <ReactSelect
            options={this.state.flow_rel_mgr}
            value={this.state.rm}
            onChange={(value) => this.handleOptionChange(value, "rm")}
            styles={this.style_for_react_select()}
            isMulti={true}
            hideSelectedOptions={true}
            placeholder="All"
          />
          <label className="select-label">{getValueFromLangJson("rm_label")}</label>
        </div>
      </div>
    ) : null}





    {/* Create Lead Button - Aligned to the Right */}
    <div className="col-auto ml-auto">
      <button
        type="button"
        // className={`btn btn-primary font-10 py-2 px-3 ${
        //   this.state.disable_apply_fa ? "disable_div" : ""
        // }`}
        className="btn btn-primary font-10 py-2 px-3"

        id="Create Lead"
        onClick={() => this.setState({ openCreateLead: true })}
      >
        <MdPersonAddAlt1 className="mr-1" color="white" /> Create Lead
      </button>

     
        {/* CreateLead Dialog */}
        {this.state.openCreateLead && check_priv('lead_pipeline','create_lead') &&(
          <CreateLead
            open={this.state.openCreateLead}
            onClose={() => this.setState({ openCreateLead: false })} // ✅ Close dialog
          />
        )}
</div>


          {this.state.flow_sales_rep &&  this.state.flow_sales_rep.length > 0 ? 
           <div className='col-xl-2 col-lg-4 col-md-6'>
            <div className='select-container'>
              <ReactSelect
                    options={this.state.flow_sales_rep}
                    value={this.state.sales_rep}
                    onChange={(value) => this.handleOptionChange(value, 'sales_rep')}
                    styles={this.style_for_react_select()}
                    isMulti={true}
                    hideSelectedOptions={true}
                    placeholder='All'
                />
                <label className='select-label'>Sales Representative</label>
              </div>

          </div>  : null
          }   
          {this.state.rm_name ? 
          <div className='col-xl-4 col-lg-8'>
            <div style={{textAlign:"right", marginTop:"15px"}}>
              <label className='text-right'>{getValueFromLangJson("rm_label")}</label>
              <p className='text-white'><b>{this.state.rm_name}</b></p>
            </div>
          </div> : null} 
        </div>
        </div>

        {this.state.mainLoader?
          <>
            <p className='text-light text-center' style={{marginTop:'30vh'}}>Loading...</p>
            <div className="product_takeup_loader"></div>
          </>
        :
          <div className="container">
                 
                  {
                    check_priv('lead_pipeline','agent_refferal') &&
                  this.PipelineStages ('Agent Refferal', ['Pending follow-up'], ['Pending follow-up'])
                  }

                  {this.PipelineStages ('Pitch Stage ', ['Lead was not available','Lead needs time'],['customer_was_not_available','customer_needs_time'])}
                  {this.PipelineStages ('Assessment Stage ', ['Statement Collection Inprogress','Assessment Inprogress', 'Reassessed'],['statement_collection_inprogress','assessment_inprogress', 'reassessed'])}
                  {this.PipelineStages ('KYC Stage ',[`Pending ${this.state.rm_label_short??''} allocation`,`Pending ${this.state.rm_label_short??''} Evaluation`,'Pending KYC', 'KYC Inprogress'],['pending_rm_allocation','pending_rm_evaluation', 'pending_kyc', 'kyc_inprogress'])}
                  {this.PipelineStages ('R&C Stage ',[`Pending with ${this.state.rm_label_short??''} for Rectification`,'Pending with R&C'],['pending_w_rm','pending_w_rnc'])}
                  {this.PipelineStages ('Disbursal Stage ',['Pending Disbursal'],['pending_disbursal'])}
                  {this.PipelineStages (`Dropped Leads (Last ${Config('days_to_inactivate_leads')} Days) `,['Uninterested', 'Ineligible', 'Assessment validity expired', 'Others'],['uninterested','ineligible','validity_expired','others'])}
          </div>
        }
      </>
    );
  }
}

export default LeadPipeline;
