import React from "react";
import { useSoftPhoneCtx } from "../../../context/softPhoneCtx/useSoftPhoneCtx";
import { useGetCustDetailsByPhNumQuery } from "../hooks/useGetCustDetailsQuery";
import { HeaderVarientH2Typo } from "../../CommonComponent/typography";
import { ERROR_INFO, FETCHING_INFO } from "../../../consts";
import FaDetailsAndPersonalInfoUI from "./faDetailsAndPersonalInfoUI";
import { Grid } from "@mui/material";
import PrevCallInteractionsUI from "./prevCallInteractionsUI";

const CallLogModalCustDetailUI = () => {
  // ctx
  const {
    softPhoneState: {
      callLogDetails: { phoneNumber },
    },
  } = useSoftPhoneCtx();

  // query
  const { data, isError, isLoading, isFetched } = useGetCustDetailsByPhNumQuery(
    {
      phoneNumber,
    }
  );

  if (isLoading)
    return (
      <HeaderVarientH2Typo
        overRideStyles={{ color: "#fff", textAlign: "center", py: 16 }}
        text={FETCHING_INFO}
      />
    );

  if (isError && !data?.data)
    return (
      <HeaderVarientH2Typo
        overRideStyles={{ color: "#fff", textAlign: "center", py: 16 }}
        text={ERROR_INFO}
      />
    );

  if (isFetched && data?.data && Array.isArray(data.data?.customer_list) && data.data.customer_list.length === 0)
    return (
      <HeaderVarientH2Typo
        overRideStyles={{ color: "#fff", textAlign: "center", py: 16 }}
        text={"No User Detail Found!."}
      />
    );

  const custObj = data?.data?.customer_list[0];

  return (
    <Grid container>
      <Grid item xs={12} lg={8}>
        {custObj && <FaDetailsAndPersonalInfoUI custObj={custObj} />}
      </Grid>
      <Grid item xs={12} lg={4}>
        {custObj && <PrevCallInteractionsUI custId={custObj.cust_id} />}
      </Grid>
    </Grid>
  );
};

export default CallLogModalCustDetailUI;
