import ConsentIcon from "@mui/icons-material/AssignmentOutlined";
import BusinessIcon from "@mui/icons-material/BusinessCenterOutlined";
import IdCardIcon from "@mui/icons-material/ContactPageOutlined";
import DescriptionIcon from "@mui/icons-material/DescriptionOutlined";
import PeopleIcon from "@mui/icons-material/GroupsOutlined";
import PhotoIcon from "@mui/icons-material/ImageOutlined";
import MapIcon from "@mui/icons-material/RoomOutlined";
import SmartphoneIcon from "@mui/icons-material/SmartphoneOutlined";
import StorefrontIcon from "@mui/icons-material/StorefrontOutlined";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import StepLabel, { stepLabelClasses } from "@mui/material/StepLabel";
import { styled } from "@mui/material/styles";

interface StepItem {
  key: string;
  title: string;
  icon: React.ReactElement;
}

export const ColorlibStepLabel = styled(StepLabel)(({ theme }) => ({
  [`&.${stepLabelClasses.active}`]: {
    [`& .${stepLabelClasses.label}`]: {
      color: theme.palette.text.primary,
      padding: "15px",
    },
  },
  [`& .${stepLabelClasses.completed}`]: {
    [`& .${stepLabelClasses.label}`]: {
      color: theme.palette.success.main,
    },
  },
  [`& .${stepLabelClasses.label}`]: {
    color: theme.palette.grey[500],
  },
}));

interface ColorlibConnectorProps {
  isAllCompleted: boolean;
}

// export const ColorlibConnector = styled(StepConnector, {
//   shouldForwardProp: (prop) => prop !== "isAllCompleted",
// })<ColorlibConnectorProps>(({ theme, isAllCompleted }) => ({
//   [`&.${stepConnectorClasses.alternativeLabel}`]: {
//     top: 12,
//   },
//   [`& .${stepConnectorClasses.line}`]: {
//     height: 3,
//     border: 0,
//     backgroundColor: "#eaeaf0",
//     borderRadius: 1,
//     ...theme.applyStyles("dark", {
//       backgroundColor: theme.palette.grey[800],
//     }),
//   },

//    [`&.${stepConnectorClasses.completed}`]: {
//       [`& .${stepConnectorClasses.line}`]: {
//         backgroundImage:
//         "linear-gradient(136deg, rgb(34, 193, 34) 0%, rgb(0, 148, 50) 50%, rgb(0, 102, 34) 100%)",
//       },
//     },

//   // ...(isAllCompleted && {
//   //   [`& .${stepConnectorClasses.line}`]: {
//   //     backgroundImage:
//   //       "linear-gradient(136deg, rgb(34, 193, 34) 0%, rgb(0, 148, 50) 50%, rgb(0, 102, 34) 100%)",
//   //   },
//   // }),
// }));

export const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 12,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
      // "linear-gradient(136deg, rgb(34, 193, 34) 0%, rgb(0, 148, 50) 50%, rgb(0, 102, 34) 100%)",
      "linear-gradient(136deg, #004a99 0%, #0069c5 50%, #0088ff 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
      "linear-gradient(136deg, #004a99 0%, #0069c5 50%, #0088ff 100%)",

        // "linear-gradient(136deg, rgb(34, 193, 34) 0%, rgb(0, 148, 50) 50%, rgb(0, 102, 34) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
    ...theme.applyStyles("dark", {
      backgroundColor: theme.palette.grey[800],
    }),
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean; error?: boolean };
}>(({ theme }) => ({
  backgroundColor: "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 30,
  height: 30,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...theme.applyStyles("dark", {
    backgroundColor: theme.palette.grey[700],
  }),
  variants: [
    {
      props: ({ ownerState }) => ownerState.active,
      style: {
        border: "2px solid white",
        backgroundImage:
          "linear-gradient(136deg, #004a99 0%, #0069c5 50%, #0088ff 100%)",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
      },
    },
    {
      props: ({ ownerState }) => ownerState.completed,
      style: {
        backgroundImage:
        "linear-gradient(136deg, #004a99 0%, #0069c5 50%, #0088ff 100%)",

          // "linear-gradient(136deg, rgb(34, 193, 34) 0%, rgb(0, 148, 50) 50%, rgb(0, 102, 34) 100%)",
      },
    },
    {
      props: ({ ownerState }) =>
        (ownerState.completed && ownerState.active) ||
        (ownerState.error && ownerState.active),
      style: {
        border: "2px solid white",
      },
    },

    {
      props: ({ ownerState }) => ownerState.error,
      style: {
        backgroundImage:
          "linear-gradient(136deg, rgb(255, 94, 98) 0%, rgb(220, 38, 38) 50%, rgb(139, 0, 0) 100%)",
      },
    },
  ],
}));

type ColorlibStepIconProps = StepIconProps & {
  steps: StepItem[];
};

const ColorlibStepIcon = (props: ColorlibStepIconProps) => {
  const { active, completed, error, icon, className, steps } = props;
  // icon prop will be the step number (e.g., 1, 2, 3, ...)
  const stepIndex = Number(icon) - 1; // StepIconProps provides a string-number from MUI
  const IconComponent = icon || <PhotoIcon />;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active, error }}
      className={className}
    >
      {IconComponent}
    </ColorlibStepIconRoot>
  );
};

export default ColorlibStepIcon;

const iconSize = {
  width: 15,
  height: 15,
};
