import { ReactNode } from "react";
import { ERROR_SHORT_INFO, FETCHING_SHORT_INFO } from "../../../consts";
import { RhfOptionType } from "../../../type";
import RHFAutocomplete from "../rhfComponents/rhfAutoComplete";
import UseGetCSListViaQuery from "./queryHooks/useGetCSListViaQuery"

const CSListAutoComplete = ({
    name,
    label,
    endIcon,
    actAs
}:{
    name: string,
    label: string,
    endIcon?: ReactNode;
    actAs: "dd" | "filter";
}) => {
    // query
    const {csList, isError} = UseGetCSListViaQuery()

    const errorCaseString = isError ? ERROR_SHORT_INFO : FETCHING_SHORT_INFO;

    // options
    const csOpions: RhfOptionType[] = [
        {name:'ALL (Customer Success)', id:'all'},
        ...((csList &&
            csList.data.map((val) => ({
                name:val.name,
                id:val.id
            })).sort(
                (a, b) => a.name.localeCompare(b.name, "en", { sensitivity: "base" })
            )
            )) || [{
                name:errorCaseString,
                id:errorCaseString
            }]
    ]

    return(
        <RHFAutocomplete
        name={name}
        label={label!!}
        options={csOpions}
        sx={{
            // overides the end icon style
            "& .MuiButtonBase-root": {
            ...(actAs === "filter" && { transform: "none" }),
            svg: {
                width: "20px",
                height: "20px",
                color: (theme) =>
                // @ts-ignore
                theme.palette.custom_colors.secondary_a_200,
            },
            },
        }}
        popupIcon={endIcon!!}
        getOptionLabel={(option) => typeof option === "object" ? option.name : option}
        getOptionKey={(option) => typeof option === "object" ? option.id : option}
        isOptionEqualToValue={(option, value) =>
          typeof option === "object" && typeof value === "object"
            ? option.id === value.id 
            : option === value
        }
        disableClearable
        />
    )

}

export default CSListAutoComplete;