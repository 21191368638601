import React from 'react';
import FlowComponent from '../common/component/core/FlowComponent';
import { get, set, check_priv } from '../../helpers/storage_helper';
import { listAccountProvider } from '../../actions/account_provider_actions';
import { searchLoan } from '../../actions/loan_actions';
import { Config } from '../../helpers/storage_helper';
import ReconStatus from '../lender/component/ReconStatus';
import { title_case, lbl_amt, Homeacc_prvdrLogoselector, format_date_tbl, rt_algn, filter } from '../../helpers/common_helper';
import CaptureFailedDisbursal from '../loan/component/CaptureFailedDisbursal';
import SyncPayment from '../lender/component/SyncPayment';
import { sortBizcommon, sortAmtcommon, sortTimecommon } from '../../helpers/sortingHelpers';
import { list_lender_txn_stmts } from '../../actions/lender_actions';
import { getRefAccounts } from '../../actions/account_txn_actions';
import { Link } from "react-router-dom";
import { dd_value, getListByUrl } from "../../actions/common_actions";
import { reinitiateRecon } from '../../actions/loan_actions';

import { OverlayTrigger, Popover } from "react-bootstrap";
import { FcInfo } from 'react-icons/fc';
import { ExcesstimeAction, TooltipLabel, AccountTransactionToolTipLabel } from "../common/partial";
import CommonReactTable from '../../helpers/commonReact_table';
import Accordion from 'react-bootstrap/Accordion';
import { Card } from 'react-bootstrap'
import { BsChevronDown } from 'react-icons/bs';
import moment from 'moment';
import { BasicReactTable } from '../../helpers/react_table';
import { Box } from '@mui/material';
import AccProviderFilter from '../common/component/AccProviderFilter';
import MUIAccordion from '../../MUI/Components/MUIAccordion';
import { setValuesIntoRedux } from '../../Redux/Slices/CsSoftphoneSlice';
import { connect } from 'react-redux';
import { handleProviderSelection } from '../../helpers/helper';
import CustomTableGridToolbar from '../CommonComponent/table/components/customTableGridToolbar';
import DataGridTable from '../CommonComponent/table';
import ExportCsv from '../common/component/ExportCsv';

class DisplayAccStmtList extends FlowComponent {

    state = {
        refresh: false,
        lists: [],
        columndatas: [],
        copy: [],
        dyn_Btn_selected: [],
        all_count: 0,
        filter_actions: {},
        all_Btn_status: false,
        all_Acc_pvdrs: [],
        show_table: false,
        selectedProviders: [],
        filteredRows: [],
        page: { page: 0, pageSize: 10 },



    }

    handleToggle = (e) => {

        switch (e) {
            case ("Account"):
                this.setState(state => state.show_table = !this.state.show_table);
                break;
        }
    }

    componentWillMount() {

        if (this.props.refresh) {
            setInterval(() => this.refreshList(), this.props.refresh)
        }

        const capture_payment_late1 = Config("capture_payment_late1")
        const capture_payment_late2 = Config("capture_payment_late2")

        this.setState({ capture_payment_late1, capture_payment_late2 })

        const market = get('market');
        var req = { country_code: market.country_code, time_zone: market.time_zone, status: "" };
        listAccountProvider({ ...req, status: 'enabled', biz_account: true })
            .then((response) => {
                if (!response) { return };
                if (response.status === "success") {
                    let acc_prvdrs = []
                    response.data.list.map(each => acc_prvdrs.push(each.acc_prvdr_code))
                    this.setState(state => state.all_Acc_pvdrs = acc_prvdrs)
                    // set("acc_pvdrs", acc_prvdrs)                      
                }
            });
        this.getList();


        // this.drawTable();  
    }


    chk_fa_id(record) {

        const loan_doc_id = record.loan_doc_id
        const recon_status = record.recon_status

        if (loan_doc_id) {
            return <Link to={"/fa/view/" + loan_doc_id} style={{ color: "grey" }} target="_blank" >{loan_doc_id}</Link>
        }
        else {
            return "NA"
        }
    }

    handleReinitiateRecon = (event, row) => {
        const amount = row.stmt_txn_type == 'credit' ? row.cr_amt : row.stmt_txn_type == 'debit' && row.dr_amt

        const resp = window.confirm("Are you sure to reinitiate this recon transaction ?")
        if (resp == false) {
            return;
        }
        reinitiateRecon(this.req({ acc_stmt_id: row.id, txn_id: row.stmt_txn_id, amount: amount }))
            .then((response) => {
                this.resp(response)
                if (!response) { return };
                if (response.status === "success") {
                    alert(response.message);
                    this.getList(this.state.selectedProviders)
                }
            })
    }

    getList = async (provider_list = null) => {
        const market = get('market')
        const market_name = market.country_code
        // await this.server_time(this.time_zone)

        this.getListRows().then((lists) => {

            let provider = provider_list ? [] : null;
            let columndatas = this.getListColumns()
            this.setState({ lists, columndatas })

            this.setState(state => state.all_count = lists ? lists.length : 0)
            this.handle_acc_prvdr("All", lists, provider)

        })
    }

    getListRows = async () => {
        let resp, data;
        // await this.server_time(this.time_zone)		
        switch (this.props.list_name) {
            case 'unmatched_credits':
                const statement_search = { stmt_txn_type: 'credit', recon_status: 'unknown_txns', stmt_txn_date__from: moment().subtract(5, 'days').format('YYYY-MM-DD'), stmt_txn_date__to: moment().format('YYYY-MM-DD') };
                resp = await list_lender_txn_stmts(this.req({ statement_search }, false, 'statement_search'))
                // this.setState(state => state.sortVariable = "stmt_txn_date")
                if (resp && resp.status === 'success') { data = resp.data.account_txns }
                break;
            case 'review_pending_payments':
                const pending_payment_criteria = { stmt_txn_type: 'credit', recon_status: 'review_pending_payments' };
                resp = await list_lender_txn_stmts(this.req({ statement_search: pending_payment_criteria, home_table: true }, false, 'statement_search'))
                // this.setState(state => state.sortVariable = "stmt_txn_date")
                if (resp && resp.status === 'success') { data = resp.data.account_txns }
                break;
            case 'unmatched_debits':
                const unmatched_debit_criteria = { stmt_txn_type: 'debit', recon_status: 'unknown_txns', stmt_txn_date__from: moment().subtract(5, 'days').format('YYYY-MM-DD'), stmt_txn_date__to: moment().format('YYYY-MM-DD') };
                resp = await list_lender_txn_stmts(this.req({ statement_search: unmatched_debit_criteria }, false, 'statement_search'))
                // this.setState(state => state.sortVariable = "stmt_txn_date")
                if (resp && resp.status === 'success') { data = resp.data.account_txns }
        }

        if (resp && resp.status === 'success') {
            this.setState(state => state.server_time = resp.server_time)
            return data;
        }
    }

    unMatchPaydynClickhandler(key, val) {
        const market = get('market')
        const market_name = market.country_code
        if (key) {
            if (val !== "ALL") {
                const find_val = this.state.dyn_Btn_selected.findIndex(each => each === val)
                if (find_val == -1) {
                    this.state.dyn_Btn_selected.push(val)
                    this.setState(state => state.filter_actions[val][1].status = key)
                    if (this.state.dyn_Btn_selected.length === this.state.all_Acc_pvdrs.length) {
                        this.setState(state => state.all_Btn_status = true)
                    }
                }
                localStorage.setItem([market_name] + '_unMatchedCredits', JSON.stringify(this.state.dyn_Btn_selected))
            } else {
                const newOne = []
                this.state.all_Acc_pvdrs.map(e => {
                    this.state.filter_actions[e][1].status = true
                    newOne.push(e)
                })
                this.setState(state => state.dyn_Btn_selected = newOne)
                localStorage.setItem([market_name] + '_unMatchedCredits', JSON.stringify(newOne))
                this.setState(state => state.all_Btn_status = key)
            }
        }
        else if (!key) {
            if (val !== "ALL") {
                const find_val = this.state.dyn_Btn_selected.findIndex(each => each === val)
                if (find_val > -1) {
                    this.state.dyn_Btn_selected.splice(find_val, 1)
                    this.setState(state => state.filter_actions[val][1].status = key)
                }
                this.setState(state => state.all_Btn_status = false)
                localStorage.setItem([market_name] + '_unMatchedCredits', JSON.stringify(this.state.dyn_Btn_selected))
            } else {
                this.state.all_Acc_pvdrs.map(e => this.state.filter_actions[e][1].status = false)
                this.setState(state => state.dyn_Btn_selected = [])
                localStorage.setItem([market_name] + '_unMatchedCredits', JSON.stringify([]))
                this.setState(state => state.all_Btn_status = key)
            }
        }
        const newArr = get([market_name] + '_unMatchedCredits')
        let combinedArr = []
        newArr.map(each => {
            const filterArr = this.state.lists.filter(indiv => indiv.acc_prvdr_code === each)
            combinedArr.push(...filterArr)
        })
        this.setState(state => state.copy = combinedArr)
    }

    unMatchDebitdynClickhandler(key, val) {
        const market = get('market')
        const market_name = market.country_code
        if (key) {
            if (val !== "ALL") {
                const find_val = this.state.dyn_Btn_selected.findIndex(each => each === val)
                if (find_val == -1) {
                    this.state.dyn_Btn_selected.push(val)
                    this.setState(state => state.filter_actions[val][1].status = key)
                    if (this.state.dyn_Btn_selected.length === this.state.all_Acc_pvdrs.length) {
                        this.setState(state => state.all_Btn_status = true)
                    }
                }
                localStorage.setItem([market_name] + '_unMatchedDebits', JSON.stringify(this.state.dyn_Btn_selected))
            } else {
                const newOne = []
                this.state.all_Acc_pvdrs.map(e => {
                    this.state.filter_actions[e][1].status = true
                    newOne.push(e)
                })
                this.setState(state => state.dyn_Btn_selected = newOne)
                localStorage.setItem([market_name] + '_unMatchedDebits', JSON.stringify(newOne))
                this.setState(state => state.all_Btn_status = key)
            }
        }
        else if (!key) {
            if (val !== "ALL") {
                const find_val = this.state.dyn_Btn_selected.findIndex(each => each === val)
                if (find_val > -1) {
                    this.state.dyn_Btn_selected.splice(find_val, 1)
                    this.setState(state => state.filter_actions[val][1].status = key)
                }
                this.setState(state => state.all_Btn_status = false)
                localStorage.setItem([market_name] + '_unMatchedDebits', JSON.stringify(this.state.dyn_Btn_selected))
            } else {
                this.state.all_Acc_pvdrs.map(e => this.state.filter_actions[e][1].status = false)
                this.setState(state => state.dyn_Btn_selected = [])
                localStorage.setItem([market_name] + '_unMatchedDebits', JSON.stringify([]))
                this.setState(state => state.all_Btn_status = key)
            }
        }
        const newArr = get([market_name] + '_unMatchedDebits')
        let combinedArr = []
        newArr.map(each => {
            const filterArr = this.state.lists.filter(indiv => indiv.acc_prvdr_code === each)
            combinedArr.push(...filterArr)
        })
        this.setState(state => state.copy = combinedArr)
    }

    reviewPendingPaydynClickhandler(key, val) {
        const market = get('market')
        const market_name = market.country_code
        if (key) {
            if (val !== "ALL") {
                const find_val = this.state.dyn_Btn_selected.findIndex(each => each === val)
                if (find_val == -1) {
                    this.state.dyn_Btn_selected.push(val)
                    this.setState(state => state.filter_actions[val][1].status = key)
                    if (this.state.dyn_Btn_selected.length === this.state.all_Acc_pvdrs.length) {
                        this.setState(state => state.all_Btn_status = true)
                    }
                }
                localStorage.setItem([market_name] + '_reviewPendingPayments', JSON.stringify(this.state.dyn_Btn_selected))
            } else {
                const newOne = []
                this.state.all_Acc_pvdrs.map(e => {
                    this.state.filter_actions[e][1].status = true
                    newOne.push(e)
                })
                this.setState(state => state.dyn_Btn_selected = newOne)
                localStorage.setItem([market_name] + '_reviewPendingPayments', JSON.stringify(newOne))
                this.setState(state => state.all_Btn_status = key)
            }
        }
        else if (!key) {
            if (val !== "ALL") {
                const find_val = this.state.dyn_Btn_selected.findIndex(each => each === val)
                if (find_val > -1) {
                    this.state.dyn_Btn_selected.splice(find_val, 1)
                    this.setState(state => state.filter_actions[val][1].status = key)
                }
                this.setState(state => state.all_Btn_status = false)
                localStorage.setItem([market_name] + '_reviewPendingPayments', JSON.stringify(this.state.dyn_Btn_selected))
            } else {
                this.state.all_Acc_pvdrs.map(e => this.state.filter_actions[e][1].status = false)
                this.setState(state => state.dyn_Btn_selected = [])
                localStorage.setItem([market_name] + '_reviewPendingPayments', JSON.stringify([]))
                this.setState(state => state.all_Btn_status = key)
            }
        }
        const newArr = get([market_name] + '_reviewPendingPayments')
        let combinedArr = []
        newArr.map(each => {
            const filterArr = this.state.lists.filter(indiv => indiv.acc_prvdr_code === each)
            combinedArr.push(...filterArr)
        })
        this.setState(state => state.copy = combinedArr)

    }

    handle_capture = (data) => {
        return <SyncPayment mode="recon" title="Review & Sync" data={data} parent="home" />
        // window.location.reload();
    }

    handle_acc_prvdr = (provider, rows = null, selected_prvdrs = null) => {
        let row_data;
        if (rows) {
            row_data = rows
        } else {
            row_data = this.state.lists
        }

        handleProviderSelection(
            provider,
            selected_prvdrs ?? this.state.selectedProviders,
            this.props.acc_prvdr_list,
            row_data,
            (updated_prvdr) => this.setState({ selectedProviders: updated_prvdr }),
            (filtered_rows) => this.setState({ filteredRows: filtered_rows })
        );
    }

    getListColumns = () => {
        switch (this.props.list_name) {
            case 'unmatched_credits':
                return [
                    {
                        headerName: "Network",
                        field: "network_prvdr_code",
                        headeralign: 'left', width: 90, resizable: true, align: 'left',
                        renderCell: row => <Homeacc_prvdrLogoselector logotype={row.value} />
                    },
                    {
                        headerName: "Account Number",
                        field: "acc_prvdr_code",
                        sortable: false, width: 200,
                        headeralign: 'left', resizable: true, align: 'left',
                        renderCell: params => <div className='d-flex align-items-center' >
                            <div><Homeacc_prvdrLogoselector logotype={params.value} /></div>
                            <div style={{ paddingLeft: '10px' }}>{params.row.acc_number}</div>
                        </div>
                    },
                   
                    {
                        headerName: "Stmt Txn Date",
                        headeralign: 'left', resizable: true, align: 'left',
                        field: 'stmt_txn_date', width: 190,
                        renderCell: params => format_date_tbl(params.value, true)
                    },
                    {
                        headerName: 'Amount',
                        headeralign: 'left', resizable: true, align: 'left',
                        field: 'cr_amt', width: 150,
                        renderCell: row => { return lbl_amt(row.value, this.currency_code) }
                    },
                    {
                        headerName: "Stmt Txn ID", width: 190,
                        headeralign: 'left', resizable: true, align: 'left',
                        field: 'stmt_txn_id'
                    },
                    {
                        headerName: "Description", width: 150,
                        headeralign: 'left', resizable: true, align: 'left',
                        width: 230,
                        field:'descr',
                        renderCell: row => <TooltipLabel full_text={dd_value(row.value)} no_reduce={true} />
                    },
                    {
                        headerName: "Last Updated at", width: 150,
                        // width:130,
                        headeralign: 'left', resizable: true, align: 'left',
                        field: "updated_at",
                        // sortMethod: (a,b) => this.sortTime(a,b),
                        renderCell: params => <ExcesstimeAction data={params.row} timedata={this.state.server_time} item={"last_updated_at"} time_limit={[this.state.capture_payment_late1, this.state.capture_payment_late2]} />
                    },
                    {
                        headerName: <div className='text-center'>Matching ID</div>, width: 150,
                        // minWidth:140,
                        headeralign: 'left', resizable: true, align: 'left',

                        // maxWidth:200, 
                        field: 'chk_fa_id',
                        renderCell: params => <div className="text-center"> {this.chk_fa_id(params.row)}</div>
                    },
                    {
                        headerName: "Matching Status", width: 150,
                        headeralign: 'left', resizable: true, align: 'left',

                        field: 'recon_status',
                        renderCell: params => <TooltipLabel full_text={dd_value(params.row.recon_status)} overlay={params.row.recon_desc} no_reduce={true} />
                    }, {
                        headerName: "Action", width: 150,
                        field: "action",
                        headeralign: 'left', resizable: true, align: 'left',

                        show: check_priv('recon', 'reinitiate_recon') ? true : false,
                        renderCell: params => <ReconStatus parent="home" show_btn={true} data={params.row} onSync={this.getList} />
                    },



                    {
                        headerName: "Action", width: 150,
                        field: "reinitiate",
                        headeralign: 'left', resizable: true, align: 'left',

                        show: check_priv('recon', 'reinitiate_recon') ? true : false,

                        renderCell: params => <> {(params.row.recon_status != "80_recon_done" && params.row.recon_status != "10_capture_payment_pending") || (params.row.recon_status != null && (params.row.recon_status != "10_capture_payment_pending" && params.row.loan_doc_id == null)) ?
                            <input id={params.row.id} type="button" value="Recon Again" onClick={(e) => { this.handleReinitiateRecon(e, params.row) }} className={`btn btn-primary btn-std}`} />
                            :
                            <input disabled={true} id={params.row.id} type="button" value="Recon Again" onClick={(e) => { this.handleReinitiateRecon(e, params.row) }} className={`btn btn-primary btn-std}`} />
                        }

                        </>
                    }]
            case 'unmatched_debits':
                return [
                    {
                        headerName: "Network", width: 90,
                        field: "network_prvdr_code",
                        headeralign: 'left', resizable: true, align: 'left',

                        renderCell: row => <Homeacc_prvdrLogoselector logotype={row.value} />
                    },
                    {
                        headerName: "Account Number",
                        width: 200,
                        field: "acc_prvdr_code",
                        headeralign: 'left', resizable: true, align: 'left',

                        renderCell: row => <div className='d-flex align-items-center'> <div><Homeacc_prvdrLogoselector logotype={row.value} style={{ width: '30px', height: '20px' }} /></div>
                            <div style={{ paddingLeft: '10px' }}>{row.row.acc_number}</div>
                        </div>
                    },

                    {
                        headerName: "Stmt Txn Date", width: 200,

                        headeralign: 'left', resizable: true, align: 'left',
                        field: 'stmt_txn_date',
                        renderCell: row => format_date_tbl(row.value, true)
                    },
                    {
                        headerName: 'Amount',
                        headeralign: 'left', resizable: true, align: 'left', width: 160,

                        field: 'dr_amt',
                        renderCell: row => { return lbl_amt(row.value, this.currency_code) }
                    },
                    {
                        headerName: "Stmt Txn ID", width: 200,
                        headeralign: 'left', resizable: true, align: 'left',

                        field: 'stmt_txn_id'
                    },
                    {
                        headerName: <div className='text-center'>Description</div>,
                        field: 'descr', width: 180,
                        headeralign: 'left', resizable: true, align: 'left',

                        renderCell: row => <TooltipLabel full_text={dd_value(row.value)} no_reduce={true} />
                    },
                    {
                        headerName: "Last Updated at", width: 200,
                        // minWxidth: 190,
                        // maxWidth: 210,
                        sortable: false,
                        headeralign: 'left', resizable: true, align: 'left',

                        field: "updated_at",
                        renderCell: row => <ExcesstimeAction data={row} timedata={this.state.server_time} item={"last_updated_at"} time_limit={[this.state.capture_payment_late1, this.state.capture_payment_late2]} />
                    },
                    {
                        headerName: 'Matching ID',
                        headeralign: 'left', resizable: true, align: 'left', width: 200,

                        field: 'chk_fa_id',
                        renderCell: row => <div className=''>{this.chk_fa_id(row)}</div>
                    },
                    {
                        headerName: "Matching Status", width: 200,
                        headeralign: 'left', resizable: true, align: 'left',

                        field: 'recon_status',
                        renderCell: row => <TooltipLabel full_text={dd_value(row.value)} overlay={row.value} no_reduce={true} />
                    },
                    {
                        headerName: "Action", width: 200,
                        field: "action",
                        headeralign: 'left', resizable: true, align: 'left',

                        show: check_priv('recon', 'reinitiate_recon') ? true : false,
                        renderCell: params => <ReconStatus parent="home" show_btn={true} data={params.row} onSync={this.getList} />
                    },

                    {
                        headerName: "Action", width: 200,
                        field: "reinitiate",
                        headeralign: 'left', resizable: true, align: 'left',

                        show: check_priv('recon', 'reinitiate_recon') ? true : false,

                        renderCell: params => <> {(params.row.recon_status != "80_recon_done" && params.row.recon_status != "10_capture_payment_pending") || (params.row.recon_status != null && (params.row.recon_status != "10_capture_payment_pending" && params.row.loan_doc_id == null)) ?
                            <input id={params.row.id} type="button" value="Recon Again" onClick={(e) => { this.handleReinitiateRecon(e, params.row) }} className={`btn btn-primary btn-std}`} />
                            :
                            <input disabled={true} id={params.row.id} type="button" value="Recon Again" onClick={(e) => { this.handleReinitiateRecon(e, params.row) }} className={`btn btn-primary btn-std}`} />
                        }

                        </>
                    }]
            case 'review_pending_payments':
                return [
                    {
                        headerName: "Network",
                        field: "network_prvdr_code", width: 150,
                        headeralign: 'left', resizable: true, align: 'left',

                        renderCell: row => <Homeacc_prvdrLogoselector logotype={row.value} />
                    },
                    {
                        headerName: "Account Number", width: 150,
                        field: "acc_prvdr_code",
                        headeralign: 'left', resizable: true, align: 'left',
                        width: 150,
                        maxWidth: 150,
                        renderCell: row => <div className='d-flex align-items-center'> <div><Homeacc_prvdrLogoselector logotype={row.row.acc_prvdr_code} style={{ width: '30px', height: '20px' }} /></div>
                            <div style={{ paddingLeft: '10px' }}>{row.row.acc_number}</div>
                        </div>
                    },

                    {
                        headerName: "Stmt Txn Date", width: 150,
                        headeralign: 'left', resizable: true, align: 'left',
                        field: 'stmt_txn_date',
                        renderCell: row => format_date_tbl(row.value, true)
                    },
                    {
                        headerName: <div className='text-right'>Amount</div>, width: 150,
                        headeralign: 'left', resizable: true, align: 'left',
                        field: 'cr_amt',
                        renderCell: row => { return rt_algn(lbl_amt(row.value, this.currency_code)) }
                    },
                    {
                        headerName: "Stmt Txn ID", width: 150,
                        headeralign: 'left', resizable: true, align: 'left',
                        field: 'stmt_txn_id'
                    },
                    {
                        headerName: "Description", width: 150,
                        field: 'descr',
                        headeralign: 'left', resizable: true, align: 'left',

                        renderCell: row => <TooltipLabel full_text={dd_value(row.value)} no_reduce={true} />
                    },
                    {
                        headerName: "Last Updated at", width: 150,
                        headeralign: 'left', resizable: true, align: 'left',

                        sortable: false,
                        field: "updated_at",
                        // sortMethod: (a,b) => this.sortTime(a,b),
                        renderCell: params => <ExcesstimeAction data={params.row} timedata={this.state.server_time} item={"last_updated_at"} time_limit={[this.state.capture_payment_late1, this.state.capture_payment_late2]} />
                    },
                    {
                        headerName: "Matching FA ID", width: 150,
                        headeralign: 'left', resizable: true, align: 'left',

                        field: 'chk_fa_id',
                        renderCell: row => { return this.chk_fa_id(row) }
                    },
                    {
                        headerName: "Review Reason", width: 150,
                        headeralign: 'left', resizable: true, align: 'left',

                        field: 'review_reason',
                        renderCell: params => { return dd_value(params.row.review_reason) }
                    },
                    {
                        headerName: "Action", width: 150,
                        headeralign: 'left', resizable: true, align: 'left',

                        field: 'recon_status',
                        renderCell: params => { return this.handle_capture(params.row) }
                    }]
        }
    }

    refreshList = async () => {
        this.setState({ refresh: true })
        await this.getList()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.refresh != this.props.refresh) {
            this.refreshList()
        }
    }


    render() {
        return (
            <Box>
                <MUIAccordion
                    titleUI={
                        <div>
                            <div className="card-header w-90 card-header-icon card-header-primary home_header_d-flex_at_response justify-space-between" >
                                <h4 className="card-title text-white tableHead_font ">{this.getListTitle()}</h4>
                                <AccProviderFilter
                                    rows={this.state.lists ?? []}
                                    handle_acc_prvdr={(provider) => this.handle_acc_prvdr(provider)}
                                    selectedProviders={this.state.selectedProviders}
                                />
                            </div>
                        </div>
                    }

                    body={
                        <>
                            <Box display={'flex'} justifyContent={'end'}>
                                <ExportCsv
                                    total_count={ 0}
                                    export_csv_datas={this.state.lists}
                                    csvFileName={dd_value(this.props.list_name)}
                                    paginationMode={ "client"}
                                    columns={this.state.columndatas ?? []}

                                />
                            </Box>
                            <DataGridTable
                                rows={this.state.filteredRows ?? []}
                                paginationMode={"client"}
                                page={this.state.page}
                                setpage={(newPage) => this.setState({ page: newPage })}
                                // isLoading={ this.state.loader }
                                rowCount={this.state.filteredRows?.length}
                                columns={this.state.columndatas ?? []}
 
                                sortingMode="client"
                                initialState={{
                                    columns: {
                                        columnVisibilityModel: {
                                            reinitiate: check_priv('recon', 'reinitiate_recon'),
                                            action: check_priv('recon', 'reinitiate_recon')
                                        },
                                    },
                                }}
                            />
                        </>

                        // <BasicReactTable
                        //     row_data={this.state.filteredRows}
                        //     column_data={this.state.columndatas} pagination={this.state.filteredRows.length > 9 ? true : false} default_page_size={10} defaultSorted={[{ id: "stmt_txn_date", desc: true }]} timeServer={this.state.server_time} excessTimeaction={true} appliedRowblink={false} paymentRowblinktime={this.state.capture_payment_late2} csv_file={this.props.list_name !== 'review_pending_payments' ? true : false} export_csv_datas={this.state.filteredRows !== undefined ? this.state.filteredRows : []} csvFile={this.getListTitle()} />

                    }
                    totalCount={this.props.acc_prvdr_list?.length > 1 ? 0 : (this.state.lists?.length)}>
                </MUIAccordion>
            </Box>

        );
    }

    getListTitle = () => {
        switch (this.props.list_name) {
            case 'unmatched_credits':
                return "Unknown Credit Txns (Last 5 days) "
            case 'review_pending_payments':
                return "Review Pending Payments"
            case 'unmatched_debits':
                return "Unknown Debit Txns (Last 5 days) "
        }
        return ''
    }
}


const mapStateToProps = (state) => {
    return {
        acc_prvdr_list: state.common_slice?.acc_prvdr_list

    };
};

const mapDispatchToProps = {
    setValuesIntoRedux

};


export default connect(mapStateToProps, mapDispatchToProps)(DisplayAccStmtList);
