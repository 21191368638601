import { Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  FormValues,
  useGetDigitalKycVerificationInfo,
} from "../../hook/auditorVerificationHooks";
import FormProvider from "../../../CommonComponent/rhfComponents/formProvider";
import moment from "moment";
import AuditorVerificationSchema from "../../formschema/AuditorVerificationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import PhotoNationalIdVerification from "./photoNationalIdVerification";
import PhotoRdbCerificate from "./photoRdbCertificate";
import { textTransform } from "../../../../styles/common_styles";
import { completeIdVerif } from "../../../../actions/lead_actions";
import { common_req } from "../../../../helpers/helper";
import { dd_value } from "../../../../actions/common_actions";

const DigitalKycDocuments = ({
  proofType,
  fields,
}: {
  proofType: "photo_rdb_certificate" | "photo_national_id";
  fields: string[];
}) => {
  const { data } = useGetDigitalKycVerificationInfo();
  const defaultValues = {
    id_proof_num: data?.self_reg_json?.photo_national_id?.id_proof_num ?? "",
    first_name: data?.self_reg_json?.photo_national_id?.first_name ?? "",
    last_name: data?.self_reg_json?.photo_national_id?.last_name ?? "",
    gender: data?.self_reg_json?.photo_national_id?.gender ?? "",
    dob: data?.self_reg_json?.photo_national_id?.dob
      ? moment(data?.self_reg_json?.photo_national_id?.dob)
      : "",
    company_code:
      data?.self_reg_json?.photo_rdb_certificate?.company_code ?? "",
    enterprise_name:
      data?.self_reg_json?.photo_rdb_certificate?.enterprise_name ?? "",
    owner_name: data?.self_reg_json?.photo_rdb_certificate?.owner_name ?? "",
    registration_date:
      moment(data?.self_reg_json?.photo_rdb_certificate?.registration_date) ??
      "",
    nationalIdIsCorrect: false,
    rdbIsCorrect: false,
    isOwnedAccount: false,
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(AuditorVerificationSchema),
  });

  const {
    watch,
    setValue,
    formState: { isValid },
  } = methods;
  const { id } = useParams();

  const nationalIdFields = [
    "id_proof_num",
    "first_name",
    "last_name",
    "gender",
    "dob",
  ];
  const rdbCertificate = [
    "company_code",
    "enterprise_name",
    "owner_name",
    "registration_date",
  ];
  const editedValue = (fields: string[]) => {
    return fields.reduce((acc, field) => {
      // @ts-ignore
      acc[field] = watch(field);
      return acc;
    }, {} as { [key: string]: any });
  };
  const photo_national_id = editedValue(nationalIdFields);
  const photo_rdb_certificate = editedValue(rdbCertificate);

  const kycApprove = (type: string) => {
    let request = {
      lead_id: id,
      audit_flags: {
        nid_verified: watch("nationalIdIsCorrect"),
        rdb_verified: watch("rdbIsCorrect"),
        auditor_verified: watch("isOwnedAccount"),
      },
      self_reg_json: {
        photo_national_id: photo_national_id,
        photo_rdb_certificate: photo_rdb_certificate,
      },
      action: type,
    };
    completeIdVerif(common_req(request))
      .then((response) => {
        if (response?.status === "success") {
          alert(`KYC ${dd_value(type)} Successful!`);
        }
      })
      .catch((error) => {
        console.error("Error during KYC Approval:", error);
        alert("KYC Approval Failed. Please try again."); // Handle errors
      });
  };

  return (
    <Box px={3} py={proofType == "photo_rdb_certificate" ? 3 : 0}>
      <FormProvider methods={methods}>
        <PhotoNationalIdVerification />
        <PhotoRdbCerificate />
      </FormProvider>

      <Box display={"flex"} justifyContent={"center"}>
        <Button
          sx={textTransform}
          variant="contained"
          color="error"
          onClick={() => kycApprove("reject")}
        >
          Reject
        </Button>
        <Button
          sx={{ ...textTransform, mx: 2 }}
          variant="contained"
          color="success"
          disabled={!isValid}
          onClick={() => kycApprove("approve")}
        >
          Approve
        </Button>
      </Box>
    </Box>
  );
};

export default DigitalKycDocuments;
