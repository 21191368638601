import { get_path } from '../helpers/common_helper';
import {call_fetch} from './../helpers/fetch_helper';


export const getReport = request => {
   return call_fetch(get_path('admin') + '/report', request);
}

export const getParFasList = request => {
   return call_fetch(get_path('admin') + '/report/par_fa_list', request);
}

export const getKPIReport = request => {
   return call_fetch(get_path('admin') + '/report/flow_kpi_report', request);
}

// export const getCustPerformance = request => {
//    return call_fetch(get_path('admin') + '/report/growth_chart', request);
// }

export const getCustomReport = request => {
   return call_fetch(get_path('admin') + '/report/custom_report', request)
}

export const getManagementDashboardReport = request => {
   return call_fetch(get_path('admin')+'/report',request)
}

export const getRMWiseReport = request => {
   return call_fetch(get_path('admin')+'/report/rm_report',request)
}


export const getCurrencyDetails = request => {
   return call_fetch(get_path('admin') + '/report/cur_details', request)
}

export const getSMSReports = request => {
   return call_fetch(get_path('admin') + '/report/sms_reports', request)
}

export const getReportDate = request => {
   return call_fetch(get_path('admin') + '/report/report_date', request)
}

export const Biz_operation_live_reports = request => {
   return call_fetch(get_path('admin') + '/report', request)
}

export const PartialPaymentReport = request  => {
   return call_fetch(get_path('admin') + '/report/partial_payment_report', request);
}

export const ontimePaymentReport =  request => {
   return call_fetch(get_path('admin') + '/report/ontime_payment_report', request);
}



export const fsdu_report =  request => {
   return call_fetch(get_path('admin') + '/report/fsdu', request);
}

export const daywiseSwitchReport =  request => {
   return call_fetch(get_path('admin') + '/report/day_wise_switch_report', request);
}

export const inbound_voice_call_report = request =>{
   return call_fetch(get_path('admin') + '/report/inbound_voice_calls_report' ,request)
}

export const getTollfreeSuccessReport =  request => {
   return call_fetch(get_path('admin') + '/report/tollfree_success_rate_report', request);
}

export const getPARHealthReport =  request => {
   return call_fetch(get_path('admin') + '/report/par_health_report', request);
}


export const getAccountBalance =  request => {
   return call_fetch(get_path('admin') + '/account/balance/founder_screen', request);
}

export const getCashBackReport = request => {
   return call_fetch(get_path('app')+'/cashback/report',request)
}

export const getInternalTransferReport = request => {
   return call_fetch(get_path('app')+'/common/internal_transfer_report',request)
}

export const uploadDupFAProof = request => {
   return call_fetch(get_path('admin') + '/duplicate_fa_management/upload_payment_proof', request);
}

export const captureDuplicatePayment = request => {
   return call_fetch(get_path('admin') + "/duplicate_fa_management/capture_by_ts", request);
}

export const uncapturedDuplicatPayment = request => {
   return call_fetch(get_path('admin') + "/report/uncaptured_dup_disb_payments", request);
}

export const list_overdue_customer = request => {
   return call_fetch(get_path('admin') + "/report/list_overdue_customer", request);
}

export const list_due_customers = request => {
   return call_fetch(get_path('admin') + "/report/list_due_customers", request);
}




