import { Box } from '@mui/material';
import React from 'react';
import SkeletonLoader from '../../common/component/skeletonLoader';

const FallBackUI = () => {
    return (
       <Box>
        <SkeletonLoader variant='rectangular' height={'50px'} width={'100%'} />
       </Box>
    );
};

export default FallBackUI;