import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CallIcon from "@mui/icons-material/Call";
import { Box, Divider, IconButton, useTheme } from "@mui/material";
import { dd_value } from "../../../actions/common_actions";
import { ERROR_INFO, FETCHING_INFO } from "../../../consts";
import { useSoftPhoneAndFreshChatCtx } from "../../../context/softPhoneAndFreshChatModalUICtx/useSoftPhoneAndFreshChatCtx";
import {
  DELIQUENCY_CALL_TASK_LOG_MODAL,
  DELIQUENCY_OVERDUE_BY_COMMIT_DATE_LOG_MODAL,
  DELIQUENCY_OVERDUE_BY_DAYS_LOG_MODAL,
} from "../../../context/softPhoneCtx/softPhoneCtx";
import { useSoftPhoneCtx } from "../../../context/softPhoneCtx/useSoftPhoneCtx";
import {
  HeaderVarientH2Typo,
  HeaderVarientH4Typo,
} from "../../CommonComponent/typography";
import { useGetCustDetailsByPhNumQuery } from "../hooks/useGetCustDetailsQuery";

const PhoneNumbersUI = () => {
  // ctx
  const {
    softPhoneAndFreshChatFloatUIState: {
      callState: {
        customer: { mobileNum: phoneNumber },
      },
    },
  } = useSoftPhoneAndFreshChatCtx();

  // query
  const { data, isLoading, isError } = useGetCustDetailsByPhNumQuery({
    phoneNumber,
  });

  // hook
  const theme = useTheme();

  if (isLoading)
    return (
      <HeaderVarientH2Typo
        overRideStyles={{ color: "#000", textAlign: "center", py: 16 }}
        text={FETCHING_INFO}
      />
    );

  if (isError && !data?.data)
    return (
      <HeaderVarientH2Typo
        overRideStyles={{ color: "#000", textAlign: "center", py: 16 }}
        text={ERROR_INFO}
      />
    );

  if (data?.data?.customer_list.length === 0 || (!isError && !data?.data))
    return (
      <HeaderVarientH2Typo
        overRideStyles={{ color: "#000", textAlign: "center", py: 16 }}
        text={"No User Detail Found!."}
      />
    );

  const custObj = data?.data?.customer_list[0];

  return (
    <Box sx={{ p: 2 }}>
      <Box
        sx={{
          borderRadius: 1,
          p: 1,
          // @ts-ignore
          backgroundColor: theme.palette.custom_colors.secondary_a_50,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <AccountCircleIcon sx={{ width: "40px", height: "40px" }} />
          {custObj && (
            <HeaderVarientH4Typo
              text={custObj.cust_name}
              overRideStyles={{ color: "#000!important", ml: 2 }}
            />
          )}
        </Box>
        <Divider
          sx={{
            my: 2,
            borderColor: "#000",
          }}
        />
        {custObj &&
          Object.keys(custObj.mob_nums).map((mn, idx) => (
            <Box key={idx}>
              {mn !== "addl_mob_num" &&
                // @ts-ignore
                custObj.mob_nums[mn] && (
                  <MobileNumAndCallBtnUI
                    mobileNumType={mn}
                    // @ts-ignore
                    mobileNum={custObj.mob_nums[mn]}
                  />
                )}
            </Box>
          ))}
        {/* additional numbers UI */}
        <p className="sizem  text-info font_weight_600 underlined_text pointer">
          Additional numbers
        </p>
        {custObj &&
          custObj.mob_nums.addl_mob_num.map((adN, idx) => (
            <Box key={idx}>
              {adN.mobile_num && (
                <MobileNumAndCallBtnUI
                  mobileNumType={`Additional Number ${idx + 1}`}
                  mobileNum={adN.mobile_num}
                />
              )}
            </Box>
          ))}
      </Box>
    </Box>
  );
};

export default PhoneNumbersUI;

const MobileNumAndCallBtnUI = ({
  mobileNumType,
  mobileNum,
}: {
  mobileNumType: string;
  mobileNum: string;
}) => {
  // ctx
  const {
    methods: { initiateCall },
    softPhoneState: { showCallStatusModal },
  } = useSoftPhoneCtx();

  const {
    softPhoneAndFreshChatFloatUIState: {
      callState: { screen, deliquencyId, churnId },
    },
  } = useSoftPhoneAndFreshChatCtx();

  // handler
  const initiateCallHandler = async (phoneNumber: string) => {
    initiateCall({
      phoneNumber: phoneNumber ?? "",
      screen,
      churnOrDeliquencyId:
        screen === DELIQUENCY_CALL_TASK_LOG_MODAL ||
        screen === DELIQUENCY_OVERDUE_BY_DAYS_LOG_MODAL ||
        screen === DELIQUENCY_OVERDUE_BY_COMMIT_DATE_LOG_MODAL
          ? deliquencyId
          : churnId,
          taskId: "",
    });
  };

  // const
  const hasAnyOngoingCall = showCallStatusModal;
  const disableCallBtn = hasAnyOngoingCall;

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box>
        <p className="m-0 agreementPersonalDetailstext">
          {dd_value(mobileNumType)}
        </p>
        <p className="my-2 font_weight_600 agreementPersonalDetailstext">
          {mobileNum}
        </p>
      </Box>
      <IconButton
        type="button"
        disabled={disableCallBtn}
        onClick={() => initiateCallHandler(mobileNum)}
        sx={{
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          backgroundColor: "#397baa",
          "&:hover": { backgroundColor: "#4a90c1" },
          ml: 2,
        }}
      >
        <CallIcon sx={{ width: "25px", height: "25px" }} />
      </IconButton>
    </Box>
  );
};
