import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import VerifiedIcon from "@mui/icons-material/Verified";
import WarningIcon from "@mui/icons-material/Warning";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import RhfFileUploadField from "../../CommonComponent/rhfComponents/hooks/digitalKyc/rhfFileUploadField";
import RHFTextField from "../../CommonComponent/rhfComponents/rhfTextField";
import TextField from "../../CommonComponent/field/textField";
import { useParams } from "react-router-dom";
import { useGetDigitalKycRecords } from "../hook/digitalKycHooks";
import RhfDatePicker from "../../CommonComponent/rhfComponents/rhfDatePicker";
import { verifyHolderName } from "../../../actions/lead_actions";
import { common_req } from "../../../helpers/helper";
import moment from "moment";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getValueFromLangJson } from "../../../helpers/common_helper";

interface IdVerificationProcessProps {
  label: string;
  fields: string[];
}
interface ProofStatus {
  [key: string]: boolean | null | undefined; // Allow dynamic keys
}
const UploadVerificationProof: React.FC<IdVerificationProcessProps> = ({
  label,
  fields,
}) => {
  const theme = useTheme();
  const {
    watch,
    setValue,
    formState: { isValid },
  } = useFormContext();
  const [isFileUploading, setisFileUploading] = useState(false);
  const { id } = useParams();
  const fileType =
    label == "nationalId" ? "photo_national_id" : "photo_rdb_certificate";
  const { data } = useGetDigitalKycRecords();
  const fileData = watch(label)?.[0]?.uploadedRes?.[fileType] ?? {};
  const [proofStatus, setproofStatus] = useState<ProofStatus>({
    nationalIdMismatch:
      data?.identity_verification?.self_reg_json?.photo_national_id
        ?.is_verified,
    rdbCertificateMismatch:
      data?.identity_verification?.self_reg_json?.photo_rdb_certificate
        ?.is_verified,
  });

  const editedValue = fields.reduce((acc, field) => {
    acc[field] = watch(field);
    return acc;
  }, {} as { [key: string]: any });

  const handleVerification = () => {
    mutate();
  };
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationFn: async () => {
      let request = {
        lead_id: id,
        type: fileType,
        self_reg_json: editedValue,
        stage: "identity_verification",
      };
      const response = await verifyHolderName(common_req(request));
      return response;
    },
    onSuccess: (response) => {
      const updateData = response.data;
      setproofStatus((prevState) => ({
        ...prevState,
        [`${label}Mismatch`]:
          updateData?.self_reg_json?.[fileType]?.is_verified,
      }));
      queryClient.setQueryData(["digitalKycLeadInfo", id], (prevData: any) => ({
        ...prevData,
        identity_verification: updateData,
      }));
    },
  });
  const customOnchangeCb = (fileType: string | null = null) => {
    setisFileUploading((prev) => !prev);
    if (fileType == "isUploaded") {
      setExtractedValuesToForms();
    }
    if (fileType && fileType != "isUploaded") {
      updateQueryValue(fileType);
      if (fileType === "photo_national_id") {
        setValue("nationalId", []);
        setValue("rdbCertificate", []);
        setValue("is_interested", null);
        setValue("not_interested", null);
        setproofStatus({
          nationalIdMismatch: null,
          rdbCertificateMismatch: null,
        });
      } else if (fileType === "photo_rdb_certificate") {
        setValue("rdbCertificate", []);
        setValue("registration_date", null);
        setproofStatus((prev) => ({
          ...prev,
          rdbCertificateMismatch: null,
        }));
      }
    }
  };
  const updateQueryValue = (fileType: string) => {
    queryClient.setQueryData(["digitalKycLeadInfo", id], (prevData: any) =>
      prevData
        ? {
            ...prevData,
            identity_verification: {
              self_reg_json: {
                ...prevData.identity_verification?.self_reg_json,
                ...(fileType === "photo_national_id" && {
                  photo_national_id: {},
                  photo_rdb_certificate: {},
                }),
                ...(fileType === "photo_rdb_certificate" && {
                  photo_rdb_certificate: {},
                }),
              },
            },
          }
        : prevData
    );
  };

  const setExtractedValuesToForms = () => {
    const extractedData = watch(label)?.[0]?.uploadedRes?.[fileType];
    if (extractedData) {
      fields.forEach((field) => {
        if (extractedData[field] !== undefined) {
          if (field == "dob" || field == "registration_date") {
            setValue(field, moment(extractedData[field]), {
              shouldDirty: true,
              shouldValidate: true,
            });
          } else {
            setValue(field, extractedData[field], {
              shouldDirty: true,
              shouldValidate: true,
            });
          }
        }
      });
    }
  };

  const updateExtractedValues = (
    field: string,
    fileType: "photo_national_id" | "photo_rdb_certificate"
  ) => {
    setValue(field, [
      {
        uploadedRes: data?.identity_verification?.self_reg_json,
        uploadedFileRelPath:
          data?.identity_verification?.self_reg_json?.[fileType]?.file_rel_path,
        uploadedFileName:
          data?.identity_verification?.self_reg_json?.[fileType]?.file_name,
        size: data?.identity_verification?.self_reg_json?.[fileType]?.file_size,
      },
    ]);
  };
  useEffect(() => {
    if (
      data &&
      data?.identity_verification?.self_reg_json?.[fileType]?.file_rel_path
    ) {
      updateExtractedValues("nationalId", "photo_national_id");
      updateExtractedValues("rdbCertificate", "photo_rdb_certificate");
    }
  }, [data]);
  const proofMismatch = label + "Mismatch";
  const proofIsVerified = proofStatus?.[proofMismatch] == null && !isPending;

  const proofLength =
    watch(label)?.length > 0 && watch(label)?.[0]?.uploadedFileRelPath;

  return (
    <Box>
      <Box
        sx={{
          border: proofLength ? "none" : "1px solid white",
          p: proofLength ? 2 : 5,
          mx: 1,
          my: 2,
          borderRadius: 1,
        }}
      >
        <Box
          display="flex"
          justifyContent={proofLength ? "start" : "center"}
          sx={{ position: "relative" }}
        >
          <Box>
            <RhfFileUploadField
              multiple={false}
              maxSize={5}
              accept={{ "image/*": [".png", ".jpeg", ".jpg"] }}
              icon={
                <DescriptionOutlinedIcon
                  sx={{ color: theme.palette.secondary.main, fontSize: 30 }}
                />
              }
              required={false}
              label={getValueFromLangJson(label)}
              fileOf={fileType}
              name={label}
              initialFiles={watch(label)}
              leadId={id}
              disabled={data?.identity_verification?.status == "verified"}
              customOnchangeCb={customOnchangeCb}
            />
            {proofLength && proofStatus?.[proofMismatch] == false && (
              <Typography color={"#FF4245"} sx={{ mt: 1, fontSize: "0.9rem" }}>
                The {getValueFromLangJson(label)} name doesn’t match the agent
                line name
              </Typography>
            )}
          </Box>

          {proofLength && !isFileUploading && (
            <Box
              sx={{
                position: "absolute",
                left: label == "nationalId" ? "145px" : "175px",
                top: "2px",
              }}
            >
              {proofStatus?.[proofMismatch] ? (
                <VerifiedIcon color="success" />
              ) : (
                proofLength &&
                proofStatus?.[proofMismatch] == false && (
                  <WarningIcon color="error" />
                )
              )}
            </Box>
          )}
        </Box>

        {proofLength && (
          <Box>
            {
              <>
                {isPending ? (
                  <Box sx={{ p: 1 }} display={"flex"} justifyContent={"center"}>
                    <CircularProgress
                      sx={{ width: "22px", height: "22px", color: "#fff" }}
                    />
                  </Box>
                ) : (
                  <Grid container spacing={4} justifyContent="start" mt={1}>
                    {fields.map((item, idx) => (
                      <Grid
                        item
                        key={idx}
                        sx={{ pt: "15px !important" }}
                        lg={
                          proofStatus?.[proofMismatch] != null
                            ? label == "nationalId"
                              ? 3
                              : 4
                            : 6
                        }
                        sm={12}
                      >
                        {proofStatus?.[proofMismatch] != null ? (
                          <Box color={theme.palette.text.primary}>
                            <TextField
                              direction="column"
                              label={getValueFromLangJson(item)}
                              val={
                                item == "dob" || item == "registration_date"
                                  ? watch(item).format("DD MMM YYYY")
                                  : watch(item)
                              }
                            />
                          </Box>
                        ) : item == "dob" || item == "registration_date" ? (
                          <RhfDatePicker
                            label={getValueFromLangJson(item)}
                            name={item}
                            format="DD-MMM-YYYY"
                            disabled={!fileData?.is_editable}
                            sx={{
                              fontSize: "0.8rem",
                              "& .MuiInputBase-input.Mui-disabled": {
                                color: "white",
                                WebkitTextFillColor: "white",
                              },
                            }}
                          />
                        ) : (
                          <RHFTextField
                            name={item}
                            label={getValueFromLangJson(item)}
                            disabled={!fileData?.is_editable}
                            sx={{
                              fontSize: "0.8rem",
                              "& .MuiInputBase-input.Mui-disabled": {
                                color: "white",
                                WebkitTextFillColor: "white",
                              },
                            }}
                          />
                        )}
                      </Grid>
                    ))}
                  </Grid>
                )}
              </>
            }
            {proofIsVerified && (
              <Box display="flex" justifyContent="center" mt={3}>
                <Button
                  variant="contained"
                  color="success"
                  sx={{ textTransform: "none", padding: "4px" }}
                  onClick={() => handleVerification()} // Trigger verification dynamically
                  disabled={!isValid}
                >
                  Verify
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default UploadVerificationProof;
