import { ReactNode, useCallback, useReducer } from "react";
import {
  SoftPhoneTimerCtx,
  SoftPhoneTimerState,
  START_TIMER,
} from "./softPhoneTimerCtx";
import softPhoneTimerReducer from "./softPhoneTimerReducer";

const initialState: SoftPhoneTimerState = {
  timer: 0,
};

const SoftPhoneTimerCtxProvider = ({ children }: { children: ReactNode }) => {
  // reducer
  const [softPhoneTimerState, dispatch] = useReducer(
    softPhoneTimerReducer,
    initialState
  );

  // fns
  const startTimer = useCallback((timer: number) => {
    dispatch({
      type: START_TIMER,
      payload: { timer },
    });
  }, []);

  const endTimer = useCallback(() => {}, []);

  return (
    <SoftPhoneTimerCtx.Provider
      value={{
        softPhoneTimerState,
        dispatch,
        methods: {
          startTimer,
          endTimer,
        },
      }}
    >
      {children}
    </SoftPhoneTimerCtx.Provider>
  );
};

export default SoftPhoneTimerCtxProvider;
