import {
  Box,
  Grid,
  IconButton,
  Button,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import DisplayImage from "../../../common/component/DisplayImage";
import {
  get_dir_path,
  getValueFromLangJson,
} from "../../../../helpers/common_helper";
import { HeaderVarientH4Typo } from "../../../CommonComponent/typography";
import {
  FormValues,
  useGetDigitalKycVerificationInfo,
} from "../../hook/auditorVerificationHooks";
import { useParams } from "react-router-dom";
import { textTransform } from "../../../../styles/common_styles";
import { dd_value } from "../../../../actions/common_actions";
const removableFields = ["photo_id_proof"];
const fullWidthFields = ["id_proof_num", "enterprise_name", "company_code"];
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import RhfDatePicker from "../../../CommonComponent/rhfComponents/rhfDatePicker";
import RHFTextField from "../../../CommonComponent/rhfComponents/rhfTextField";

import moment from "moment";
import { useForm, useFormContext } from "react-hook-form";

const PhotoProof = ({ fields }: { fields: string[] }) => {
  const { data } = useGetDigitalKycVerificationInfo();
  const { id } = useParams();
  const theme = useTheme();
  const proofType = "photo_national_id";
  const [isEditing, setIsEditing] = useState(false);

  const {
    watch,
    setValue,
    formState: { isValid },
  } = useFormContext();

  const getDisplayValue = (field: keyof FormValues) => {
    const value = watch(field);
    if (value instanceof moment) {
      return moment(value).format("DD-MMM-YYYY");
    }
    return value ?? "-";
  };

  return (
    <Box>
      <Grid container spacing={4} justifyContent={"space-between"}>
        <Grid item xs={12} md={6} lg={6}>
          <Box sx={{ width: "100% !important", position: "relative" }}>
            <DisplayImage
              image_path={get_dir_path(
                data?.self_reg_json?.photo_national_id
                  ?.photo_national_id_full_path,
                data?.self_reg_json?.photo_national_id?.photo_national_id
              )}
              file_name={
                data?.self_reg_json?.photo_national_id?.photo_national_id
              }
              lead_id={id}
              style={{ height: 400, width: "100%", borderRadius: 20 }}
            />

            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                height: "200px",
                width: "100%",
                background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 80%)`,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"end"}
              >
                <Box sx={{ marginBottom: "20px" }}>
                  <Typography
                    sx={{
                      color: theme.palette.text.primary,
                      fontWeight: 600,
                      my: 1,
                    }}
                  >
                    {getValueFromLangJson(proofType)}
                  </Typography>
                  <Box display={"flex"} justifyContent={"center"}>
                    <Button
                      variant="contained"
                      sx={textTransform}
                      color="secondary"
                    >
                      View
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={5}>
          <Box
            sx={{
              borderRadius: 2,
              border: "1px solid gray",
              height: "100%",
            }}
          >
            <Box
              sx={{
                background: "#223B56",
                padding: "15px 0px",
                borderRadius: "10px 10px 0 0",
                textAlign: "center",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                px: 2,
              }}
            >
              <HeaderVarientH4Typo
                text={`${dd_value(proofType)} Details`}
                overRideStyles={{ fontWeight: 500, textAlign: "center" }}
              />
              <IconButton
                onClick={() => setIsEditing((prev) => !prev)}
                sx={{ color: "white", position: "absolute", right: 10 }}
              >
                {isEditing ? "" : <ModeEditOutlineOutlinedIcon />}
              </IconButton>
            </Box>

            <Grid
              container
              spacing={4}
              p={3}
              sx={{
                margin: "auto !important",
                width: "100% !important",
                height: "67%",
              }}
            >
              {fields.map(
                (key, idx) =>
                  !removableFields.includes(key) && (
                    <Grid
                      item
                      xs={12}
                      md={fullWidthFields.includes(key) ? 12 : 6}
                      key={key}
                      alignItems="center"
                      sx={{
                        paddingTop: "20px !important",
                        paddingLeft: "0 !important ",
                        paddingRight: isEditing ? 1 : 0,
                      }}
                    >
                      <Typography variant="body2" sx={{ color: "#ACAFBC" }}>
                        {getValueFromLangJson(key)}
                      </Typography>

                      {isEditing ? (
                        <>
                          {["dob", "registration_date"].includes(key) ? (
                            <RhfDatePicker
                              name={key}
                              format="DD-MMM-YYYY"
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  height: "40px !important",
                                },
                              }}
                            />
                          ) : (
                            <RHFTextField
                              name={key}
                              variant="outlined"
                              size="small"
                            />
                          )}
                        </>
                      ) : (
                        <Typography
                          variant="body2"
                          fontWeight={500}
                          color="white"
                        >
                          {getDisplayValue(key as keyof FormValues)}
                        </Typography>
                      )}
                    </Grid>
                  )
              )}
            </Grid>
            {isEditing && (
              <Box display={"flex"} mt={2} justifyContent={"center"}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setIsEditing(false)}
                >
                  Save
                </Button>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PhotoProof;
