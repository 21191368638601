import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import CampaignIcon from "@mui/icons-material/Campaign";
import FormProvider from "../../CommonComponent/rhfComponents/formProvider";
import RHFCheckBoxField from "../../CommonComponent/rhfComponents/rhfCheckBoxField";
import RHFTextField from "../../CommonComponent/rhfComponents/rhfTextField";
import { useGetDigitalKycRecords } from "../hook/digitalKycHooks";
import { processDigitalKyc } from "../../../actions/lead_actions";
import { common_req } from "../../../helpers/helper";
import {
  BodyVarientOneTypo,
  HeaderVarientH3Typo,
} from "../../CommonComponent/typography";
import Pagination from "./digitalKycpagination";

const SalesPitch = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { data, isLoading } = useGetDigitalKycRecords();
  const salesPitchPointers = data?.sales_pitch?.sales_pitch_pointers ?? [];
  const isVerified = data?.sales_pitch?.status === "verified";
  const methods = useForm({
    defaultValues: {
      is_interested: null,
      not_interested: null,
      not_interested_reason: null,
    },
  });
  const { watch } = methods;
  const [checkedValue, setCheckedValue] = useState<string | null>(null);
  const handleCheckboxChange = (value: string) =>
    setCheckedValue(value === checkedValue ? null : value);

  const { id } = useParams();
  const submitHandler = () => {
    let req = {
      lead_id: id,
      stage: "sales_pitch",
      self_reg_json: {
        value: false,
        remarks: watch("not_interested_reason"),
      },
    };
    processDigitalKyc(common_req(req)).then((response) => {
      if (response?.status == "success") {
        navigate("/lead/lead_pipeline", { replace: true });
      }
    });
  };

  return (
    <FormProvider methods={methods}>
      <Box
        width="60%"
        mx="auto"
        my={7}
        p={3}
        borderRadius={2}
        bgcolor="#011137"
      >
        <Box display="flex" alignItems="center">
          <CampaignIcon sx={{ color: "text.primary", fontSize: 25, mr: 1 }} />
          <HeaderVarientH3Typo
            text=" Sales Pitch Pointers"
            overRideStyles={{
              fontWeight: 600,
              color: theme.palette.text.primary,
            }}
          />
        </Box>
        <Grid container spacing={2} mt={1}>
          {salesPitchPointers.map((item, idx) => (
            <Grid item key={idx} lg={4} sm={12}>
              <Typography variant="caption" color="grey.400" fontSize="0.8rem">
                {item}
              </Typography>
            </Grid>
          ))}
        </Grid>

        <BodyVarientOneTypo
          text="  Is the agent interested?"
          overRideStyles={{
            fontWeight: 600,
            color: theme.palette.text.primary,
            mt: 3,
            my: 2,
          }}
        />

        <Box display="flex" my={1} className={isVerified ? "disable_div" : ""}>
          {["yes", "no"].map((option) => (
            <Box
              key={option}
              sx={{
                border: `2px solid ${
                  checkedValue === option || (option === "yes" && isVerified)
                    ? theme.palette.secondary.main
                    : "white"
                }`,
                borderRadius: 2,
                pr: 2,
                mx: option === "no" ? 2 : 0,
                display: "flex",
                alignItems: "center",
              }}
            >
              <RHFCheckBoxField
                name={option === "yes" ? "is_interested" : "not_interested"}
                checked={
                  checkedValue === option || (option === "yes" && isVerified)
                }
                customOnchangeCb={() => handleCheckboxChange(option)}
              />
              <Typography
                color={
                  checkedValue === option ? "secondary.main" : "text.primary"
                }
              >
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </Typography>
            </Box>
          ))}
        </Box>

        {checkedValue === "no" && (
          <Box my={2}>
            <Typography
              fontWeight={500}
              variant="body2"
              mb={2}
              color={theme.palette.text.primary}
            >
              Comments
            </Typography>
            <RHFTextField
              name="not_interested_reason"
              label="Comments"
              placeholder="Type Here..."
              sx={{ fontSize: "13px", width: "50%" }}
              multiline
              rows={4}
            />
            <Box display="flex" justifyContent="center" mt={2}>
              <Button
                variant="contained"
                disabled={!watch("not_interested_reason")}
                color="secondary"
                onClick={submitHandler}
              >
                Submit
              </Button>
            </Box>
          </Box>
        )}
        {checkedValue !== "no" && (
          <Pagination
            isDisabled={watch("is_interested") || isVerified}
            request={{ self_reg_json: { value: true } }}
          />
        )}
      </Box>
    </FormProvider>
  );
};

export default SalesPitch;
