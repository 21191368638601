import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DELIQUENCY_ROUTE, PHYSICAl_PHONE, SOFT_PHONE } from "../../../consts";
import { useSoftPhoneAndFreshChatCtx } from "../../../context/softPhoneAndFreshChatModalUICtx/useSoftPhoneAndFreshChatCtx";
import {
  DELIQUENCY_CALL_TASK_LOG_MODAL,
  DELIQUENCY_OVERDUE_BY_COMMIT_DATE_LOG_MODAL,
  DELIQUENCY_OVERDUE_BY_DAYS_LOG_MODAL,
} from "../../../context/softPhoneCtx/softPhoneCtx";
import { useSoftPhoneCtx } from "../../../context/softPhoneCtx/useSoftPhoneCtx";
import OverdueCallTasks from "./OverdueCallTasks";

const DeliquencyManagement = () => {
  // softphone ctx
  const { softPhoneState, methods: softPhoneMethods } = useSoftPhoneCtx();

  // float ui and fresh chat ctx
  const {
    softPhoneAndFreshChatFloatUIState,
    methods: softPhoneAndFreshChatMethods,
  } = useSoftPhoneAndFreshChatCtx();

  // hook
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // state
  const [isValidating, setisValidating] = useState(true);

  // const
  const deliquencyId = searchParams.get("delinquency_id");
  const delinquencyTaskId = searchParams.get("delinquency_task_id");
  const screen = searchParams.get("screen");
  const deviceType = searchParams.get("device_type") as
    | typeof PHYSICAl_PHONE
    | typeof SOFT_PHONE;
  const isPendingCallLog = deliquencyId || delinquencyTaskId ? true : false;

  // effect
  useEffect(() => {
    getUserCallLog();
  }, []);

  // cb
  const getUserCallLog = async () => {
    if (isPendingCallLog) {
      const { hasAnyPrevPendCallLog, callLog } =
        await softPhoneMethods.checkUserHasAnyPendingCallLog({ screen: "" });
      let isUserDoingAnyWrongAction = true;
      // if (
      //   hasAnyPrevPendCallLog &&
      //   callLog?.screen === DELIQUENCY_OVERDUE_BY_COMMIT_DATE_LOG_MODAL &&
      //   deliquencyId === callLog?.delinquency_id
      // ) {
      //   isUserDoingAnyWrongAction = false;
      // }
      // if (
      //   hasAnyPrevPendCallLog &&
      //   callLog?.screen === DELIQUENCY_OVERDUE_BY_DAYS_LOG_MODAL &&
      //   deliquencyId === callLog?.delinquency_id
      // ) {
      //   isUserDoingAnyWrongAction = false;
      // }
      // if (
      //   hasAnyPrevPendCallLog &&
      //   callLog?.screen === DELIQUENCY_CALL_TASK_LOG_MODAL &&
      //   delinquencyTaskId === callLog?.task_id
      // ) {
      //   isUserDoingAnyWrongAction = false;
      // }
      // if (isUserDoingAnyWrongAction) {
      //   navigateToDeliquencyRoute();
      // }
      const validCallLogs = [
        {
          screen: DELIQUENCY_OVERDUE_BY_COMMIT_DATE_LOG_MODAL,
          id: callLog?.delinquency_id,
          match: deliquencyId,
        },
        {
          screen: DELIQUENCY_OVERDUE_BY_DAYS_LOG_MODAL,
          id: callLog?.delinquency_id,
          match: deliquencyId,
        },
        {
          screen: DELIQUENCY_CALL_TASK_LOG_MODAL,
          id: callLog?.task_id,
          match: delinquencyTaskId,
        },
      ];

      isUserDoingAnyWrongAction = !(
        hasAnyPrevPendCallLog &&
        validCallLogs.some(
          ({ screen, id, match }) => callLog?.screen === screen && id === match
        )
      );
      setisValidating(false);
    }
    setisValidating(false);
  };

  const navigateToDeliquencyRoute = () => navigate(DELIQUENCY_ROUTE);

  if (isValidating) return <></>;

  return (
    <OverdueCallTasks
      softPhoneCtxState={softPhoneState}
      softPhoneCtxMethods={softPhoneMethods}
      softPhoneAndFreshChatCtxFloatUIState={softPhoneAndFreshChatFloatUIState}
      softPhoneAndFreshChatCtxMethods={softPhoneAndFreshChatMethods}
      isPendingCallLog={isPendingCallLog}
      deliquencyId={deliquencyId}
      delinquencyTaskId={delinquencyTaskId}
      navigateToDeliquencyRoute={navigateToDeliquencyRoute}
      screen={screen}
      deviceType={deviceType}
    />
  );
};

export default DeliquencyManagement;
