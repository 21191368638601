import { Alert, Box, Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { ERROR_INFO, FETCHING_INFO } from "../../../consts";
import {
  HeaderVarientH2Typo
} from "../../CommonComponent/typography";
import { useGetCustDetailsByPhNumQuery } from "../hooks/useGetCustDetailsQuery";
import FaDetailsAndPersonalInfoUI from "./faDetailsAndPersonalInfoUI";
import NoCustFoundUI from "./noCustFoundUI";
import PrevCallInteractionsUI from "./prevCallInteractionsUI";

const CustDetailUI = () => {
  // form ctx
  const { watch } = useFormContext();

  // const
  const phoneNumber = watch("phoneNumber");

  // query
  const { isLoading, isError, data, isFetched } = useGetCustDetailsByPhNumQuery(
    {
      phoneNumber,
    }
  );
  const isCustomer = data?.data && data?.data?.customer_list ? true : false;
  const isRm = data?.data && data?.data?.is_rm ? true : false;

  if (isLoading)
    return (
      <HeaderVarientH2Typo
        overRideStyles={{ color: "#fff", textAlign: "center", py: 16 }}
        text={FETCHING_INFO}
      />
    );

  if (isError && !data?.data)
    return (
      <HeaderVarientH2Typo
        overRideStyles={{ color: "#fff", textAlign: "center", py: 16 }}
        text={ERROR_INFO}
      />
    );

  if (
    isFetched &&
    data?.data &&
    data.data?.customer_list &&
    Array.isArray(data.data.customer_list) &&
    data.data.customer_list.length === 0
  )
    return <NoCustFoundUI />;

  // const
  const custObj = isCustomer ? data?.data?.customer_list[0] : null;
  const rmObj = isRm ? data?.data?.rm_details : null;

  return (
    <Grid container>
      <Grid item xs={12} lg={7}>
        {custObj && isCustomer && (
          <FaDetailsAndPersonalInfoUI custObj={custObj} />
        )}
        {isRm && rmObj && <RmDetailUI rmDetails={rmObj} />}
      </Grid>
      <Grid item xs={12} lg={5}>
        {custObj && isCustomer && (
          <PrevCallInteractionsUI custId={custObj?.cust_id} />
        )}
      </Grid>
    </Grid>
  );
};

export default CustDetailUI;

const RmDetailUI = ({
  rmDetails,
}: {
  rmDetails: { rm_name: string; id: string };
}) => {
  return (
    <Box my={2}>
      <Alert severity="info">{`${`This number belongs to Relationship Manager ${rmDetails.rm_name}`}`}</Alert>
    </Box>
  );
};
