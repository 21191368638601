import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { digitalKyc, getLeadPipeline, viewLead } from "../../../actions/lead_actions";
import { common_req } from "../../../helpers/helper";
import { verify } from "crypto";

type SalesPitch = {
  sales_pitch_pointers: string[]
  self_reg_json: {
    value: null | boolean;
  };
  status: string
  verify: boolean
};

type NationalId = {
  self_reg_json: {
    photo_national_id: {
      id_proof_num: string;
      first_name: string;
      last_name: string;
      gender: string;
      dob: string;
      file_name: string;
      file_rel_path: string;
      file_size: number;
      is_editable: boolean | null;
      is_verified: boolean | null;
    }
    photo_rdb_certificate: {
      company_code: string;
      enterprise_name: string;
      owner_name: string;
      registration_date: string;
      file_name: string;
      file_rel_path: string;
      file_size: number;
      is_editable: boolean | null;
      is_verified: boolean | null;
    }
  }

  status:string
};

type IdProofFields = {
  type: string;
  value: string;
};

type Agreement = {
  text:string[]
  icon_key:string,
  status:string

};

type CustInfo = {
  acc_prvdr_code:string;
  account: string;
  distributor_code: string;
  eligibility: string | number;
  first_fa_limit: string | number;
  name: string
}

type DigitalKycVerificationProps = {
  cust_info: CustInfo;
  sales_pitch: SalesPitch;
  identity_verification: NationalId;
  agreement: Agreement;
  stage:string;
  status:string;
  icon_key:string
  otp_expiry:number;

};

export const useGetDigitalKycRecords = () => {
  const { id } = useParams();

  const { data, isLoading, isError } = useQuery<DigitalKycVerificationProps>({
    queryKey: ["digitalKycLeadInfo", id],
    queryFn: async () => {
      if (!id) return null; // Prevent API call if `id` is undefined
      const response = await digitalKyc(common_req({ lead_id: id }));
      if (response?.status === "success") {
        return response.data;
      } else {
        throw new Error(response.message || "Failed to fetch lead data");
      }
    },
    enabled: !!id, // Only run query when `id` is available
    staleTime: 100000,
  });

  return { data, isLoading, isError };
};

