import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MuiStepper from "../../common/component/muiStepper";
import { useGetDigitalKycRecords } from "../hook/digitalKycHooks";
import { useQueryClient } from "@tanstack/react-query";

const DigitalKycStepper = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const kyc_sections = [
    {
      icon: <Box sx={{ fontSize: "13px" }}>1</Box>,
      key: "sales_pitch",
      title: "Sales Pitch",
    },
    {
      icon: <Box sx={{ fontSize: "13px" }}>2</Box>,
      key: "identity_verification",
      title: "Identity Verification",
    },
    {
      icon: <Box sx={{ fontSize: "13px" }}>4</Box>,
      key: "agreement",
      title: "Agreement",
    },
  ];
  const activeStep = location.pathname.split("/").filter(Boolean).pop();
  const activeIdx = kyc_sections.findIndex(
    (section) => section.key === activeStep
  );

  const disableStep = kyc_sections.map((_, index) => {
    if (index <= activeIdx) return true;
    if (index === activeIdx + 1) return false;
    return true;
  });


  const [completedSteps, setCompletedSteps] = useState<number[]>([]);
  const [activeIndex, setActiveIndex] = useState<number>(activeIdx);
  const { data, isLoading } = useGetDigitalKycRecords();
  const queryClient = useQueryClient();
  const queryData = queryClient.getQueryData(["digitalKycLeadInfo"]);
  useEffect(() => {
    if (activeIdx !== -1) {
      setActiveIndex(activeIdx);
      setCompletedSteps(Array.from({ length: activeIdx }, (_, i) => i));
      findCompletedStage();
    }
  }, [location.pathname, activeIdx]);
  const verifiedIndexes: number[] = [];

  const findCompletedStage = () => {
    const responseData = data ?? {};
    console.log("responseData", responseData, queryData);
    Object.entries(responseData).forEach(([key, value], index) => {
      if (
        value &&
        typeof value === "object" &&
        "verify" in value &&
        value.verify === true
      ) {
        verifiedIndexes.push(index);
      }
    });

    console.log("verifiedIndexes", verifiedIndexes);
  };

  const stepHandler = (key: string) => {
    navigate(`/digitalKyc/${key}`);
  };

  console.log("activeIdx,activeIndex", activeIdx, activeIndex);

  return (
    <Box width="70%" sx={{ margin: "0 auto" }}>
      <MuiStepper
        steps={kyc_sections}
        isStepCompleted={completedSteps}
        isStepFailed={[]} // Provide valid failed steps if needed
        activeStep={activeIndex}
        stepHandler={stepHandler}
        disableStepOnClick={false}

      />
    </Box>
  );
};

export default DigitalKycStepper;
