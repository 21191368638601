import SoftPhoneTimerCtxProvider from "../../../context/softPhoneTimerCtx/softPhoneTimerCtxProvider";
import CallLogModal from "./modal/callLogModal";
import CallStatusModal from "./modal/callStatusModal";

const SoftPhoneAndCallLogUI = () => {
  return (
    <SoftPhoneTimerCtxProvider>
      <CallStatusModal />
      <CallLogModal />
    </SoftPhoneTimerCtxProvider>
  );
};

export default SoftPhoneAndCallLogUI;
