import React, { lazy, LazyExoticComponent, Suspense } from "react";
import { resolvePromiseWithDelay } from "../../../helpers/common_helper";
import FallBackUI from "./Components/FallBackUI";

// lazy
const CustFollowUps: LazyExoticComponent<React.ComponentType> = lazy(() =>
  resolvePromiseWithDelay(import("./Components/CustFollowUps"))
);

const CustReferel = () => {
  return (
    <Suspense fallback={<FallBackUI/>}>
      <CustFollowUps />
    </Suspense>
  );
};

export default CustReferel;
