import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined";
import { Box, IconButton } from "@mui/material";
import { CUSTOMER_SUCCESS, CUSTOMER_SUCCESS_OFFICER } from "../../../consts";
import { MISSED_CALL_MODAL } from "../../../context/softPhoneAndFreshChatModalUICtx/softPhoneAndFreshChatFloatUICtx";
import { useSoftPhoneAndFreshChatCtx } from "../../../context/softPhoneAndFreshChatModalUICtx/useSoftPhoneAndFreshChatCtx";
import { checkUserHasCallPriv } from "../../../helpers/helper";
import { get } from "../../../helpers/storage_helper";
import { HeaderVarientH5Typo } from "../../CommonComponent/typography";
import { useGetNewCustOrientationList } from "../../home/newOnboardedCustomers/queryHooks/useGetNewCustOrientationList";
import useGetMissedCallLists from "../hooks/useGetMissedCallLists";

const MissedCallAndCustOrientationFloatIconUI = () => {
  // const
  const iconStyle = { width: "45px", height: "45px", color: "#fff" };

  const hasCallPriv = checkUserHasCallPriv();

  // ctx
  const {
    softPhoneAndFreshChatFloatUIState: { showFloatBtnsOptions },
    methods: { toggleFloatModalFor },
  } = useSoftPhoneAndFreshChatCtx();

  // hook query
  const { data: missedCallData } = useGetMissedCallLists({
    enabled: hasCallPriv ?? false,
  });
  const { newCustList } = useGetNewCustOrientationList();

  const missedCallsListCount = missedCallData?.data.missed_calls?.length ?? 0;
  const newCustListCount = newCustList.length;
  const totalCount = missedCallsListCount + newCustListCount;

  const canShowMissedCallOption =
    hasCallPriv && !showFloatBtnsOptions && missedCallsListCount > 0;
  const canShowCustOrientationOption =
    !showFloatBtnsOptions &&
    [CUSTOMER_SUCCESS, CUSTOMER_SUCCESS_OFFICER].includes(get("role_codes"));
  const count =
    (canShowMissedCallOption && canShowCustOrientationOption)
      ? totalCount
      : canShowMissedCallOption
      ? missedCallsListCount
      : newCustListCount;

  return (
    <>
      {((canShowMissedCallOption || canShowCustOrientationOption) && count) && (
        <Box
          className={"zoomIn_animation"}
          sx={{
            position: "fixed",
            bottom: "100px",
            right: "16px",
            cursor: "pointer",
            backgroundColor: "#231184",
            zIndex: 9999,
            width: "60px",
            borderRadius: "50%",
            height: "60px",
          }}
        >
          <Box
            sx={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: -10,
                right: -10,
                backgroundColor: "#fff",
                borderRadius: "50%",
                minWidth: "30px",
                minHeight: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "solid 2px #1A2035",
              }}
            >
              <HeaderVarientH5Typo
                text={String(count)}
                overRideStyles={{
                  color: "#231184 !important",
                  fontWeight: "600",
                }}
              />
            </Box>
            <IconButton
              onClick={() =>
                toggleFloatModalFor({ floatModalFor: MISSED_CALL_MODAL })
              }
            >
              <ContactPhoneOutlinedIcon
                sx={{
                  ...iconStyle,
                  p: 1,
                }}
              />
            </IconButton>
          </Box>
        </Box>
      )}
    </>
  );
};

export default MissedCallAndCustOrientationFloatIconUI;
