import { Box, Button } from "@mui/material";
import { useState } from "react";
import { CUSTOMER_SUCCESS, CUSTOMER_SUCCESS_OFFICER } from "../../../consts";
import { PHONE_NUMS_LIST } from "../../../context/softPhoneAndFreshChatModalUICtx/softPhoneAndFreshChatFloatUICtx";
import { useSoftPhoneAndFreshChatCtx } from "../../../context/softPhoneAndFreshChatModalUICtx/useSoftPhoneAndFreshChatCtx";
import { checkUserHasCallPriv } from "../../../helpers/helper";
import { get } from "../../../helpers/storage_helper";
import {
  HeaderVarientH2Typo,
  HeaderVarientH5Typo,
} from "../../CommonComponent/typography";
import { useGetNewCustOrientationList } from "../../home/newOnboardedCustomers/queryHooks/useGetNewCustOrientationList";
import useGetMissedCallLists from "../hooks/useGetMissedCallLists";
import CustOrientationCallListUI from "./custOrientationCallListUI";
import MissedCallsUI from "./missedCallsUI";
import PhoneNumbersUI from "./phoneNumbersUI";

const FloatModalUI = () => {
  //ctx
  const {
    softPhoneAndFreshChatFloatUIState: { floatModalFor },
  } = useSoftPhoneAndFreshChatCtx();

  // query
  const { data: missedCallsList } = useGetMissedCallLists({ enabled: true });
  const { newCustList } = useGetNewCustOrientationList();

  // const
  const isPhoneNumsListUI = floatModalFor === PHONE_NUMS_LIST;
  const missedCallsListCount = missedCallsList?.data.missed_calls?.length ?? 0;
  const newCustListCount = newCustList.length;
  const hasCallPriv = checkUserHasCallPriv();
  const hasPrivToNewlyOnboardedCustList = [
    CUSTOMER_SUCCESS,
    CUSTOMER_SUCCESS_OFFICER,
  ].includes(get("role_codes"));

  // state
  const [tab, settab] = useState(hasCallPriv ? 0 : 1);

  return (
    <>
      {isPhoneNumsListUI ? (
        // softphone header section UI
        <div className="bg_dark_blue px-4 py-3">
          <HeaderVarientH2Typo
            text={"Softphone"}
            overRideStyles={{ color: "#fff" }}
          />
        </div>
      ) : (
        // tab section UI
        <Box sx={{ display: "flex" }}>
          {hasPrivToNewlyOnboardedCustList && (
            <TabLikeBtnUI
              settab={settab}
              tab={0}
              activeTab={tab}
              label="Newly Onboarded"
              count={newCustListCount}
            />
          )}
          {hasCallPriv && hasPrivToNewlyOnboardedCustList && (
            <Box py={1} sx={{ backgroundColor: "#231184" }}>
              <Box
                sx={{ width: "2px", height: "100%", backgroundColor: "#fff" }}
              />
            </Box>
          )}
          {hasCallPriv && (
            <TabLikeBtnUI
              settab={settab}
              activeTab={tab}
              tab={1}
              label="Missed Calls"
              count={missedCallsListCount}
            />
          )}
        </Box>
      )}
      {/* phone numbers list UI */}
      <Box sx={{ height: "calc(500px - 61px)", overflowY: "auto" }}>
        {isPhoneNumsListUI ? (
          <PhoneNumbersUI /> // softphone phone numbers UI
        ) : (
          <>{tab === 0 ? <CustOrientationCallListUI /> : <MissedCallsUI />}</> // missed call or new customer orientation list
        )}
      </Box>
    </>
  );
};

const TabLikeBtnUI = ({
  settab,
  tab,
  activeTab,
  label,
  count,
}: {
  settab: Function;
  tab: number;
  activeTab: number;
  label: string;
  count: number;
}) => (
  <Button
    type="button"
    sx={{
      backgroundColor: "#231184",
      borderRadius: tab === 0 ? "4px 0px 0px 0px" : "0px 4px 0px 0px",
      flex: 1,
      height: 60,
      fontWeight: "600",
      color: activeTab === tab ? "#fff!important" : "#919191!important",
      "&:hover": {
        backgroundColor: "#3A1EB4",
      },
      textTransform: "none",
    }}
    onClick={() => settab(tab)}
    endIcon={
      <>
        {count > 0 && (
          <Box
            sx={{
              borderRadius: "50%",
              backgroundColor: activeTab === tab ? "#fff" : "#919191",
              width: "30px",
              height: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <HeaderVarientH5Typo
              text={`${count}`}
              overRideStyles={{ color: "#231184 !important" }}
            />
          </Box>
        )}
      </>
    }
  >
    {label}
  </Button>
);

export default FloatModalUI;
