import React from "react";
import SkeletonLoader from "../../common/component/skeletonLoader";
import { Box } from "@mui/material";

const ScreenLoader = () => {
  return (
    <Box>
      <SkeletonLoader variant="rectangular" height={"150px"} width={"100%"} />
    </Box>
  );
};

export default ScreenLoader;
