import { Box, Grid } from "@mui/material";
import moment from "moment";
import { useFormContext } from "react-hook-form";
import { MIN_DATE_FOR_AUDITOR_PERFORMANCE_DASHBOARD } from "../../../consts";
import AuditorsListAutoComplete from "../../CommonComponent/commonAutoCompleteDD/auditorsListAutoComplete";
import RMsListAutoComplete from "../../CommonComponent/commonAutoCompleteDD/rmSListAutoComplete";
import CommonMuiBtn from "../../CommonComponent/commonMuiBtn";
import RhfDatePicker from "../../CommonComponent/rhfComponents/rhfDatePicker";

const FilterFormUI = () => {
  // form context
  const {
    formState: { isValid, errors, isSubmitting },
  } = useFormContext();

  const enableButton =
    isValid && Object.keys(errors).length === 0 && !isSubmitting;

  return (
    <Box sx={{ py: 4, px: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={10}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={3}>
              <AuditorsListAutoComplete
                name="auditor"
                label="Auditor"
                actAs="dd"
                roleCodes={["operations_auditor"]}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <RMsListAutoComplete name="rm" label="RMs" actAs="dd" />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <RhfDatePicker
                name="startDate"
                label="Start date"
                sx={{
                  // overides the end icon style
                  "& .MuiButtonBase-root": {
                    svg: {
                      width: "20px",
                      height: "20px",
                      color: (theme) =>
                        // @ts-ignore
                        theme.palette.custom_colors.secondary_a_200,
                    },
                  },
                }}
                format="DD MMM YYYY"
                minDate={moment(MIN_DATE_FOR_AUDITOR_PERFORMANCE_DASHBOARD)}
                maxDate={moment()}
                disableFuture
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <RhfDatePicker
                name="endDate"
                label="End date"
                sx={{
                  // overides the end icon style
                  "& .MuiButtonBase-root": {
                    svg: {
                      width: "20px",
                      height: "20px",
                      color: (theme) =>
                        // @ts-ignore
                        theme.palette.custom_colors.secondary_a_200,
                    },
                  },
                }}
                format="DD MMM YYYY"
                minDate={moment(MIN_DATE_FOR_AUDITOR_PERFORMANCE_DASHBOARD)}
                maxDate={moment()}
                disableFuture
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={2} textAlign="center">
          <CommonMuiBtn type="submit" disabled={!enableButton} text="Submit" />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FilterFormUI;
