import { useQuery } from "@tanstack/react-query";
import { common_req } from "../../../../helpers/common_helper"
import { RhfOptionType } from "../../../../type"
import { getNameList } from "../../../../actions/common_actions";

type CSGetResProps = { data: RhfOptionType[] };


const UseGetCSListViaQuery = () => {
    
    const {
        data: csList,
        isError,
        isLoading
    } = useQuery<CSGetResProps>({
        queryKey:['list_of_cs'],
        queryFn: ()=>
            getNameList(
                'customer_success_officer',
                {
                    ...common_req(),
                    status: "enabled"
                }
            ),
        staleTime: 1000 * 60 * 5,
    })

    return { csList, isError, isLoading };
}

export default UseGetCSListViaQuery;