import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Box } from "@mui/material";
import moment from "moment";
import { ERROR_INFO, FETCHING_INFO, NO_DATA_FOUND_INFO } from "../../../consts";
import {
  BodyVarientTwoTypo,
  CustomFontSizeTypo,
  HeaderVarientH2Typo,
} from "../../CommonComponent/typography";
import NewCustOrientationMakeCallUI from "../../home/newOnboardedCustomers/components/newCustOrientationMakeCallUI";
import { useGetNewCustOrientationList } from "../../home/newOnboardedCustomers/queryHooks/useGetNewCustOrientationList";

const CustOrientationCallListUI = () => {
  // query
  const { newCustList, isError, isLoading } = useGetNewCustOrientationList();

  if (isLoading)
    return (
      <HeaderVarientH2Typo
        text={FETCHING_INFO}
        overRideStyles={{
          color: "#000",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          py: 16,
        }}
      />
    );

  if (isError)
    return (
      <HeaderVarientH2Typo
        text={ERROR_INFO}
        overRideStyles={{
          color: "#000",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          py: 16,
        }}
      />
    );

  if (newCustList.length === 0 && !isLoading && !isError)
    return (
      <HeaderVarientH2Typo
        text={NO_DATA_FOUND_INFO}
        overRideStyles={{
          color: "#000",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          py: 16,
        }}
      />
    );
  return (
    <Box sx={{ p: 2 }}>
      {newCustList.map((nCL) => (
        <Box
          key={nCL.id}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: 'center',
            p: 2,
            backgroundColor: (theme) =>
              // @ts-ignore
              theme.palette.custom_colors.secondary_a_50,
            borderRadius: 2,
            mb: 2,
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Box sx={{ mr: 2 }}>
              <AccountCircleIcon
                sx={{ width: "30px", height: "30px", color: "#313237" }}
              />
            </Box>
            <Box>
              <BodyVarientTwoTypo
                text={nCL.cust_name}
                overRideStyles={{ fontWeight: "600", color: "#313237" }}
              />
              <CustomFontSizeTypo
                fontSize="10px"
                text={`Onboarded ${moment(
                  nCL.onboarded_date,
                  "YYYY-MM-DD HH:mm:ss"
                ).fromNow()}`}
                overRideStyles={{
                  color: (theme: any) =>
                    // @ts-ignore
                    theme.palette.custom_colors.secondary_a_900,
                }}
              />
              <BodyVarientTwoTypo
                text={nCL.mobile_num}
                overRideStyles={{ color: "#4F5167" }}
              />
            </Box>
          </Box>
          <Box display={"flex"} alignItems={"center"} flexDirection={"column"}>
            <NewCustOrientationMakeCallUI
              UIFor="floatUI"
              taskId={nCL.id}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default CustOrientationCallListUI;
