import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FiPhoneCall } from 'react-icons/fi';
import { BiMessageDetail, BiCalendar } from 'react-icons/bi';
import { RiQuestionFill } from 'react-icons/ri';
import moment from 'moment';
import Select from "react-select";
import DatePicker from 'react-datetime';
import {updateCalls,setCallLog} from '../../../actions/delinquency_actions';
import { MdPhone, MdOutlinePhoneDisabled } from "react-icons/md";
import { ImPhoneHangUp } from "react-icons/im";
import { FiPhoneMissed, FiPhone, FiPhoneOff } from "react-icons/fi";
import { check_priv, Config, get } from '../../../helpers/storage_helper';
import { getValueFromLangJson, lbl_amt } from '../../../helpers/common_helper';
import {dd_value} from '../../../actions/common_actions';
import { TbClockCheck } from "react-icons/tb";
import { MdOutlineLocationOn } from "react-icons/md";
import { connect } from 'react-redux';
import { setValuesIntoRedux } from '../../../Redux/Slices/CsSoftphoneSlice';
import { PHYSICAl_PHONE, SOFT_PHONE } from '../../../consts';
import { DELIQUENCY_CALL_TASK_LOG_MODAL, DELIQUENCY_OVERDUE_BY_COMMIT_DATE_LOG_MODAL, DELIQUENCY_OVERDUE_BY_DAYS_LOG_MODAL } from '../../../context/softPhoneCtx/softPhoneCtx';


class CallLogModal extends FlowComponent {



    constructor(props) {
        super(props);
        this.state = {
            callLogModal: true,
            mobile_num_arr:[],
            currentPage: 0, 
            itemsPerPage: 3, 
            faOverdueInfo:[
                { label: "FA Amount", key: "fa_amount" },
                { label: "Fee", key: "fee" },
                { label: "Penalty", key: "penalty" },
                { label: "Outstanding FA", key: "outstanding_fa" },
                { label: "Disbursal Date", key: "disbursal_date" },
                { label: "Due Date", key: "due_date" },        ]
    
        },
        this.channel = new BroadcastChannel('cs_soft_phone')
        this.softphone_is_supported 

       
    }

    getInputHandler(value, type) {
        this.setState({ [type]: value })
        if(type=="remarks"){
         const myArray = value.split(" ");
         const nonEmptyRemarks = myArray.filter((remark) => remark !== "");

         this.setState({remarks : value,remarksArr:nonEmptyRemarks});
        }

    }

    componentWillMount() {
        var key = `od_reason_max_days_${(get('market').country_code).toLowerCase()}`
       var od_reasons_day_limit= Config(key)
        let screen_width = window.screen.availWidth
        this.setState({ screen_width,od_reasons_day_limit })
        this.softphone_is_supported =  check_priv('home', 'soft_phone')  && this.props.softPhoneState.is_softphone_supported

    }
    getStatus(data) {
       this.getInputHandler(data.value, "reason_for_overdue") 
    }
    disable_div_conditions(idx,item) {
        if(this.props.datas.using_physicalphone) {
            var a = false;
        
            if(((this.state[`not_answered_${idx-1}`] || this.state[`not_reachable_${idx-1}`]) || ((this.props.datas.callLogDatas.cust_mob_num[idx-1].status=="not_answered")||(this.props.datas.callLogDatas.cust_mob_num[idx-1].status=="not_reachable")))){
                a = true;
                    return true
            }
           
        return a;
        } else return true
    }

    // called only for the softphone case
    // called twice when the user clicks the phone number directly and during the form submit
    // the submit section only enabled if any one of the number has the status as answered
    async call_via_softphone(idx, key,item){
        let arr =[...this.state.mobile_num_arr]
        // var is_not_clicked = true
        let mobile_num = ''
        // if ((this.state[`not_answered_${idx}`] || this.state[`not_reachable_${idx}`] || this.state[`answered_${idx}`]) || ((item.status=="answered") || (item.status=="not_answered") ||( item.status=="not_rechable"))) {
        //     is_not_clicked = false
        // }

        // if (is_not_clicked) {
        var obj={}
        var a=[]

        for (const [key, value] of Object.entries(item)) {
            if(key!=="status"){
                a.push(key)
                mobile_num = key
            }
        }   
        var mob_num_key = a[0]
    
        obj["mobile_num"]= this.props.datas.callLogDatas.cust_mob_num[idx][mob_num_key]
        obj["status"]= key.slice(0, -1);
        arr = arr.filter(num => num.mobile_num !== obj.mobile_num)
        arr.push(obj)

        // in softphone the user can change the number status during the call log,
        // at a moment only one status is enabled for the particular number
        // this below obj is used to keep that
        const stateKeysManageCallStatus = arr.map(() => ({
            ["answered_"+idx]: false,
            ["not_answered_"+idx]: false,
            ["not_reachable_"+idx]: false,
        }))[0]
        stateKeysManageCallStatus[key + idx] = true
        const { currentPage, itemsPerPage } = this.state;
        const startIndex = currentPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const listedMobNums = this.props.datas.callLogDatas.cust_mob_num.slice(startIndex, endIndex)
        // if all the numbers status are not answered
        // for each unanswered case we will submit the log by calling makeCallLogApi
        // for that we need to keep the call log modal opened
        // canCloseTheLogModal manages that *if any one of the number status is answered then the submit section will be enabled and the user can enters the remarks there
        const canCloseTheLogModal = listedMobNums.length === arr.length

        const { callLog, hasAnyPrevPendCallLog } = await this.props.softPhoneCtxMethods.checkUserHasAnyPendingCallLog({screen: ""})
    
        if(hasAnyPrevPendCallLog) { // if the user hasn't any pend call log
            const isEndUserHasAnswered = callLog?.call_start_time;
            const isWronglySelectsAsAnswered = !isEndUserHasAnswered && key === 'answered_'
            const isWronglySelectsAsUnAnsOrNotReach = isEndUserHasAnswered && key !== "answered_" 
            const isUserSelectsWrongNumberToLog = callLog?.mobile_num !== obj?.mobile_num
            const isUserSelectsWrongStatus = isWronglySelectsAsUnAnsOrNotReach || isWronglySelectsAsAnswered || isUserSelectsWrongNumberToLog
            if(isUserSelectsWrongStatus) { // info to user in that un matched log case
                alert(`Your previous call was on mobile number: ${callLog?.mobile_num} and the status was ${isEndUserHasAnswered ? "answered" : "un answered"}`)
                return 
            }
            // this setstate manages the mobile numbers status in the UI
            this.setState(stateKeysManageCallStatus)
            this.setState({mobile_num_arr:arr,current_mob_num :{num: obj["mobile_num"],status :  obj["status"]}})
            if(obj['status'] !== 'answered') {
                this.makeCallLogApi({key: obj["status"], callLog: callLog, mob_num: obj["mobile_num"], type: 'end_call', canCloseTheLogModal: canCloseTheLogModal})
            }
        } else {
            alert("You haven't initiated the softphone call yet")
        }
        // this.getInputHandler(true, key + idx)
        // this.callLogApi(item,idx,key)
        // }
        // if(idx+1 !== this.props.datas.callLogDatas.cust_mob_num.length && obj&&  obj["status"] !="answered"){
        //     this.callLogSubmit(obj["mobile_num"],obj["status"],idx+1)
        // }
    }

    is_not_clicked(idx, key,item) {
        var country_code = get('market').country_code

        if(this.softphone_is_supported && !this.props.datas.using_physicalphone){
            this.call_via_softphone(idx, key,item)
        }else{
            this.manual_call(idx, key,item)
        }
   
        //  return  is_not_clicked;
    }


    manual_call(idx, key,item){
        var arr=[...this.state.mobile_num_arr]
        var is_not_clicked = true
        if ((this.state[`not_answered_${idx}`] || this.state[`not_reachable_${idx}`] || this.state[`answered_${idx}`]) || ((item.status=="answered") || (item.status=="not_answered") ||( item.status=="not_rechable"))) {
            is_not_clicked = false
        }

        if (is_not_clicked) {
            var obj={}
            var a=[]

            for (const [key, value] of Object.entries(item)) {
                if(key!=="status"){
                    a.push(key)

                }
            }   
            console.log('a', a)
            var mob_num_key = a[0]
        
            obj["mobile_num"]= this.props.datas.callLogDatas.cust_mob_num[idx][mob_num_key]
            obj["status"]= key.slice(0, -1);
            arr.push(obj)
            this.setState({mobile_num_arr:arr, current_mob_num : {num: obj["mobile_num"], status : obj["status"]}})
            this.getInputHandler(true, key + idx)
            // this.callLogApi(item,idx,key)
            if(obj.status !== 'answered') {
                const { currentPage, itemsPerPage } = this.state;
                const startIndex = currentPage * itemsPerPage;
                const endIndex = startIndex + itemsPerPage;
                const listedMobNums = this.props.datas.callLogDatas.cust_mob_num.slice(startIndex, endIndex)
                const canCloseTheLogModal = listedMobNums.length === arr.length
                console.log(listedMobNums, arr, 'arrays to check...', canCloseTheLogModal)
                this.makeCallLogApi({key: obj["status"], callLog: {}, mob_num: obj["mobile_num"], type: null, canCloseTheLogModal: canCloseTheLogModal})
            }

        }
    }
    // callLogApi(item,idx,add_key){
    //     var data_key=''
    //     for (const [key, value] of Object.entries(item)) {
    //         data_key=key
    //     }
    //     console.log('this.props.datas.callLogDatas.cust_mob_num', this.props.datas.callLogDatas.cust_mob_num,data_key)
    //     var req={mob_num_index:idx, "mobile_num":this.props.datas.callLogDatas.cust_mob_num[idx][data_key],task_id:this.props.datas.Call_log_data.id, loan_doc_id:this.props.datas.Call_log_data.loan_doc_id,status: add_key.slice(0, -1) }
    //     updateCalls(this.req(req))
    //     .then((response)=>{
    //         if(!response){return}
    //         if(response.status=="success"){
    //             console.log('response', response)
    //         }
    //     })
    // }

    contactNumbers(item){
        var a=[]
 
        for (const [key, value] of Object.entries(item)) {
            a.push(
                
                <>{(key!=="status")&&
                <>
                        <p className='m-0'> {dd_value(key)}</p>
                        <p className='text-white sizem  m-0'> {value}</p>
                </>
                 }
                </>
            )
        }    
        return a;
        }

    checkAnsweredStatus(){
        var answered=false
       for(var i =0;i<this.props.datas.callLogDatas.cust_mob_num.length;i++){
         if(this.props.datas.callLogDatas.cust_mob_num[i].status==="answered"){
            answered=true
         }
       }
       return answered;
    }

    handleNextPage = () => {
        const { currentPage } = this.state;
        const { callLogDatas } = this.props.datas;
        const totalItems = callLogDatas.cust_mob_num.length;
    
        if (currentPage < Math.ceil(totalItems / this.state.itemsPerPage) - 1) {
          this.setState({ currentPage: currentPage + 1 });
        }
      };
    
      handlePrevPage = () => {
        const { currentPage } = this.state;
    
        if (currentPage > 0) {
          this.setState({ currentPage: currentPage - 1 });
        }
      };

      checkDisable(){
        var flag=""
        if(this.props.datas.callLogDatas && this.props.datas.callLogDatas.cust_mob_num.length == this.state.mobile_num_arr.length){
            if(this.state.mobile_num_arr.some(item => item.status !== "answered")){
                flag=""
            }
        }else{
            flag="disable_div"
        }

        return flag;

      }
    
    async callLogSubmit(mob_num =null,key = null,type=null){
        if(this.props.datas.using_physicalphone) {
            this.makeCallLogApi({key: 'answered', callLog: {}, mob_num, type, canCloseTheLogModal: true})
        } else {
            const { callLog, hasAnyPrevPendCallLog } = await this.props.softPhoneCtxMethods.checkUserHasAnyPendingCallLog({screen: ""}) 
            if(!hasAnyPrevPendCallLog) {
                alert("You haven't initiated the softphone call yet")
                if(this.props.isPendingCallLog) {
                    this.props.navigateToDeliquencyRoute()
                    window.location.reload()
                }
            }else{
                this.makeCallLogApi({key: 'answered', callLog, mob_num, type, canCloseTheLogModal: true})
            }
        }
    }

    makeCallLogApi ({key, callLog, mob_num, type, canCloseTheLogModal }) {
        let softohone_priv = this.softphone_is_supported
        var req={
            reason:this.state.reason_for_overdue ? this.state.reason_for_overdue :null,
            remarks:this.state.remarks ? this.state.remarks:null,
            commitment_date:this.state.call_tasks_date ? this.state.call_tasks_date : null,
        } 

        if((softohone_priv)&& !this.props.datas.using_physicalphone  ){
            req.status = key ? key :this.state.current_mob_num.status
            req['call_log_id'] = callLog?.call_log_id
            req.recov_activity_id =this.state.recov_activity_id ? this.state.recov_activity_id:null  
            req.mobile_num = mob_num ? mob_num : this.state.current_mob_num.num  
            req.call_log = (type=="end_call" ) ? "end_log" : null
            
        }else{
            const screen = this.props.datas.initial_tab === 'call_task' ? DELIQUENCY_CALL_TASK_LOG_MODAL : this.props.datas.initial_tab === "due_by_days" ? DELIQUENCY_OVERDUE_BY_DAYS_LOG_MODAL : DELIQUENCY_OVERDUE_BY_COMMIT_DATE_LOG_MODAL
            req['call_log_id']=callLog?.call_log_id
            // req.mobile_num = this.state.mobile_num_arr
            req.mobile_num = mob_num ? mob_num : this.state.current_mob_num.num 
            if(key === 'answered') {
                req.call_start_time = this.props.datas.call_start_time
                req.call_end_time = moment().format("YYYY-MM-DD HH:mm:ss")
            }
            if(key !== 'answered') {
                req.recov_activity_id =this.state.recov_activity_id ? this.state.recov_activity_id : null 
            }
            req.call_log = type
            req.physical_phone = true
            req.screen = screen
            req.status = key ? key : this.state.current_mob_num.status
        }

        if(this.props.datas.initial_tab=="call_task"){
        req["id"]=this.props.datas.Call_log_data.id

        }
        if((this.props.datas.initial_tab =="due_by_days") || (this.props.datas.initial_tab=="commitment_date")){
            req["loan_doc_id"]= (this.props.datas &&this.props.datas.Call_log_data && this.props.datas.Call_log_data.loan_doc_id) ? this.props.datas.Call_log_data.loan_doc_id : null            
            req['call_log_id'] = callLog?.call_log_id

            if(this.state.update_commitment_date){
                req["update_commitment_date"]= this.state.update_commitment_date

            }
        }
        
        setCallLog(this.req(req))
        .then((response)=>{
            if(!response){return   this.resp("response")
            }
            if(response.status=="success"){         
                this.resp(response)
                if(softohone_priv && !this.props.datas.using_physicalphone){
                    this.setState({recov_activity_id: response.data.recov_activity_id ?response.data.recov_activity_id:'' })
                
                    this.props.setValuesIntoRedux({ call_log_details: null,CallLogModal:false, ModalIsOpen: false,incoming_call_details:[],incomingcall_num:null,callNum:null ,pending_rms_details :null,pending_cust_details:null})
    
                    this.channel.postMessage({message:"submit_call_log", data:{call_log_details: null,CallLogModal:false, ModalIsOpen: false,incoming_call_details:[],incomingcall_num:null,callNum:null ,pending_rms_details :null,pending_cust_details:null}})
                    
                    if((type=="end_call") ){
                        if(canCloseTheLogModal) this.props.handleClose("call_log_end")
                        alert(response.message)
                    }

                }else{
                    this.setState({recov_activity_id: response.data.recov_activity_id ?? '' })
                    this.resp(response)
                    alert(response?.message ?? "Activity logged successfully")
                    if(canCloseTheLogModal) this.props.handleClose("call_log_end")
                }
                // if the screen for pending call log we need to remove the delinquency id from the route
                this.props.navigateToDeliquencyRoute()
            }
        })
    }

    checkDisableStatus(){

    var remarks = (this.state.remarks &&this.state.remarksArr && this.state.remarksArr.length >4)
    
    var commitment_date =  this.state.reason_for_overdue ? this.state.od_reasons_day_limit[this.state.reason_for_overdue]['is_opt'] :false
   
    var status = ((this.state.call_tasks_date && this.state.reason_for_overdue &&  remarks) || (remarks && ( this.state.mobile_num_arr.some(item => item.status == "answered") ? ((this.state.reason_for_overdue || this.props.datas.callLogDatas.od_reason )&& commitment_date || this.props.datas.callLogDatas.commitment_date ) :true))) 
  
      return status;
    }
    render() {
        const { currentPage, itemsPerPage } = this.state;
        const startIndex = currentPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const select_date_based_od_reasons = this.state.reason_for_overdue && ['going_to_pay_today','no_float','business_issues','customer_not_available','no_float','personal_engagement','sick','weather'].includes(this.state.reason_for_overdue)
        
    
            return (
            <div className='' >
                {console.log(this.state, "all state values are here to check...")}
                <Modal className='cust_follow_up' size={"xl"} show={this.state.callLogModal} onHide={()=>this.props.handleClose(null,this.state.mobile_num_arr)}  keyboard={false}>
                    
                    <Modal.Header className='py-3 modal_header_bg modal-header' closeButton>
                        <Modal.Title className='p-0'> <h2 className='text-white my-2'> <b>Overdue - Call Log</b></h2></Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ background: "#0F273F" }}>
                        <div className='p-2 modal_body_border' >
                            {this.props.datas && this.props.datas.callLogDatas &&
                            <div className='row m-0 '>

                                <div className='col-lg-8 col-md-12 col-sm-12 '>
                                    <div className='row m-0'>
                                        <div className={`col-lg-6 col-md-12 col-sm-12 call_log_modal_size`}>
                                            <h2 className='text-white mx-0 sizem mt-3' >  <b>Customer Info</b></h2>
                                            <div className='mt-3'>
                                                <p className='m-0'> Name</p>
                                                <p className='text-white   m-0'> {this.props.datas.callLogDatas.cust_info.cust_name ? this.props.datas.callLogDatas.cust_info.cust_name:"-"}</p>
                                            </div>
                                            <div className='mt-3'>
                                                <p className='m-0'> {getValueFromLangJson("rm_label")}</p>
                                                <p className='text-white   m-0'> {this.props.datas.callLogDatas.cust_info.rm_name ? this.props.datas.callLogDatas.cust_info.rm_name:"-"}</p>
                                            </div>
                                            <div className='mt-3'>
                                                <p className='m-0'> Total FAs</p>
                                                <p className='text-white   m-0'> {this.props.datas.callLogDatas.cust_info.total_fas ? this.props.datas.callLogDatas.cust_info.total_fas:"-"}</p>
                                            </div>
                                        </div>

                                        <div className={`col-md-12 col-sm-12 col-lg-6 }`}>
                                            <h2 className='text-white mx-0 mt-3 sizem' > <b>FA Overdue Info</b> </h2>

                                            <div className='row mx-0 my-2 '>
                                                {this.state.faOverdueInfo.map((item,idx)=>{return(
                                                  <div className='col-6 p-0 mt-3'>
                                                  <p className='m-0'> {item.label}</p>
                                                  <p className='text-white   m-0'> {(item.key=="disbursal_date" ||  item.key=="due_date") ? moment( this.props.datas.callLogDatas.fa_od_info[item.key]).format("DD MMM YYYY"):
                                                  this.props.datas.callLogDatas.fa_od_info[item.key] ? lbl_amt(this.props.datas.callLogDatas.fa_od_info[item.key], get("market").currency_code):"-" }</p>
                                              </div>  
                                                )})}
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className=' d-flex justify-content-center align-items-center my-4 mx-0'>
                                        <div className='col-4 p-0'>
                                            <hr className='m-0 hr_line' />
                                        </div>
                                        <p className='m-0 px-4 reassignment_Label text-white text-center'> <b className='mx-2'>Contact Numbers</b></p>
                                        <div className='col-4 p-0'>
                                            <hr className='m-0 hr_line' />

                                        </div>

                                    </div>
                                    <div className={`row d-flex  ${this.props.datas.callLogDatas.cust_mob_num.length>2 ? "justify-content-center":"justify-content-around"} align-items-center  mx-0`}>
                                        {this.props.datas.callLogDatas.cust_mob_num.slice(startIndex, endIndex).map((item, idx) => {
                                            return (
                                                <div key={idx} className={`col-lg-4 col-md-12 px-2 pointer  ${(idx == 0) ? "" : ((this.disable_div_conditions(idx,item)  )? "" : "disable_div")} `} > 

                                                    <div className='row d-flex aign-items-center mx-0'>
                                                        <div className='col-2   mt-1' >
                                                            <FiPhoneCall size="20" color='white' />
                                                        </div>
                                                        <div className='col-10 pl-2 '>
                                                          {this.contactNumbers(item)}

                                                        </div>
                                                    </div>

                                                    <div className='row d-flex aign-items-center mt-2 mx-2'>
                                                        <div className='col-4   mt-1'>
                                                            <div className={` d-flex justify-content-center circled_call_bttn ${(this.state[`answered_${idx}`] || (item.status=="answered") ) ? "bg-success" : ""}`} onClick={() => { this.is_not_clicked(idx, "answered_",item) }}>
                                                                <FiPhone size="15" color='white' />
                                                            </div>
                                                        </div>
                                                        <div className='col-4  mt-1'>
                                                            <div className={` d-flex justify-content-center circled_call_bttn  ${(this.state[`not_answered_${idx}`] || (item.status=="not_answered")) ? "bg_notAnswered" : ""}`} onClick={() => { this.is_not_clicked(idx, "not_answered_",item) }}>
                                                                <FiPhoneOff size="15" color='white' />
                                                            </div>
                                                        </div>
                                                        <div className='col-4 mt-1'>
                                                            <div className={` d-flex justify-content-center circled_call_bttn  ${(this.state[`not_reachable_${idx}`] || (item.status=="not_reachable"))? "bg-danger" : ""}`} onClick={() => { this.is_not_clicked(idx, "not_reachable_",item) }}>
                                                                <FiPhoneMissed size="15" color='white' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row d-flex aign-items-center  mx-0'>
                                                        <div className='col-4 p-0  mt-1'>

                                                            <p className='m-0 text-white  text-center small_fontSize'> Answered</p>
                                                        </div>
                                                        <div className='col-4 p-0  mt-1'>

                                                            <p className='m-0 text-white text-center small_fontSize'> Not Answered</p>
                                                        </div>
                                                        <div className='col-4 p-0 mt-1'>

                                                            <p className='m-0 text-white  text-center small_fontSize'> Not Reachable</p>
                                                        </div>
                                                    </div>

                                                </div>

                                            )
                                        })}

                                    </div>
                                    {this.props.datas.callLogDatas.cust_mob_num.length>3 &&
                                    <div className='d-flex align-items-center justify-content-end mt-2'>
                                        <button className='mx-3' onClick={this.handlePrevPage}>Previous</button>
                                        <button onClick={this.handleNextPage}>Next</button>
                                    </div>
                                 }

                                    {(this.props.datas.initial_tab == "commitment_date") &&
                                    <div className={`d-flex justify-content-start align-items-center my-2 mt-5 mx-4 ${this.state.mobile_num_arr.some(item => item.status === "answered") ? "":"disable_div"}`}>
                                        <div className='pointer'>
                                           <input type="checkbox" id="commitment_date_checkbox" className='pointer' name="vehicle1 " style={{width:'19px',height:'19px'}} onChange={(e) => this.getInputHandler(e.target.checked, "update_commitment_date")}/>
                                        </div>
                                        <label className='px-2 sizem  pointer' for="commitment_date_checkbox">Customer preferred to change their commitment date</label>
                                        
                                    </div>
                                    }

                                    <div className=' d-flex justify-content-center align-items-center my-3 mt-5 mx-0'>
                                        <div className='col-4 p-0'>
                                            <hr className='m-0 hr_line' />
                                        </div>
                                        <p className='m-0 px-4 reassignment_Label text-white text-center'> <b className='mx-2'>Reasons & Remarks</b></p>
                                        <div className='col-4 p-0'>
                                            <hr className='m-0 hr_line' />

                                        </div>

                                    </div>
                                    <div className={`row  align-items-center col-12 p-0  mx-0 ${(this.state.mobile_num_arr.some(item => item.status == "answered")) ?"": "disable_div"}`}>
                                        <h2 className=' mx-0 pl-4 ' style={{ fontSize: '17px' }}>  <b> <b className='text-white'>Reason for Overdue </b><span style={{ color: 'red' }}>*</span></b></h2>

                                        <div className='row m-0 col-12 p-0 d-flex  justify-content-center'>

                                        <div className={`col-lg-6 col-md-12 col-sm-12 ${
                                            
                                            (this.state.update_commitment_date) ? "" :
                                      
                                            (this.props.datas.callLogDatas.od_reason && 
                                            this.props.datas.initial_tab === "commitment_date" && 
                                            this.state.mobile_num_arr.some(item => item.status === "answered")) ? "disable_div" :  this.state.mobile_num_arr.some(item => item.status ==="answered") ? "":"disable_div"}
                                          
                                            `}>


                                                <div className={`col-12 call_task_select_box  px-0 mb-4 date_select
                                               `} id="call_tasks_filter" style={{ position: 'relative' }}>
                                                    <Select
                                                        maxMenuHeight={160}
                                                        onChange={(e) => { this.getStatus(e) }}
                                                        value={this.state.select_value}
                                                        options={this.getMultiSelectOptions('reason_for_overdue')} 
                                                        placeholder= {(this.props.datas.callLogDatas.commitment_date && (this.props.datas.initial_tab == "commitment_date") )? dd_value(this.props.datas.callLogDatas.od_reason) :  "Choose"}
                                                        styles={this.select_custom_styles()}
                                                        classNamePrefix="my-select"
                                                    />
                                                </div>
                                                <fieldset className={`Commitment_fieldset my-2 ${
                                                    this.state.reason_for_overdue ? "" :(this.props.datas.callLogDatas.commitment_date && (this.props.datas.initial_tab == "commitment_date") && (this.state.update_commitment_date)) ? "" : "disable_div"}`} >
                                                    <legend className="legend_rekyc d-flex align-items-center text-white mb-0 " style={{ fontSize: '13px' }}> <b className='mr-3'> Commitment Date </b>


                                                    </legend>
                                                    <div className='col-12  pl-2 px-0 date_select call_tasks_filter' id="call_tasks_filter" style={{ position: 'relative' }}>
                                                        <DatePicker closeOnSelect={true}

                                                                isValidDate={(current) => {
                                                                if (select_date_based_od_reasons) {
                                                                    const day_limit = moment().add((this.state.od_reasons_day_limit && this.state.od_reasons_day_limit[this.state.reason_for_overdue]) ? this.state.od_reasons_day_limit[this.state.reason_for_overdue]['days'] : 0, 'days');
                                                                    return current.isSameOrAfter(moment(), 'day') && current.isSameOrBefore(day_limit, 'day');
                                                                } else {
                                                                    const day_limit = moment().add((this.state.od_reasons_day_limit && this.state.od_reasons_day_limit[this.state.reason_for_overdue]) ? this.state.od_reasons_day_limit[this.state.reason_for_overdue]['days'] : 0, 'days');
                                                                    return current.isSameOrAfter(moment(), 'day') && current.isSameOrBefore(day_limit, 'day');
                                                                }
                                                            }}

                                                            value={this.state.call_tasks_date ? moment(this.state.call_tasks_date).format('DD MMM YYYY') :""} onChange={(value) => this.getInputHandler(moment(value).format("YYYY-MM-DD"), "call_tasks_date")} inputProps={{ id: 'call_tasks_date', name: 'Referral_from', autoComplete: 'off' ,placeholder: `${(this.props.datas.callLogDatas.commitment_date && (this.props.datas.initial_tab == "commitment_date"))?moment(this.props.datas.callLogDatas.commitment_date).format('DD MMM YYYY') :'DD MM YYYY'}` }} dateFormat={"DD MM YYYY"}
                                                            showMonthYearPicker timeFormat={false} required="required" onKeyDown={(value) => value.preventDefault()} />
                                                        <label className='col-1.5 no-margin d-flex p-0 ' style={{ position: 'static' }} htmlFor='call_tasks_date'>
                                                            <p className='m-0 text-white' style={{ position: 'absolute' }}><BiCalendar size={"18"} /></p>
                                                        </label>

                                                    </div>

                                                </fieldset>
                                        
                                            </div>
                                            <div className='col-lg-6 col-md-12 col-sm-12'>

                                         
                                                <div className={`col-12 w-100 h-100 p-0 mt-4 `}>

                                                    <textarea className='pl-3 pt-3 call_log_textArea' id="w3review" name="w3review" maxlength="150" rows="5" cols="50" placeholder='Remarks' onChange={(e) => this.getInputHandler(e.target.value, "remarks")}></textarea>
                                                   <div className='d-flex align-items-center row m-0'>
                                                    {(this.state.remarks &&this.state.remarksArr&& this.state.remarksArr.length < 5)&&
                                                    <p className='m-0 col-10 px-0' style={{color:'#FF0000',fontWeight:'500'}}> Minimum 5 words required* </p>
                                                    }
                                                    {this.state.remarks &&this.state.remarks.length==150 && 
                                                    <p className='m-0 col-10 px-0' style={{color:'#FF0000',fontWeight:'500'}}>You have reached your character limit</p>
                                                    }
                                                    <p className={`text-white col-2  text-right m-0 px-0 d-flex justify-content-end ${(this.state.remarks &&this.state.remarksArr&& this.state.remarksArr.length < 5) ? "col-2" :"col-12"}`}>  {this.state.remarks ? this.state.remarks.length : "0"}/150</p>
                                                   </div>
                                                    </div>
                         

                                            </div>
                                            <div className= {`d-flex align-items-center my-3 col-3 ${this.state.calling_api ? "disable_div":""}`}>
                                                    <button type="button" class="btn btn-primary col-12 " disabled= 
                                                    
                                                    {(this.props.datas.initial_tab=="commitment_date" && (!this.state.update_commitment_date) &&  (this.state.mobile_num_arr.some(item => item.status == "answered"))) ?  !(this.state.remarks &&this.state.remarksArr && this.state.remarksArr.length >4): 
                                                  
                                                    this.checkDisableStatus()  ?false:true

                                                     } 
                                                     
                                                     onClick={()=>this.callLogSubmit(null,null,"end_call")}>submit</button>

                                            </div>
                                        </div>


                                    </div>
                                </div>


                                <fieldset className={`border border-white p-2 my-2 col-md-12 col-lg-4 reason_remarks_bg_img `} style={{ borderRadius: '10px', position: 'relative' }}>
                                    <legend className='w-auto text-light ml-4 sizem px-3'> <b>Previous Interactions</b> </legend>

                                   
                                    {this.props.datas.callLogDatas.prev_inter ?
                                    <div id="past_churn_remarks_scroll_end" className={` col-lg-12 col-md-12 col-sm-12 m-0 p-0  ${this.props.datas.callLogDatas.prev_inter.length > 3 ? "overdue_call_tasks_scroll" : ""}`}>
                                        {this.props.datas.callLogDatas.prev_inter&&this.props.datas.callLogDatas.prev_inter.map((item, idx) => {
                                            return (
                                                <div className='call_prev_interactions px-4 my-3 mx-3 py-3'>
                                                    <div className='row mx-0 mt-2' style={{position:'relative'}}>
                                                        {item.commitment_date &&
                                                        <p className='m-0 text-white d-flex align-items-center justify-content-end col-4 px-0' style={{position:'absolute',right:'-8px'}}>
                                                            <TbClockCheck className='call_log_icon_size mt-0 mx-1' size="15" color='red' />
                                                            <p className='m-0 text-white'>{moment(item.commitment_date).format('DD MMM YYYY')}</p>
                                                        </p>}
                                                        {item.task_type == 'call_task' && 
                                                            <p className='m-0 col-1 p-0'>
                                                                <FiPhoneCall className='call_log_icon_size mt-1' size="20" color='white' />
                                                            </p>
                                                        }
                                                        {item.task_type != 'call_task' && 
                                                            <p className='m-0 col-1 p-0'>
                                                                <MdOutlineLocationOn className='call_log_icon_size mt-1' size="20" color='white' />
                                                            </p>

                                                        }
                                                       
                                                        <div className='col-10'>
                                                            <p className='text-white m-0 '> <b>{item.rms_name}</b></p>
                                                            <p className='text-white m-0 '> {moment(item.date).format("DD MMM YYYY")}</p>
                                                            {item.task_type == 'call_task' && 
                                                            <p className='text-white m-0 '> 
                                                            {(item.cont_arr && item.cont_arr.length>0 )?item.cont_arr.map((values,idx)=>{return(
                                                                <span key={idx}> {values} <span> {(item.cont_arr.length-1 ==idx ) ? "":" | "} </span> </span>
                                                            )}) :"-" } {item.label ? "(" +dd_value(item.label) +")" :""} </p>
                                                            }

                                                        </div>
                                                    </div>

                                                    <div className='row mx-0 mt-2'>
                                                        <p className='m-0 col-1 p-0'>
                                                            <RiQuestionFill className='call_log_icon_size ' size="20" color='white' />
                                                        </p>
                                                        <div className='col-10'>
                                                            <p className='text-white m-0 '> {item.reason ?dd_value(item.reason, 'any'):"-" }</p>

                                                        </div>
                                                    </div>

                                                    <div className='row mx-0 mt-2'>
                                                        <p className='m-0 col-1 p-0'>
                                                            <BiMessageDetail className='call_log_icon_size mt-1' size="20" color='white' />
                                                        </p>
                                                        <div className='col-10'>
                                                            <p className='text-white m-0 sizem'> {item.remarks ? dd_value(item.remarks):"-"}</p>

                                                        </div>
                                                    </div>
                                                </div>


                                            )
                                        })}
                                    </div>
                                    :
                                    <div>
                                        <p className='m-0 text-white text-center py-4'>No Previous Interactions</p>
                                    </div>
                                    }

                                </fieldset>
                            </div>
                            }
                           



                        </div>

                    </Modal.Body>
                </Modal>
                


               
            </div>
        );

    }
}

const mapStateToProps = (state) => {
    return {
        softPhoneState: state.cs_soft_phone,

    };
};

const mapDispatchToProps = {
    setValuesIntoRedux
  };

export default connect(mapStateToProps,mapDispatchToProps)(CallLogModal);

