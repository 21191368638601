import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import '../../../styles/flow.css';
import '../../../styles/chat.css';
import {viewComments} from '../../../actions/loan_actions';
import {dd_value} from '../../../actions/common_actions';
import {format_date} from '../../../helpers/common_helper';


class ViewComments extends FlowComponent {
	state = {
		variant: "primary",
		comp_name: "ViewComments", 
	};
	
	get_style(cmt_frm){
		if(cmt_frm == this.logged_in_user_id){
			return "message-blue"
		}else{
			return "message-orange"
		}


	}
	componentWillMount(){
		const loan_doc_id = this.props.loan_doc_id
	 viewComments(this.req({loan_doc_id}))
        .then((response) => {
          if(!response){return };
          if(response.status === "success" && response.data != null){
          		
          	  var comments = response.data.list;
          	  this.logged_in_user_id = response.data.logged_in_user_id
          	  const comment_list = comments.map
	            ((comment,i)=> 
				    <div className={this.get_style(comment.cmt_from)}>
				    	<div className="">{dd_value(comment.cmt_type)}</div>
				        <p className="message-content">{comment.cmt_from_name} : {comment.comment}</p>
				        <div className="message-timestamp-left">{format_date(comment.created_at, true)}</div>
				    </div>

	            	);
	          this.setState({comment_list});
          }	
        });
    } 


render(){

	return(
			<>
				<h4>Comments</h4>
				
				<div className="chat_container">
					{this.state.comment_list}
				</div>
			
			</>
		);
	}

}

export default ViewComments;