import { Box, Button } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { processDigitalKyc } from "../../../actions/lead_actions";
import { common_req } from "../../../helpers/helper";
import { useGetDigitalKycRecords } from "../hook/digitalKycHooks";

const SECTIONS = ["sales_pitch", "identity_verification", "agreement"];

const Pagination = ({
  isDisabled,
  request,
}: {
  isDisabled: boolean | null;
  request?: any;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { data } = useGetDigitalKycRecords();

  const activeStep = location.pathname.split("/").pop() || "";
  const currentIndex = SECTIONS.indexOf(activeStep);

  const navigateTo = (step: number) => {
    if (step >= 0 && step < SECTIONS.length) {
      navigate(`/lead/digital_kyc/${id}/${SECTIONS[step]}`);
    }
  };

  const { mutate, isPending } = useMutation({
    mutationFn: async ({ stage }: { stage: string }) => {
      const req: any = { lead_id: id, stage };
      if (request?.self_reg_json) {
        req.self_reg_json = request.self_reg_json;
        console.log("request", req);
      } else {
        console.log("first", Object.assign(req, request));
        Object.assign(req, request);
      }
      const response = await processDigitalKyc(common_req(req));
      return response;
    },
    onSuccess: (response, { stage }) => {
      const responseData = response.data;
      queryClient.setQueryData(
        ["digitalKycLeadInfo", id],
        (prevData: any = {}) => ({
          ...prevData,
          [stage]: {
            ...prevData?.[stage],
            status: "verified",
            icon_key: stage === "agreement" ? responseData?.icon_key : null,
          },
        })
      );
      navigateTo(currentIndex + 1);
    },
    onError: (error) => {
      console.error("Mutation failed:", error);
    },
  });

  const handleNext = () => {
    // @ts-ignore
    if (data?.[activeStep]?.status === "verified") {
      navigateTo(currentIndex + 1);
    } else {
      mutate({ stage: activeStep });
    }
  };

  return (
    <Box display="flex" justifyContent="center" gap={2}>
      {currentIndex > 0 && (
        <Button
          sx={{
            background: "#B8DEFF",
            color: "#0775D6 !important",
            textTransform: "none",
          }}
          onClick={() => navigateTo(currentIndex - 1)}
        >
          Back
        </Button>
      )}

      <Button
        variant="contained"
        color="secondary"
        onClick={() => (isPending ? null : handleNext())}
        className={isDisabled ? "" : "disable_div"}
        sx={{ textTransform: "none" }}
      >
        {isPending
          ? "Processing..."
          : currentIndex < SECTIONS.length - 1
          ? "Next"
          : "Submit"}
      </Button>
    </Box>
  );
};

export default Pagination;
