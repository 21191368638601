import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import FormProvider from "../../CommonComponent/rhfComponents/formProvider";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormSchema from "../formschema/FormSchema";
import { HeaderVarientH3Typo } from "../../CommonComponent/typography";
import RHFCheckBoxField from "../../CommonComponent/rhfComponents/rhfCheckBoxField";
import Pagination from "./digitalKycpagination";
import moment from "moment";
import UploadVerificationProof from "./uploadVerificationProof";
import { useGetDigitalKycRecords } from "../hook/digitalKycHooks";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { processDigitalKyc } from "../../../actions/lead_actions";
import { common_req } from "../../../helpers/helper";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";

const IdentityVerificationProcess = ({ selfRegJson }: { selfRegJson: any }) => {
  const defaultValues = {
    nationalId: [],
    rdbCertificate: [],
    is_interested: null,
    not_interested: null,
    first_name: selfRegJson?.photo_national_id?.first_name ?? "",
    last_name: selfRegJson?.photo_national_id?.last_name ?? "",
    dob: moment(selfRegJson?.photo_national_id?.dob) ?? "",
    id_proof_num: selfRegJson?.photo_national_id?.id_proof_num ?? "",
    gender: selfRegJson?.photo_national_id?.gender ?? "",
    registration_date:
      moment(selfRegJson?.photo_rdb_certificate?.registration_date) ?? "",
    enterprise_name: selfRegJson?.photo_rdb_certificate?.enterprise_name ?? "",
    owner_name: selfRegJson?.photo_rdb_certificate?.owner_name ?? "",
    company_code: selfRegJson?.photo_rdb_certificate?.company_code ?? "",
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(FormSchema),
  });
  const {
    watch,
    setValue,
    formState: { isValid },
  } = methods;
  const theme = useTheme();
  const { data } = useGetDigitalKycRecords();
  const handleCheckboxChange = (value: string) => {
    if (value == "no") {
      setValue("is_interested", false);
      setValue("rdbCertificate", []);
    } else if (value == "yes") {
      setValue("not_interested", false);
    }
  };
  const nationalIdFileds = [
    "first_name",
    "last_name",
    "dob",
    "id_proof_num",
    "gender",
  ];
  const rdbFields = [
    "company_code",
    "enterprise_name",
    "owner_name",
    "registration_date",
  ];
  const nationalIdProof = watch("nationalId") ?? null;
  const rdbCertificate =
    data?.identity_verification?.self_reg_json?.photo_rdb_certificate;
  const { id } = useParams();
  const nationalIdMismatch =
    data?.identity_verification?.self_reg_json?.photo_national_id?.is_verified;
  const rdbCertificateMismatch =
    data?.identity_verification?.self_reg_json?.photo_rdb_certificate
      ?.is_verified;
  const enableNextBtn = nationalIdMismatch
    ? isValid && nationalIdMismatch != null
    : nationalIdMismatch == false
    ? isValid && rdbCertificateMismatch != null
    : false;
  const checkedNotInterstedCb =
    (watch("not_interested") && !watch("is_interested")) ?? false;
  const checkedInterstedCb =
    (watch("is_interested") && !watch("not_interested")) ?? false;

  useEffect(() => {
    if (rdbCertificate?.file_rel_path) {
      setValue("is_interested", true);
    }
  }, [rdbCertificate]);

  const navigate = useNavigate();
  const submitHandler = () => {
    let req = {
      lead_id: id,
      stage: "identity_verification",
      is_rdb_available: false,
    };
    processDigitalKyc(common_req(req)).then((response) => {
      if (response?.status == "success") {
        navigate("/lead/lead_pipeline", { replace: true });
      }
    });
  };
  return (
    <FormProvider methods={methods}>
      <Box width="60%" sx={{ margin: "0 auto" }}>
        <Box sx={{ background: "#011137", borderRadius: "20px", p: 3, my: 7 }}>
          <Box display={"flex"} alignItems={"center"}>
            <BadgeOutlinedIcon
              sx={{ color: theme.palette.text.primary, fontSize: 25, mx: 1 }}
            />
            <HeaderVarientH3Typo
              text="Identity Verification"
              overRideStyles={{
                fontWeight: 600,
                color: theme.palette.text.primary,
              }}
            />
          </Box>
          <UploadVerificationProof
            fields={nationalIdFileds}
            label={"nationalId"}
          />
          {nationalIdProof && nationalIdProof?.length > 0 && (
            <Box>
              {nationalIdMismatch == false &&
                !(rdbCertificateMismatch != null) && (
                  <Box mx={2}>
                    <Typography
                      color={theme.palette.text.primary}
                      sx={{ fontWeight: 600 }}
                    >
                      Can the agent share the RDB certificate now?
                    </Typography>
                    <Box display={"flex"} alignItems={"center"} my={1}>
                      <Box
                        sx={{
                          border: "2px solid white",
                          borderColor: checkedInterstedCb
                            ? theme.palette.secondary.main
                            : "",
                          borderRadius: 2,
                          pr: 2,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <RHFCheckBoxField
                          name="is_interested"
                          checked={checkedInterstedCb}
                          customOnchangeCb={() => handleCheckboxChange("yes")}
                        />
                        <Typography
                          color={
                            checkedInterstedCb
                              ? theme.palette.secondary.main
                              : theme.palette.text.primary
                          }
                        >
                          Yes
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          border: "2px solid white",
                          borderColor: checkedNotInterstedCb
                            ? theme.palette.secondary.main
                            : "",
                          borderRadius: 2,
                          mx: 2,
                          pr: 2,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <RHFCheckBoxField
                          name="not_interested"
                          checked={checkedNotInterstedCb}
                          customOnchangeCb={() => handleCheckboxChange("no")}
                        />
                        <Typography
                          color={
                            checkedNotInterstedCb
                              ? theme.palette.secondary.main
                              : theme.palette.text.primary
                          }
                        >
                          No
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )}

              {!checkedNotInterstedCb &&
                (watch("is_interested") || rdbCertificate?.file_rel_path) && (
                  <UploadVerificationProof
                    fields={rdbFields}
                    label={"rdbCertificate"}
                  />
                )}
            </Box>
          )}
        </Box>
      </Box>
      {checkedNotInterstedCb && (
        <Box display={"flex"} justifyContent={"center"}>
          <Button variant="contained" color="secondary" onClick={submitHandler}>
            Submit
          </Button>
        </Box>
      )}
      {!checkedNotInterstedCb && (
        <Pagination
          isDisabled={enableNextBtn}
          request={{ is_rdb_available: true }}
        />
      )}
    </FormProvider>
  );
};

export default IdentityVerificationProcess;
