import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { Accept, useDropzone } from "react-dropzone";
import { HeaderVarientH4Typo } from "../../../typography";
import FormLabelUI from "../../components/formLabel";
import RhfFilePreview from "../../components/rhfFilePreview";
import useRfhFileUploadFieldMethods from "../useRfhFileUploadFieldMethods";
import { get_path } from "../../../../../helpers/common_helper";

type Props = {
  name: string;
  multiple?: boolean;
  maxSize?: number;
  accept: Accept;
  required: boolean;
  label: string;
  fileOf: string;
  custId?: string;
  icon?: ReactNode;
  leadId?: number | string;
  customOnchangeCb?: Function;
  disabled?:boolean;
  initialFiles: Pick<
    ExtendedFile,
    "uploadedFileName" | "uploadedFileRelPath" | "size"
  >[];
};

export type ExtendedFile = Pick<File, "name" | "size" | "type"> & {
  blob: string;
  dataUrl: string;
  file?: File;
  uploadedFileName: string;
  uploadedFileRelPath: string;
  size: number;
};

const bytesPerMb = 1048576;

/**
 *
 * @param multiple Allows selecting multiple files or a single file. The multiple file upload feature is not fully implemented yet.
 * @param maxSize Limits the file size in megabytes, a default value of 5MB.
 * @param accept Manages the accepted file formats.
 * @param required Manages * to this field
 * @param label Adds label to the field
 * @param fileOf Need to sent this to server for file uploading
 * @param custId Need to sent this to server for file uploading
 * @param initalFiles This is used to showing the files at first when the component mount
 * @returns
 */
const RhfFileUploadField = ({
  name,
  multiple,
  maxSize = 5,
  accept,
  required,
  label,
  fileOf,
  custId,
  initialFiles,
  icon,
  leadId,
  customOnchangeCb,
  disabled
}: Props) => {
  // state
  const [selectedFileForPreview, setselectedFileForPreview] =
    useState<ExtendedFile>(); // used for preview the selected file inside the modal

  // hook
  const {
    onDrop,
    uploadHandler,
    removeHandler,
    imgFiles,
    otherFiles,
    setimgFiles,
    setotherFiles,
    isFileUploading,
  } = useRfhFileUploadFieldMethods({
    fileOf,
    custId,
    name,
    leadId,
    customOnchangeCb,
  });

  // dropzone hook
  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      accept,
      onDrop,
      multiple,
      maxSize: maxSize * bytesPerMb,
    });

  // handler
  // if the file got uploaded to the server then the server file's url will be used for showing the file
  // else if the file not yet uploaded to the server the file is previewed by using it's local blob
  // this is the common function for image preview inside the modal
  // at there we need to show the image with *l quality
  // but for pdf we don't need that
  const getFileUrlForPreview = (
    f: ExtendedFile,
    fileType: "Image" | "Other",
    quality: "l" | ""
  ) => {
    let url = "";
    if (fileType === "Image") {
      // @ts-ignore
      url = `https://prakash.flowlocal.net/${f.uploadedFileRelPath}/${
        quality ? quality + "_" : ""
      }${f.uploadedFileName}`;
    } else {
      // @ts-ignore
      url = `https://prakash.flowlocal.net/${f.uploadedFileRelPath}/${f.uploadedFileName}`;
    }
    return f.uploadedFileName ? url : f.blob;
  };

  // effect
  useEffect(() => {
    const imgFiles: ExtendedFile[] = [];
    const otherFiles: ExtendedFile[] = [];
    initialFiles.forEach((e) => {
      const fileType = e?.uploadedFileName?.split(".");
      const fileSize = e?.size ?? 0;
      if (
        Array.isArray(fileType) &&
        (fileType[1] === "png" ||
          fileType[1] === "jpeg" ||
          fileType[1] === "jpg") &&
        e.uploadedFileName
      ) {
        imgFiles.push({
          name: e.uploadedFileName ?? "",
          size: fileSize,
          type: `image/${fileType[1]}`,
          blob: "",
          dataUrl: "",
          file: undefined,
          uploadedFileName: e.uploadedFileName ?? "",
          uploadedFileRelPath: e.uploadedFileRelPath ?? "",
        });
      } else if (
        Array.isArray(fileType) &&
        fileType[1] === "pdf" &&
        e.uploadedFileName
      ) {
        otherFiles.push({
          name: e.uploadedFileName ?? "",
          size: fileSize,
          type: `application/${fileType[1]}`,
          blob: "",
          dataUrl: "",
          file: undefined,
          uploadedFileName: e.uploadedFileName ?? "",
          uploadedFileRelPath: e.uploadedFileRelPath ?? "",
        });
      }

      setimgFiles(imgFiles);
      setotherFiles(otherFiles);
    });
  }, [initialFiles]);

  useEffect(() => {
    if (fileRejections.length > 0) {
      // fle size exceeded alert
      if (fileRejections[0].errors[0].code === "file-too-large")
        alert(
          `File is larger than recommended maximum file size is ${maxSize}`
        );
    }
  }, [fileRejections]);

  return (
    <>
      <Box display="flex" alignItems="center" columnGap="10px">
        {!(imgFiles.length > 0) && (
          <Box {...getRootProps()}>
            <input {...getInputProps()} />
            <Box
              display={"flex"}
              justifyContent={"center"}
              sx={{
                borderRadius: "10px",
              }}
            >
              <IconButton>
                <Box sx={{ background: "#DCEEFF", p: 1, borderRadius: "19px" }}>
                  {icon ?? (
                    <AddOutlinedIcon sx={{ width: "40px", height: "40px" }} />
                  )}
                </Box>
              </IconButton>
            </Box>
            <Box display={"flex"} justifyContent={"center"}>
              <Box>
                <FormLabelUI required={!!required} label={label as string} />
                <Typography
                  sx={{ textAlign: "center", fontSize: "0.7rem" }}
                  color={"white"}
                  display={"flex"}
                >
                  <Typography
                    color={"secondary"}
                    sx={{ textDecoration: "underline" }}
                    mr={1}
                  >
                    Click here{" "}
                  </Typography>
                  to upload file
                </Typography>
              </Box>
            </Box>
          </Box>
        )}

        {/* image type file */}
        {imgFiles.length > 0 &&
          !isFileUploading &&
          imgFiles.map((f, idx) => (
            <Box
              key={idx}
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box sx={{ width: "50px", height: "45px" }}>
                <img
                  src={getFileUrlForPreview(f, "Image", "")}
                  alt="No image found"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  onClick={() => setselectedFileForPreview(f)}
                />
              </Box>

              <Box mx={2}>
                <Typography color={"white"} sx={{ fontSize: "0.89rem" }}>
                  {label}
                </Typography>
                <Typography color={"white"} sx={{ fontSize: "0.69rem" }}>
                  {(f.size / bytesPerMb)?.toFixed(2)} mb
                </Typography>
              </Box>

              <Box mx={6}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setselectedFileForPreview(f)}
                  sx={{ textTransform: "none", mx: 1, padding: "4px" }}
                >
                  View
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  sx={{ textTransform: "none", mx: 1, padding: "4px" }}
                  onClick={() => removeHandler(f, setimgFiles, imgFiles)}
                  disabled={disabled}
                >
                  Remove
                </Button>
              </Box>
            </Box>
          ))}
        {/* other types files */}
        <Box display="flex" alignItems="end" flexDirection="column">
          {otherFiles.length > 0 &&
            otherFiles.map((f, idx) => (
              <Box
                key={idx}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <Box onClick={() => setselectedFileForPreview(f)}>
                  <HeaderVarientH4Typo text={f.name} />
                </Box>
                <IconButton
                  sx={{ zIndex: 2 }}
                  onClick={() => removeHandler(f, setotherFiles, otherFiles)}
                >
                  <DoNotDisturbOnIcon
                    sx={{ width: "20px", height: "20px", color: "#DC3545" }}
                  />
                </IconButton>
              </Box>
            ))}
        </Box>

        {isFileUploading && (
          <Box sx={{ p: 1 }} display={"flex"} justifyContent={"center"}>
            <CircularProgress
              sx={{ width: "22px", height: "22px", color: "#fff" }}
            />
          </Box>
        )}
      </Box>
      {/* files preview UI */}
      <RhfFilePreview
        setselectedFileForPreview={setselectedFileForPreview}
        showModal={!!selectedFileForPreview}
        selectedFileForPreview={selectedFileForPreview}
        getFileUrlForPreview={getFileUrlForPreview}
      />
    </>
  );
};

export default RhfFileUploadField;

// export default memo(RhfFileUploadField, (prevProps, nextProps) => {
//   console.log(
//     prevProps,
//     nextProps,
//     "prev and next props are here to check...."
//   );
//   return prevProps.renderValidateConst === nextProps.renderValidateConst; // if it is true rerender will not happen
// });
