import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import { Modal , Button} from 'react-bootstrap';
import '../../../styles/flow.css';
import {createCaptureRepayment, handleTransactionID} from '../../../actions/loan_actions';
import { Navigate} from 'react-router-dom';
import {lbl_amt} from '../../../helpers/product_helper';
import {format_date, to_amount,rt_algn} from '../../../helpers/common_helper';
import {check_priv} from '../../../helpers/storage_helper';
import DatePicker from 'react-datetime';
class CaptureRepayment extends FlowComponent {
    state = {
     view_loan: false,    
     modalBox: false,
     send_sms: true,
     total_amount: 0,
     penalty_div_style: "show_div",
     penalty_collected: '',
     is_part_payment:false,
     txn_data : '',
     capture_btn : 'disable_div',
     txn_id_style : 'txn_id_style'
  }

componentWillMount(){
   
}

handleRepaymentClick = (event) => {

    //this.waive_penalty = false;
    // this.is_part_payment = false;

    this.provisional_penalty = this.props.data.provisional_penalty;
    this.penalty_collected = this.props.data.penalty_collected;
    this.os_penalty = (this.provisional_penalty - this.penalty_collected);

    //const cust_id = this.props.data.cust_id;
    const lender_code = this.props.data.lender_code; 
    const network_prvdr_code = this.props.data.acc_prvdr_code;
    const status = "enabled" ;
    const lender_account = {lender_code, status, network_prvdr_code};

    this.setOptionsListToStateFromAppApi("common","lender_accounts", this.req({lender_account}), ["account_id", "acc_num_disp_txt"]);
    //this.resp({lender_account});

    
  this.repayment_txn = {loan_doc_id: this.props.data.loan_doc_id,current_os_amount:this.props.data.current_os_amount};
  this.penalty_collected = 0;
  this.setState({modalBox: true, penalty_collected:''});
         //const cust_id = this.props.data.cust_id;
         //const lender_code = this.props.data.lender_code;
         //const  account = {"cust_id":cust_id,"lender_code":lender_code};
}

handleSMSCheckboxClick = (event) => {
  if(event.target.checked){
    this.setState({send_sms: true});
  }else{
    this.setState({send_sms: false});
  }
}
handlePartPaymentCheckboxClick = (event) =>{
  if(event.target.checked){
    this.setState({is_part_payment:true});
  }else{
    this.setState({is_part_payment:false});
  }
}

handleClose = (event) => {
    
    this.penalty_collected = 0;
    this.repayment_amount = 0;
    this.setState({ modalBox: false , total_amount: 0, is_part_payment:false, send_sms: false, txn_data: '', 
    capture_btn : 'disable_div', txn_id_style : 'txn_id_style', txn_failed_msg : ''});
    
}

handleSubmit = (event)=>{
    event.preventDefault(); 
    var resp = true;
    var response = true;
    const txn_amt = to_amount(this.repayment_txn.amount);
    const os_amt = to_amount(this.props.data.current_os_amount);
    
    if(txn_amt > os_amt){
        window.alert("Unable to process. You are trying to make payment more than Current OS amount.");
        return;
    }

    if (this.state.is_part_payment){
        
        if(txn_amt == os_amt){
          window.alert("Unable to process. You are trying to make full payment. Make sure whether you are trying to pay full amount or partial amount");
          return;
        }
    }
    else{
        if(txn_amt < os_amt){
        window.alert("Unable to process. You are trying to make partial payment less than Current OS amount.");
        return;
    }
      /*resp = window.confirm("You are trying to make partial payment less than Current OS amount. \nPress Cancel to abort the transaction. Press OK to Proceed.");
      
    }  this.is_part_payment = resp;*/
      
    }
    
    const penalty_collected = to_amount(this.repayment_txn.penalty_collected);
    const prov_penalty = to_amount(this.props.data.provisional_penalty);
  
    if(prov_penalty > 0) {
        if (penalty_collected == 0){
          resp = window.confirm("Are you sure to completely waive the penalty for this FA? \nPress Cancel to abort the transaction. Press OK to Proceed.");
        
        } else if(penalty_collected < prov_penalty) { 
          resp = window.confirm(`Are you sure to waive a penalty of ${this.current_os_penalty - penalty_collected} for this FA? \nPress Cancel to abort the transaction. Press OK to Proceed.`);

        }else if(penalty_collected > prov_penalty) { 
          alert("You can not collect penalty higher than provisional penalty.");
          resp = false;
        }
      
    }
    
    if(resp === false){
      return;
    }
    else{
      response = window.confirm("Are you sure to proceed?");
    }
    

    if(response === false){
      return;
    }
    const repayment_txn = this.repayment_txn;

    repayment_txn.to_ac_id = this.to_acc.value;
    //repayment_txn.to_ac_id = this.selected.lender_accounts;

    repayment_txn.send_sms = this.state.send_sms;
    repayment_txn.is_part_payment = this.state.is_part_payment;
    // repayment_txn.waive_penalty = this.waive_penalty;
   

    
     createCaptureRepayment(this.req({repayment_txn}))
        .then((response) => {
          this.resp(response);
          if(!response){return };

            if(response.status === "success"){
              this.setState({ modalBox: false});
              window.location.reload();
    
            }else{
              alert(response.message + "\n" + JSON.stringify(response.data));  
            }
          }
        );
  }

  repayment_amount = 0;
  penalty_collected = 0;

  handleAmountChange = (event) => {
    /*const re = /^[0-9\b]+$/;
    if(event.target.value === '' || re.test(event.target.value)){
      this.setState({repayment_amount: event.target.value});
    }*/
    if(event.target.id === "amount"){

      this.repayment_amount = event.target.value;     
    }else if(event.target.id === "penalty_collected"){

      this.penalty_collected = event.target.value;
      this.setState({penalty_collected: this.penalty_collected});
    }
    
      const total_amount = Number(this.repayment_amount) + Number(this.penalty_collected);
      // if(this.waive_penalty === true){
      //   const total_amount = Number(this.repayment_amount);
      //   this.setState({total_amount, penalty_collected: 0});
      // }else{
      //this.setState({total_amount,  penalty_collected: this.penalty_collected});
      this.setState({total_amount});
          
      this.handleSelfChange(event);
  }

  handleTxnId = (event) => {
    let txn_id = event.target.value;
    
    this.setState({txn_data : '', txn_msg : ''});

    if(txn_id.length > 5){
      
      handleTransactionID(this.req({'acc_id' : this.repayment_txn.to_ac_id , 'txn_id' : txn_id, 'mode' : 'credit'}, true))
      .then((response) => {
        this.resp(response);
        if(!response){return };

          if(response.status === "success"){
            this.setState({capture_btn : 'disable_div', txn_data :'', txn_failed_msg: ''});
            
            if(response.data){
              if(response.data.hasOwnProperty('ignore_check')){
                this.setState({capture_btn : ''});
              }
              else if(response.data.hasOwnProperty('message')){
                this.setState({txn_failed_msg : response.data.message});
              }
              else if(response.data.cr_amt == this.repayment_txn.amount){
                this.setState({txn_data : response.data, capture_btn : ''});
                
              }
              else if(response.data.cr_amt != this.repayment_txn.amount){
                this.setState({txn_data : response.data});
                
              }
              
            }
            
            else{
              this.setState({txn_failed_msg : 'Please check the transaction ID. No payment received to the account with this transaction ID.'});
            }
  
          }else{
            alert(response.message + "\n" + JSON.stringify(response.data));  
          }
        }
      );

    }
  }

  enableTxnID = (event) => {
    const repayment_txn = this.repayment_txn;
    
    if(repayment_txn.amount > 0 && repayment_txn.to_ac_id != null && repayment_txn.txn_date != null){
      this.setState({txn_id_style :'' });
    }
  }
  // waivePenaltyCheckbox = (event) => {
    
  //   if(event.target.checked){
  //     //var ev = new Event(event.target.id, {bubbles: true});
  //     //ev.simulated = true;
  //     //element.value = 0;
  //     //.dispatchEvent(ev);
  //     this.repayment_txn.penalty_collected = null;
  //     this.waive_penalty = true
     
  //     const total_amount = Number(this.repayment_amount);
  //     this.setState({total_amount, penalty_div_style: "disable_div",penalty_collected: 0});
     
  //   }else{
  //     this.waive_penalty = false
  //     this.setState({penalty_div_style: "show_div"});
  //   }
  // }


  render(){
    //alert(this.selected.lender_accounts)
    if (this.state.view_loan === true) { 
      return (
      <Navigate to={`/fa/view/${this.props.data.loan_doc_id}`} />
      );
    }
    
    this.current_os_penalty = this.props.data.provisional_penalty - this.props.data.penalty_collected;
  
    return (
   
      <div id="" >

        <div className="row">
       
          <div className="col-md-12">
            <input type="button" id="float_style" className="btn btn-primary repayment" value="Capture Repayment" onClick={this.handleRepaymentClick} />&nbsp;&nbsp;&nbsp;
          </div>
        
        </div>
        <br/>

        <Modal show={this.state.modalBox} onHide={this.handleClose} size="xl">
          <Modal.Header closeButton show={this.state.modalBox} onHide={this.handleClose}>
            <Modal.Title>Capture Repayment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">

              <div className="row">
                <div className="col-md-6">
                    <h4>Float Advance ID: {this.props.data.loan_doc_id} </h4>
                </div>
                <div className="col-md-6">
                <h4 className="account">FA Due Date: {format_date(this.props.data.due_date)}</h4>
                </div>
              </div>
             
              <div className="row">
                <div className="col-md-6">
                    <h4>Current OS FA Amount : {rt_algn(lbl_amt(this.props.data.current_os_amount, this.currency_code))}</h4> 
                    
                    {this.props.data.provisional_penalty > 0  &&
                      <h4>Current OS Penalty Amount : {rt_algn(lbl_amt(this.current_os_penalty, this.currency_code))}</h4> 
                    }
                </div>
                 { (this.props.data.provisional_penalty > 0) &&
                <div className="col-md-6">
                  <h4 className="account">Provisional Penalty : {lbl_amt(this.props.data.provisional_penalty , this.currency_code)} for {this.props.data.penalty_days} days</h4>
                </div>
                 }
              </div>
             

              <br/>

              <div className="row">
                <div className="col-md-6">

                  <div className="row">
                    <div className="col-md-7">
                      <label>FA Repayment</label><font className="text-danger">   *  </font>
                    </div>
                    <div className="col-md-5">
                      <input type="text" id="amount" value={this.state.repayment_amount} onChange={(e) => { this.handleAmountChange(e);this.enableTxnID(e)}} className="form-control" required="required"/>
                    </div>
                  </div>
                  {check_priv("loan","capture_part_payment")  && this.props.data.allow_partial_payment == "enabled" && 
                  <div className="row">
                  <div className="col-md-7">
                  </div>
                    <div className="col-md-5">
                      <label><input type="checkbox" onChange={this.handlePartPaymentCheckboxClick}/>&nbsp;
                      <b className="red_color_font">Part Payment</b></label>
                    </div>
                  </div>
                  }
                  <br/>
                  
                  <div className="row">
                    <div className="col-md-7">
                      <label>Repaid To Account ( {this.props.data.lender_name} )</label><font className="text-danger">   *  </font>
                    </div>
                    <div className="col-md-5">
                      <select defaultValue={this.selected.lender_accounts} id="to_ac_id" ref={select => this.to_acc = select} onChange={(e) => { this.handleSelfChange(e);this.enableTxnID(e)}} className="form-control" type="text">
                      {/*<select defaultValue={this.selected.lender_accounts} id="to_ac_id" onChange={this.handleSelfChange} className="form-control" type="text">*/}
                      {this.state.lender_accounts_dd}   
                      </select>
                    </div>
                  </div>
                  <br/>

                  <div className="row">
                    <div className="col-md-7">
                        <label>Payment Date</label><font className="text-danger">   *  </font>
                    </div>
                    <div className="col-md-5">
                          <DatePicker closeOnSelect={true} onChange={(value) => {this.handleSelfDateChange("txn_date", value);this.enableTxnID(value)}}  dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>
                    </div>
                  </div>
                  <br/>
                  <div className="row">
                    <div className="col-md-7">
                      <label>Payment Mode</label><font className="text-danger">   *  </font>
                    </div>
                    <div className="col-md-5">
                      <select id="txn_mode" onChange={this.handleSelfChange} className="form-control" type="text" required="required">
                      {this.getSelectOptions("transaction_mode")}
                      </select>
                    </div>
                  </div>
                  <br/>
                  </div>

                <div className="col-md-6">

                  {this.props.data.provisional_penalty > 0  &&
                    <>
                    <div className="row">
                      <div className="col-md-7">
                        {/* <label>Penalty &nbsp;&nbsp;<input type="checkbox" onClick={this.waivePenaltyCheckbox}/>&nbsp;&nbsp; <b className="red_color_font">Waive Penalty</b></label> */}
                        <label>Penalty Payment</label>
                      </div>
                      <div className={`col-md-5 ${this.state.penalty_div_style}`}>
                        <input type="text" id="penalty_collected" value={this.state.penalty_collected} onChange={this.handleAmountChange} className="form-control" required="required"/>
                        
                      </div>
                    </div>
                    <br/>
                    </>
                    }
                
                  <div className="row">
                    <div className="col-md-7">
                      <label>Paid By</label>
                    </div>
                    <div className="col-md-5">
                      <input type="text" id="txn_exec_by"  onChange={this.handleSelfChange} className="form-control" required="required"/>
                    </div>
                  </div>
                  <br/>
                  <div className="row">
                    <div className="col-md-7">
                      <label>Remarks</label>
                    </div>
                    <div className="col-md-5">
                      <input type="text" id="remarks" onChange={this.handleSelfChange} className="form-control" required="required"/>
                    </div>
                  </div>
                  <br/>
                  <div className="row">
                        <div className="col-md-7">
                          <label>Payment Txn ID</label><font className="text-danger">   *  </font>
                        </div>
                        <div className= {`col-md-5 ${this.state.txn_id_style}`}>
                          {/* <input type="text" id="txn_id"  onChange={this.handleSelfChange} className="form-control spacing" required="required"/> */}
                          <input type="text" id="txn_id" onChange={(e) => { this.handleSelfChange(e);this.handleTxnId(e)}} className="form-control spacing" required="required"/>
                        </div>
                  </div>
                  <br/>
                  
                  <div className="row">
                    {this.state.txn_data &&
                        <>
                        <br/>
                        <div class="alert alert-success">
                          <h6>Amount : {this.state.txn_data.cr_amt} | Txn Date : {this.state.txn_data.stmt_txn_date}</h6>
                          <h6>Description : {this.state.txn_data.descr}</h6>
                          <h6></h6>
                        </div>
                        </>
                    }
                    
                    {this.state.txn_failed_msg &&
                          <div class="alert alert-danger">
                            <h6>{this.state.txn_failed_msg}</h6>
                          </div>
                    }
                  </div>
                  <br/>
                  
                </div>
              </div>  
                  <div className="row">
                    <div className="col-md-4">
                    </div>
                    <div className="col-md-8">
                      <h4><label>Total Repayment Amount: {(lbl_amt(this.state.total_amount, this.currency_code))}</label></h4>
                    </div>
                  </div>
               <div className="row">
                <div className="col-md-4">
                    <label><input type="checkbox" onChange={this.handleSMSCheckboxClick} defaultChecked/>&nbsp;
                    Send Payment confirmation notification SMS</label>
                </div>
                
              </div>

              </div>

          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>Cancel</Button>
            <input type="button" className={`btn btn-info ${this.state.capture_btn}`} disabled={this.state.calling_api} onClick={this.handleSubmit} value="Submit"/>  
          </Modal.Footer>
        </Modal>


      </div>


      );
  }
}

export default CaptureRepayment;