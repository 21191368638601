import SkeletonLoader from "../../../CommonComponent/skeletonLoader";

const FallBackUI = () => {
  return (
    <SkeletonLoader
      variant="rectangular"
      height={"52px"}
      width={"100%"}
      overRideStyles={{ borderRadius: 2, m: "25px 0px" }}
    />
  );
};

export default FallBackUI;
