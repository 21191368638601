import { useForm } from "react-hook-form";
import SearchBoxContainer from "../../../commonContainers/searchBoxContainer";
import FormProvider from "../../CommonComponent/rhfComponents/formProvider";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { RhfOptionType, RhfOptionTypeDataKeyOmited } from "../../../type";
import moment from "moment";
import {
  END_DATE_LT_START_DATE,
  END_DATE_REQUIRED,
  END_DATE_WONT_FUTURE,
  START_DATE_GT_END_DATE,
  START_DATE_REQUIRED,
  START_DATE_WONT_FUTURE,
} from "../../../consts";
import { useTheme } from "@mui/material";
import { GridPaginationModel, GridSortModel } from "@mui/x-data-grid";
import { useCallback, useEffect, useState } from "react";
import FilterFormUI from "./component/filterFormUI";
import FieldsAndTableUI, { CSVDataType } from "./component/fieldsAndTableUI";
import { useParams } from "react-router-dom";
import { call_fetch } from "../../../helpers/fetch_helper";
import { common_req, get_path } from "../../../helpers/common_helper";

export type FormDefaultProps = {
  caller: RhfOptionTypeDataKeyOmited;
  callerName: RhfOptionType;
  callDirection: RhfOptionTypeDataKeyOmited;
  callPurpose: RhfOptionTypeDataKeyOmited;
  custIdOrMblNo: string;
  individualCallLogs: boolean;
  startDate: moment.Moment;
  endDate: moment.Moment;
};

const FormSchema = Yup.object().shape({
  caller:
    Yup.mixed<RhfOptionTypeDataKeyOmited>().required("Caller is required"),
  callerName: Yup.mixed<RhfOptionType>().required("Caller Name is required"),
  callDirection: Yup.mixed<RhfOptionTypeDataKeyOmited>().required(
    "Call Direction is required"
  ),
  callPurpose: Yup.mixed<RhfOptionTypeDataKeyOmited>().required(
    "Call Purpose is required"
  ),
  custIdOrMblNo: Yup.string().default(""),
  individualCallLogs: Yup.boolean().default(false),
  startDate: Yup.mixed<moment.Moment>()
    .required(START_DATE_REQUIRED)
    .test("startDate", START_DATE_WONT_FUTURE, (val) => {
      const minDate = moment();
      return !val.isAfter(minDate, "day");
    })
    .test("startDate", START_DATE_GT_END_DATE, function (val, ctx) {
      const { endDate } = this.parent;
      if (!endDate || !val) return true;
      return !val.isAfter(endDate, "day");
    }),
  endDate: Yup.mixed<moment.Moment>()
    .required(END_DATE_REQUIRED)
    .test("endDate", END_DATE_WONT_FUTURE, (val) => {
      const minDate = moment();
      return !val.isAfter(minDate, "day");
    })
    .test("endDate", END_DATE_LT_START_DATE, function (val, ctx) {
      const { startDate } = this.parent;
      if (!startDate || !val) return true;
      return !val.isBefore(startDate, "day");
    }),
});

const CallLogsSearch = () => {
  const theme = useTheme();
  const params = useParams<{ cust_id?: string }>();

  const defaultValues = {
    caller: { id: "all", name: "ALL", actualId: "all" },
    callerName: { id: "all", name: "ALL" },
    callDirection: { id: "all", name: "ALL", actualId: "all" },
    callPurpose: { id: "all", name: "ALL", actualId: "all" },
    custIdOrMblNo: params?.cust_id ?? "",
    individualCallLogs: params?.cust_id ? true : false,
    startDate: params?.cust_id ? moment().subtract(1, "months") : moment(),
    endDate: moment(),
  };

  const defaultSort: GridSortModel = [{ field: "Date", sort: "desc" }];
  const defaultPage: GridPaginationModel = { page: 0, pageSize: 10 };

  // state
  const [callerOptions, setcallerOptions] = useState([]);
  const [sort, setsort] = useState<GridSortModel>(defaultSort);
  const [page, setpage] = useState<GridPaginationModel>(defaultPage);
  const [queryFilters, setqueryFilters] = useState(defaultValues);

  const [CSVData, setCSVData] = useState<CSVDataType>([]);

  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    watch,
    formState: { errors },
    clearErrors,
  } = methods;

  const rhfStartDate = watch("startDate");
  const rhfEndDate = watch("endDate");

  useEffect(() => {
    fetchCallLogCallersList();
  }, []);

  // helper
  const fetchCallLogCallersList = useCallback(async () => {
    // const
    const reqObj = common_req({} as any);
    await call_fetch(
      // @ts-ignore
      `${get_path("admin")}/call_log/get_call_log_role_code_priv`,
      reqObj
    ).then((r) => {
      const options = r?.data;
      const formatted = options.map((o: any) => ({
        name: o.data_value,
        id: o.data_code,
        actualId: o.id,
      }));
      setcallerOptions(formatted);
    });
  }, []);

  useEffect(() => {
    if (
      (rhfEndDate &&
        rhfStartDate &&
        Object.keys(errors).includes("startDate") &&
        errors.startDate?.message === "Start date cannot be after end date") ||
      (Object.keys(errors).includes("endDate") &&
        errors.endDate?.message === "End date cannot be before start date")
    ) {
      if (
        rhfStartDate.isBefore(rhfEndDate) ||
        rhfStartDate.isSame(rhfEndDate)
      ) {
        clearErrors(["startDate", "endDate"]);
      }
    }
  }, [rhfEndDate, rhfStartDate, clearErrors]);

  const onSubmit = handleSubmit((fromData) => {
    setqueryFilters({ ...fromData });
    if (JSON.stringify(fromData) !== JSON.stringify(queryFilters)) {
      setCSVData([]);
      setpage(defaultPage);
      setsort(defaultSort);
    }
  });

  return (
    <SearchBoxContainer title="Search Call Logs">
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <FilterFormUI
          setsort={setsort}
          defaultValues={defaultValues}
          callerOptions={callerOptions}
        />
        <FieldsAndTableUI
          sort={sort}
          page={page}
          queryFilters={queryFilters}
          setpage={setpage}
          setsort={setsort}
          CSVData={CSVData}
          setCSVData={setCSVData}
          callerOptions={callerOptions}
        />
      </FormProvider>
    </SearchBoxContainer>
  );
};

export default CallLogsSearch;
