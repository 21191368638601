import { useGetDigitalKycRecords } from "../hook/digitalKycHooks";
import IdentityVerificationProcess from "./IdentityVerificationProcess";

const IdentityVerification = () => {
  const { data } = useGetDigitalKycRecords();
  const selfRegJson = data?.identity_verification?.self_reg_json;

  if (!selfRegJson) return <></>;
  return <IdentityVerificationProcess selfRegJson={selfRegJson} />;
};

export default IdentityVerification;
