import { createContext, Dispatch } from "react";

// state
export type SoftPhoneTimerState = {
  timer: number;
};

// dispatch type
export const START_TIMER = "START_TIMER";
export const END_TIMER = "END_TIMER";

// dispatch handler
export type SoftPhoneTimerDispatchProps =
  | {
      type: typeof START_TIMER;
      payload: { timer: number };
    }
  | {
      type: typeof END_TIMER;
      payload: { timer: number };
    };

export type DispatchProps = SoftPhoneTimerDispatchProps;

type SoftPhoneTimerCtxProps = {
  softPhoneTimerState: SoftPhoneTimerState;
  dispatch: Dispatch<DispatchProps>;
  methods: {
    startTimer: (timer: number) => void;
    endTimer: (timer: number) => void;
  };
};

export const SoftPhoneTimerCtx = createContext({} as SoftPhoneTimerCtxProps);
