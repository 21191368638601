import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import '../../../styles/flow.css';
import {getAppUsers} from '../../../actions/common_actions';
import {lbl_acc_name} from '../../../helpers/common_helper';
import {getRefAccounts} from '../../../actions/account_txn_actions';
import {createAccountTxn} from '../../../actions/account_txn_actions';
import { Modal , Button} from 'react-bootstrap';
import DatePicker from 'react-datetime';
import  {check_priv} from '../../../helpers/storage_helper';

export class AccountTransaction extends FlowComponent {
	state = {
    	
    	txn_cat_name: "Ref Account",
    	ref_acc_section_style : "show_div",
    	cr_dr_style: 'show_div',
    	cr_dr:false
 	}
 	addAccTxn = (event) => {
 		
		const entity_code = this.props.entity+"_code";
		var account = {"acc_id" : this.props.account.id, "country_code": this.country_code};
		account[entity_code] = this.props.entity_id;
		if(this.props.entity == 'lender' && this.props.selected_acc_prvdr_code){
			account.network_prvdr_code = this.props.selected_acc_prvdr_code
		}
		getRefAccounts(this.req({account}))
			.then((response) => {
				if(!response){return };
				if(response.status === "success"){
			    if(response.data != "")
			    {
			       
			        	this.getSelectOptionsFromResult(response.data, "ref_accounts", ['id','name']);
			    }  		
				
				}else{
					 this.setState({
				   	        ref_acc_section_style:"hide_div",
				   	        cr_dr_style: "hide_div"
				   });	
					alert(response.message + "\n" + JSON.stringify(response.data));  
				}
			}
		);

		const request = {status: "enabled"};
		getAppUsers(this.req(request))
			.then((response) => {
				if(!response){return };
				if(response.status === "success"){
					this.getSelectOptionsFromResult(response.data.list, "app_users", ["id", "name_email"]);
				}else{
					alert(response.message + "\n" + JSON.stringify(response.data));  
				}
			}
		);
 		
		

		if(this.props.account.acc_purpose == "disbursement"){
			this.setState({modalBox: true});
			this.acc_txn.acc_txn_type = "capital_investment"
			this.acc_txn.acc_txn_category = "credit"
			event.target.id = 'acc_txn_category';
			event.target.value = 'credit';	

			this.handleCategoryChange(event, false)
		}else{
			this.setState({modalBox: true});
		}

		
	}

	handleClose = (event) => {
      this.setState({ modalBox: false });   
    }


 	handleAddTxn = (event) => {

 	this.acc_txn.acc_id = 	this.props.account.id;
 	const request = {"country_code": this.country_code, "acc_txn": this.acc_txn};
    	
    	createAccountTxn(this.req(request))
			.then((response) => {
				if(!response){return };
				if(response.status === "success"){
					window.location.reload();
				}else{
					alert(response.message + "\n" + JSON.stringify(response.data));  
				}
			}
		);
	}


	handleCheckbox = (event) => {
		var ref_acc_section_style = null;
		var cr_dr = false;
		if(event.target.checked){
			ref_acc_section_style =  "show_div";
			cr_dr = true;
		}else{
			this.ref_acc_id.value = '';
			ref_acc_section_style =  "disable_div";
			cr_dr = false;
		}
		this.setState({cr_dr,ref_acc_section_style});
	}



	handleCategoryChange = (event , reset_ref = true) => {

		var ref_acc_section_style = "disable_div";
		var cr_dr_style = 'show_div';
		var txn_cat_name = null
			if(event.target.value == "credit"){
				txn_cat_name = "Debit this A/C";
			}else if(event.target.value == "debit"){
				txn_cat_name = "Credit this A/C";
			}
			else {
				cr_dr_style = "hide_div"
				ref_acc_section_style = "hide_div"
			}
			
		this.setState({txn_cat_name, cr_dr_style, ref_acc_section_style,cr_dr:false});
		this.handleSelfChange(event);

		if(reset_ref){
			this.ref_acc_id.value = '';
		}
	}

	componentWillMount(){
		this.acc_txn = {};
		if(this.props.entity == "lender"){
			this.txn_type = [{'id' : 'capital_investment', 'name' : 'Capital Investment'},
							 {'id' : 'repayment_internal_transfer', 'name' : 'Repayment Internal Transfer'},
							 {'id' : 'others' , 'name' : 'Others'}];
							 this.getSelectOptionsFromResult(this.txn_type, "acc_txn_type", ['id','name']);
		}

		if(check_priv(this.props.entity , 'add_acc_txn')){
          this.add_txn_btn_style = "show_div"
      	}else{
          this.add_txn_btn_style = "disable_div"
      	}
		
	}

	

	render(){
		return(
			<>
			<input type="button" value="Add Txn" onClick={this.addAccTxn} className={`btn btn-primary ${this.add_txn_btn_style}`} />

			<Modal show={this.state.modalBox} onHide={this.handleClose} size="xl">
				<Modal.Header closeButton show={this.state.modalBox} onHide={this.handleClose}>
					<Modal.Title><h3>Add Transaction for {lbl_acc_name(this.props.account)}</h3></Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<div className="container date-range">
					<div className="row">
						<div className="col-md-3">
							<span>Transaction Category</span><font className="text-danger">   *  </font>
						</div>
						<div className="col-md-3">
							<select id="acc_txn_category" value={this.acc_txn.acc_txn_category} onChange={this.handleCategoryChange} className="form-control" type="text" required>
			                    <option value="">choose</option>
			                    <option value="credit">Credit</option>
			                    <option value="debit">Debit</option>
			                    <option value="balance">Opening Balance</option>							
							</select>
						</div>
						<div className={`col-md-3 ${this.state.cr_dr_style}`}>
							<span><input id="cr_dr"  checked={this.state.cr_dr} type="checkbox" onChange={this.handleCheckbox}></input> 		{this.state.txn_cat_name}</span>
						</div>


						<div className={`col-md-3 ${this.state.ref_acc_section_style}`}>

							<select id="ref_acc_id" defaultValue={this.selected.ref_accounts} onChange={this.handleSelfChange}  ref={(ref) => {this.ref_acc_id = ref}} className="form-control" required="required" >
                    			{this.state.ref_accounts_dd}                               
                  			</select>
						</div>
						
					</div><br/>
					<div className="row">
						<div className="col-md-3">
							<span>Transaction Type</span><font className="text-danger">   *  </font>
						</div>
						<div className="col-md-3">
							<select id="acc_txn_type" defaultValue={this.acc_txn.acc_txn_type} onChange={this.handleSelfChange} className="form-control" required="required" >
                    			{this.state.acc_txn_type_dd}                               
                  			</select>
						</div>
						<div className="col-md-3">
							<span>Amount ( {this.currency_code} )</span><font className="text-danger">   *  </font>
						</div>
						<div className="col-md-3">
							<input id="amount" type="text" className="form-control" onChange={this.handleSelfChange} required />
						</div>
						
					</div><br/>
					<div className="row">
						<div className="col-md-3">
							<span>Transaction Mode</span><font className="text-danger">   *  </font>
						</div>
						<div className="col-md-3">
							<select id="txn_mode" onChange={this.handleSelfChange} className="form-control" required="required" >
                    			{this.getSelectOptions('transaction_mode')}                               
                  			</select>
						</div>
						<div className="col-md-3">
							<span>Transaction Date</span><font className="text-danger">   *  </font>
						</div>
						<div className="col-md-3">
	                           <DatePicker closeOnSelect={true} onChange={(value) => {this.handleSelfDateChange("txn_date", value)}}  dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>
	                    </div>
						
						
						
					</div><br/>
					<div className="row">

						<div className="col-md-3">
							<span>Transaction ID</span>
						</div>
						<div className="col-md-3">
							<input id="txn_id" type="text" className="form-control spacing" onChange={this.handleSelfChange}/>
						</div>
						
						<div className="col-md-3">
							<span>Txn Executed By</span><font className="text-danger">   *  </font>
						</div>
						<div className="col-md-3">
							<select id="txn_exec_by" onChange={this.handleSelfChange} className="form-control" type="text" required="required">
                      			{this.state.app_users_dd} 
                      		</select>
						</div>
						
					</div><br/>
					<div className="row">
						<div className="col-md-3">
							<span>Remarks</span>
						</div>
						<div className="col-md-3">
							<input type="text" id="remarks" onChange={this.handleSelfChange} className="form-control" required="required"/>
						</div>
					</div>
				</div>
					
				</Modal.Body>

				<Modal.Footer>
					<Button variant="primary" onClick={this.handleAddTxn}>Add</Button>
					<Button variant="secondary" onClick={this.handleClose}>Close</Button>
				</Modal.Footer>
			</Modal>
			</>
			
				
			);
	}

}

export default AccountTransaction;