import { useState, useEffect } from "react";
import { Homeacc_prvdrLogoselector, getSelectOptions, req } from "../../helpers/common_helper";
import DatePicker from 'react-datetime';
import { Form, Button, Card, Modal } from "react-bootstrap";
import { BiCalendar} from "react-icons/bi";
import { BasicReactTable } from "../../helpers/react_table";
import { Config, check_priv, get } from "../../helpers/storage_helper";
import { dd_value } from "../../actions/common_actions";
import { Link } from "react-router-dom";
import { lbl_amt } from "../../helpers/product_helper";
import { BsUpload } from "react-icons/bs";
import { Biz_operation_live_reports, uploadDupFAProof } from "../../actions/report_actions";
import { useDispatch, useSelector } from "react-redux";
import Softphone from "../cs_management/container/cs_soft_phone";
import moment from "moment";
import CallLog from "../call_log/component/CallLog";
import { StartCallLog } from "../../actions/call_log_actions";
import { setValuesIntoRedux } from "../../Redux/Slices/CsSoftphoneSlice";
import { DEF_OPT } from "../../constants";
import { useSoftPhoneCtx } from "../../context/softPhoneCtx/useSoftPhoneCtx";

const DuplicateTransactions = () => {

    const show_extra_fields = check_priv("dup_txn_report", "extra_fields");
    const call_action = check_priv("dup_txn_report", "call_action");
    const market = get('market')

    const filterLabels = [
        {key:"status", label:"Status", type:"duplicate_fa_management_status"},
        {key:"txn_type", label:"Txn Type", type:"duplicate_fa_management_txn_status"},
        {key:"start_date", label:"Start Date", type:"date"},
        {key:"end_date", label:"End Date", type:"date"},
    ]

    const columnValues = [
        {key:"to_acc", label:"A/C Number", show:true},
        {key:"cust_id", label:"Customer ID", show:true},
        {key:"entity_id", label: market.country_code == 'UGA' ? "Loan Doc ID / Switch ID" : 'Loan Doc ID', show:true},
        {key:"type", label:"Txn Type", show:true},
        {key:"txn_date", label:"Txn Date", show:true},
        {key:"transferred_amt", label:"Transferred Amt", show:true},
        {key:"partially_paid", label:"Paid Amount", show:show_extra_fields},
        {key:"amt_to_recover", label:"Amt to Recover", show:show_extra_fields},
        {key:"status_value", label:"Status", show:show_extra_fields},
        {key:"action", label:"Action", show:call_action},

    ]

    const [columns, setColumns] = useState([]);

    const [rowData, setRowData] = useState([]);
    
    const [inputValues, setInputValues] = useState({})

    const [loader, setLoader] = useState(false)
    const [showUploadProof, setShowuploadProof] = useState(false);
    const [paymentDetails, setPaymentDetails] = useState({});
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
    const [file, setFile] = useState(null);
    const [uploadDetails, setUploadDeatails] = useState(null)
    const dispatch = useDispatch();
    const datas = useSelector((state) => state.cs_soft_phone)
    const [callPropsData, setcallPropsData] = useState(null)
    const [callLogData, setCallLogData] = useState(null)
    const [openModel, setOpenmodel] = useState(false)
    const [disableSession1, setDisableSession1] = useState("");
    const [disableSession2, setDisableSession2] = useState("disable_div");
    const { methods: softPhoneMethods } = useSoftPhoneCtx();

    useEffect(()=>{
        getColumnData();
        // getData()
        dispatch(setValuesIntoRedux({screen_from:"dup_txn_report"}))
    }, [call_action])

    useEffect(() => {
        let session1 = "";
        let session2 = "";
        Object.keys(inputValues).map((key) => {
            if(key !== "search_by" && inputValues[key]){
                session2 = "disable_div";
            }else if(key === "search_by" && inputValues[key]){
                session1 = "disable_div";
            }
        })
        setDisableSession1(session1);
        setDisableSession2(session2);
    }, [inputValues])

    const getInputValues = (value, key, type = null) => {
        
        if(type == "paymentProof"){
            setUploadDeatails({...uploadDetails, [key]:value})
        }else{
            setInputValues({...inputValues, [key]:value})
        }
    }

    const uploadProof = (val, data = null) => {
        setShowuploadProof(val)
        if(data){
            setPaymentDetails(data)
        }
        if(val == false){
            setUploadDeatails(null)
            setImagePreviewUrl(null)
        }
    }

    const handleImageClick = () => {
        document.getElementById('upload_file').click();
    }

    const onfileChange = (event) => {
        const file = event.target.files[0];
        if(file){
            setFile(file);
        
            const reader = new FileReader();
            reader.onloadend = () => {
            setImagePreviewUrl(reader.result);
            let imagedata = {
                file_type: file['type'],
                file_name: file['name'],
                file_data_type: "data-url",
                file_data:reader.result,
                file_of:"dup_payment_reversal_proof"
            }
            setUploadDeatails({...uploadDetails, ...imagedata})

            };
            reader.readAsDataURL(file);
        }
        
    }

    const getData = () => {
        setLoader(true)
        let data = {report_type:"get_dup_txn_report", exclude_recovered : call_action}
        if(inputValues){
            data = {...data, ...inputValues}
        }
        Biz_operation_live_reports(req(data))
        .then((response)=>{
            setLoader(false)
			if(!response){return };
			if(response.status === "success"){
				setRowData(response.data)
			}
		});
    }

    const call_handler = (row) => {
        
        // const  softphone_is_supported =  datas.duplicate_txn_data && datas.is_softphone_supported
        const softphone_is_supported = datas.is_softphone_supported

        if(softphone_is_supported){
            // row = {...row, cust_mobile_num :row.mobile_num, duplicate_txn_data:true}
            // dispatch(setValuesIntoRedux({cust_id : row.cust_id, outgoing_call_data : row, duplicate_txn_data : row}))

            softPhoneMethods.initiateCall({
                phoneNumber: row.mobile_num ?? "",
                screen: "dup_txn_report",
                churnOrDeliquencyId: "",
                taskId: "",
              });
      
      
        }else{
            setcallPropsData(row)
            handleCallLog(row.cust_id)
        }
        

    }



    const handleCallLog = (cust_id) =>{
            let target = "outgoing"
            StartCallLog(req({cust_id: cust_id, call_type: target}))
                .then((response)=> {
                    if(!response){return };
                    if(response.status === "success"){
                        const  log = response.data
                        var resp = true;
                        if(log.action == "lastcall"){
                            resp = window.confirm(log.message);
                        }
                        if(resp && log.call_log_id){
                            setOpenmodel(true)
                            setCallLogData({cust_id:log.cust_id, call_log_id:log.call_log_id})
                        }
                        }else{
                            alert(response.message  +"\n" + JSON.stringify(response.data));  
                        }
            })
  }


    const getColumnData = () => {
        let filter_folumn = columnValues.filter((val, i) => val.show == true)
        let columns = filter_folumn.map((val, i)=>{return{
            Header:<p className="text-center">{val.label}</p>,
            id:val.key,
            minWidth:(val.key == 'action' || val.key == 'entity_id') ? 200 :"",
            accessor:row => <div>
                                {val.key == 'from_acc' ? 
                                 <div className='d-flex align-items-center'>  <Homeacc_prvdrLogoselector logotype={ row.acc_prvdr_code}/> <p className='text-white m-0 pl-2'>{row[val.key]}</p></div>
                                :
                                val.key == "entity_id" ? 
                                <p className="text-center">{row.type == "float_advance" ? <Link to={"/fa/view/"+row.loan_doc_id} target="_blank" className="on_going">{row.loan_doc_id}</Link> : row.sales_doc_id}</p>
                                :

                                val.key == "action" ? 
                                <div className='d-flex align-items-center justify-content-center'>  
                                    <Button variant="primary" onClick={()=>call_handler(row)}>Log Call</Button>
                                    <Button variant="primary" className="ml-2" onClick={()=>uploadProof(true, row)}>Upload Proof</Button>
                                </div>
                                :
                                val.key == 'cust_id' ? 
                                <div className="text-center"> <Link to={"/borrower/indiv/view/"+row.cust_id} target="_blank" className="flow_custid" >{row.cust_id}</Link>  </div>
                                :
                                val.key == "txn_date" ?
                                <p className="text-center">{moment(row.txn_date).format("DD MMM YYYY")}</p>
                                :
                                (val.key == "transferred_amt" || val.key == "amt_to_recover" || val.key == "partially_paid") ? 
                                <p className="text-center">{lbl_amt(row[val.key] ? row[val.key] : 0, market.currency_code)}</p>
                                :
                                <p className="text-center">{val.key == "type" ? dd_value(row.type, 'any') : row[val.key] } </p>
                                }
                            </div>
        }})

        setColumns(columns)
    }

    const handelUploadProofSubmit = () => {
        let entity_id = paymentDetails.type == "float_advance" ? "loan_doc_id" : "sales_doc_id"
        let data = {cust_id : paymentDetails.cust_id, type:paymentDetails.type, entity_code:paymentDetails[entity_id], [entity_id]:paymentDetails[entity_id]}
        if(uploadDetails){
            data = {...data, ...uploadDetails}
        }
        uploadDupFAProof(req(data))
        .then((response)=>{
            setLoader(false)
			if(!response){return };
			if(response.status === "success"){
                alert("Payment uploaded successfully.")
                uploadProof(false)
                getData()
			}
		});
        
    }

    const handleModal = () => {
        setOpenmodel(false)
        getData()
    }

    const resetAll = () => {
        setInputValues({})
        setDisableSession1("");
        setDisableSession2("");
    }

    const callModel = () => {
        return(
            <Modal dialogClassName= "rec-form-modal" show={openModel} onHide={()=>handleModal()}>
                <Modal.Body closeButton>
					<CallLog is_duplicate match={{params: {id: callLogData.call_log_id, cust_id: callLogData.cust_id}}} callPropsData = {callPropsData} call_type = {"outgoing"} not_redirect ={true} onCancel={()=>handleModal()}/>
				</Modal.Body>
		    </Modal>
        )
    }

    const getDropDownOptions = (data_key) => {
        if(data_key == 'duplicate_fa_management_status' && call_action){
            let data = getSelectOptions(data_key, true, null, true)
            let filtered_data = data.filter(val => val.data_code != 'recovered' && val.status == 'enabled')
                                    .map((item, index) => (
                                        <option key={index} value={item.data_code}>{item.data_value}</option>
                                    ));
            return [...DEF_OPT, ...filtered_data];
        }else{
            return getSelectOptions(data_key, true, null, null, true)
        }
    }
 
    const updateProofModal = () => {
        return(
            <Modal show={showUploadProof}>
              <Modal.Header closeButton show={showUploadProof} onHide={()=>uploadProof(false)} style={{boxShadow:"0px 4px 4px 0px rgba(0, 0, 0, 0.25)"}} className="pb-3">
                  <Modal.Title>Upload Payment Proof</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row mt-3">
                    <div className="col-6" >
                        <span className="dup_txn_text">No of Duplicate FAs <b className="ml-2">{paymentDetails.dup_fa_count ? paymentDetails.dup_fa_count : 0}</b></span>
                    </div> 
                    {paymentDetails.dup_switch_count && 
                        <div className="col-6" style={{borderLeft:"1px solid #FFFFFF"}}>
                            <span className="dup_txn_text">No of Duplicate Switches <b className="ml-2">{paymentDetails.dup_switch_count ? paymentDetails.dup_switch_count : 0}</b></span>
                        </div>
                    }
                </div>
                <div className="mt-4">
                    <span className="dup_txn_text">Amount to recover <b className="ml-2">{lbl_amt(paymentDetails.amt_to_recover ? paymentDetails.amt_to_recover : 0, market.currency_code)}</b></span>
                </div>
                
                <div className="mt-4">
                    <label>Enter Txn ID<span style={{color:"red"}} className="ml-1">*</span></label>
                    <Form.Control id="txn_id" autoComplete='off' onChange={(e) => getInputValues(e.target.value,'txn_id', "paymentProof")} />
                </div>
                <div className="centered-content mt-4">
                    <div style={{width:"60%", height:"150px", background:"white", borderRadius:"12px"}} onClick={() => handleImageClick()}>
                        {imagePreviewUrl ? 
                        <img src={imagePreviewUrl} alt="Selected"  style={{width:"100%", height:"100%", borderRadius:"12px"}}/> : 
                        <div style={{width:"100%", height:"100%"}}>
                            <div style={{height:"50%"}} className="centered-content"><BsUpload style={{width:"30px", height:"30px", color:"gray"}}/></div>
                            <div style={{height:"50%"}} className="d-flex justify-content-center"><p style={{color:"gray"}}>Upload the proof of the payment</p></div>
                        </div>}
                        <input type="file" id="upload_file" style={{display:"none"}} onChange={(e)=>onfileChange(e)}/>
                    </div>
                    
                </div>
               

                <div className="form-group row reset-margin justify-content-center py-4">
                    <Button className= "col-md-2 mr-3 btn btn-primary" onClick={()=>handelUploadProofSubmit()} disabled={uploadDetails && uploadDetails.txn_id && uploadDetails.file_name ? false : true}>Submit</Button>
                </div>
                
              </Modal.Body>
          </Modal>
        )
    }

    return (
       <div className="container containerTopmargin max-width no-padding">
            <div className="mx-3">
                {callLogData && callModel()}
                {updateProofModal()}
                <Card>
                    <Card.Header style={{color:"#FFFFFF", background:"#3D3E53", fontSize:"28px"}} className="px-4">Duplicate Transactions</Card.Header>
                    <Card.Body className="ml-3">
                        <div>
                            <div className="row">
                                {filterLabels.map((val, i) =>(
                                    (val.key == "status" && !show_extra_fields) ? null :
                                    <div className={`col-md-6 col-sm-12 col-lg-3 my-3 ${disableSession1}`} key={i}>
                                        <div style={{width:"93%"}}>
                                            <fieldset className='h-100 py-0 px-3 new_re_kyc_text_bg_color' style={{ border: '1px solid white', background: "#202940", borderRadius: '10px' }}>
                                                <legend className={`add_watchlist_text float-none `}>
                                                    <b> {val.label} </b>
                                                </legend>
                                                {val.type == 'date' ? 
                                                <div style={{position:"relative", display: 'inline-block', width:"100%" }}>
                                                    <DatePicker placeholderText="select" closeOnSelect={true} onChange={(value) => getInputValues( moment(value).format('YYYY-MM-DD'), val.key)} inputProps={{id:val.key, placeholder:"select", value:inputValues[val.key] ? moment(inputValues[val.key]).format('DD MMM YYYY') : ""}} dateFormat="DD MMM YYYY" timeFormat={false} required="required"/>
                                                    <label className='col-1.5 no-margin d-flex p-0 ' style={{ position: 'absolute', right:"0px", top:"8px" }} htmlFor={val.key}>
                                                        <p className='m-0 pr-1'><BiCalendar size={20} /></p>
                                                    </label>
                                                </div>
                                                :
                                                <select value={inputValues?.[val.key] ?? ""} onChange={(e) => getInputValues(e.target.value, val.key)} className="form-control  w-100 col-12 px-0">
                                                    {getDropDownOptions(val.type)}
                                                </select>
                                                }
                                            </fieldset>
                                        </div>
                                    </div>
                                ))}
                                <div className="col-12 text-center">
                                    <h3 className="font-weight-bolder no-margin p-3">OR</h3>
                                </div>
                                <div className={`col-md-6 col-sm-12 col-lg-6 mb-3 ${disableSession2}`}>
                                    <div style={{width:"93%"}}>
                                        <fieldset className='h-100 py-0 px-3 new_re_kyc_text_bg_color' style={{ border: '1px solid white', background: "#202940", borderRadius: '10px' }}>
                                            <legend className={`add_watchlist_text float-none `}>
                                                <b>Search By</b>
                                            </legend>
                                            <Form.Group className="pb-0">
                                                <Form.Control id="search_by" value={inputValues.search_by ?? ''} placeholder={`Customer ID, Account Number, Loan Doc ID${market.country_code == 'UGA' ? ', Switch ID' : ''}`} autoComplete='off' onChange={(e) => getInputValues(e.target.value,"search_by")}>
                                                </Form.Control>
                                            </Form.Group>
                                        </fieldset>
                                    </div>
                                </div>
                            </div>
                            <div className="row no-margin">
                                <div className="col-12 text-center" style={{paddingBottom:15,paddingTop:5}}>						
                                    <Button type="reset" variant="secondary" onClick={resetAll}>Reset</Button>	&nbsp;
                                    <Button id="searchbtn" varient="info" className={`btn-primary`} onClick={()=>getData()}>Submit</Button> &nbsp;										
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                {rowData.length > 0 ? 
                <div className="mt-5" style={{display:!loader ?'':'none'}}>	
                    <p className='no-margin text-white mb-2'><b>Total Entries : {rowData.length}</b></p>
                    <div>
                        <div>
                            <BasicReactTable pagination={rowData && rowData.length > 9 ? true : false} row_data={rowData ? rowData : []} column_data={columns} default_page_size={10}/> 
                        </div>
                    </div>					
                     				
                </div>
                :
                !loader && 
                <p className='text-center m-0 text-white my-2'>NO DATA</p>
                }

                <div style={{display:loader ?'':'none'}}>
                    <div style={{marginTop:"15%"}} > <p className='text-light mt-3 text-center'>Loading...</p>
                        <div className="product_takeup_loader"></div>
					</div>
                </div>
                {/* {datas.duplicate_txn_data && 
                <Softphone screen_from ={"dup_txn_report"} is_direct_outgoing = {true} cust_id={datas.duplicate_txn_data.cust_id ?datas.duplicate_txn_data.cust_id:null} outgoing_call_data = {datas.duplicate_txn_data}/>
                } */}
                
            </div>
       </div>
    )
}

export default DuplicateTransactions;