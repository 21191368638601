import React, { ReactNode } from "react";
import { Dialog, DialogContent, Typography, Button, Box } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface SuccessModalProps {
  open: boolean;
  onClose: () => void;
  body :ReactNode;
}

const ConfirmationModal: React.FC<SuccessModalProps> = ({ open, onClose,body }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogContent
        sx={{
          textAlign: "center",
          backgroundColor: "#0B1437",
          color: "white",
          padding: "40px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          
        }}
      >
       
       <Box>
        {body}
       </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationModal;
