import { Box, Button, Dialog, Grid, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  HeaderVarientH3Typo,
  HeaderVarientH5Typo,
} from "../../CommonComponent/typography";
import CampaignIcon from "@mui/icons-material/Campaign";
import FormProvider from "../../CommonComponent/rhfComponents/formProvider";
import { useForm } from "react-hook-form";
import RHFCheckBoxField from "../../CommonComponent/rhfComponents/rhfCheckBoxField";
import { useNavigate, useParams } from "react-router-dom";
import RHFTextField from "../../CommonComponent/rhfComponents/rhfTextField";
import { MuiOtpInput } from "mui-one-time-password-input";
import { input_otp } from "../../../styles/common_styles";
import VerifiedIcon from "@mui/icons-material/Verified";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ConfirmationModal from "../../CommonComponent/ConfirmationDialog";
import Pagination from "./digitalKycpagination";
import { useGetDigitalKycRecords } from "../hook/digitalKycHooks";
import { common_req } from "../../../helpers/helper";
import { digitalKycOtpVerification } from "../../../actions/lead_actions";
import { string } from "yup";
import WarningIcon from "@mui/icons-material/Warning";
import CustomSnackbar from "../../../MUI/Components/CustomSnackbar";
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
type LeadStatus = "audit" | "onboard";

const Agreement = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { data } = useGetDigitalKycRecords();
  const sales_pitch_pointers = data?.agreement?.text ?? [];

  const defaultValues = {
    is_interested: null,
    not_interested: null,
    not_interested_reason: null,
  };
  const methods = useForm({
    defaultValues,
  });
  const { watch } = methods;
  const [isOtpSend, setisOtpSend] = useState(false);
  const [user_otp, setOtp] = useState("");
  const [timeLeft, setTimeLeft] = useState<number>(data?.otp_expiry ?? 40);
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [agentOnboarded, setagentOnboarded] = useState<boolean>(false);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [showSnackbar, setshowSnackbar] = useState(false);

  const sendOtp = (callType?: string) => {
    if (callType == "resendOtp") {
      setOtp("");
    }
    setLoading(true);
    let request = { lead_id: id, stage: "agreement" };
    digitalKycOtpVerification(common_req(request)).then((response) => {
      if (response?.status == "success") {
        setshowSnackbar(true);
        if (response?.data?.otp_code) {
          setOtp(response.data.otp_code);
        }
        if (callType == "resendOtp") {
          setIsRunning(true);
          setTimeLeft(data?.otp_expiry ?? 40);
        } else {
          setisOtpSend(true);
          setIsRunning(true);
        }
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isRunning && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsRunning(false);
    }

    return () => clearInterval(timer);
  }, [isRunning, timeLeft]);

  const handleChange = (newValue: any) => {
    setOtp(newValue);
  };

  const resendOTP = () => {
    sendOtp("resendOtp");
  };

  const dialogJson = {
    onboard: {
      icon: <VerifiedIcon sx={{ fontSize: "80px", color: "#199d6c" }} />,
      msg: "Agent Onboarded Successfully",
    },
    audit: {
      icon: <WarningIcon sx={{ fontSize: "80px", color: "#FFAD42" }} />,
      msg: "Submitted for audit",
    },
  };

  const leadStatus: LeadStatus = data?.agreement?.icon_key as LeadStatus;
  useEffect(() => {
    if (
      !agentOnboarded &&
      leadStatus != null &&
      data?.agreement?.status != "verified"
    ) {
      setagentOnboarded(true);
    }
  }, [leadStatus]);

  const className = data?.agreement?.status == "verified" ? "disable_div" : "";

  return (
    <FormProvider methods={methods}>
      <Box width="60%" sx={{ margin: "0 auto" }}>
        <Box sx={{ background: "#011137", borderRadius: "20px", p: 3, my: 7 }}>
          <Box display={"flex"} alignItems={"center"}>
            <DescriptionRoundedIcon
              sx={{ color: theme.palette.text.primary, fontSize: 25, mx: 1 }}
            />
            <HeaderVarientH3Typo
              text="FA Terms & Conditions"
              overRideStyles={{
                fontWeight: 600,
                color: theme.palette.text.primary,
              }}
            />
          </Box>

          <Grid container spacing={4} justifyContent="start" mt={2}>
            {sales_pitch_pointers?.map((item, idx) => (
              <Grid
                item
                key={idx}
                sx={{ pt: "5px !important" }}
                lg={12}
                sm={12}
              >
                <Typography
                  variant="caption"
                  color="grey.400"
                  sx={{ fontSize: "0.8rem" }}
                >
                  {item}
                </Typography>
              </Grid>
            ))}
          </Grid>

          <Typography
            sx={{ color: theme.palette.text.primary, fontWeight: 500, my: 2 }}
            variant="body2"
          >
            OTP Verification
          </Typography>

          <Typography
            variant="caption"
            color="grey.400"
            sx={{ fontSize: "0.8rem" }}
          >
            Send OTP to the agent line number <b>{data?.cust_info?.account}</b>,
            to confirm the agreement
          </Typography>

          {!isOtpSend && (
            <Button
              className={className}
              variant="contained"
              color="secondary"
              onClick={() => (loading ? null : sendOtp())}
              sx={{ textTransform: "none", mx: 2 }}
            >
              {loading ? "Sending... " : "Send OTP"}
            </Button>
          )}

          {isOtpSend && (
            <Box sx={{ width: "50%", my: 2 }}>
              <MuiOtpInput
                sx={input_otp(theme)}
                typeof="number"
                value={user_otp}
                onChange={handleChange}
                className={`default_outlined_variant d-flex justify-content-start digital_kyc ${className}`}
              />
            </Box>
          )}
          {isOtpSend && !agentOnboarded && (
            <>
              {timeLeft !== 0 && (
                <Typography color={"white"} sx={{ fontSize: "0.7rem" }}>
                  00:{timeLeft}
                </Typography>
              )}

              {timeLeft == 0 && (
                <Typography
                  className={className}
                  color={theme.palette.secondary.main}
                  sx={{
                    fontSize: "0.8rem",
                    cursor: "pointer",
                    mt: 1,
                    fontWeight: 800,
                  }}
                  onClick={resendOTP}
                >
                  Resend OTP
                </Typography>
              )}
            </>
          )}
        </Box>
      </Box>

      {agentOnboarded && (
        <ConfirmationModal
          open={agentOnboarded}
          onClose={() => setagentOnboarded(false)}
          body={
            <Box className="zoomIn_animation">
              {dialogJson[leadStatus]?.icon}

              <Typography variant="h5" fontWeight="bold" mt={1} mb={3}>
                {dialogJson?.[leadStatus]?.msg}
              </Typography>

              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  navigate("/lead/lead_pipeline");
                  setagentOnboarded(false);
                }}
              >
                Done
              </Button>
            </Box>
          }
        />
      )}

      {showSnackbar && <CustomSnackbar message={"OTP sent successfully."} />}

      <Pagination
        isDisabled={user_otp.length == 4}
        request={{ otp: user_otp, lead_id: id, stage: "agreement" }}
      />
    </FormProvider>
  );
};

export default Agreement;
