import { useQuery } from "@tanstack/react-query";
import { common_req, get_path } from "../../../../helpers/common_helper";
import { call_fetch } from "../../../../helpers/fetch_helper";

type OrientationListProps = Record<
  string,
  { title: string; content: string[] }
>;

/**
 *
 * @returns the inquiry_list which are kept in the server
 */
const useGetInquiryList = ({ leadId }: { leadId?: string }) => {
  // const
  const reqObj = common_req({} as any);
  delete reqObj.access_token;

  // query
  const {
    data: inquiryList,
    isError,
    isLoading,
  } = useQuery<OrientationListProps>({
    queryKey: ["inquiry_list"],
    queryFn: () =>
      call_fetch(
        // @ts-ignore
        `${get_path("app")}/task/get_cust_orentation_text`,
        { ...reqObj, lead_id: leadId }
      ),
    staleTime: 1000 * 60 * 60 * 24,
    enabled: leadId ? true : false,
    refetchOnWindowFocus: false,
  });
  return { inquiryList, isError, isLoading };
};

export default useGetInquiryList;
