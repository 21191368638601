
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  useTheme,
} from "@mui/material";
import type { PDFDocumentProxy } from "pdfjs-dist";
import { useMemo, useState } from "react";
import { Document, Page } from "react-pdf";
import { ExtendedFile } from "../hooks/digitalKyc/rhfFileUploadField";
const RhfFilePreview = ({
  setselectedFileForPreview,
  showModal,
  selectedFileForPreview,
  getFileUrlForPreview,
}: {
  setselectedFileForPreview: Function;
  showModal: boolean;
  selectedFileForPreview: ExtendedFile | undefined;
  getFileUrlForPreview: Function;
}) => {
  // state
  const [numPages, setnumPages] = useState(0);
  const file = useMemo(() => selectedFileForPreview, [selectedFileForPreview]);
  function onDocumentLoadSuccess({
    numPages: nextNumPages,
  }: PDFDocumentProxy): void {
    setnumPages(nextNumPages);
  }
  // hook
  const theme = useTheme();
  return (
    <Dialog
      open={showModal}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.primary.main,
          borderRadius: "20px",
          minWidth: "700px",
        },
      }}
      onClose={() => setselectedFileForPreview(null)}
    >
      <IconButton
        aria-label="close"
        onClick={() => setselectedFileForPreview(null)}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon sx={{ width: "22px", height: "22px" }} />
      </IconButton>
      <DialogTitle sx={{ color: "#fff", textAlign: "center" }}>
        {"File preview"}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: "600px", margin: "auto" }}>
          {(file?.type === "image/png" ||
            file?.type === "image/jpeg" ||
            file?.type === "image/jpg") && (
            <img
              style={{ width: "100%" }}
              src={getFileUrlForPreview(file, "Image", "l")}
              alt="preview-image"
            />
          )}
          {file?.type === "application/pdf" && (
            <>
            
            <Document
              file={getFileUrlForPreview(file, "Other", "")}
              onSourceError={(e) => alert("Unable to load the pdf file")}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {Array.from(new Array(numPages), (_el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  pageIndex={index}
                />
              ))}
            </Document>
            </>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default RhfFilePreview;