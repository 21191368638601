import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton } from "@mui/material";
import { useSoftPhoneAndFreshChatCtx } from "../../../context/softPhoneAndFreshChatModalUICtx/useSoftPhoneAndFreshChatCtx";
import FloatModalUI from "./floatModalUI";

const PhoneNumbersOrMissedCallModalUI = () => {
  // const
  const iconStyle = { width: "25px", height: "25px", color: "#fff" };

  //ctx
  const {
    softPhoneAndFreshChatFloatUIState: { floatModalFor },
    methods: { toggleFloatModalFor, toggleFloatBtnUI },
  } = useSoftPhoneAndFreshChatCtx();

  if (floatModalFor === "") return <></>;

  return (
    <div className="Call_log_section zoomIn_animation">
      <div className={"call_log_modal_size over_view_z_index"}>
        <div className="d-flex justify-content-end">
          <Box
            sx={{
              backgroundColor: "#4f5167",
              mb: 1,
              borderRadius: 2,
              padding: "2px 5px",
            }}
          >
            <IconButton
              onClick={() => {
                toggleFloatModalFor({ floatModalFor: "" });
                toggleFloatBtnUI({ showFloatBtnsOptions: false });
              }}
            >
              <CloseIcon sx={iconStyle} />
            </IconButton>
          </Box>
        </div>
        <div className={"call_log_from_screen pb-3  call_chat_height"}>
          <FloatModalUI />
        </div>
      </div>
    </div>
  );
};

export default PhoneNumbersOrMissedCallModalUI;
