import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { AT_TOKEN, SOFTPHONE_ROUTE } from "../../consts";
import { get, set } from "../../helpers/storage_helper";
import SoftPhoneCtxProvider from "./softPhoneCtxProvider";
// @ts-ignore
import Africastalking from "africastalking-client";
import {
  createClient,
  getWebrctConfigs,
} from "../../actions/cs_management_actions";
import { checkUserHasCallPriv } from "../../helpers/helper";
import { HeaderVarientH2Typo } from "../../UI/CommonComponent/typography";
import SoftPhoneAndFreshChatContainer from "../../UI/softPhone/containers/softPhoneAndFreshChatContainer";
import SoftPhoneAndFreshChatFloatUICtxProvider from "../softPhoneAndFreshChatModalUICtx/softPhoneAndFreshChatFloatUICtxProvider";

/**
 *
 * @param children
 * @returns the children by wrapping it with the SoftPhoneCtxProvider
 * the at instance is created in this provider
 * if softfone route and the at token is not present in the storage api then only the instance will be created
 * while fetching for the token an loading or error UI will be shown
 */
const SoftPhoneInstanceProvider = ({ children }: { children: ReactNode }) => {
  // hook
  const { pathname } = useLocation();

  // const
  const atToken = get(AT_TOKEN);
  const canGetAtToken = pathname === SOFTPHONE_ROUTE && !atToken;
  const canCreateAtInstance = pathname === SOFTPHONE_ROUTE;
  const hasCallPriv = checkUserHasCallPriv();

  // bc
  const broadCastChannelForDocTitleChange = useMemo(
    () => new BroadcastChannel("Soft_Phone_Doc_Title_Change"),
    []
  );

  // ref
  const atClient: any = useRef(null);
  const params = useMemo(
    () => ({
      sounds: {
        dialing: "/sounds/dial.mp3",
        ringing: "/sounds/ring.mp3",
      },
    }),
    []
  );

  // state
  const [atTokenInState, setatTokenInState] = useState(atToken);
  const [atTokenGetStatus, setatTokenGetStatus] = useState("INITIAL");
  const [instanceId, setinstanceId] = useState<null | number>(null);

  // effect
  useEffect(() => {
    // at instance will be created only for the cs_management/soft_phone page
    if (canCreateAtInstance && atTokenInState && hasCallPriv) {
      createATInstance(atTokenInState);
    }
    if (canGetAtToken && hasCallPriv) {
      getAtTokenCb();
    }
  }, [canGetAtToken, canCreateAtInstance, atTokenInState, hasCallPriv]);

  // cb
  const getAtTokenCb = useCallback(() => {
    setatTokenGetStatus("FETCHING");
    getWebrctConfigs().then((res: any) => {
      if (res.status === "success") {
        createClient(res.data).then((createClientRes: any) => {
          set(AT_TOKEN, createClientRes.token);
          setatTokenInState(createClientRes.token);
          setatTokenGetStatus("FETCHED");
        });
      }
    });
  }, []);

  const createATInstance = useCallback(
    (atToken: string) => {
      atClient.current = new Africastalking.Client(atToken, params);
      setinstanceId(Math.random());
      broadCastChannelForDocTitleChange.postMessage({
        type: "NewATInstanceCreated",
      });
    },
    [params]
  );

  const isErrorWhileFetchAtToken =
    atTokenGetStatus === "FETCHED" && !atTokenInState;

  if (atTokenGetStatus === "FETCHING")
    return (
      <HeaderVarientH2Typo
        overRideStyles={{ textAlign: "center", color: "#fff", mt: 12 }}
        text="Fetching AT Token please wait..."
      />
    );
  else if (isErrorWhileFetchAtToken)
    return (
      <HeaderVarientH2Typo
        overRideStyles={{ textAlign: "center", color: "#fff", mt: 12 }}
        text="An error occured while fetching AT Token"
      />
    );

  return (
    <SoftPhoneAndFreshChatFloatUICtxProvider>
      <SoftPhoneCtxProvider
        atClient={atClient.current}
        broadCastChannelForDocTitleChange={broadCastChannelForDocTitleChange}
        canCreateAtInstance={canCreateAtInstance}
        instanceId={instanceId}
        hasCallPriv={hasCallPriv}
      >
        {children}
        <SoftPhoneAndFreshChatContainer />
      </SoftPhoneCtxProvider>
    </SoftPhoneAndFreshChatFloatUICtxProvider>
  );
};

export default SoftPhoneInstanceProvider;
