import {admin_path, app_path, call_fetch } from './../helpers/fetch_helper';
import {get} from './../helpers/storage_helper';
import {capitalize_first_letter,get_path,title_case} from './../helpers/common_helper';

let kyc_path = get("country_code") == "RWA" ? process.env.REACT_APP_RWA_KYC_APP_API_ROOT : process.env.REACT_APP_UGA_KYC_APP_API_ROOT

export const touch = (request) =>{
   return call_fetch(get_path('app') + '/touch', request);
}


export const update = (model, request)  => {
   return call_fetch(get_path('admin') + '/' + model + '/update', request);
}

export const getAddrConfig = (request)  => {
   return call_fetch(get_path('admin') + '/address/addr_config', request);
}


export const getNameList = (data_key, request, path = 'admin')  => {
   let main_path =  get_path(path);
   return call_fetch(main_path + '/' + data_key + '/name_list' , request);
} 

export const getListByUrl = (type, dataKey, request)  => {
   return call_fetch(get_path('app') + '/' + type + '/' + dataKey, request);
}

export const getLoanSearchCriteria = request  => {
   return call_fetch(get_path('app') + '/common/loan_search_criteria', request);
}

export const getCurrencyCode = (request)  => {
   return call_fetch(get_path('app') + '/common/currency_code', request);
}
export const delcohort = request => {
   return call_fetch(get_path('app') + '/common/del_cohort_col', request);
}

export const getOrgDetails = request  => {
   return call_fetch(get_path('admin') + '/org/org_details', request);
}

/*export const getCustomerAccounts = request  => {
   return call_fetch(get_path('app') + '/common/customer_accounts', request);
}*/


export const sendSMS = request  => {
   return call_fetch(get_path('app') + '/common/sms', request);
}

export const updateStatus = request  => {
   return call_fetch(get_path('app') + '/common/update', request);
}

export const getAppUsers = request  => {
   return call_fetch(get_path('app') + '/common/app_users', request);
}


export const get_country_master_data = (request)  => {
   return call_fetch(get_path('app') + '/user/master_data', request);
}

export const select_market = (request)  => {
   return call_fetch(get_path('app') + '/user/select_market', request);
}


export const getMasterData = request  => {
   return call_fetch(get_path('app') + '/common/master_data', request);
}



const get_master_data = () => {
   const data = get('master_data');
   
  if(data == ""){
      alert("Master Data not configured for the application. Ensure you are using modern browser");
      return;
  }
  return data;
}
export const dd = (data_key, parent_data_code = null) => {

  if(parent_data_code){
      if(Array.isArray(data_key)){  
         return getMultiDataKey(data_key,parent_data_code)
      }
      else{
         return dd_parent(data_key, parent_data_code)
      }
  }

   if(Array.isArray(data_key)){ 
   return getMultiDataKey(data_key,parent_data_code)
   }else{
   return get_master_data().filter(item => item.data_key === data_key);
  }
}

export const getMultiDataKey = (data_key, parent_data_code) => {
   var arr = [];
   for (var i = 0; i < data_key.length; i++) {
      arr = [...arr, ...get_master_data().filter(item => item.data_key === data_key[i])];
  } 
  return arr;
}

export const dd_parent = (data_key, parent_data_code) => {
   return get_master_data().filter(item => item.data_key === data_key && item.parent_data_code === parent_data_code)
}

export const dd_value = (data_code, data_key = null) => {
 
   var filtered_data = get_master_data();

   if(data_key!=null && data_key != "any"){
      filtered_data = dd(data_key)
   }

   if(filtered_data && filtered_data.length > 0 ) {      
      filtered_data = filtered_data.filter(item => item.data_code === data_code);
   }

   if(filtered_data == "" &&  (data_code?.toString().includes("_"))){
      return title_case(data_code)
   }else if(filtered_data == ""){
    return capitalize_first_letter(data_code)
   }

   if(filtered_data && filtered_data.length === 1 || (filtered_data.length > 1)) {
      return filtered_data[0].data_value;
   }else{
      return data_code;
   }
}

export const get_record_audits = request  => {
    return call_fetch(kyc_path + '/common/audit_history', request);
}

export const ListStmtImports = request  => {
   return call_fetch(get_path('app') + '/common/stmt_imports', request);
}

export const searchStmtImports = request  => {
   return call_fetch(get_path('app') + '/common/stmt_imports/search', request);
}

export const requestForExportCsv = request  => {
   return call_fetch(get_path('app') + '/common/csv', request);
}

export const listMarkets = (request)  => {
   return call_fetch(get_path('app') + '/common/market_list', request);
}
export const scheduleList = request  => {
   return call_fetch(get_path('app') + '/common/scheduler_list', request);
}

export const ViewConfigurations = request => {
   return call_fetch( get_path('admin')+ '/settings/view', request);
}

export const getconfigdata = request => {
   return call_fetch( get_path('admin')+ '/settings/config', request);
}

export const updateConfigurations = request => {
   return call_fetch( get_path('admin')+ '/settings/update', request);
}

export const getCreateUserPrivilege = request => {
   return call_fetch(get_path('app')+'/common/get_create_user_privilege',request)
}

export const Markets= (request)  => {
   return call_fetch(app_path + '/country', request);
}

export const getRmNameUnderTm = request => {
   return call_fetch(get_path("kyc")  + '/rel_mgrs_under_tm/name_list', request);
}

export const get_otps = request => {
   return call_fetch(admin_path + '/otps/view', request)
}

export const getMultiSelectOptions =(data_key,enabled_options=null)=>{    // Take dropdown values from "master_data" table

   const filtered_data = dd(data_key);
   //alert(JSON.stringify(filtered_data));
   var options = [];
   if(filtered_data.length > 0){
   if(enabled_options){ options = filtered_data
     .filter(item => item.status == 'enabled')
     .map((item, index) => (
       { value: item.data_code, label: item.data_value, id : data_key+"_"+index }      ));}   
     else{
     options =  filtered_data.map((item, index) => ({ value: item.data_code, label: item.data_value, id : data_key+"_"+index }));
     }
     return options;
   }else{
      //this.get_db_options(data_key);
   }
 }

 export const user_profile_pic = request => {
   return call_fetch(get_path('app')+'/common/profile_photo',request)
}

export const lender_list = request => {
   return call_fetch(get_path('kyc')+'/user/lender',request)
}

export const disbursal_attempt = request  => {
   return call_fetch(get_path('app') + '/common/disb_atmpts', request);
}

export const referAnAgent = (request) => {
   return call_fetch(get_path('kyc') + '/refer_an_agent', request);
};

export const agent_follow_up_action =(request) => {
   return call_fetch(get_path('kyc') + '/follow_up_action', request);
};

export const get_acc_prvdr_config =(request) => {
   return call_fetch(get_path('kyc') + '/get_acc_prvdr_config', request);
};

export const getReferralCustomerFollowUpList = request => {
   return call_fetch(get_path('kyc')+'/followup_list',request)
}