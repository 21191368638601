import { Box } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { NEW_CUST_ORIENTATION_CALL_LOG_MODAL } from "../../../../context/softPhoneCtx/softPhoneCtx";
import { useSoftPhoneCtx } from "../../../../context/softPhoneCtx/useSoftPhoneCtx";
import { get_path } from "../../../../helpers/common_helper";
import { call_fetch } from "../../../../helpers/fetch_helper";
import { checkUserHasCallPriv } from "../../../../helpers/helper";
import CustomModal from "../../../../MUI/Components/CustomModal";
import CommonMuiBtn from "../../../CommonComponent/commonMuiBtn";
import {
  HeaderVarientH3Typo,
  HeaderVarientH4Typo,
} from "../../../CommonComponent/typography";

/**
 *
 * @param UIFor - defines the UI to render.
 *                - for the home screen table, the "Log Call" button UI is needed.
 *                - inside the floating modal, the "Call" button UI is needed.
 * @param taskId - used to check whether the task is already assigned or not.
 * @param mobNum - required to make a call.
 * @returns the UI for making a call or the modal UI if the user has already taken action.
 */
const NewCustOrientationMakeCallUI = ({
  UIFor,
  taskId,
}: {
  UIFor: "homeScreenTable" | "floatUI";
  taskId: string;
}) => {
  // query
  const queryClient = useQueryClient();

  // ctx
  const {
    methods: {
      triggerManualCallLog,
      triggerCallLogModalForNewCustOrientation,
      checkUserHasAnyPendingCallLog,
      pendingCallLogHandler,
    },
    softPhoneState: { showCallStatusModal },
  } = useSoftPhoneCtx();

  // state
  const [showUserHasAssigedModal, setshowUserHasAssigedModal] = useState(false);
  const [isCustAssignedChecking, setisCustAssignedChecking] = useState(false);
  const [assignedUserStatusMsg, setassignedUserStatusMsg] = useState("");

  // const
  const hasAnyOngoingCall = showCallStatusModal;
  const disableCallBtn = hasAnyOngoingCall || isCustAssignedChecking;

  // handler
  const logCallHandler = async () => {
    setisCustAssignedChecking(true);
    // checking current loggedin user has any pending call log
    const { hasAnyPrevPendCallLog, callLog } =
      await checkUserHasAnyPendingCallLog({ screen: "" });
    if (hasAnyPrevPendCallLog) {
      pendingCallLogHandler({ callLog });
      setisCustAssignedChecking(false);
    } else {
      const hasCallPriv = checkUserHasCallPriv();

      // check the selected task is already assigned
      const res = await call_fetch(
        // @ts-ignore
        `${get_path("app")}/task/check_orientation_initiated_by`,
        { task_id: taskId }
      );
      if (res?.status === "success") {
        if (res.data === "allow") {
          // users with supported market only allowed to make a call
          if (hasCallPriv) {
            triggerCallLogModalForNewCustOrientation({
              screen: NEW_CUST_ORIENTATION_CALL_LOG_MODAL,
              selectedNewCustOrientationtaskId: taskId,
              showCallLogModal: true,
            });
          } // physical phone
          else {
            triggerCallLogModalForNewCustOrientation({
              screen: NEW_CUST_ORIENTATION_CALL_LOG_MODAL,
              selectedNewCustOrientationtaskId: taskId,
              showCallLogModal: true,
            });
            triggerManualCallLog({
              isManualCallLog: true,
              screen: NEW_CUST_ORIENTATION_CALL_LOG_MODAL,
            });
          }
        } else {
          // invalidate query for getting fresh data
          setTimeout(() => {
            queryClient.invalidateQueries({
              queryKey: ["new_cust_orientation_list"],
            });
          }, 2000); // for stability
          setassignedUserStatusMsg(res.data);
          setshowUserHasAssigedModal(true);
        }
        setisCustAssignedChecking(false);
      } else {
        setisCustAssignedChecking(false);
      }
    }
  };

  const handleAssignedUserModalConfirm = () => {
    setshowUserHasAssigedModal(false);
  };

  return (
    <>
      {UIFor === "homeScreenTable" && (
        <CommonMuiBtn
          type="button"
          disabled={disableCallBtn}
          text={isCustAssignedChecking ? "..." : "Log Call"}
          onClickHandler={logCallHandler}
          sx={{
            backgroundColor: "#0069C5",
            textTransform: "capitalize",
            fontSize: "14px !important",
            minWidth: "85px",
          }}
        />
      )}
      {UIFor === "floatUI" && (
        <Box
          onClick={logCallHandler}
          sx={{ cursor: disableCallBtn ? "default" : "pointer" }}
        >
          <HeaderVarientH4Typo
            text="View"
            overRideStyles={{
              color: disableCallBtn ? "#fff !important" : "#397baa !important",
              textDecoration: "underline",
              fontWeight: 600,
              fontSize: "14px",
            }}
          />
        </Box>
      )}
      {/* already cust taken Modal UI */}
      <CustomModal
        footer={
          <Box sx={{ margin: "16px auto" }}>
            <CommonMuiBtn
              text="Ok"
              onClickHandler={handleAssignedUserModalConfirm}
              variant="contained"
              color="secondary"
              sx={{ minWidth: "105px" }}
            />
          </Box>
        }
        body={<HeaderVarientH3Typo text={assignedUserStatusMsg} />}
        onClose={handleAssignedUserModalConfirm}
        header="For Your Information"
        size="sm"
        show={showUserHasAssigedModal}
      />
    </>
  );
};

export default NewCustOrientationMakeCallUI;
