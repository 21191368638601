import { Box, Divider, Grid } from "@mui/material";
import moment from "moment";
import { dd_value } from "../../../actions/common_actions";
import { format_date, lbl_amt } from "../../../helpers/common_helper";
import TextField from "../../CommonComponent/field/textField";
import {
  HeaderVarientH3Typo,
  HeaderVarientH4Typo,
  SubTitleVarientTwoTypo,
} from "../../CommonComponent/typography";
import { CustDetailProp } from "../hooks/useGetCustDetailsQuery";
import CustNameAndSinceUI from "./custNameAndSinceUI";

const FaDetailsAndPersonalInfoUI = ({
  custObj,
}: {
  custObj: CustDetailProp;
}) => {
  const currOrLastLoan = custObj?.current_loan ? "current_loan" : "last_loan";
  return (
    <>
      {/* title UI */}
      <CustNameAndSinceUI custDetail={custObj} textColor={"#fff"} />
      {/* fa switch count UI */}
      <Box display="flex" alignItems="center" gap={2}>
        <BgCoveredFieldUI
          label="No.of FAs"
          val={`${custObj?.tot_loans ?? "-"}`}
        />
        <BgCoveredFieldUI
          label="No.of Switches"
          val={`${custObj?.tot_switch ?? "-"}`}
        />
        <BgCoveredFieldUI
          label="Customer Status"
          val={`${custObj?.activity_status ?? "-"}`}
        />
      </Box>
      {/* cust info UI */}
      <HeaderVarientH3Typo
        text="Customer Info"
        overRideStyles={{ marginTop: 2, color: "#fff" }}
      />
      <Grid container>
        <Grid item xs={12} md={6}>
          <TextFieldUI label="Name" val={custObj?.cust_name ?? "-"} />
          <TextFieldUI
            label="Mobile Number"
            val={custObj?.cust_mobile_num ?? "-"}
          />
          <TextFieldUI
            label="DOB"
            val={
              custObj?.cust_dob
                ? moment(custObj?.cust_dob).format("DD MMM YYYY")
                : "-"
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldUI label="Location" val={custObj?.location ?? "-"} />
          <TextFieldUI label="Flow Cust ID" val={custObj?.cust_id ?? "-"} />
          <TextFieldUI
            label="Data Prvdr Cust ID"
            val={custObj?.cust_id ?? "-"}
          />
        </Grid>
      </Grid>
      <Divider />
      {/* FA details UI */}
      <HeaderVarientH3Typo
        text="FA Details"
        overRideStyles={{ marginTop: 2, color: "#fff" }}
      />
      <Grid container>
        <Grid item xs={12} md={6}>
          <TextFieldUI
            label="FA ID"
            val={custObj[currOrLastLoan]?.loan_doc_id ?? "-"}
          />
          <TextFieldUI
            label="Paid Amount"
            val={lbl_amt(custObj[currOrLastLoan]?.paid_amount ?? "-")}
          />
          <TextFieldUI
            label="Current Os Amount"
            val={lbl_amt(custObj[currOrLastLoan]?.current_os_amount ?? "-")}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldUI
            label="Status"
            val={
              custObj[currOrLastLoan]?.status
                ? dd_value(custObj[currOrLastLoan]?.status)
                : "-"
            }
          />
          <TextFieldUI
            label="Due Date"
            val={
              custObj[currOrLastLoan]?.due_date
                ? format_date(custObj[currOrLastLoan]?.due_date)
                : "-"
            }
          />
          <TextFieldUI
            label="Due  "
            val={
              custObj[currOrLastLoan]?.current_os_amount
                ? lbl_amt(custObj[currOrLastLoan]?.current_os_amount)
                : "-"
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FaDetailsAndPersonalInfoUI;

const BgCoveredFieldUI = ({ label, val }: { label: string; val: string }) => {
  return (
    <Box
      sx={{
        p: 2,
        backgroundColor: "#4f5167",
        borderRadius: "5px",
      }}
    >
      <SubTitleVarientTwoTypo
        text={label}
        overRideStyles={{
          textTransform: "capitalize",
          color: "#D9D9D9",
          fontWeight: 400,
        }}
      />
      <HeaderVarientH4Typo text={val} overRideStyles={{ color: "#fff" }} />
    </Box>
  );
};

const TextFieldUI = ({ label, val }: { label: string; val: string }) => {
  return (
    <Box sx={{ marginY: 3 }}>
      <TextField
        label={label}
        pairKeyColor="#D9D9D9"
        val={val}
        direction="column"
      />
    </Box>
  );
};
