import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";

import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import { Box, Button, Grid, useTheme } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  csDetailsUpdate,
  getCSDevice,
  softphoneCallDuration,
} from "../../../actions/cs_management_actions";
import { useSoftPhoneCtx } from "../../../context/softPhoneCtx/useSoftPhoneCtx";
import {
  HeaderVarientH3Typo,
  SubTitleVarientTwoTypo,
} from "../../CommonComponent/typography";
import StatusToggleUI from "./statusToggleUI";

type SoftPhoneCallResProp = {
  data: {
    incoming: SoftPhoneInOutProp;
    outgoing: SoftPhoneInOutProp;
  };
};

export type CSDetailsResProp = {
  data: CSStatusProp;
};

export type CSStatusProp = {
  call_status: string;
  number: string;
  status: string;
  type: string;
  on_break: 0 | 1;
  destinationNumber: string;
};

type SoftPhoneInOutProp = {
  count: number;
  duration: {
    hours: number;
    mins: number;
    secs: number;
  };
};

const SoftPhoneHeaderSection = () => {
  // hook
  const theme = useTheme();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // ctx
  const {
    softPhoneState: { isSoftPhoneClientReady },
  } = useSoftPhoneCtx();

  // const
  const incomingOutGoingIconStyle = {
    width: "40px",
    height: "40px",
    color: "#fff",
    p: 1,
    borderRadius: "50%",
    backgroundColor: "#4f5167",
  };

  // mutate query
  const { mutate, isSuccess, isPending } = useMutation({
    mutationFn: (data: Record<string, string | boolean | number>) =>
      updateCSDetails(data),
  });

  const {
    data: csDetails,
    // refetch: refetchCsDetails,
    isFetched: csDetailsIsFetched,
  } = useQuery<CSDetailsResProp>({
    queryKey: ["cs_device_details"],
    queryFn: () => getCSDevice({ type: "webrtc" }),
    enabled: isSuccess,
  });

  // react query
  const { data: softPhoneHistoryDetails } = useQuery<SoftPhoneCallResProp>({
    queryKey: ["soft_phone_history"],
    queryFn: () => softphoneCallDuration(),
    enabled: csDetailsIsFetched,
  });

  // effect
  useEffect(() => {
    mutate({ logout: false });
  }, []);

  // cb
  const updateCSDetails = useCallback(
    async (data: Record<string, string | boolean | number>) => {
      const response = await csDetailsUpdate(data);
      queryClient.invalidateQueries({ queryKey: ["cs_device_details"] });
      if (response.status !== "success") {
        alert(response.message);
      }
    },
    []
  );

  const logoutHandler = () => {
    mutate({
      logout: true,
    });
    navigate("/");
  };

  // const
  const csStatusEnabled = csDetails?.data.status === "enabled";

  return (
    <Box
      sx={{
        p: 2,
        //@ts-ignore
        backgroundColor: theme.palette.custom_colors.old_primary_400,
        borderRadius: 2,
      }}
    >
      <Grid container minHeight={"80px"}>
        <Grid
          item
          xs={12}
          md={6}
          lg={5}
          display="flex"
          alignItems="center"
          sx={{ justifyContent: { xs: "center", md: "start" } }}
        >
          <HeaderVarientH3Typo
            text="SOFT PHONE"
            overRideStyles={{ marginRight: 8, color: "#fff", fontWeight: 500 }}
          />
          {softPhoneHistoryDetails && (
            <>
              <Box display="flex" alignItems="center" mr={8}>
                <PhoneCallbackIcon sx={incomingOutGoingIconStyle} />
                <Box textAlign="center" sx={{ ml: 2 }}>
                  <TimeShowTypo
                    text={`${softPhoneHistoryDetails.data.incoming.duration.hours}h ${softPhoneHistoryDetails.data.incoming.duration.mins}m ${softPhoneHistoryDetails.data.incoming.duration.secs}s`}
                  />
                  <TimeShowTypo
                    text={`${softPhoneHistoryDetails.data.incoming.count} calls`}
                  />
                </Box>
              </Box>
              <Box display="flex" alignItems="center">
                <PhoneForwardedIcon sx={incomingOutGoingIconStyle} />
                <Box textAlign="center" sx={{ ml: 2 }}>
                  <TimeShowTypo
                    text={`${softPhoneHistoryDetails.data.outgoing.duration.hours}h ${softPhoneHistoryDetails.data.outgoing.duration.mins}m ${softPhoneHistoryDetails.data.outgoing.duration.secs}s`}
                  />
                  <TimeShowTypo
                    text={`${softPhoneHistoryDetails.data.outgoing.count} calls`}
                  />
                </Box>
              </Box>
            </>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          lg={7}
          display="flex"
          alignItems="center"
          sx={{ justifyContent: { xs: "center", md: "end" } }}
        >
          {csDetails && (
            <Box display="flex">
              {csStatusEnabled && (
                <StatusToggleUI
                  csDetails={csDetails.data}
                  updateCSDetails={mutate}
                  isUpdatingCsDetails={isPending}
                />
              )}
              <Box display="flex" alignItems="center" gap={1} ml={2}>
                <FiberManualRecordIcon
                  sx={{
                    color: csStatusEnabled ? "#02D130" : "red",
                  }}
                />
                <SubTitleVarientTwoTypo
                  text={csStatusEnabled ? "Assigned" : "Not assigned"}
                  overRideStyles={{ color: "#fff" }}
                />
              </Box>
              <Box display="flex" alignItems="center" gap={1} ml={2}>
                <FiberManualRecordIcon
                  sx={{
                    color: isSoftPhoneClientReady ? "#02D130" : "red",
                  }}
                />
                <SubTitleVarientTwoTypo
                  text={isSoftPhoneClientReady ? "Connected" : "Not connected"}
                  overRideStyles={{ color: "#fff" }}
                />
              </Box>
              {/* logout ui */}
              <Button
                onClick={logoutHandler}
                variant="contained"
                color="secondary"
                sx={{ minWidth: "75px", ml: 2 }}
              >
                Logout
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default SoftPhoneHeaderSection;

const TimeShowTypo = ({ text }: { text: string }) => (
  <p style={{ color: "#fff", margin: 0 }}>{text}</p>
);
