import CallIcon from "@mui/icons-material/Call";
import { Box, IconButton } from "@mui/material";
import { dd_value } from "../../../../actions/common_actions";
import { ERROR_INFO, FETCHING_INFO } from "../../../../consts";
import { NEW_CUST_ORIENTATION_CALL_LOG_MODAL } from "../../../../context/softPhoneCtx/softPhoneCtx";
import { useSoftPhoneCtx } from "../../../../context/softPhoneCtx/useSoftPhoneCtx";
import { checkUserHasCallPriv } from "../../../../helpers/helper";
import TextField from "../../../CommonComponent/field/textField";
import TextWrappedByHrTag from "../../../CommonComponent/textWrappedByHrTag";
import { HeaderVarientH2Typo } from "../../../CommonComponent/typography";
import { useGetCustDetailsByPhNumQuery } from "../../../softPhone/hooks/useGetCustDetailsQuery";

const ContactNumsUI = ({
  phoneNumber,
  taskId,
}: {
  phoneNumber: string;
  taskId: string;
}) => {
  // query
  const { data, isLoading, isError } = useGetCustDetailsByPhNumQuery({
    phoneNumber,
  });
  const custObj = data?.data?.customer_list[0];

  if (isLoading)
    return (
      <>
        <TextWrappedByHrTag text={"Contact Numbers"} />
        <HeaderVarientH2Typo
          overRideStyles={{ color: "#fff", textAlign: "center", py: 8 }}
          text={FETCHING_INFO}
        />
      </>
    );

  if (isError && !data?.data)
    return (
      <>
        <TextWrappedByHrTag text={"Contact Numbers"} />
        <HeaderVarientH2Typo
          overRideStyles={{ color: "#fff", textAlign: "center", py: 8 }}
          text={ERROR_INFO}
        />
      </>
    );

  return (
    <>
      <TextWrappedByHrTag text={"Contact Numbers"} />
      <Box p={2}>
        <Box display="grid" gridTemplateColumns={"repeat(4, 1fr)"}>
          {custObj &&
            Object.keys(custObj.mob_nums).map((mn, idx) => (
              <Box
                key={idx}
                sx={{
                  // @ts-ignore
                  display: !custObj.mob_nums[mn] ? "none" : "block",
                }}
              >
                {mn !== "addl_mob_num" &&
                  // @ts-ignore
                  custObj.mob_nums[mn] && (
                    <MobileNumAndCallBtnUI
                      mobileNumType={mn}
                      // @ts-ignore
                      mobileNum={custObj.mob_nums[mn]}
                      taskId={taskId}
                    />
                  )}
              </Box>
            ))}
          {/* additional numbers UI */}
          {/* {custObj &&
            custObj.mob_nums.addl_mob_num.map((adN, idx) => (
              <Box key={idx}>
                {adN.mobile_num && (
                  <MobileNumAndCallBtnUI
                    mobileNumType={`Additional Number ${idx + 1}`}
                    mobileNum={adN.mobile_num}
                  />
                )}
              </Box>
            ))} */}
        </Box>
      </Box>
    </>
  );
};

export default ContactNumsUI;

const MobileNumAndCallBtnUI = ({
  mobileNumType,
  mobileNum,
  taskId,
}: {
  mobileNumType: string;
  mobileNum: string;
  taskId: string;
}) => {
  // ctx
  const {
    methods: { initiateCall },
    softPhoneState: { showCallStatusModal },
  } = useSoftPhoneCtx();

  // const
  const isSoftPhoneSupported = checkUserHasCallPriv(); // if the user has the softphone priv the call should be initiated via softphone

  // handler
  const initiateCallHandler = async (phoneNumber: string) => {
    initiateCall({
      phoneNumber: phoneNumber ?? "",
      screen: NEW_CUST_ORIENTATION_CALL_LOG_MODAL,
      churnOrDeliquencyId: "",
      taskId,
    });
  };

  // const
  const hasAnyOngoingCall = showCallStatusModal;
  const disableCallBtn = hasAnyOngoingCall;

  return (
    <Box display="flex" alignItems="center" my={2}>
      <TextField
        label={dd_value(mobileNumType)}
        val={mobileNum}
        direction="column"
        pairKeyColor="#B2B2B2"
        pairKeyCase="capitalize"
        styleOverrides={{ maxWidth: "180px", width: "150px" }}
      />
      {isSoftPhoneSupported && (
        <IconButton
          type="button"
          disabled={disableCallBtn}
          onClick={() => initiateCallHandler(mobileNum)}
          sx={{
            width: "40px",
            height: "40px",
            borderRadius: "50%",
            backgroundColor: "#528D2C",
            "&:hover": { backgroundColor: "#4a90c1" },
            ml: 2,
          }}
        >
          <CallIcon sx={{ width: "25px", height: "25px" }} />
        </IconButton>
      )}
    </Box>
  );
};
