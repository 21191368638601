import {
  GridColDef,
  GridPaginationModel,
  GridRowsProp,
  GridSortModel,
  GridValidRowModel,
} from "@mui/x-data-grid";
import { FormDefaultProps } from "..";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { getCallLogs } from "../../../../actions/call_log_actions";
import {
  common_req,
  resolvePromiseWithDelay,
} from "../../../../helpers/common_helper";
import { Box, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { BodyVarientTwoTypo } from "../../../CommonComponent/typography";
import { LazyExoticComponent, Suspense, lazy, useCallback, useEffect, useState } from "react";
import DataTableLoadingOrErrorUI from "../../../CommonComponent/table/dataTableLoadingOrErrorUI";
import { TableUIProps } from "../../../flagged_customers/components/tableUI";
import ExportCsv from "../../../common/component/ExportCsv";
import { Config } from "../../../../helpers/storage_helper";
import { LightTooltip } from "../../../CommonComponent/tooltip";
import { RhfOptionTypeDataKeyOmited } from "../../../../type";

// lazy
const TableUI: LazyExoticComponent<React.ComponentType<TableUIProps>> = lazy(
  () =>
    resolvePromiseWithDelay(
      import("../../../flagged_customers/components/tableUI")
    )
);

export type IndividualCallSearchType = {
  Date: string;
  "Customer ID": string;
  "Caller Name": string;
  "Call Direction": string;
  "role_codes": string;
  "Call Purpose": string;
  Remarks: string;
  Recording: string;
};

export type IndividualCallRespType = {
  data: {
    data: IndividualCallSearchType[];
    total: number;
  };
};

export type CallSearchType = {
  Date: string;
  "Call Logger Name": string;
  Logs: string;
};

export type CallLogRespType = {
  data: {
    data: CallSearchType[];
    total: number;
  };
};

type ResponseType = IndividualCallRespType | CallLogRespType;

export type CSVDataType = IndividualCallSearchType[] | CallSearchType[] | [];

const FieldsAndTableUI = ({
  sort,
  page,
  queryFilters,
  setpage,
  setsort,
  CSVData,
  setCSVData,
  callerOptions,
}: {
  sort: GridSortModel;
  page: GridPaginationModel;
  queryFilters: FormDefaultProps;
  setpage: Function;
  setsort: Function;
  CSVData: CSVDataType;
  setCSVData: Function;
  callerOptions: RhfOptionTypeDataKeyOmited[]
}) => {
  const theme = useTheme();
  const filterData = {
    caller: queryFilters.caller.id === 'all' ? callerOptions.map(cO => (cO.id)) : [queryFilters.caller.id],
    callerName: queryFilters.callerName.id,
    callDirection: queryFilters.callDirection.id,
    callPurpose: queryFilters.callPurpose.id,
    custIdOrMblNo: queryFilters.custIdOrMblNo,
    individualCallLogs: queryFilters.individualCallLogs,
    startDate: queryFilters.startDate.format("YYYY-MM-DD"),
    endDate: queryFilters.endDate.format("YYYY-MM-DD"),
  };

  const indivCallHeaders = [
    "Date",
    "Mobile No",
    "Customer ID",
    "Caller Name",
    "Call Direction",
    "Call Purpose",
    "Remarks",
    "Recording"
  ];

  const callLogsHeaders = [
    "Date",
    "Call Logger Name",
    "Logs"
  ]

  const headers = filterData.individualCallLogs === true ? indivCallHeaders : callLogsHeaders;

  const reqObj = common_req({} as any);

  const [csvRespose, setCsvRrespose] = useState(false);

  const enabled = callerOptions.length > 0

  // call the search call log api
  const { data, isLoading, isError, refetch } = useQuery<ResponseType>({
    queryKey: [
      "call_log_search",
      { sort: [sort?.[0]?.["field"], sort?.[0]?.["sort"]] },
      { page: [page["page"], page["pageSize"]] },
      {
        filters: filterData,
      },
    ],
    enabled,
    queryFn: () =>
      getCallLogs({
        ...reqObj,
        filters: filterData,
        sort: { field: sort?.[0]?.["field"], order: sort?.[0]?.["sort"] },
        pagination: {
          skip: page["page"] * page["pageSize"],
          per_page: page["pageSize"],
        },
      }),
  });

  //set width
  const getWidth = useCallback((name: string) => {
    const defaultWidth = 150;

    const get_width: Record<string, number> = {
      'Caller Name': 180,
      'Call Purpose': 180,
      'Remarks': 400,
      'Recording': 300,
    };

    return get_width[name] || defaultWidth;
  }, []);

  // set color
  const getColor = useCallback((name: string) => {
    const defaultColor = theme.palette.text.primary;

    const get_color: Record<string, string> = {
      'Customer ID': "#5168CC !important",
    };
    return get_color[name] || defaultColor;
  }, []);

  // set font weight
  const getFontWeight = useCallback((name: string) => {
    const defaultFont = "";
    const get_font_wieght: Record<string, string> = {
      'role_codes': "bold",
    }
    return get_font_wieght[name] || defaultFont;
  },[]);
  

  // text type
  const TextShowTypo = ({
    name,
    text_value,
  }: {
    name: string;
    text_value: string;
  }) => (
    <BodyVarientTwoTypo
      overRideStyles={{
        color: getColor(name),
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontWeight: getFontWeight(name),
      }}
      text={text_value}
    />
  );

  // set rows
  const rows = data?.data?.data.map((val, i) => ({
    ...val,
    id: i,
  })) as GridRowsProp;

  // set columns
  const columns =
    headers
      .map((key, i) => ({
        field: key,
        headerName: key,
        minWidth: getWidth(key),
        flex: 1,
        align: "left",
        headerAlign: "left",
        renderCell: (params: any) => (
          <Box 
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
          >
            {key === "Recording" ? (
                <audio controls style={{ minWidth: "242px", maxWidth: "242px", height: "39px" }}>
                  <source src={params.value} type="audio/ogg" />
                </audio>
            ) : key === "Customer ID" ? (
              params.value === 'Unknown Customer' ? 
              <TextShowTypo name={'default'} text_value={params.value} />
              :
              <Link to={`/borrower/indiv/view/${params.value}`} target="_blank">
                <TextShowTypo name={key} text_value={params.value} />
              </Link>
            ) : key === "Caller Name" ? (
              <Box
              sx={{
                display: "flex",
                justifyContent:"left",
                alignItems:"center"
              }}
              >
                <TextShowTypo name={key} text_value={params.value ? params.value + ' - ' : ''} />
                <TextShowTypo name="role_codes" text_value={params.row?.role_codes} />
              </Box>
            ) : key === "Remarks" ? (
              <LightTooltip
              title={params.value}
              arrow
              placement="top"
              >
              <span style={{ overflow: "hidden" }}>
                  <TextShowTypo name={key} text_value={params.value} />
              </span>
              </LightTooltip>
            ) : (
              <TextShowTypo name={key} text_value={params.value} />
            )}
          </Box>
        ),
      }))
      .filter(Boolean)
  
  // handle csv datas
  const handleExportCSV = async(type: string) => {
    setCsvRrespose(false)

    if(type === "email"){
      let confirm = window.confirm(`The current search exceeds ${Config("export_csv").limit} rows. So the exported data will be sent to your email shortly.`)
      if(!confirm){
        setCsvRrespose(true);
      }
      let emailResp: {status:string} = await getCallLogs({
        ...reqObj,
        filters: filterData,
        export: true
  
      })
      if(emailResp.status == "success"){
        setCsvRrespose(true);
      }
    }else{
      let csvData: ResponseType = await getCallLogs({
        ...reqObj,
        filters: filterData,  
      })
      setCSVData(csvData?.data?.data);
      return true;
    }
  }

  return (
    <>
      <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center"
      }}
      p={2}
      >
        {data?.data?.total ? 
        <ExportCsv
        total_count={data?.data?.total}
        handleExport={handleExportCSV}
        export_csv_datas={CSVData || []}
        csvFileName="Search Call Logs"
        paginationMode="server"
        columns={columns as unknown as GridColDef<GridValidRowModel>[]}
        isCsvExportedToMail={csvRespose}
        /> 
        : null}
      </Box>

      <Suspense
        fallback={
          <Box>
            <DataTableLoadingOrErrorUI
              isError={false}
              errorText=""
              loadingRows={10}
              loadingColumns={6}
            />
          </Box>
        }
      >
        <TableUI
          rows={enabled ? rows as unknown as GridValidRowModel[] : []}
          columns={columns as unknown as GridColDef<GridValidRowModel>[]}
          page={page}
          sort={sort}
          rowCount={data?.data?.total}
          setpage={setpage}
          setsort={setsort}
          isError={isError}
          isLoading={isLoading || !enabled}
        />
        {data?.data?.total && filterData.individualCallLogs === true ? <span className="text-white p-3">Note: If there is no record, the recording will be displayed as '0:00'.</span> : null}
      </Suspense>
    </>
  );
};

export default FieldsAndTableUI;
