import { Box, Button, Typography, useTheme } from "@mui/material";
import { HeaderVarientH4Typo } from "../../../CommonComponent/typography";
import { dd_value } from "../../../../actions/common_actions";
import DigitalKycDocuments from "./digitalKycDocuments";
import { useGetDigitalKycVerificationInfo } from "../../hook/auditorVerificationHooks";
import ScreenLoader from "../screenLoader";
import RHFCheckBoxField from "../../../CommonComponent/rhfComponents/rhfCheckBoxField";
import { textTransform } from "../../../../styles/common_styles";

const DigtalKycVerification = () => {
  const theme = useTheme();

  const { data, isLoading } = useGetDigitalKycVerificationInfo();
  const accDetails = data?.cust_info ?? {};

  if (isLoading) return <ScreenLoader />;
  const nationalIdFields = [
    "id_proof_num",
    "first_name",
    "last_name",
    "gender",
    "dob",
  ];
  const rdbFields = [
    "company_code",
    "enterprise_name",
    "owner_name",
    "registration_date",
  ];

  return (
    <Box>
      <Box sx={{ background: "#223B56" }}>
        <HeaderVarientH4Typo
          text="Account Details"
          overRideStyles={{
            color: theme.palette.text.primary,
            textAlign: "center",
            fontWeight: 500,
            py: 2,
          }}
        />
      </Box>
      <Box
        sx={{ background: theme.palette.primary.main, p: 4 }}
        display={"flex"}
        justifyContent={"space-around"}
      >
        {Object.entries(accDetails).map(([key, value]) => (
          <Box>
            <Typography key={key} variant="body2" sx={{ color: "#ACAFBC" }}>
              {dd_value(key)}
            </Typography>
            <Typography
              variant="body2"
              color={"white"}
              sx={{ fontWeight: 500 }}
            >
              {/* @ts-ignore */}
              {value ?? ""}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box sx={{ background: theme.palette.primary.main, my: 1, px: 1, py: 2 }}>
        <DigitalKycDocuments
          proofType={"photo_national_id"}
          fields={nationalIdFields}
        />
      </Box>
    </Box>
  );
};

export default DigtalKycVerification;
