import FlowComponent from '../../common/component/core/FlowComponent';
import React from 'react'
import moment from 'moment';
import DatePicker from 'react-datetime';
import {Form, Col , Row, Button,checkbox ,Modal, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { dd_value } from '../../../actions/common_actions';
import { BasicReactTable } from '../../../helpers/react_table';
import { get } from '../../../helpers/storage_helper';
import { Homeacc_prvdrLogoselector, RoundOffSplitter, lbl_amt } from '../../../helpers/common_helper';
import { getInternalTransferReport } from '../../../actions/report_actions';
import { Link } from 'react-router-dom';
import { admin_path, call_fetch } from "../../../helpers/fetch_helper";
class InternalTransferReport extends FlowComponent{

    state = {
        row_datas: [], 
        filters: [
            { key: "initiated_from", type: "date" },
            { key: "initiated_to", type: "date" },
            { key: "from_acc", type: "input" },
            { key: "to_acc", type: "input" },
            { key: "status", type: "select" },
            { key: "acc_prvdr_code", type: "select" },
        ],
        internal_transfer_fields: [
            { key: "acc_prvdr_code", label: "Account Provider" },
            { key: "from_acc", label: "From Account" },
            { key: "to_acc", label: "To Account" },
            { key: "amount", label: "Amount" },
            { key: "initiated_at", label: "Initiated At" },
            { key: "transaction_date", label: "Transaction Date" },
            { key: "txn_id", label: "Txn ID" },
            { key: "status", label: "Status" },
            { key: "created_by", label: "Created_By " },
            { key: "action", label: "Action"},
            
        ],
        criteria_arr: {},
        loader: false, 
    };
    

    componentDidMount() {
        this.setState({ column_datas: this.column_datas() });
        this.setOptionsListToStateFromAppApi('common', 'list', {...this.def_req , status: "enabled", biz_account: true}, ['acc_prvdr_code', 'name'], "acc_prvdr", null, "remove_choose");
    }
    

   internaltransferlist(filter = null) {
    

    const req = filter ? { search_criteria: filter } : {};

    this.setState({ loader: true }); 

    getInternalTransferReport(req)
        .then((response) => {
           

            if (response && Array.isArray(response.data)) {
                this.setState({ row_datas: response.data }, () => {
                  
                });
            } 
        })
        .catch((error) => {
            console.error("API Error:", error);
        })
        .finally(() => {
            this.setState({ loader: false }); 
        });
}

handleOnChange(val, type) {
    const criteria = { ...this.state.criteria_arr };

    
    if (type === "initiated_from" || type === "initiated_to") {
        criteria[type] = val ? moment(val).format("YYYY-MM-DD") : null;
    } else {
       
        criteria[type] = val || null;
    }

    if (val === "All") {
        criteria[type] = "All";
    }

    if (criteria[type] === null) {
        delete criteria[type];
    }

    this.setState({ criteria_arr: criteria, is_onchanged: true });
}

column_datas() {
    const columns = this.state.internal_transfer_fields.map((item, idx) => {
        return {
            Header: (
                <p className='text-center m-0'>
                    {item.key === "created_by" ? "Initiated By" : item.label} {}
                </p>
            ),
            id: item.key,
            accessor: item.key,
            Cell: (cell) => {
                const cellValue = cell.row.original[item.key];

                if (item.key === "from_acc" || item.key === "to_acc") {
                    return (
                        <div className='d-flex justify-content-center'>
                            <p className='text-light m-0'>{cellValue || "NA"}</p>
                        </div>
                    );
                }

                if (item.key === "action") {
                    const statusValue = cell.row.original.status;
                    return (
                        <div className='d-flex justify-content-center'>
                            {statusValue === "failed" ? (
                                <button
                                    className='btn btn-primary btn-lg'
                                    onClick={() => this.retryAction(cell.row.original)}
                                >
                                    Retry
                                </button>
                            ) : (
                                <p className='text-light m-0'>-</p>
                            )}
                        </div>
                    );
                }
                

                const displayValue =
                    typeof cellValue === "string" || Array.isArray(cellValue) ? dd_value(cellValue) : "NA";

                return (
                    <div className='d-flex justify-content-center'>
                        {item.key === "acc_prvdr_code" ? (
                            <div className='d-flex align-items-center'>
                                <Homeacc_prvdrLogoselector logotype={cell.row.original.acc_prvdr_code} />
                                <p className='no-margin' style={{ paddingLeft: '10px' }}></p>
                            </div>
                        ) : item.key === "amount" ? (
                            <p className='text-light m-0'>
                                {lbl_amt(cell.row.original[item.key], get('market').currency_code)}
                            </p>
                        )  : item.key === "transaction_date" ? (
                            <p className='text-light m-0'>
                                {cell.row.original[item.key] && moment(cell.row.original[item.key]).isValid()
                                    ? `${moment(cell.row.original[item.key]).format('DD MMM YYYY')} | ${moment(cell.row.original[item.key]).format('HH:mm:ss')}`
                                    : ""}
                            </p>
                        ) : item.key === "initiated_at" ? (
                            <p className='text-light m-0'>
                                {moment(cell.row.original[item.key]).isValid()
                                    ? moment(cell.row.original[item.key]).format('DD MMM YYYY') + " | " + moment(cell.row.original[item.key]).format('HH:mm:ss')
                                    : "NA"}
                            </p>
                        ) : item.key === "status" ? (
                            <p className='text-light m-0'>{displayValue}</p>
                        ) : item.key === "txn_id" ? (
                            <p className='text-light m-0'>{cell.row.original[item.key]}</p>
                        ) : item.key === "created_by" ? (
                            <p className='text-light m-0'>{cellValue ? cellValue : "NA"}</p>
                        ) : (
                            <p className='text-light m-0'>{displayValue}</p>
                        )}
                    </div>
                );
            },
            minWidth: item.key === "txn_id" ? 180 : 150,
        };
    });

    return columns;
}

async retryAction(rowData) {
   

    const isConfirmed = window.confirm("Are you sure  want to retry?");
    
    if (isConfirmed) {
        const req = { 
            data: {
                acc_number: rowData.to_acc,
                from_acc_number: rowData.from_acc, 
                acc_prvdr_code: rowData.acc_prvdr_code,
                transferAmount: rowData.amount, 
                status: rowData.status,
            }
        };

       
            const response = await call_fetch(admin_path + '/account/internal_trans', req);
            
            if (response.status === 'success') {
                alert("Transfer successful.");
              const filterCriteria = { ...this.state.criteria_arr };
              this.internaltransferlist(filterCriteria);

            } else {
                alert(response.message || 'Transfer failed. Please try again.');
            }
       
    } 
}


    submitHandler() {
        const filterCriteria = { ...this.state.criteria_arr };
        this.internaltransferlist(filterCriteria);
    }
    
    
    

    render(){
    

        return(
            <div>
                <div className={`${this.state.modalBox1 ? "disable_div":""}`}>
            
                    <div className='floatAdvancetitleBox mt-4 p-3'>
                        <h2 className='text-white m-0 py-2 '>Internal Transfer Report</h2>
                    </div>
            
                    <div className='py-4' style={{backgroundColor: 'rgb(32, 41, 64)'}}>

                        <div className='row m-0 d-flex align-items-center'>

                            {this.state.filters&& this.state.filters.map((item,idx)=>{return(
                                <div className='col-lg-3 col-md-12 col-sm-12 my-3' >
                                <fieldset className='border border-white p-2 my-2 ' style={{borderRadius:'5px'}}>
                                    <legend className='w-auto text-white ml-4 fieldset_legend px-2  mb-0'style={{fontSize:'13px'}}>{dd_value(item.key)}</legend>
                                    <div className='flagged_cust'>
                                {(item.type=="input") &&
                                    <Form.Group as={Col} className={` px-2 py-0 no-margin  `}>

                                    <Form.Control id="req_parameter" placeholder='Acc Number' className='p-0' autoComplete='off' onChange={(e) => {this.handleOnChange(e.target.value,item.key)}} >
                                    </Form.Control>
                                </Form.Group>
                                }

                                {item.type=="select" &&


                                <Form.Control as="select" id="audited_by" onChange={(e) =>{this.handleOnChange(e.target.value, item.key)}}>
                                <>
                                
                                <option value={""}> All</option>

                                {item.key=="acc_prvdr_code" ? 
                                this.state.acc_prvdr_dd

                                :
                                <>
                                {
                                
                                item.key=="status" ?
                                <>
                                    <option value={"pending"}> Pending</option>
                                    <option value={"success"}> Success</option>
                                    <option value={"failed"}> Failed</option>
                                </>
                                
                                :
                                this.getSelectOptions("cashback_type")}
                                </>
                            }
                                </>

                            
                                </Form.Control>
                            
                                }
                                {item.type=="date" && 
                                <div className='px-2'>
                                <DatePicker  closeOnSelect={true} value={this.state.criteria_arr[item.key]? moment(this.state.criteria_arr[item.key]).format("DD MMM YYYY"):"" }  dateFormat="DD MMM YYYY" timeFormat={false} required="required"
                                isValidDate={(current) => {
                                    const today = moment().startOf('day');
                                    return current.isSameOrBefore(today, 'day');  }} 
                                onChange={(value) => {this.handleOnChange( moment(value).format("YYYY-MM-DD"),item.key)}}  inputProps={{ placeholder: `Date / Month/ Year`,autoComplete:'off' }}  />
                                </div>
                                }
                                </div>
                                </fieldset>
                                </div>
                            )})}

                        </div>

                        <div className='d-flex justify-content-center my-3 mb-5'>
                            <div>
                                <button type="button" class={`btn btn-primary btn-lg  ${( this.state.is_onchanged )  ? "":"disable_div"} ${this.state.loader ? "disable_div":""} `} onClick={()=>{this.submitHandler()}}>Submit</button>
                            </div>
                        </div>
                    </div>

                <div>

            {this.state.loader ? <>
            <p className='text-light mt-5 text-center' >Loading...</p>
            <div className="product_takeup_loader "></div></>:
            <>
        {this.state.row_datas && this.state.row_datas.length > 0 ? (
            
        <BasicReactTable
            className="border"
            row_data={this.state.row_datas}
            csv_file={true}
            FName="Internal Transfer Report"
            column_data={this.state.column_datas}
            default_page_size={10}
            pagination={this.state.row_datas.length > 10}
        />
    ) : (
        <p className="text-white text-center my-3">NO DATA</p>
    )}

            </>
            }
            </div>

                </div>            
            </div>
        )
    }
}
export default InternalTransferReport
