import { Box, Button, DialogContentText, Grid } from "@mui/material";

import { useCallback, useState } from "react";
import { BRIDGED } from "../../../../consts";
import { NEW_CUST_ORIENTATION_CALL_LOG_MODAL } from "../../../../context/softPhoneCtx/softPhoneCtx";
import { useSoftPhoneCtx } from "../../../../context/softPhoneCtx/useSoftPhoneCtx";
import { useSoftPhoneTimerCtx } from "../../../../context/softPhoneTimerCtx/useSoftPhoneTimerCtx";
import { FormatSecondsToHHMMSS } from "../../../../helpers/helper";
import CustomModal from "../../../../MUI/Components/CustomModal";
import {
  HeaderVarientH2Typo,
  HeaderVarientH3Typo,
} from "../../../CommonComponent/typography";
import NewCustOrientationCallLogModal from "../../../home/newOnboardedCustomers/components/newCustOrientationCallLogModal";
import { CustDetailProp } from "../../hooks/useGetCustDetailsQuery";
import SoftPhoneCallLogModal from "./softPhoneCallLogModal";

const CallLogModal = () => {
  // state
  const [showSuccessModal, setshowSuccessModal] = useState(false);
  const [customerList, setcustomerList] = useState<CustDetailProp | undefined>(
    undefined
  );

  // ctx
  const {
    softPhoneState: {
      showCallLogModal,
      callLogDetails: { callLogForScreen },
      manualCall: { isManualCallLog },
    },
    methods: {
      closeCallLogModal,
      closeCallLogModalOnParticularWindow,
      triggerManualCallLog,
      triggerCallLogModalForNewCustOrientation,
    },
  } = useSoftPhoneCtx();

  // handler
  const closeModalHandler = useCallback(() => {
    closeCallLogModal();
    setshowSuccessModal(true);
  }, []);

  const closeLogSuccessModal = () => {
    setcustomerList(undefined);
    setshowSuccessModal(false);
  };

  const closeCallLogModalHandler = () => {
    if (isManualCallLog) {
      triggerManualCallLog({
        screen: "",
        isManualCallLog: false,
      });
      triggerCallLogModalForNewCustOrientation({
        screen: "",
        selectedNewCustOrientationtaskId: "",
        showCallLogModal: false,
      });
    } else {
      closeCallLogModalOnParticularWindow();
    }
  };

  // const
  const isNewCustomerOrientationCallLogModal =
    callLogForScreen === NEW_CUST_ORIENTATION_CALL_LOG_MODAL;

  return (
    <>
      {/* call log modal */}
      <CustomModal
        body={
          <>
            {isNewCustomerOrientationCallLogModal ? (
              <NewCustOrientationCallLogModal />
            ) : (
              // : callLogForScreen === "" ? (
              //   <HeaderVarientH2Typo
              //     text="Please wait..."
              //     overRideStyles={{
              //       color: "#fff!important",
              //       py: 16,
              //       textAlign: "center",
              //     }}
              //   />
              // )
              <SoftPhoneCallLogModal
                setcustomerList={setcustomerList}
                closeModalHandler={closeModalHandler}
              />
            )}
          </>
        }
        onClose={closeCallLogModalHandler}
        header={
          <>
            {isNewCustomerOrientationCallLogModal ? (
              <Grid container alignItems="center">
                <Grid item md={2}></Grid>
                <Grid item md={8}>
                  <HeaderVarientH2Typo
                    text="New Customer Orientation - Call Log"
                    overRideStyles={{ color: "#fff" }}
                  />
                </Grid>
                <Grid item md={2}>
                  <TimerUI />
                </Grid>
              </Grid>
            ) : (
              <HeaderVarientH2Typo
                text={"Call Log"}
                overRideStyles={{ color: "#fff" }}
              />
            )}
          </>
        }
        show={showCallLogModal}
        size="lg"
      />
      {/* logged status show modal UI */}
      <CustomModal
        footer={
          <Box sx={{ m: 2, width: "100%", textAlign: "center" }}>
            <Button
              onClick={closeLogSuccessModal}
              variant="contained"
              color="secondary"
              sx={{ minWidth: "75px" }}
              autoFocus
            >
              Ok
            </Button>
          </Box>
        }
        body={
          <DialogContentText sx={{ color: "#fff" }}>
            {`Your call log with customer ${
              customerList?.biz_name ?? "Unknown"
            } has been successfully logged`}
          </DialogContentText>
        }
        onClose={closeLogSuccessModal}
        header="Confirmation"
        show={showSuccessModal}
        size="sm"
      />
    </>
  );
};

export default CallLogModal;

const TimerUI = () => {
  // ctx
  const {
    softPhoneTimerState: { timer },
  } = useSoftPhoneTimerCtx();
  const {
    softPhoneState: {
      callState: { status },
      manualCall: { isManualCallLog },
    },
  } = useSoftPhoneCtx();

  // const
  const isCallConnected = status === BRIDGED;
  const showTimer = isManualCallLog || isCallConnected;

  return (
    <>
      {timer !== 0 && (
        <HeaderVarientH3Typo
          text={showTimer ? FormatSecondsToHHMMSS(timer) : ""}
          overRideStyles={{ color: "#fff" }}
        />
      )}
    </>
  );
};
