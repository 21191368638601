import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BodyVarientTwoTypo, HeaderVarientH2Typo, HeaderVarientH4Typo, SubTitleVarientOneTypo } from "../../CommonComponent/typography";
import { useTheme } from "@emotion/react";
import DateFilter from "../component/DateFilter";
import { useForm } from "react-hook-form";
import FormProvider from "../../CommonComponent/rhfComponents/formProvider";
import RhfDatePicker from "../../CommonComponent/rhfComponents/rhfDatePicker";
import RHFAutocomplete from "../../CommonComponent/rhfComponents/rhfAutoComplete";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CustomButton from "../../../MUI/Components/CustomButton";
import ExportCsv from "../../common/component/ExportCsv";
import DataGridTable from "../../CommonComponent/table"
import { Link } from "react-router-dom";
import { fill_null, getValueFromLangJson, lbl_amt } from "../../../helpers/common_helper";
import TextField from "../../CommonComponent/field/textField";
import { getReport } from "../../../actions/report_actions";
import { Config, get } from "../../../helpers/storage_helper";
import { Button } from "react-bootstrap";
import { getMasterData } from "../../../actions/common_actions";
import { RhfOptionsTypeAndKeyCheckProperties } from "../../../helpers/helper";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";


const LoanDisbursementReport = () => {

    const [loanData, setLoanData] = useState({});
    const [isLoading, setIsloading] = useState(false)
    const [loanMetrics, setLoanMetrics] = useState({ "<30": 0, "30-60": 0, "60-90": 0, ">90": 0 });
    const [isGetMetrics, setIsGetMetrics] = useState(true)
    const [paginate, setPaginate] = useState(Config('paginate') ?? null);
    const [totalCount, setTotalCount] = useState(0)
    const [page, setPage] = useState({ page: 0, pageSize: 10 })
    const [exportData, setExportData] = useState([])
    const [isCsvExportedToMail, setIsCsvExportedToMail] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false);
    const [loanDisbStatus, setLoanStatus] = useState([]);

    const theme = useTheme();

    useEffect(() => {
        getLoanDisbReportStatus();
    }, [])

    useEffect(() => { getMontlyLoanReport(); }, [page])

    const FormSchema = Yup.object().shape({
        start_date: Yup.mixed()
            .required(getValueFromLangJson("start_date_required"))
            .test("start_date", getValueFromLangJson("start_date_cannot_be_future"), (val) => {
                const minDate = moment();
                return !val.isAfter(minDate, "day");
            })
            .test("start_date", getValueFromLangJson("start_date_cannot_be_after_end_date"), function (val) {
                const { end_date } = this.parent;
                if (!end_date || !val) return true;
                return !val.isAfter(end_date, "day");
            }),
        end_date: Yup.mixed()
            .required(getValueFromLangJson("end_date_required"))
            .test("end_date", getValueFromLangJson("end_date_cannot_be_future"), (val) => {
                const minDate = moment();
                return !val.isAfter(minDate, "day");
            })
            .test("end_date", getValueFromLangJson("end_date_cannot_be_before_start_date"), function (val) {
                const { start_date } = this.parent;
                if (!start_date || !val) return true;
                return !val.isBefore(start_date, "day");
            }),
    });



    const methods = useForm({
        resolver: yupResolver(FormSchema),
        defaultValues: {
            status: { name: getValueFromLangJson('all'), id: 'all' },
            start_date: moment().startOf('month'),
            end_date: moment(new Date()),
        },

    });

    const getLoanDisbReportStatus = () => {

        const request = { "country_code": get('market').country_code, data_key: 'loan_disb_report_status' }
        const request_data = { "master_data": request };

        getMasterData(request_data).then((response) => {
            if (response) {
                if (response.status == 'success') {

                    const data = response.data.map((item) => ({
                        name: item.data_value,
                        id: item.data_code

                    }))
                    setLoanStatus(data)
                }
            }
        })
    }

    const formatRequestData = () => {

        return {
            status: watch('status').id,
            start_date: watch('start_date').format('YYYY-MM-DD'),
            end_date: watch('end_date').format('YYYY-MM-DD'),
            report_type: 'loan_disbursement_report',
            is_get_metrics: isGetMetrics,
            paginate
        }

    }

    const getMontlyLoanReport = () => {

        let request_data = formatRequestData()

        setIsloading(true)
        setIsDisabled(true)
        getReport(request_data).then((response) => {
            setIsloading(false)
            if (response) {
                if (response.status == 'success') {
                    let loanData = response?.data?.loan_data ?? [];
                    let loanMetric = response?.data?.loan_metrics ?? loanMetrics

                    setLoanData(loanData)
                    if (isGetMetrics) {
                        setLoanMetrics(loanMetric)
                    }

                    setTotalCount(response.data.total_count ?? loanData.length);

                }
                setIsDisabled(false)
            }
        });
    }

    const { handleSubmit, watch } = methods;

    const onSubmit = handleSubmit(() => {
        if (!isGetMetrics) {    
            setIsGetMetrics(true);
        }

        setPaginate({ ...paginate, pagination_page: 0 , pagination_count: page.pageSize})
        setPage({ page: 0, pageSize: page.pageSize })
        setExportData([]);
        

    })

    const handleChangePage = (newPage) => {
        setIsGetMetrics(false);
        setPaginate({ ...paginate, pagination_count: newPage.pageSize, pagination_page: newPage.page })
        setPage(newPage)
    }


    const handleExportCSV = (event) => {

        let request_data = {
            status: watch('status').id,
            start_date: watch('start_date').format('YYYY-MM-DD'),
            end_date: watch('end_date').format('YYYY-MM-DD'),
            report_type: 'loan_disbursement_report',
            is_get_metrics: false,
            paginate: false

        }

        let resp = null;
        if (event == 'email') {
            request_data.export = 'email'
            let emailExportMsg = getValueFromLangJson('csv_export_via_email_info_msg').replace(':limit', Config("export_csv").limit)
            resp = window.confirm(emailExportMsg)
        }
        if (event == 'email' && !resp) {
            return false
        } else {
            handleCSVapi(request_data, event)
            setIsCsvExportedToMail(false)
            return true
        }
    }

    const handleCSVapi = (req, action) => {

        getReport(req).then((response) => {
            if (response) {
                if (response.status == 'success') {
                    if (action == 'email') {
                        alert(getValueFromLangJson('csv_email_export_success'))
                        setIsCsvExportedToMail(true);
                        return
                    }
                    setExportData(response.data.loan_data)
                }
            }
        });
    }

    const columndata = [
        {
            headerName: getValueFromLangJson('cust_name'),
            field: 'cust_name',
            sortable: false,
            resizable: true,
            width: 200,
            headerAlign: 'left',

        },
        {
            headerName: getValueFromLangJson('cust_id'),
            field: 'cust_id',
            resizable: true,
            width: 200,
            sortable: false,
            renderCell: params => <Link to={"/borrower/indiv/view/" + params.row.cust_id} target="_blank" >{params.row.cust_id}</Link>,

        },
        {
            headerName: getValueFromLangJson('loan_id'),
            field: 'loan_doc_id',
            width: 200,
            resizable: true,
            sortable: false,
            renderCell: params => <Link to={"/fa/view/" + params?.row?.loan_doc_id} target="_blank" >{params?.row?.loan_doc_id}</Link>,
        },
        {
            headerName: getValueFromLangJson('national_id_num'),
            field: 'national_id',
            sortable: false,
            width: 200,
            headerAlign: 'left', resizable: true, align: 'left',
            renderCell: params => fill_null(params.row.national_id)
        },
        {
            headerName: getValueFromLangJson('fa_amount'),
            field: 'loan_principal',
            sortable: false,
            resizable: true,
            width: 200,
            headerAlign: 'left', resizable: true, align: 'left',
            renderCell: params => lbl_amt(params.row.loan_principal)
        },
        {
            headerName: getValueFromLangJson('disb_date'),
            field: 'disbursal_date',
            sortable: false,
            width: 200,
            headerAlign: 'left', resizable: true, align: 'left'

        },
        {
            headerName: getValueFromLangJson('due_date'),
            field: 'due_date',
            sortable: false,
            width: 200,
            headerAlign: 'left', resizable: true, align: 'left',

        },
        {
            headerName: getValueFromLangJson('fa_status'),
            field: 'status',
            sortable: false,
            width: 200,
            headerAlign: 'left', resizable: true, align: 'left',

        },
        {
            headerName: getValueFromLangJson('outstanding_amt'),
            field: 'current_os_amount',
            sortable: false,
            resizable: true,
            width: 200,
            headerAlign: 'left', resizable: true, align: 'left',
            renderCell: params => lbl_amt(params.row.current_os_amount)

        },
        {
            headerName: getValueFromLangJson('penalty'),
            field: 'penalty',
            sortable: false,
            resizable: true,
            width: 200,
            headerAlign: 'left', resizable: true, align: 'left',
            renderCell: params => lbl_amt(params.row.penalty)

        },
        {
            headerName: getValueFromLangJson('repayment_date'),
            sortable: false,
            field: 'paid_date',
            resizable: true,
            resizable: true,
            width: 200,
            renderCell: params => fill_null(params.row.paid_date)
        },
        {
            headerName: getValueFromLangJson('repayment_amount'),
            sortable: false,
            field: 'paid_amount',
            resizable: true,
            width: 270,
            renderCell: params => lbl_amt(params.row.paid_amount),

        }

    ]

    return (
        <Box sx={{ pb: 4 }}>

            <HeaderVarientH2Typo
                text={getValueFromLangJson('loan_disb_report')}
                overRideStyles={{
                    color: theme.palette.text.primary,
                    textTransform: "capitalize",
                    fontWeight: 600,
                    py: 4,
                    textAlign: 'center'
                }}
            />
            <Box>
                <FormProvider methods={methods} onSubmit={onSubmit}>
                    <Box>
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12} lg={3}>
                                    <RHFAutocomplete
                                        name="status"
                                        options={loanDisbStatus}
                                        label={getValueFromLangJson('status')}
                                        {...RhfOptionsTypeAndKeyCheckProperties}
                                        sx={{
                                            // overides the end icon style
                                            "& .MuiButtonBase-root": {
                                                transform: "rotate(0deg)!important",
                                                svg: {
                                                    width: "20px",
                                                    height: "20px",
                                                    color: (theme) =>
                                                        // @ts-ignore
                                                        theme.palette.custom_colors.secondary_a_200,
                                                },
                                            },
                                        }}
                                        popupIcon={<FilterAltOutlinedIcon />}
                                        disableClearable
                                    />
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <RhfDatePicker
                                        name={'start_date'}
                                        disableFuture
                                        label={getValueFromLangJson('start_date')}
                                        format={'DD MMM YYYY'}
                                        sx={{
                                            "& .MuiButtonBase-root": {
                                                svg: {
                                                    width: "20px",
                                                    height: "20px",
                                                    color: (theme) =>
                                                        // @ts-ignore
                                                        theme.palette.custom_colors.secondary_a_200,
                                                },
                                            },
                                        }}
                                        maxDate={moment()}
                                    />

                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <RhfDatePicker
                                        name={'end_date'}
                                        disableFuture
                                        label={getValueFromLangJson('end_date')}
                                        format={'DD MMM YYYY'}
                                        sx={{
                                            "& .MuiButtonBase-root": {
                                                svg: {
                                                    width: "20px",
                                                    height: "20px",
                                                    color: (theme) =>
                                                        // @ts-ignore
                                                        theme.palette.custom_colors.secondary_a_200,
                                                },
                                            },
                                        }}
                                        maxDate={moment()}
                                    />
                                </Grid>

                                <Grid item xs={12} md={2} lg={1} textAlign="center">

                                    <CustomButton
                                        //  @ts-ignore
                                        variant="full_width_inter_sm"
                                        text={'Submit'}
                                        type={"submit"} component="button"
                                        style={{
                                            borderRadius: '10px',
                                            background: theme.palette.secondary.main
                                        }}
                                        isDisabled={isDisabled}


                                    />

                                </Grid>

                            </Grid>
                        </Box>
                        <Box>
                            <Grid container paddingY={4}>
                                {Object.keys(loanMetrics).map((key) => {
                                    return <Grid item lg={3} md={6} xs={12}>
                                        <TextField
                                            label={`${getValueFromLangJson('late_payment')} ${key} ${getValueFromLangJson('days')}`}
                                            // val={data ? data[d] : ""}
                                            val={loanMetrics[key]}
                                            direction="column"
                                            pairKeyCase="capitalize"
                                            pairKeyColor="#B2B2B2"
                                            pairValCase="capitalize"
                                            isLoading={isLoading}
                                        />
                                    </Grid>

                                })}

                            </Grid>
                        </Box>

                    </Box>
                </FormProvider>
            </Box>
            <Box container >
                {!isLoading && totalCount > 0 &&
                    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                        <Box display={'flex'} >
                            <BodyVarientTwoTypo text={`${getValueFromLangJson('total_entries')} :`} overRideStyles={{ color: "#B2B2B2" }} />
                            <HeaderVarientH4Typo
                                text={totalCount}
                                overRideStyles={{
                                    color: theme.palette.text.primary,
                                    paddingLeft: 1
                                }}
                            />
                        </Box>

                        <Box display={'flex'} alignSelf={'center'}>
                            <ExportCsv
                                total_count={totalCount}
                                handleExport={handleExportCSV}
                                export_csv_datas={exportData}
                                csvFileName={'Monthly FA Report'}
                                paginationMode={"server"}
                                columns={columndata}
                                isCsvExportedToMail={isCsvExportedToMail}
                            />
                        </Box>
                    </Box>
                }
                <DataGridTable
                    columns={columndata}
                    rows={loanData}
                    setpage={(newPage) => handleChangePage(newPage)}
                    rowCount={totalCount}
                    paginationMode={"server"}
                    isLoading={isLoading}
                    page={page}
                    noOfColumnsToLoad={5}
                />
            </Box>
        </Box>
    );

}


export default LoanDisbursementReport;