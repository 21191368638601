import { call_fetch, admin_path, app_path } from './../helpers/fetch_helper';
import { get } from '../helpers/storage_helper';
import { get_path } from '../helpers/common_helper';


export const submitLead = request => {
    return call_fetch(get_path('kyc') + '/lead/create', request);
}
export const viewLead = request => {
    return call_fetch(get_path('kyc') + '/lead/view', request);
}
export const searchLead = request => {
    return call_fetch(get_path("kyc") + '/lead/search', request);
}
export const closeLead = request => {
    return call_fetch(get_path('kyc') + '/lead/close', request);
}
export const allowManualCapture = request => {
    return call_fetch(get_path('kyc') + '/lead/allow_capture', request);
}
export const updateAuditedby = request => {
    return call_fetch(get_path('kyc') + '/lead/audited_by', request);
}
export const view_remarks = request => {
    return call_fetch(get_path('kyc') + '/lead/remarks/view', request);
}
export const add_remarks = request => {
    return call_fetch(get_path('kyc') + '/lead/remarks/add', request);
}
export const allocate_lead = request => {
    return call_fetch(get_path('kyc') + '/lead/allocate_lead', request);
}

export const stmtUpload = request => {
    return call_fetch(get_path('kyc') + '/lead/stmt_upload', request)
}
export const stmtRemove = request => {
    return call_fetch(get_path('kyc') + '/lead/stmt_remove', request)
}
export const fileProcess = request => {
    return call_fetch(get_path('kyc') + '/lead/file_process', request)
}

export const AssignAuditor = request => {
    return call_fetch(get_path('kyc') + '/auditor/assign', request);
}

export const UpdateFlags = request => {
    return call_fetch(get_path('kyc') + '/lead/update_flags', request);
}
export const addComments = request => {
    return call_fetch(get_path('kyc') + '/lead/add_comment', request);
}
export const auditKycVerification = request => {
    return call_fetch(get_path('kyc') + '/lead/verify', request);
}
export const auditKyRetrievals = request => {
    return call_fetch(get_path('kyc') + '/audit_kyc/retry_holder_name_retrievals', request);
}
export const auditKycFileUpload = request => {
    return call_fetch(get_path('kyc') + '/audit_kyc/submit_holder_name_proof', request);
}
export const submitCallLog = request => {
    return call_fetch(get_path('kyc') + '/lead/call_log', request);
}

export const rekycVerify = request => {
    return call_fetch(get_path('kyc') + '/lead/rekyc_verify', request);
}

export const RejectCallLog = request => {
    return call_fetch(get_path('kyc') + '/lead/reject_call_log', request);
}
export const auditkycModify = request => {
    return call_fetch(get_path('kyc') + '/audit_kyc/modify_holder_name', request);
}
export const RotatedImageUpload = request => {
    return call_fetch(get_path('kyc') + '/file/update/audit_img', request);
}

export const kycPendingLeads = request => {
    return call_fetch(get_path('kyc') + '/lead/kyc_pending_leads', request);
}

export const updateAccPrvdrCode = request => {
    return call_fetch(get_path('kyc') + '/lead/update_acc_prvdr_code', request);
}
export const getHolidayList = request => {
    return call_fetch(get_path("app") + '/common/holiday_list', request);

}

export const get_pending_list = request => {
    return call_fetch(get_path('kyc') + '/cust/verification/get_pending_list', request);
}

export const get_data = request => {
    return call_fetch(get_path('kyc') + '/cust/verification/get_data', request);
}

export const update_lead = request => {
    return call_fetch(get_path('kyc') + '/cust/verification/update', request);
}

export const retrieve_name = request => {
    return call_fetch(get_path('kyc') + '/cust/verification/retrieve_name', request);
}

export const submit_handler = (request, type) => {
    return call_fetch(get_path('kyc') + `/cust/verification/action/${type}`, request);
}

export const submit_holder_name_proof = (request) => {
    return call_fetch(get_path('kyc') + '/cust/verification/submit_holder_name_proof', request);
}

export const updateUnderWatch = request => {
    return call_fetch(get_path('kyc') + '/lead/under_watch', request);
}
export const getLeadPipeline = request => {
    return call_fetch(get_path('kyc') + '/lead/lead_pipeline/list', request);
}
export const getLeadPipelineCount = request => {
    return call_fetch(get_path('kyc') + '/lead/lead_pipeline/count', request);
}
export const selfRegistrationReport = request => {
    return call_fetch(get_path('kyc') + '/lead/self_reg_report', request);
}
export const verified_by_audit = request => {
    return call_fetch(get_path('kyc') + '/cust/verification/verified_by', request);
}

export const updateMismatchReason = request => {
    return call_fetch(get_path('kyc') + '/lead/update_kyc_acc_info', request);
}

export const missingDocuments = request => {
    return call_fetch(get_path('kyc') + '/lead/missing_documents', request);
}

export const getRMnamebySalesRep = (request) => {
    return call_fetch(get_path('kyc') + '/rel_mgr/name_by_sales_rep', request);
}

export const leadReopen = (request) => {
    return call_fetch(get_path('kyc') + '/lead/reopen', request);
}

export const digitalKyc = (request) => {
    return call_fetch(get_path('kyc') + '/lead/digital_kyc/initiate', request);
}

export const processDigitalKyc = (request) => {
    return call_fetch(get_path('kyc') + '/lead/digital_kyc/process', request);
}

export const verifyHolderName = (request) => {
    return call_fetch(get_path('kyc') + '/lead/digital_kyc/verify_holder_name', request);
}

export const digitalKycFileRemove = (request) => {
    return call_fetch(get_path('kyc') + '/lead/digital_kyc/file_remove', request);
}

export const digitalKycOtpVerification = (request) => {
    return call_fetch(get_path('kyc') + '/lead/digital_kyc/sent_otp', request);
}


export const viewIdentityVerification = (request) => {
    return call_fetch(get_path('kyc') + '/lead/digital_kyc/view_identity_verification', request);
}

export const completeIdVerif = (request) => {
    return call_fetch(get_path('kyc') + '/lead/digital_kyc/complete_id_verif', request);
}












