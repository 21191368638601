import {
  Box,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormHelperText,
  styled,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

interface RHFCheckBoxProps extends CheckboxProps {
  name: string;
  label?: string;
  customOnchangeCb?: () => void;
}

// custom checked icon
const Icon = styled("span")(() => ({
  borderRadius: 5,
  width: 25,
  height: 25,
  backgroundColor: "#fff",
}));

const CheckedIcon = styled(Icon)({
  backgroundColor: "#137cbd",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 25,
    height: 25,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

const RHFCheckBoxField = ({
  name,
  label,
  customOnchangeCb,
  ...others
}: RHFCheckBoxProps) => {
  const { control, setValue } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <FormControlLabel
            sx={{
              m: 0,
            }}
            control={
              <Checkbox
                {...field}
                onChange={(event) => {
                  if (customOnchangeCb) {
                    setValue(name, event.target.checked, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                    customOnchangeCb();
                  } else
                    setValue(name, event.target.checked, {
                      shouldValidate: true,
                      shouldDirty: true,
                    });
                }}
                checked={field.value}
                sx={{
                  "&.Mui-checked": {
                    color: "#0594FB",
                  },
                  "& .MuiTouchRipple-root": {
                    border: "none",
                  },
                  "& span": {
                    border: fieldState.error ? "solid 2px #DC3545" : "none",
                  },
                }}
                checkedIcon={<CheckedIcon />}
                icon={<Icon />}
                {...others}
              />
            }
            label={label}
          />
          {/* error text UI */}
          {fieldState.error && (
            <FormHelperText
              sx={{
                fontSize: "10px",
                margin: 0,
                color: "#DC3545!important",
              }}
            >
              {fieldState.error.message}
            </FormHelperText>
          )}
        </Box>
      )}
    />
  );
};

export default RHFCheckBoxField;
