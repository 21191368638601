import { Button, ButtonProps, SxProps } from "@mui/material";

interface CommonMuiBtnProps extends ButtonProps {
  text: string;
  onClickHandler?: () => void;
  sx?: SxProps;
}

const CommonMuiBtn = ({
  text,
  onClickHandler,
  sx,
  ...props
}: CommonMuiBtnProps) => {
  return (
    <Button
      {...props}
      sx={{
        backgroundColor: "#0594FB",
        color: (theme) => theme.palette.text.primary,
        "&.Mui-disabled": {
          backgroundColor: "#111936 !important",
        },
        ":hover": {
          backgroundColor: "#40AFFF",
        },
        ...sx,
      }}
      onClick={
        props.type === "button" && onClickHandler
          ? () => onClickHandler()
          : () => {}
      }
    >
      {text}
    </Button>
  );
};

export default CommonMuiBtn;
