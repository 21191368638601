import { useTheme } from "@emotion/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, Box } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import * as React from "react";

interface CommonAccordionProps {
  titleUI: React.ReactNode;
  body: React.ReactNode;
  totalCount?: number | null;
}

/**
 *
 * @param titleUI
 * @param body
 * @param totalCount
 * @returns this component gets the titleUI, body and totalCount(optional) as props and returns the accordion UI
 */
const MUIAccordion: React.FC<CommonAccordionProps> = ({
  titleUI,
  body,
  totalCount,
}) => {
  const theme = useTheme();

  return (
    <Accordion
      sx={{
        // @ts-ignore
        background: theme.palette.custom_colors.old_primary_400,
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        "&.MuiPaper-root": {
          marginY: "25px !important",
        },
        ":before": {
          height: "0px",
        },
        borderRadius: "5px",
      }}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon sx={{ fontSize: "28px", fontWeight: "400" }} />
        }
        aria-controls="panel-content"
        id="panel-header"
      >
        <Box display={"flex"} alignItems={"center"} flex={1}>
          <Box display={"flex"} alignItems={"center"} flex={1}>
            <>{titleUI}</>
            {totalCount && totalCount > 0 ? (
              <Box
                component={"span"}
                sx={{
                  ml: 2,
                  // @ts-ignore
                  backgroundColor: theme.palette.secondary.main,
                  borderRadius: "50%",
                  padding: "4px 10px",
                  fontSize: "13px",
                }}
              >
                {" "}
                {totalCount}
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>{body}</AccordionDetails>
    </Accordion>
  );
};

export default MUIAccordion;
