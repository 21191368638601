import { useQuery } from "@tanstack/react-query";
import { common_req, get_path } from "../../../../helpers/common_helper";
import { call_fetch } from "../../../../helpers/fetch_helper";

type OrientationListProps = { data: UserDataProps[] };

type UserDataProps = {
  task_json: {
    acc_prvdr_code: string;
    account_num: string;
    cust_name: string;
    flow_rel_mgr_number: string;
    mobile_num: string;
    eligible_amount: string;
    rm_name: string;
    onboarded_date: string;
    flags: Record<string, boolean>;
    distributor_code: string;
  };
  id: string;
  lead_id: string;
  country_code: string;
  cust_id: string;
};

export type OrientationNewCustProps = {
  id: string;
  account_num: string;
  acc_prvdr_code: string;
  cust_id: string;
  cust_name: string;
  mobile_num: string;
  rm_name: string;
  onboarded_date: string;
  flow_rel_mgr_number: string;
  eligible_amount: string;
  distributor_code: string;
};

/**
 *
 * @returns new customers orientation list and it's loading and error state
 */
export const useGetNewCustOrientationList = () => {
  // const
  const reqObj = common_req({} as any);
  delete reqObj.access_token;

  // query
  const { data, isError, isLoading } = useQuery<OrientationListProps>({
    queryKey: ["new_cust_orientation_list"],
    queryFn: () =>
      call_fetch(
        // @ts-ignore
        `${get_path("app")}/task/list_orientation`,
        reqObj
      ),
    // staleTime: 1000 * 60 * 60 * 24,
  });

  const newCustList =
    data?.data?.map((d) => ({
      id: d?.id,
      lead_id: d?.lead_id,
      account_num: d?.task_json?.account_num,
      acc_prvdr_code: d?.task_json?.acc_prvdr_code,
      cust_id: d?.cust_id,
      cust_name: d?.task_json?.cust_name,
      mobile_num: d?.task_json?.mobile_num,
      rm_name: d?.task_json?.rm_name,
      onboarded_date: d?.task_json?.onboarded_date,
      flow_rel_mgr_number: d?.task_json.flow_rel_mgr_number,
      eligible_amount: d?.task_json.eligible_amount,
      distributor_code: d?.task_json?.distributor_code,
    })) ?? [];

  return { newCustList, isError, isLoading };
};

/**
 *
 * @returns particular new customer's orientation list and it's loading and error state
 */
export const useGetNewCustOrientationListByTaskId = ({
  taskId,
}: {
  taskId: string;
}) => {
  // const
  const reqObj = common_req({} as any);
  delete reqObj.access_token;

  // query
  const { data, isError, isLoading, isSuccess } = useQuery<{
    data: UserDataProps;
  }>({
    queryKey: ["new_cust_orientation_task"],
    queryFn: () =>
      call_fetch(
        // @ts-ignore
        `${get_path("app")}/task/get_task_details`,
        { ...reqObj, task_id: taskId }
      ),
    refetchOnWindowFocus: false,
    staleTime: 5 * 1000,
  });

  const newCustData = isSuccess
    ? {
        id: data?.data?.id,
        account_num: data?.data?.task_json?.account_num,
        acc_prvdr_code: data?.data?.task_json?.acc_prvdr_code,
        cust_id: data?.data?.cust_id,
        cust_name: data?.data?.task_json?.cust_name,
        mobile_num: data?.data?.task_json?.mobile_num,
        rm_name: data?.data?.task_json?.rm_name,
        onboarded_date: data?.data?.task_json?.onboarded_date,
        flow_rel_mgr_number: data?.data?.task_json.flow_rel_mgr_number,
        eligible_amount: data?.data?.task_json.eligible_amount,
        flags: data?.data?.task_json?.flags,
        lead_id: data?.data?.lead_id,
        distributor_code: data?.data?.task_json?.distributor_code,
      }
    : undefined;

  return { newCustData, isError, isLoading };
};
