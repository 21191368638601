import { Grid, SxProps, useTheme } from "@mui/material";
import { ReactNode } from "react";
import SkeletonLoader from "../skeletonLoader";
import { BodyVarientTwoTypo } from "../typography";

type TextTransform =
  | "capitalize"
  | "full-size-kana"
  | "full-width"
  | "lowercase"
  | "none"
  | "uppercase";

/**
 *
 * @param param0 label
 * @param param1 val
 * @param param2 direction used to show field view in row or column direction
 * @returns using the lable and val shows the field view
 */
const TextFieldWithComponentPair = ({
  label,
  direction,
  styleOverrides,
  pairKeyColor,
  pairKeyCase = "uppercase",
  pairChildren,
  isLoading,
}: {
  label: string;
  direction: "row" | "column";
  styleOverrides?: SxProps;
  pairKeyColor?: string;
  pairKeyCase?: TextTransform;
  isLoading?: boolean;
  pairChildren: ReactNode;
}) => {
  // hook
  const theme = useTheme();

  return (
    <Grid container sx={{ ...styleOverrides }}>
      <Grid item xs={direction === "row" ? 5 : 12}>
        {isLoading ? (
          <SkeletonLoader variant="rectangular" height={17} width={150} />
        ) : (
          <BodyVarientTwoTypo
            text={label}
            overRideStyles={{
              color: pairKeyColor || theme.palette.text.primary,
              textTransform: pairKeyCase,
            }}
          />
        )}
      </Grid>
      <Grid item xs={direction === "row" ? 7 : 12}>
        {isLoading ? (
          <SkeletonLoader
            variant="rectangular"
            height={17}
            width={110}
            overRideStyles={{ mt: 1 }}
          />
        ) : (
          <>{pairChildren}</>
        )}
      </Grid>
    </Grid>
  );
};

export default TextFieldWithComponentPair;
