import { FormLabel, Typography } from "@mui/material";
import { BodyVarientTwoTypo } from "../../typography";

/**
 *
 * @required boolean used to show * required symbol
 * @returns the form label UI
 */
const FormLabelUI = ({
  required,
  label,
}: {
  label: string;
  required: boolean;
}) => {
  return (
    <FormLabel
      required={required}
      sx={{
        "& .MuiFormLabel-asterisk": {
          // adds style to *
          color: "#DC3545",
          fontSize: "18px",
        },
        // adds style to label
        fontSize: "10px",
        margin: 0,
        color: "white !important",
        width:'100% !important',
        textAlign:'center'
      }}
    >
      <BodyVarientTwoTypo text={label} overRideStyles={{fontWeight:500 }}  />
      
    </FormLabel>
  );
};

export default FormLabelUI;