import { Grid, Box } from "@mui/material";
import TextField from "../../CommonComponent/field/textField";

const dummyObjForLoader = {
  i: "ii",
  j: "jj",
  k: "kk",
  l: "ll",
  m: "mm",
};

const AdditonalFieldUI = ({
  isLoading,
  isError,
  data,
}: {
  isLoading: boolean;
  isError: boolean;
  data: Record<string, string> | undefined;
}) => {
  if ((!data && !isLoading) || isError)
    return (
      <Box sx={{ p: 2 }}>
        <TextField label="" val="" direction="column" isLoading />
      </Box>
    );

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2} alignItems="center">
        {Object.keys(data ?? dummyObjForLoader).map((d, dIdx) => (
          <Grid item xs={6} md={3} key={dIdx}>
            <TextField
              label={d}
              val={data ? data[d] : ""}
              direction="column"
              pairKeyCase="capitalize"
              pairKeyColor="#B2B2B2"
              pairValCase="capitalize"
              isLoading={isLoading}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AdditonalFieldUI;
