import TextField, { TextFieldProps } from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

export type RHFTextFieldProps = TextFieldProps & {
  name: string;
};

export default function RHFTextField({
  name,
  helperText,
  type,
  ...other
}: RHFTextFieldProps) {
  const {
    control,
    setValue,
    getValues,
    formState: { isDirty },
  } = useFormContext();

  // const
  const thisFormFieldVal = getValues(name);

  // state
  const [inputValue, setinputValue] = useState<string>(thisFormFieldVal);

  // at initial set the thisFormFieldVal into the local state
  useEffect(() => {
    setinputValue(thisFormFieldVal);
  }, [thisFormFieldVal]);

  // effect
  // to prevent a re-render on every keystroke by the user,
  // the setValue function from rhf is updated as a side effect of the onChange event
  useEffect(() => {
    const interval = setTimeout(
      () =>
        setValue(name, inputValue, {
          shouldDirty: true,
          shouldTouch: true,
          ...(isDirty && { shouldValidate: true }), // only re validated after user interaction
        }),
      500
    );
    return () => clearInterval(interval);
  }, [inputValue, isDirty]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          type={type}
          value={inputValue}
          onChange={(event) => setinputValue(event.target.value)}
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
        />
      )}
    />
  );
}
