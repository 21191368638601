import FlowComponent from '../common/component/core/FlowComponent';
import React from 'react';
import {Link} from 'react-router-dom';
import { get } from '../../helpers/storage_helper';
import {borrowerSearch} from '../../actions/borrower_actions';
import {dd} from '../../actions/common_actions';
import  {check_priv} from '../../helpers/storage_helper';
import  {format_month_change, getValueFromLangJson} from '../../helpers/common_helper';
import '../../styles/flow.css';
import $ from 'jquery';
import Accordion from 'react-bootstrap/Accordion';
import {Card} from 'react-bootstrap';
import {BsChevronDown} from 'react-icons/bs';
import StatusButton from '../common/component/core/StatusButton';
import { Homeacc_prvdrLogoselector } from '../../helpers/common_helper';
import CommonReactTable from '../../helpers/commonReact_table';

class ExpiredAgreement extends FlowComponent {
     
    state={
        show_table: false,
        columndatas:[]
    }
    componentWillMount(){
        const borrower_search_criteria = {have_expired_agrmnt : "true"};

        borrowerSearch(this.req({borrower_search_criteria}, true))
        .then((response) => {
            if(!response){return };
            if(response){
                this.setState(state=>state.rowdatas = response.data.results)
                this.setState(state=>state.columndatas = this.columndatas())
            }
        });
    }
    handleToggle = (e) => {

        switch(e){
            case("Account"):
                this.setState(state => state.show_table = !this.state.show_table);
                break;
        }
    }
    columndatas () {
        return[
          {
            Header: "Account Number",
            id: 'name',    
            accessor: row => {return row.acc_number}
          },
          {
            Header: "Cust Name",
            id: 'first_name',    
            accessor: row => {return row.first_name}
          },
          {
            Header: "Cust ID",
            id: 'cust_id',    
            accessor: row => { return row.cust_id}
          },
          {
            Header: "Biz Name",
            id: 'Biz Name',    
            accessor:  row => {return row.biz_name}
          },
          {
            Header: `${getValueFromLangJson("rm_label_short")} Name`,
            id: 'flow_rel_mgr_name',    
            accessor:  row => {return row.flow_rel_mgr_name}
          },
          {
            Header: "Category",
            id: 'Categoryame',    
            accessor:  row => {return row.category}
          },
          {
            Header: "Agreement Valid Upto",
            id: 'Agreement Valid Upto',    
            accessor:  row => {return row.aggr_valid_upto != null ? format_month_change(row.aggr_valid_upto) : "-NA-"}
          },
          {
            Header: "Renewal Reason",
            id: 'Renewal Reason',    
            accessor:  row => {return "Agreement Expired"}
          },
          // { 
          //   Header: "Action",
          //   id: 'action',  
          //   accessor: row => { return check_priv('cust_agreement','sign_upload') &&  <Link to={"/agreement/upload/"+ row .cust_id} target="_blank">Renew</Link>}  
          // },    
        ]
      }
    render(){
        return(
            <div>
                <Accordion>
                 <Card>
                {/* <div className="card"> */}
                    <div className="card-header card-header-icon card-header-primary d-flex justify-content-between ">
                    {/* <div class="d-flex justify-content-between">...</div> */}
                    <div>
                    <h4 className="card-title text-white d-inline-block" style={{padding:'15px 0px 25px 0px'}}>Agreements Renewal</h4>
                    </div>
                    <div>
                    <Accordion.Toggle className = "col" as = {Card.Header} eventKey="0" onClick = {()=>this.handleToggle("Account")}>
                         <BsChevronDown className = {this.state.show_table ? "arrow-icon down renewal_arrow" : "arrow-icon renewal_arrow"} size="18"/>
                    </Accordion.Toggle>
                    </div>
                    </div>
                    {this.state.show_table ?
                    <div className="card-body">
                        <div className="table-responsive">
                        {/* <CommonReactTable showPag={true} minRow={0} exportCSV={true} csvData={this.state.rowdatas !== undefined ? this.state.rowdatas : []} row_data={this.state.rowdatas !== undefined ? this.state.rowdatas : []} column_data={this.state.columndatas} resizable={false}  csvFile={"Agreements Renewal"} />   */}

                        <BasicReactTable  row_data={this.state.rowdatas !== undefined ? this.state.rowdatas : []} column_data={this.state.columndatas} pagination={true}   FName = {"Agreements Renewal"}  csv_file={true}  />  

                    </div>
                    </div>
                    : " "
                 }
                {/* </div> */}
                </Card>
                </Accordion>
            </div>

        );
      }
}
export default ExpiredAgreement;
