import { Box, Button } from "@mui/material";
import { useFormContext } from "react-hook-form";
import RHFTextField from "../../../CommonComponent/rhfComponents/rhfTextField";
import TextWrappedByHrTag from "../../../CommonComponent/textWrappedByHrTag";
import CommonMuiBtn from "../../../CommonComponent/commonMuiBtn";

/**
 *
 * @returns the remarks field UI with the submit button
 */
const RemarksUI = () => {
  // rhf ctx
  const {
    formState: { isValid, isSubmitting },
  } = useFormContext();
  return (
    <>
      <TextWrappedByHrTag text={"Remarks"} />
      <Box p={2}>
        <RHFTextField
          name="remarks"
          multiline
          rows={5}
          placeholder="Remarks"
          sx={{ mb: 2, "& .MuiInputBase-root": { backgroundColor: "#111936" } }}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CommonMuiBtn
          type="submit"
          disabled={!isValid || isSubmitting}
          text="Submit"
          sx={{
            backgroundColor: (theme) =>
              //@ts-ignore
              theme.palette.custom_colors.secondary_500,
            minWidth: "100px",
            height: "40px",
            textTransform: "capitalize",
          }}
        />
      </Box>
    </>
  );
};

export default RemarksUI;
