import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import { Modal , Button} from 'react-bootstrap';

import {createLoanComment,getAssignList} from '../../../actions/loan_actions';
import {getAppUsers} from '../../../actions/common_actions';
import '../../../styles/flow.css';

class AddComment extends FlowComponent {
  state = {
     modalBox: false,
     comp_name: "loan_comment",
     dropdown_div_style: "disable_div",
     statevalue : ""
  }

componentWillMount(){

  if(this.props.data)
  {
    const person_id = this.props.data.cmt_to;
    getAssignList(this.req({person_id}))
            .then((response) => { 
              if(!response){return };

                if(response.status === "success"){

                    this.setState({email_id: "(" + (response.data.email_id)+")", 

                                  mobile_num: "(" + (response.data.mobile_num)+")"
                    
                    });      
        
             }
            
    }
    );
  }
   
}


handleCommentClick = (event) => {
    //const country_code = this.country_code;
    //this.getSelectOptionsByType("common","all_users",{country_code});
    const request = {status: "enabled"};
        getAppUsers(this.req(request))
        .then((response) => {
          
          if(!response){return };
            if(response.status === "success"){
             
                this.getSelectOptionsFromResult(response.data.list, "app_users", ["id", "name"]);
            }else{
              alert(response.message + "\n" + JSON.stringify(response.data));  
            }
          }
        );

    this.setState({modalBox: true});
}

handleClose = (event) => {
    this.setState({ modalBox: false });
}

handleTaskClick = (event) =>
{
  if(event.target.checked){
    this.create_task = true;
  }
  else
  {
    this.create_task = false;
  }
 
}

handleSmsClick = (event) =>
{
  if(event.target.checked){
    this.send_sms = true;
  }else{
    this.send_sms = false;
  }
}

handleEmailClick = (event) =>
{
   if(event.target.checked){
    this.send_email = true;
  }else{
    this.send_email = false;
  }
}



handleSubmit = (event)=>{
    event.preventDefault();
          const loan_comments = this.props.data;
          loan_comments.country_code = this.country_code;
          loan_comments.loan_doc_id= this.props.loan_doc_id;
          loan_comments.task =  this.create_task;
          loan_comments.sms = this.send_sms;
          loan_comments.email = this.send_email;
          loan_comments.cmt_to = this.assign_by.value;
         createLoanComment(this.req({loan_comments}))
            .then((response) => {
              if(!response){return };

                if(response.status === "success"){
          
                  window.location.reload();
       
                }          
    }
    );
  }

  handleDropdownValue = (event)=>{

    const person_id = event.target.value;

    getAssignList(this.req({person_id}))
            .then((response) => { 
              if(!response){return };

                if(response.status === "success"){

                    this.setState({email_id: "(" + (response.data.email_id)+")", 

                                  mobile_num: "(" + (response.data.mobile_num)+")"
                    
                    });      
        
             }
            
    }
    );


     if( this.state.dropdown_div_style === "disable_div"){

      this.setState({dropdown_div_style: "show_div"});
      this.setState({div_style: "disable_div"});

    }
  }
 

  render(){
    
    return (
    <div id="" >
        <div className="row">
          <div className="col-md-6">
            <Button variant={this.props.variant} onClick={this.handleCommentClick}>{this.props.btn_name || "Add Comment"}</Button> 

          </div>
        </div>
        <br/>

        <Modal className="content" show={this.state.modalBox} onHide={this.handleClose}>
              <Modal.Header closeButton>
                  <Modal.Title>{this.props.title ||"FA Comment"}</Modal.Title>
              </Modal.Header>

              <Modal.Body>


               <div className="row">
      
                  <div className="col-md-6">
                  <span>Comment Type</span><font className="text-danger">   *  </font>     
                  <select id="cmt_type" onChange={this.handleChange} className="form-control" type="text">
                            {this.getSelectOptions("comment_type")}
                  </select>
                  </div>
                  <div className="col-md-6">
                  </div>
               </div><br/>
                 <div className="row">
                    <div className="col-md-6">
                        <label>Assign To</label>      
                      <select id="cmt_to" value ={this.props.data ? this.props.data.cmt_to : null} ref={select => this.assign_by = select}
                            onChange={(e) => {this.handleChange(e); this.handleDropdownValue(e)}} 

                            className={`form-control ${this.props.data ? this.state.div_style= "disable_div" : this.state.div_style=""}`}type="text" >
                            {this.state.app_users_dd}
                      </select>
                    </div>

                      <div className="col-md-6">
                       <span><div id="" className={`${this.state.dropdown_div_style}`} >
                       <div className="checkbox">
                       <label><input type="checkbox" onClick={this.handleTaskClick}/> &nbsp;&nbsp;{"Create Task"}</label>
                       </div>
                       </div>
                       </span> 
                    </div>
                    </div><br/>

                  <div className="row">
                  <div className="col-md-12">
                    <span>Comment</span><font className="text-danger">   *  </font>      
                    <textarea id="comment"  className="form-control"onChange={this.handleChange}/>
                  </div>
                  </div><br/>


                  <div className="row">
                  <div className="col-md-6">
                    <span><div id="" className={`${this.state.dropdown_div_style}`} >
                    
                    <div className="checkbox">
                    <label><input type="checkbox" id="checkbox" onClick={this.handleSmsClick} />&nbsp;&nbsp;{"Send Sms"}{(this.state.mobile_num)}</label>
                    </div>
                    </div>
                    </span>
                  </div>

                  <div className="col-md-6">
                  <span><div id="" className={`${this.state.dropdown_div_style}`} >
                  <div className="checkbox">
                    <label><input type="checkbox" onClick={this.handleEmailClick} />&nbsp;&nbsp;{"Send Email"}{(this.state.email_id)}</label>
                  </div>
                  </div>
                  </span>
                  </div>

                </div>

              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>Cancel</Button>
                <input type="button" className="btn btn-primary" onClick={this.handleSubmit} value="Submit"/>
              </Modal.Footer>
          </Modal>
    </div>
      );
  }
}

export default AddComment;
