import React from 'react'
import FlowContainer from '../../common/container/core/FlowContainer'
import { getSalesDetails, getSalesTxns } from '../../../actions/loan_product_actions'
import CommonReactTable from '../../../helpers/commonReact_table'
import { Link } from 'react-router-dom'
import { getValueFromLangJson, Homeacc_prvdrLogoselector, lbl_amt, page_count, rt_algn, title_case } from '../../../helpers/common_helper'
import moment from 'moment'
import { TooltipLabel } from '../../common/partial'
import {ImArrowUpRight,ImArrowDownLeft} from "react-icons/im";
import { Modal } from 'react-bootstrap'
import { FcCalendar, FcExternal, FcInternal } from 'react-icons/fc'
import { getFloatSwitchAccounts, getRefAccounts } from '../../../actions/account_txn_actions'
import DatePicker from 'react-datetime';
import { dd_value } from '../../../actions/common_actions'
import Select from "react-select";
import { Config } from '../../../helpers/storage_helper'
import withRouter from '../../../Router/Withrouter'
import { BasicReactTable } from '../../../helpers/react_table'
import DataGridTable from '../../CommonComponent/table'
import { ERROR_INFO } from '../../../consts'
import { Box, Button} from '@mui/material'
import ExportCsv from '../../common/component/ExportCsv'


 class SalesContainer extends FlowContainer {


	state={
			sales_search:{},
			row_data:[],
			column_data:[],
			loader:false,
			no_data:false,
			txn_data:[],
			txn_column:[],
			txn_modal:false,
			paginate : Config('paginate') ? Config('paginate') : null,
			salesData: {},
			modify_search:false,
			floatIn: [],
			floatOut:[],
			total_count:0,
			paymentProof: null,
			pageData : {
				page:0, 
				pageSize:10
			},
			copy: {},
			pageSort: [{field: "created_at", sort: 'desc'}]
	}
	componentWillMount(){
		const searchParams = this.props.params.cust_id;
		this.loadAccounts()
		this.load_flow_rel_mgr(null,"multiselect_rm_name")
		this.setOptionsListToStateFromAppApi('common', 'list', {...this.def_req ,status: "enabled", biz_account: true}, ['sales_status', 'name'], "sales_status");
		if(searchParams!="report"){
		  this.set_request_data(searchParams)			
		}

	}

	componentDidUpdate(prevProps, prevState) {
        if(prevState.pageData !== this.state.pageData || prevState.pageSort != this.state.pageSort){
           if(this.state.pageData.pageSize > this.state.total_count) return;
			this.pagination();

        }
		if (prevState.sales_search != this.state.sales_search) {
			this.setState({ copy: prevState.sales_search });
		  }
	}

	set_request_data = (searchParams) =>{

		var sales = {}
		sales['req_parameter'] = searchParams
		sales['initiated_from'] = moment().subtract(1, 'months').date(moment().date()).format('YYYY-MM-DD')
		sales['initiated_to'] =  moment().format('YYYY-MM-DD')
		this.setState({sales_search:sales,params_value:searchParams},()=>{this.handleSubmit(true,searchParams)})
	}

	loadAccounts = () => {
		var account = {"country_code": this.country_code};    
		  getFloatSwitchAccounts(this.req({account},true))
			.then((response) => {
			this.resp(response)
			  if(!response){return };
			  if(response.status === "success"){
				if(response.data != ""){
				  this.setState({accounts:response.data.acc_prvdrs})
				}     
			  }else{ 
				alert(response.message + "\n" + JSON.stringify(response.data));  
			  }
			}
		  );
	  }

	 

	getTxn=(id)=>{
		var request={
			sales_doc_id:id
		}
		
		getSalesTxns(this.req(request))
		.then((response)=>{
			this.resp(response)
			if(!response)return;
			if(response.status=='success'){
				this.setState({txn_data:response.data,txn_column:this.txn_column_data(),sales_doc_id:id},()=>this.setState({txn_modal:true}))
			}
		})
	}

	setPaymentProof = (proof) =>{
		let lastProof = null;
		if (Array.isArray(proof) && proof.length > 0) {
			 lastProof = proof[proof.length - 1];
		}

		this.setState({paymentProof : lastProof});
	}

	handleSubmit=(onMount,type=null)=>{
		this.setState({loader:true,no_data: true,row_data:[], total_count: 0, copy : {...JSON.parse(JSON.stringify(this.state.sales_search))}})
		const { sales_search } = this.state;
		const newSalesSearch = sales_search.status == null ? { ...sales_search, status: undefined } : { ...sales_search };
		var request = { sales_search: newSalesSearch };
		if(this.state.paginate != null){
			request['paginate'] = this.state.paginate
		}
		if(onMount){
			if(type != "report"){
				request = {sales_search: this.state.sales_search}
			}else{
				request = {
					sales_search: {
					  req_parameter: type !== "report" ? type : undefined,
					  created_at: moment().format('YYYY-MM-DD')
					}
				  };
			}
		
		  }
		// getSalesDetails(this.req(request))
		// .then((response)=>{
		// this.resp(response)
		// if(!response){return this.setState((state)=>{state.loader=false;state.no_data=true})}
		// if(response.status=='success'){
		// 	if(response.data.results.length<=0){this.setState({no_data:true})}
		// 	this.setState((state)=>{state.loader=false})
		// 	this.setState({row_data:response.data.results, total_count:response.data.total_count ? response.data.total_count : null },()=>this.setState({column_data:this.columnData()}))
		// }
		// })
		getSalesDetails(this.req(request))
			.then((response)=>{
			this.resp(response)
			if(!response){return this.setState({loader : false})}
			if(response.status=='success'){
				this.setState({loader : false})
				this.setState({row_data:this.handleConvertData(response.data.results), total_count:response.data.total_count ? response.data.total_count : 0 },()=>this.setState({column_data:this.columnData(), modify_search: true}))
			}
		})
	}

	columnData = () => {
		return [
			  {
				field: "transfer",
				headerName: "Transfer",
				minWidth: 200,
				align: "center",
                headerAlign: "center",
				flex: 1,
				renderCell: (params) => {
					const fromProvider = params.row.from_acc_prvdr_code;
					const toProvider = params.row.to_acc_prvdr_code;
	
					return (
						<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
							<img
								className="img-fluid"
								style={{ maxWidth: "60px", height: "37px", marginRight: "10px", borderRadius:'20px' }}
								src={`/img/${fromProvider}_switch_logo.png`}
								alt={fromProvider}
							/>
							<span style={{ fontSize: "20px", margin: "0 10px" ,color: "gray"}}>→</span>
							<img
								className="img-fluid"
								style={{ maxWidth: "60px", height: "37px", marginLeft: "10px",borderRadius:'20px' }}
								src={`/img/${toProvider}_switch_logo.png`}
								alt={toProvider}
							/>
						</div>
					);
				},
			},
			  {
				field: "sales_doc_id",
				headerName: "Switch ID",
				minWidth: 165,
				flex: 1,
				align: "center",
                headerAlign: "center",
				renderCell: (params) => {
					const row = params.row; 
					const salesDocId = params.value;
					return (
					<Link  to={{pathname: `/sales/view/${salesDocId}`,state: {row}}} target="_blank" >{title_case(salesDocId)}</Link>
					)
				},
			  }, 
			  {
				field: "cust_name",
				headerName: "Customer Name",
				minWidth: 200,
				align: "center",
                headerAlign: "center",
				flex: 1,
				renderCell: (params) => (
					<div className="text-center">{params.value}</div>
				),
			  },
			  {
				field: "cust_id",
				headerName: "Customer ID",
				minWidth: 170,
				align: "center",
                headerAlign: "center",
				flex: 1,
				renderCell: (params) => (
					<Link  to={"/borrower/indiv/view/"+params.value} target="_blank" >{title_case(params.value)}</Link>
				),
			  },                                                                                                                                                                                                                                                   

			  {
				field: "status",
				headerName: "Status",
				minWidth: 150,
				align: "center",
                headerAlign: "center",
				flex: 1,
				renderCell: (params) => (
				  <div className="text-center">{dd_value(params.value, params.value)}</div>
				),
			  },
			  {
				field: "switch_type",
				headerName: "Switch Type",
				minWidth: 150,
				align: "center",
                headerAlign: "center",
				flex: 1,
				renderCell: (params) => (
				  <div className="text-center">{title_case(params.value)}</div>
				),
			  },
			  {
				field: "product_amt",
				headerName: "Switch Amount",
				minWidth: 150,
				align: "center",
				flex: 1,
                headerAlign: "center",
				renderCell: (params) => (
					<div>{ params.value? rt_algn(lbl_amt(params.value,this.currency_code)):''}</div>
				),
			  },
			  {
				field: "created_at",
				headerName: "Initiated At",
				minWidth: 200,
				align: "center",
                headerAlign: "center",
				flex: 1,
				renderCell: (params) => (
					<div>{params.value==null ? 'NA' : moment(params.value).format("DD MMM YYYY HH:mm")}</div>
				),
			  },
			  {
				field: "channel",
				headerName: "Channel",
				minWidth: 160,
				align: "center",
                headerAlign: "center",
				flex: 1,
				renderCell: (params) => (
					<div>{title_case(params.value)}</div>
				),
			  },
			  {
				field: "action",
				headerName: "Action",
				minWidth: 200,
				align: "center",
                headerAlign: "center",
				flex: 1,
				renderCell: (params) => (
					<div className='px-3'>
						<Button
							onClick={()=>this.getTxn(params.row.sales_doc_id)}
							variant="contained"
            				color="secondary"
				  		>
						View Transactions
				  		</Button>
					</div>
				),
			  },


		]
	}


	chk_txn_type(txn_type){


				const txn_types = ['float_out', 'duplicate_disbursal','float_in_refunded', 'float_in_reversed'];
				if (txn_types.includes(txn_type)) {
					
				return<span > <ImArrowUpRight className="mr-2" color="red"/> {title_case(txn_type)}</span>
				}
				else{
					return <span> <ImArrowDownLeft className="mr-2" color = "green"/> {title_case(txn_type)}</span>
				}
			}

	txn_column_data=()=>{
		return [
			{
				Header : "SI No.",
				id: "row",
				width:50,
				Cell: (cell) => {
					return <div className='text-left pl-2'>{cell.row.index+1}</div>;
				}
			},
			{
				Header: <div className='text-center'>Transaction type</div>,
				id: "txn_type",
				accessor: row=>row.txn_type,
				minWidth:130,
				Cell: (cell) => {
					{return <div className='text-center'>{this.chk_txn_type(cell.value)}</div>}
				},
			  },
			  {
				Header: <div className='text-center'>Transaction Amount</div>,
				id: "amount",
				accessor: row=>row.amount,
				minWidth:130,
				Cell: (cell) => {
					return <div style={{paddingRight: '25%'}}>{rt_algn(lbl_amt(cell.value,this.currency_code))}</div>;
				},
			  },
			  {
				Header: <div className='text-center'>Transaction Date / Time</div>,
				id: "txn_date",
				accessor: row=>row.txn_date,
				minWidth:150,
				Cell: (cell) => {
					return <div className='text-center'>{moment(cell.value).format('DD-MMM-YYYY hh-mm a')}</div>
				}
			  },
			  {
				Header: <div className='text-center'>From Account</div>,
				id: "from_ac_name",
				accessor: row=>row.from_ac_name,
				Cell: (cell) => {
					return <><div className='text-center'><TooltipLabel className='d-block' full_text={cell.value} no_reduce={false} /></div>
					</>
				}
			  },
			  {
				Header: <div className='text-center'>To Account</div>,
				id: "to_ac_name",
				accessor: row=>row.to_ac_name,
				Cell: (cell) => {
					return <><div className='text-center'><TooltipLabel className='d-block' full_text={cell.value}  no_reduce={false} /></div>
					</>
				}
			  },
			  {
				Header: <div className='text-center'>Transaction ID</div>,
				id: "txn_id",
				accessor: row=>row.txn_id,
				Cell: (cell) => {
					return <div className='text-center'>{cell.value} 
					</div>
				}
			  },
		]
	}
	handleFilter = (e,name) => {
	
		var id;
		var value;
		if(Array.isArray(e)){
			this.setMultiselectOptionOnState(e,name,this.state.sales_search)
			this.setState({select_value:e})
		}
		else{
		 id = e.target.id;
		 value = e.target.value;
		}
		this.setState(prevState => ({
		  ...prevState,sales_search: {...prevState.sales_search,[id]: value}
		}));
	}

	handleDatepicker(value, name){
		value = moment(value).format("YYYY-MM-DD");
		if (value === "Invalid date") {
			const updatedSalesSearch = { ...this.state.sales_search };
			delete updatedSalesSearch[name];
			this.setState({ sales_search: updatedSalesSearch });
		} else {
			this.setState((prevState) => ({sales_search: {...prevState.sales_search,[name]: value,}}));
		}
	}

	

	oncheckBoxChange = (e)=>{
		var checkbox = document.getElementById('flexCheckDefault');
		var isChecked = checkbox.checked;
		if(isChecked == true){
			this.setState({sales_search :{...this.state.sales_search,is_flow_acc_transfer : true}})
		}else{
			this.setState({sales_search :{...this.state.sales_search,is_flow_acc_transfer : false}})
		}
	}
	handleClose=()=>{
		this.setState({txn_modal:false,txn_data:[]})
	}
	
	pagination = () => {
		let page = this.state.pageData.page;
		let count = this.state.pageData.pageSize;
	
		const isEqual = this.areSearchesEqual(this.state.copy, this.state.sales_search);

		this.setState((prevState) => {
	  
		  return {
			paginate: {
			  ...prevState.paginate,
			  pagination_count: count,
			  pagination_page: !isEqual ? 0 : page,
			  total_count: !isEqual ? 0 : this.state.total_count,
			},
		  };
		}, this.handleSubmit);
	  };

	   areSearchesEqual = (val1, val2) => {
		const keys1 = Object.keys(val1).filter(key => val1[key] != null);
		const keys2 = Object.keys(val2).filter(key => val2[key] != null);
	  
		if (keys1.length !== keys2.length) return false;
	  
		return keys1.every(key => val2.hasOwnProperty(key) && val1[key] === val2[key]);
	  };


	handleExportCSV = (event) => {

		const { sales_search } = this.state;
		const newSalesSearch = sales_search.status == null ? { ...sales_search, status: undefined } : { ...sales_search };
		let search_criteria = JSON.parse(JSON.stringify(newSalesSearch));
		let resp = null;
		if(event == 'email') {
			search_criteria.export = 'email'
			resp = window.confirm(`The current search exceeds ${Config("export_csv").limit} rows. So the exported data will be sent to your email shortly.`)
		}

		if(event == 'email' && !resp){
			return false
		}else{
			this.handleCSVapi(search_criteria, event)
			return true
		}
	}
	
	handleCSVapi(sales_search, action){
		let calling_api = true 
		var request={sales_search, paginate : false}
		if (action == 'email') calling_api = false 
		
		getSalesDetails(this.req(request))
			.then((response) => {
				this.resp(response)
				if(action == 'email'){
					return
				}

				if(response){

					this.setState(state=>state.export_data = this.handleConvertData(response.data.results))
				}
				
			});

		}

		handleConvertData = (data) => {
			let items = data ? data : [] ;
			const updatedData = items.map(item => ({
				...item,
				transfer: `${item.from_acc_prvdr_code} - ${item.to_acc_prvdr_code}`
			  }));

			return updatedData;
		}

		handlePage = (data) => {
			this.setState({pageData : data});
		}

		handleSort = (sort) => {
			this.setState({pageSort : sort});
		}


  render() {

	let {salesData, paymentProof} = this.state;
	const is_server_side = this.state.paginate ? true : false
    return (
      <div>
		
		<Modal className='txn_modal' show={this.state.txn_modal} onHide={this.handleClose} backdrop="static" keyboard={false}>
			<Modal.Header className='p-0 sms_for_close' closeButton>
				<Modal.Title className='sms_modal_header p-2'><h4 className=' text-center text-light'>Transactions of Float Switch ID : {this.state.sales_doc_id}</h4></Modal.Title>
			</Modal.Header>
			<Modal.Body className='text-left '>
				<div className="w-100 ">
				{this.state.txn_data && this.state.txn_data.length > 0 ?
					<BasicReactTable 
						row_data={this.state.txn_data !== undefined ? this.state.txn_data : []} 
						column_data={this.state.txn_column}  
						pagination={ false} 
						default_page_size = {10} 
						export_csv={false} 
						searchData={[{title:'Switch Transactions'}]} 
						FName={"Float Switch"}    
						export_csv_datas={this.state.txn_data !== undefined ? this.state.txn_data : [] } 
						csv_file={true}    
					/>
					:
					<h4 className='text-center p-3'>No Data Found</h4>
				}
				</div>
			</Modal.Body>
			<Modal.Footer>
				
			</Modal.Footer>
		</Modal>
	
        <div className="container containerTopmargin max-width no-padding">
				<div className="floatAdvancetitleBox">
					<h3 className="preHeaderpad no-margin" style={{paddingLeft:15,fontSize:20}}>SEARCH - Float Switch </h3>
				</div>	

				{/* {!this.state.modify_search ?  */}
				<>
					{(!this.state.params_value || this.state.modify_search) &&
					<>
				<div className='row mt-4'>
					<div className=' col-md-12  row'>
						<div className='col-md-3 col-sm-6 mt-3'>
							<div className='m-2'>
								<div className='row'>
									<label className='col-10'  htmlFor='start_date' >From</label>
									<label className='col-2  product_textr' htmlFor='start_date'><FcCalendar size={20}/></label>
								</div>							
								<DatePicker defaultValue = {moment(this.state.sales_search.initiated_from, "YYYY-MM-DD")} inputProps={{ id: 'start_date', name: 'start_date' ,autoComplete:'off'}} closeOnSelect={true} dateFormat="DD MMM YYYY" onChange={(value) => {this.handleDatepicker(value, "initiated_from")}} timeFormat={false} required="required" isValidDate={(current) => {
									return current.isSameOrBefore(moment().startOf("day"));
								}}/>
							</div>
						</div>

						<div className='col-md-3 col-sm-6 mt-3'>
							<div className='m-2'>
								<div className='row'>
									<label className='col-10'  htmlFor='end_date' >To</label>
									<label className='col-2 product_textr' htmlFor='end_date'><FcCalendar size={20}/></label>
								</div>
								<DatePicker defaultValue = {moment(this.state.sales_search.initiated_to, "YYYY-MM-DD")}  inputProps={{ id: 'end_date', name: 'end_date' ,autoComplete:'off'}} closeOnSelect={true} dateFormat="DD MMM YYYY" onChange={(value) => {this.handleDatepicker(value, "initiated_to")}} timeFormat={false} required="required" isValidDate={(current) => {
									return current.isSameOrBefore(moment().startOf("day"));
								}}
								/>
							</div>
						</div>
						<div className='col-md-3 col-sm-6 mt-3'>
							<div className='my-3 no-padding-input'>
								<label className='product_check_label'>From A/C</label>
								<select className="form-control" value={this.state.sales_search.from_acc_prvdr ? this.state.sales_search.from_acc_prvdr : ""}  onChange={(e)=>{this.handleFilter(e)}}  id="from_acc_prvdr" >
								<option value="">choose</option>
								{this.state.accounts&&this.state.accounts.length>0&&this.state.accounts.map((item,index)=>{
									return(<option key={index} value={item}>{item}</option>)
								})}
								</select>
							</div>
						</div>
						<div className='col-md-3 col-sm-6 mt-3'>
							<div className='my-3 no-padding-input'>
								<label className='product_check_label'>To A/C</label>
								<select className="form-control" value={this.state.sales_search.to_acc_prvdr ? this.state.sales_search.to_acc_prvdr : ""}  onChange={(e)=>{this.handleFilter(e)}}  id="to_acc_prvdr" >
								<option value="">choose</option>
								{this.state.accounts&&this.state.accounts.length>0&&this.state.accounts.map((item,index)=>{
									return(<option key={index} value={item}>{item}</option>)
								})}
								</select>
							</div>
						</div>

						<div className="col-md-3 col-sm-6 mt-3">
							<div className='my-3 mx-2 no-padding-input'>
								<label className='product_check_label'>Switch type</label>
								<select className="form-control"  value={this.state.sales_search.switch_type ? this.state.sales_search.switch_type : ""}  onChange={(e)=>{this.handleFilter(e)}}  id="switch_type" >
									<option value="">choose</option>
									<option value="agent">Agent</option>
									<option value="self">Self</option>
								</select>
							</div>
						</div>

						<div className="col-md-3 col-sm-6 mt-3">
							<div className='my-3 mx-2 no-padding-input'>
								<label className='product_check_label'>Status</label>
								<select className="form-control" defaultValue={null}  onChange={(e)=>{this.handleFilter(e)}}  id="status" >
								{this.getSelectOptions("float_switch_status")}
								</select>
							</div>
						</div>

						<div className='col-md-3 col-sm-6 mt-3'>
							<div className='my-3 no-padding-input'>
								<label className='product_check_label'>Channel</label>
								<select className="form-control" value={this.state.sales_search.channel ? this.state.sales_search.channel : ""}  onChange={(e)=>{this.handleFilter(e)}}  id="channel" >
								<option value="">choose</option>
								<option value="sms">SMS</option>
								<option value="ussd">USSD</option>
								<option value="cust_app">Customer App</option>
								<option value="new_cust_app">New Customer App</option>
								</select>
							</div>
						</div>
						<div className="col-md-3 col-sm-6 mt-3">
              			<div className="my-3">
						  	<label className='product_check_label'>Relationship Manager</label>
							<Select onChange={(e) => {this.handleFilter(e,'flow_rel_mgr_id')}}
                              value = {this.state.select_value}
							  options={this.state.flow_rel_mgr}
							  placeholder={getValueFromLangJson("rm_label")}
							  styles={this.select_custom_styles()}
							  classNamePrefix="my-select" // Add a custom class prefix to target the components
							  isMulti/>
               			</div>
             		</div>
					</div>
				</div>
				<div className='col my-5 w-100'>
					<div className="col-md-6 col-sm-12 pl-0 mt-3">
						<div className='sales_input_ph no-padding-input' >
								<label className='product_check_label'>Customer ID / Float Switch ID /Mobile Number</label>
								<input className='form-control' id='req_parameter' placeholder='Enter Customer ID / Float Switch ID ' onChange={(e)=>this.handleFilter(e)} type='text' value={this.state.sales_search['req_parameter']}/>
						</div>
					</div>
					<div className=' col my-5 d-flex align-items-center'>
						<div className='m-2 w-100 text-center'>
						<Button
							// onClick={()=>this.handleSubmit()}
							onClick={()=>this.pagination()}
							disabled={(this.state.loader || Object.keys(this.state.sales_search).length <= 0 )}
							// @ts-ignore
							variant="xs_small"
							type="submit"
							sx={{
							backgroundColor: "#0594FB",
							color: (theme) => theme.palette.text.primary,
							}}
				  		>
						Search
				  		</Button>
						</div>
					</div>
				</div>
               		</>
			   		}
			   </>
				{/* : */}
					<div  style={{display: this.state.no_data ? 'block':'none'}}>
						<div className='text-left pt-3'>
							<label><b>Total Count : </b></label><label className='ml-2'><b>{this.state.paginate && this.state.total_count ? this.state.total_count : this.state.row_data.length}</b></label>
						</div>

						<Box display={'flex'} justifyContent={'end'} alignItems={'center'} sx={{p: 2}}>
							<ExportCsv
								total_count={this.state.paginate && this.state.total_count ? this.state.total_count : this.state.row_data.length} 
								handleExport={this.handleExportCSV} 
								export_csv_datas={is_server_side ?( this.state.export_data ?? [] ): this.state.row_data}
								csvFileName = {'Switch Details'}
								paginationMode= {"server"}
								columns={this.state.column_data ?? []}
								isCsvExportedToMail={false}

							/>
							{/* <Box sx={{cursor : 'pointer'}} onClick={() => {this.setState({modify_search : false})}}>
								<p className='text-white m-0' style={{fontSize: '14px'}}  >
									Modify Search
								</p>
							</Box> */}
						</Box>
						
						<DataGridTable
							columns={this.state.column_data}
							rows={this.state.row_data}
							page={this.state.pageData}
							sort={this.state.pageSort}
							setpage={this.handlePage}
							setsort={this.handleSort}
							rowCount={this.state.total_count}
							paginationMode="server"
							sortingMode="server"
							errorText={ERROR_INFO}
							isLoading={this.state.loader}
							isError={false}
							noOfColumnsToLoad={5}
						/>          

					</div>
  				{/* } */}
			</div>
      </div>
    )
  }
}
export default withRouter(SalesContainer)