import {
    DispatchProps,
    END_TIMER,
    SoftPhoneTimerState,
    START_TIMER,
} from "./softPhoneTimerCtx";

function softPhoneTimerReducer(
  state: SoftPhoneTimerState,
  action: DispatchProps
) {
  const { type, payload } = action;
  switch (type) {
    case START_TIMER:
      return {
        ...state,
        timer: payload.timer,
      };

    case END_TIMER:
      return {
        ...state,
        timer: payload.timer,
      };

    default:
      return state;
  }
}

export default softPhoneTimerReducer;
