import { useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";
import { remove_file, upload_file } from "../../../../actions/file_actions";
import { ExtendedFile } from "./digitalKyc/rhfFileUploadField";
import { digitalKycFileRemove, processDigitalKyc } from "../../../../actions/lead_actions";

type Props = {
  fileOf: string;
  custId?: string;
  name: string;
  leadId?: number | string;
  customOnchangeCb?: Function;
};

const imgTypesArr = ["image/jpeg", "image/jpg", "image/png"];

const useRfhFileUploadFieldMethods = ({
  fileOf,
  custId,
  name,
  leadId,
  customOnchangeCb
}: Props) => {
  // state
  const [imgFiles, setimgFiles] = useState<ExtendedFile[]>([]); // all the image files
  const [otherFiles, setotherFiles] = useState<ExtendedFile[]>([]); // all other format files
  const [isFileUploading, setisFileUploading] = useState(false); // used to show circular progress while uploading is in progress

  // form ctx
  const { setValue } = useFormContext();

  /**
   * Converts a File object to a base64 string.
   * @param {File} f - The file to convert.
   * @returns {Promise<string>} - A promise resolving to a base64 string.
   */
  const base64ConvertHandler = (f: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = (err) => {
        reject("");
      };
      reader.readAsDataURL(f);
    });
  };

  /**
   * Uploads an individual file to the server.
   *
   * @param {ExtendedFile} file - The file object to be uploaded.
   * @returns {Promise<any>} - A promise that resolves with the server response.
   */
  const uploadFileHandler = async (file: ExtendedFile): Promise<any> => {

    return await processDigitalKyc({
      file_size: file.size,
      file_of: fileOf,
      cust_id: custId,
      lead_id: leadId,
      file_data_type: "data-url", // for base64 format
      file_name: file.name,
      file_type: file.type,
      file_data: file.dataUrl,
      stage: "identity_verification"
    });
  };

  /**
   * Uploads image and other format files one by one.
   */
  const uploadHandler = async (
    imageFiles = imgFiles,
    otherTypeFiles = otherFiles
  ) => {
    customOnchangeCb && customOnchangeCb()
    setisFileUploading(true); // start uploading
    try {
      const imageTypes = new Set(["image/png", "image/jpeg"]); // store image types
      const allFiles = [...imageFiles, ...otherTypeFiles]; // merge file arrays
      const uploadedImageFilesObj = [];
      const uploadedOtherFilesObj = [];

      for (let index = 0; index < allFiles.length; index++) {
        const file = allFiles[index];

        try {
          const uploadedRes = await uploadFileHandler(file);
          const uploadedFileName = uploadedRes.data.self_reg_json?.[fileOf]?.file_name;
          const uploadedFileRelPath = uploadedRes.data.self_reg_json?.[fileOf]?.file_rel_path;
          if (imageTypes.has(file.type)) {
            // Update imgFiles state
            uploadedImageFilesObj.push({
              ...file,
              uploadedFileName,
              uploadedFileRelPath,
              uploadedRes: uploadedRes.data?.self_reg_json
            });
          } else if (file.type === "application/pdf") {
            // Update otherFiles state
            uploadedOtherFilesObj.push({
              ...file,
              uploadedFileName,
              uploadedFileRelPath,
              uploadedRes
            });
          }
        } catch (err) {
          // alert("An error occurred while uploading");
        }
      }
      setimgFiles(uploadedImageFilesObj);
      setotherFiles(uploadedOtherFilesObj);
      setValue(name, [...uploadedImageFilesObj, ...uploadedOtherFilesObj], {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
      customOnchangeCb && customOnchangeCb("isUploaded")

    } finally {

      setisFileUploading(false); // Ensure uploading state is reset only after all files are processed
    }
  };

  /**
   * Handles the file drop, processes the files, and separates image files from others.
   *
   * @param acceptedFiles - An array of accepted files from the drop event.
   */
  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    // convert each file to ExtendedFile format with base64 data and other properties
    const fileOrFiles: ExtendedFile[] = await Promise.all(
      acceptedFiles.map(async (file) => {
        const dataUrl = await base64ConvertHandler(file); // convert file to base64

        return {
          blob: URL.createObjectURL(file), // create a Blob URL for the file
          dataUrl, // base64 representation of the file
          name: file.name, // original file name
          size: file.size, // file size
          type: file.type, // file MIME type
          file, // the file object
          uploadedFileName: "", // placeholder for uploaded file name
          uploadedFileRelPath: "", // placeholder for uploaded file relative path
        };
      })
    );

    // separate image and other file types
    const [imageFiles, otherTypeFiles] = fileOrFiles.reduce(
      ([images, others], file) =>
        imgTypesArr.includes(file.type)
          ? [[...images, file], others] // if the file type is in imgTypesArr, add to images
          : [images, [...others, file]], // otherwise, add to other files
      [[], []] as [ExtendedFile[], ExtendedFile[]]
    );

    // update the state with the separated files
    setimgFiles(imageFiles);
    setotherFiles(otherTypeFiles);
    uploadHandler(imageFiles, otherTypeFiles);
  }, []);

  /**
   * Handles removing files either locally (from state) or from the server.
   *
   * @param f - The file to remove.
   * @param cb - Callback function to update the state after removal.
   * @param fromArr - The array from which the file should be removed.
   */
  const removeHandler = async (
    f: ExtendedFile,
    cb: (updatedArray: ExtendedFile[]) => void, // More specific type for callback
    fromArr: ExtendedFile[]
  ) => {

    setisFileUploading(true);
    let selectedItemRemovedBlobArr: ExtendedFile[] = [];
    customOnchangeCb && customOnchangeCb()

    try {

      if (f.uploadedFileName) {

        // File is already uploaded to the server, so remove from server
        const req = {
          file_of: fileOf,
          file_name: f.uploadedFileName,
          file_rel_path: f.uploadedFileRelPath,
          lead_id: leadId
        };
        const response = await digitalKycFileRemove(req); // Assuming remove_file is an API call to remove the file on the server

        // Filter out the file from the array based on its `uploadedFileRelPath`
        selectedItemRemovedBlobArr = fromArr.filter(
          ({ uploadedFileName }) => uploadedFileName !== f.uploadedFileName
        );
        console.log('selectedItemRemovedBlobArr', selectedItemRemovedBlobArr)
        customOnchangeCb && customOnchangeCb(fileOf)
      } else {
        // If the file is not uploaded to the server, remove it from the local array
        selectedItemRemovedBlobArr = fromArr.filter(
          ({ blob }) => blob !== f.blob
        );
      }
      // Call the callback function to update the state with the new array
      cb(selectedItemRemovedBlobArr);
    } catch (error) {
      console.log("unable to remove the file")
    } finally {

      setValue(name, selectedItemRemovedBlobArr, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
      setisFileUploading(false);

    }
  };

  return {
    uploadHandler,
    removeHandler,
    onDrop,
    imgFiles,
    setimgFiles,
    setotherFiles,
    otherFiles,
    isFileUploading,
  };
};

export default useRfhFileUploadFieldMethods;
